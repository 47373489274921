import React, {Component} from 'react'
import {Badge, Img, Button, Actor} from '../../../element'
import { base, mainUrl } from '../../../constant/Data'
import {ValidationService, UserService, OrderService, MiscService, RouterService, MetaService, DataService} from '../../../service'
import {Table} from '../../../component'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import {activeDateFilter} from '../../../constant/Enum'
const animatedComponents = makeAnimated();
const coS = new DataService('companyOrder'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService(),
oS = new OrderService()
export default class MyOrders extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            activeDateFilter: activeDateFilter[0],
            activeBranches: [],
            branches: [],
            tableData: null,
            isCompanyBranch: false,
            orderData: null,
            activeRecommendation: null
        }
    }
    componentDidMount () {
        mS.setTitle('My Orders', 'public')
        let orgId = false
        if (this.user?.role === 'representative') {
            orgId = this.user.parent?.value
            this._getCompanyBranches(orgId)
        } else if (this.user?.role === 'company') {
            orgId = this.user.uid
            this._getCompanyBranches(orgId)
        } else {
            if (['manager', 'company', 'branch', 'representative'].includes(this.user?.role))
                this.setState({ isCompanyBranch: true }, () => this._getOrdersData(true))
            else
                this._getOrdersData()
        }
    }
    _getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        this._getOrdersData(true, activeBranchIds)
    }
    _getCompanyBranches = async v => {
        let query = {
            parentId: v
        }, branches = [], { activeDateFilter } = this.state
        const result = await uS.getCompanyBranches({ query: JSON.stringify(query) })
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            });
            let activeBranches = uS.getActiveBranches() === null ? uS.getActiveBranches() : branches
            this.setState({ branches, activeBranches }, () => {
                uS.setGlobalActiveBranches(activeBranches)
                this._getDashboardData(activeDateFilter, activeBranches)
            })
        }
    }
    _getOrdersData = async (isCompanyBranch, branches = false) => {
        if (isCompanyBranch) {
            let query = {
                ownerId: ['manager','representative'].includes(this.user.role) ? this.user.parent?.value : this.user.uid
            }
            if (branches) {
                query.ownerId = {
                    $in: branches
                }
            }
            const result = await coS.fetchByAttr({ query: JSON.stringify(query) })
            if (result.status) {
                if (result.data.length === 0) {
                    this.setState({ orderData: null })
                } else {
                    let orderData = []
                    result.data.reverse().forEach(e => {
                        if (e.carDetails && e.carDetails !== "undefined" && vS.isValidJson(e.carDetails) && e.orderData && vS.isValidJson(e.orderData)) {
                            e.carDetails = vS.isValidJson(e.carDetails) ? JSON.parse(e.carDetails) : false
                            e.carImages = vS.isValidJson(e.carImages) ? JSON.parse(e.carImages) : false
                            e.orderData = vS.isValidJson(e.orderData) ? JSON.parse(e.orderData) : false
                            orderData.push(e)
                        }
                    })
                    this.setState({ orderData })
                }
            }
        } else {
            let query = {
                uid: this.user.uid
            }
            const result = await oS.getUserOrders({ query: JSON.stringify(query) })
            if (result.status) {
                if (result.data.length === 0)
                    this.setState({ tableData: [] })
                else
                    this.setState({ tableData: result.data.reverse() })
            }
        }
    }
    _getOrderTableData = filterData => {
        let data = []
        filterData?.forEach(( item ) => {
            let e = {
                orderId: `#${item.uid}`,
                name: JSON.parse(item.userData).name,
                timing: miS.getFormattedDate(item.timing),
                status: <Badge item={item.status} />,
                cartTotal: JSON.parse(item.orderData).discount ?
                    <div>₹ {(JSON.parse(item.orderData).cartTotal - JSON.parse(item.orderData).discount).toFixed(2)}</div>:
                    <div>₹ {(JSON.parse(item.orderData).cartTotal).toFixed(2)}</div>,
                action: <div className="action-bar">
                    <Actor type='view' onClick={() => rS.navigateTo('/order/view/'+item.uid)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _browsePurchase = item => {
        if (typeof item.carDetails.carData === 'string') {
            item.carDetails.carData = JSON.parse(item.carDetails.carData)
        }
        this.setState({activeRecommendation: item}, () => miS.openModal('recommendation-modal'))
    }
    _getRecommendationsTableData = filterData => {
        let data = []
        filterData?.forEach(( e ) => {
            let car = e.carDetails
            let carData = typeof car.carData === 'string' ? JSON.parse(car.carData) : car.carData
            let product = e.orderData[0]
            let el = {
                orderOd: `#${e.uid}`,
                actionDate: miS.getFormattedDate(e.actionDate),
                car: <div className='flex'>
                        {
                            carData?.image_path && <Img alt="Blank Placeholder" src={base.carImageBase+carData.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />
                        }
                        <div className='fs12'>
                            {(car.registrationNo).toUpperCase()}<br />
                            {carData.name+' '+carData.Segment}
                        </div>
                    </div>,
                image: <div className='flex'>
                        {product.image && <Img src={base.imageBaseUrl+JSON.parse(product.image)[0]} alt={product.title} style={{ width: 50, borderRadius: 5, marginRight: 5, marginBottom: 'auto' }} />}
                        { product.title ? product.title : product.value }
                    </div>,
                status: <Badge item={e.status} />,
                action: <div className="action-bar">
                    <Actor type='view' onClick={() => this._browsePurchase(e)} />
                    { e.invoiceNo && <Actor type='download' onClick={() => rS.navigateTo(base.invoiceBaseUrl+"/invoice_"+e.invoiceNo+".pdf", true)} /> }
                </div>
            }
            data.push(el)
        })
        return data
    }
    render() {
        let { activeBranches, activeDateFilter, branches, tableData, orderData, activeRecommendation, activeShownTotal} = this.state
        let tableRecommendationContent = {
            headers: [
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Date',
                    key: 'date'
                },
                {
                    label: 'Car',
                    key: 'car'
                },
                {
                    label: 'Product / Service',
                    key: 'product_Service'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: this._getRecommendationsTableData(orderData)
        }
        let tableOrderContent = {
            headers: [
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Date',
                    key: 'date'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Total',
                    key: 'total'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getOrderTableData(tableData)
        }
        return <>
            <div id="content-box" className="content">
                <div className='flex overflow-visible'>
                    <h1 className="nowrap mr20">My Orders</h1>
                    {
                        ['company', 'representative'].includes(this.user.role) && branches.length > 0 &&
                        <Select
                            isClearable
                            closeMenuOnSelect={false}
                            component={animatedComponents}
                            placeholder='Select Company'
                            defaultValue={activeBranches}
                            onChange={e => {
                                this.setState({ activeBranches: e }, () => {
                                    uS.setGlobalActiveBranches(e)
                                    this._getDashboardData(activeDateFilter, e)
                                })
                            }}
                            options={branches}
                            isMulti
                        />
                    }
                </div>
                <div className="order-table">
                    <Table
                        data={orderData ? tableRecommendationContent : tableOrderContent}
                        pageSize={activeShownTotal}
                    />
                </div>
            </div>
            <div className="modal" id="recommendation-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content content medium">
                    <Button className="close" onClick={() => miS.closeModal('recommendation-modal')}><i className="hi-close"></i></Button>
                    {activeRecommendation && <div className="modal-body p20">
                        <h3>{['service', 'microService'].includes(activeRecommendation.type)?'Service':'Product'} Details</h3>
                        <div className='product-box flex'>
                        {activeRecommendation.image && <Img className={"mbauto"} src={base.imageBaseUrl+JSON.parse(activeRecommendation.image)[0]} alt={activeRecommendation.title} style={{ width:'100%', borderRadius: 5, marginRight: 5, maxHeight: 240 }} />}
                            <div className='description pl20'>
                                <h4>{
                                    activeRecommendation.title ?
                                    <>{
                                        activeRecommendation.title ?
                                        <span>{ activeRecommendation.title}{activeRecommendation.fuelQuantity && activeRecommendation.fuelType?.value && ' ('+activeRecommendation.fuelQuantity+' lt. '+activeRecommendation.fuelType?.value+')'}</span>
                                        : activeRecommendation.value
                                    }</>
                                    : activeRecommendation.value
                                }</h4>
                                {activeRecommendation.title && <div className="content-detail pb40" dangerouslySetInnerHTML={{__html: activeRecommendation.excerpt}}></div>}
                            </div>
                        </div>
                        <hr />
                        <h3>Car Details</h3>
                        <div className='flex'>
                            {
                                activeRecommendation.carDetails.carData.image_path?
                                <Img alt="Blank Placeholder" src={base.carImageBase+activeRecommendation.carDetails.carData.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                                <p>No Image</p>
                            }
                            <div className='fs12'>
                                {activeRecommendation.carDetails.registrationNo.toUpperCase()}<br />
                                {activeRecommendation.carDetails.carData.name+' '+activeRecommendation.carDetails.carData.Segment}
                            </div>
                        </div>
                        <h3>Recent Car Images</h3>
                        <ul className='car-images'>
                            {
                                activeRecommendation.carImages.map((e, i) => {
                                    let img = (typeof e === 'object') ? 'data:image/jpg;base64,'+e.data : base.imageBaseUrl+e
                                    return <Img hasZoom key={i} src={img} alt={activeRecommendation.title} style={{ maxWidth: 120, borderRadius: 5 }} />
                            })
                            }
                        </ul>
                        <hr />
                        <h3>Service Guy Comments</h3>
                        <p>{activeRecommendation.comments}</p>
                    </div>}
                </div>
            </div>
        </>
    }
}