import React, {Component} from 'react'
import {base} from '../../../constant/Data'
import {TrashConfirmModal, Table} from '../../../component'
import {DataService, MiscService, RouterService, MetaService} from '../../../service'
import {Img, Button, Actor} from '../../../element'
const dS = new DataService('testimonial'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class TestimonialList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            filteredList: [],
            showTrashConfirm: false,
            isMultipleDelete: false,
            checkedList: [],
            checkedAll: false    
        }
    }
    componentDidMount () {
        mS.setTitle('Testimonials List', 'admin')
        this._getTestimonialsData()
    }
    _cloneTestimonial = async item => {
        let testimonialObject = {
            content: item.content,
            name: item.name,
            image: item.image
        }
        testimonialObject.uid = new Date().getTime()
        const result = await dS.save(testimonialObject)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'Testimonial clone successfully!' })
            this._getTestimonialsData()
        } else miS.showAlert({ type: 'error', msg: 'Unable to clone testimonial!' })
     }
    _getTestimonialsData = async () => {
        const result = await dS.fetchAll()
        if (result.status) {
            let tableData = result.data.reverse();
            this.setState({ tableData, filteredList: tableData })
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No testimonial added yet!' })
            }
        } else miS.showAlert({ type: 'error', msg: 'No testimonial added yet!' })
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _trashTestimonial = async () => {
        let { activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        const result = await dS.delete({ query: JSON.stringify(query) })
        if (result.status) {
            this._getTestimonialsData()
            miS.showAlert({ type: 'success', msg: 'Testimonial deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else miS.showAlert({ type: 'error', msg: 'Unable to delete testimonial!' })
    }
    _getTableData = filterData => {
        let data = [], {checkedAll} = this.state
        filterData.map(( item, index ) => {
            let e = {
                checkAction: <span className="check-all">
                    {
                        checkedAll ?
                        <span onClick={() => this.setState({ checkedAll: !checkedAll })}>
                            <i className="orange hi-check-circle"></i>
                        </span>:
                        <span onClick={() => this.setState({ checkedAll: !checkedAll })}>
                            <i className="hi-circle"></i>
                        </span>
                    },
                </span>,
                image: item.image?
                    <Img alt={index.content} src={base.imageBaseUrl+JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>,
                content: item.content,
                action: <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    <Actor type='edit' onClick={() => rS.navigateTo('/admin/testimonial/edit/'+item.uid)} />
                    <Actor type='copy' onClick={() => this._cloneTestimonial(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _renderFilterList = filterData => {
        return filterData.map(( item, index ) => {
            return <span className={ index===0 ? 'active link' : 'item link' } key={index}>{item.item } ({item.count})</span>
        })
    }
    render() {
        let { tableData, filterList, checkedAll, showTrashConfirm, isMultipleDelete, filteredList, checkedList, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                        {
                            checkedAll || checkedList.length === filteredList.length?
                            <i className="orange hi-check-circle"></i>:
                            <i className="hi-circle"></i>
                        }
                    </span>
                },
                {
                    label:'Image',
                    key: 'image'
                },
                {
                    label:'Content',
                    key: 'content'
                },
                {
                    label:'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(tableData)
        }
        return <>
            <h1 className="title flex">
                All Testimonials
                <a className="btn btn-primary btn-small ml20" href="/admin/testimonial/add">Add</a>
                {
                    (checkedList.length !== 0 || checkedAll) &&
                    <Button type="button" onClick={() => {
                        this.setState({ isMultipleDelete:true, showTrashConfirm: true})
                    }} className="btn btn-outline btn-small">Delete</Button>
                }
            </h1>
            <div className="filter-box">
                <div className="filters">
                    {this._renderFilterList(filterList || [] )}
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._trashTestimonial()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}