import React, {Component} from 'react'
import {Banner} from '../../../widget'
import {MiscService, RouterService, DataService, MetaService} from '../../../service'
import {Button} from '../../../element'
import { brandInfo } from '../../../constant/Data'
const dS = new DataService('feedback'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class Feedback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            testimonialList: [],
            uid: '',
            orderId: '',
            rating: '',
            submitDate: '',
            comments: ''
        }
    }
    componentDidMount () {
        mS.setTitle('Give Feedback', 'public')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        this.setState({ orderId: urlData[2] })
    }
    _feedBackSubmit = async e => {
        e.preventDefault()
        let { rating, orderId, comments } = this.state,
        error = false,
        errorMsg = '',
        feedbackObject = {
            rating: rating,
            orderId: orderId,
            submitDate: new Date(),
            comments: comments
        }
        if (rating === '') {
            errorMsg = 'Please give a rating'
            error = true
        }
        if (!error) {
            feedbackObject.uid = new Date().getTime()
            const result = await dS.save(feedbackObject)
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Your feedback has been submited successfully.' })
                setTimeout(() => {
                    rS.navigateTo('/home')
                }, 2000)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to submit your feedback' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { comments, rating } = this.state
        return <>
            <Banner title="Feedback" image="blue-shutter-1.jpg" />
            <div className="feedback-page mt40 mb40">
                <div className="jumbo-container">
                    <div className="heading-box flex mb20">
                        <p className="star-icon"><i className="hi-star-o"></i> </p>
                        <h1>Feedback</h1>
                    </div>
                    <div className="title"><b>Service: </b> Pick & Drop</div>
                    <div className="body-feedback mb20">
                        <p className="mb20">How likely are you to recommend {brandInfo.name.capital} to your friends and colleagues ?</p>
                        <form onSubmit={this._feedBackSubmit}>
                            <div className="rating-grid-box mb20">
                                <span className={rating === 1?'rate1 active': 'rate1'} onClick={() => this.setState({ rating: 1})}>1</span>
                                <span className={rating === 2?'rate2 active': 'rate2'} onClick={() => this.setState({ rating: 2})}>2</span>
                                <span className={rating === 3?'rate3 active': 'rate3'} onClick={() => this.setState({ rating: 3})}>3</span>
                                <span className={rating === 4?'rate4 active': 'rate4'} onClick={() => this.setState({ rating: 4})}>4</span>
                                <span className={rating === 5?'rate5 active': 'rate5'} onClick={() => this.setState({ rating: 5})}>5</span>
                            </div>
                            <p>Any Other Feedback ?</p>
                            <textarea className="textarea-feedback" placeholder="Type something.." value={comments} onChange={(e) => this.setState({ comments: e.target.value })}></textarea>
                            <Button type="submit" className="btn btn-primary">Submit</Button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    }
}