const MsgModel = [
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'sender',
        dataType: String
    },
    {
        key: 'receiver',
        dataType: String
    },
    {
        key: 'message',
        dataType: String
    },
    {
        key: 'time',
        dataType: String
    },
    {
        key: 'room',
        dataType: String
    },
    {
        key: 'type',
        dataType: String
    }
]
export default MsgModel