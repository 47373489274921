const routes = [
    {
        "pathName": "/packages",
        "module": ["Ecommerce"],
        "component": "Pricing",
        "type": "public",
        "rejectRoles": ["manager", "company", "branch", "representative"],
        "mode": "static"
    },
    {
        "pathName": "/package/list",
        "module": ["Ecommerce"],
        "component": "MyPackages",
        "type": "postLogin",
        "rejectRoles": ["manager", "company", "branch", "representative"],
        "mode": "static"
    },
    {
        "pathName": "/package/view",
        "module": ["Ecommerce"],
        "component": "MyPackageDetail",
        "type": "postLogin",
        "mode": "dynamic"
    }
]
export default routes