import React from 'react'
import {Banner} from '../../../widget'
import {SubscriptionData} from '../../../component'
import {UserService, MetaService} from '../../../service'
import { brandInfo } from '../../../constant/Data'
const uS = new UserService(),
mS = new MetaService()
const Subscription = () => {
    mS.setTitle('User Subscriptions', 'public')
    return <>
        <Banner title="Subscriptions" image="header-car-back.jpg" />
        <div className="pricing-page subscription">
            <div className="jumbo-container">
                <div className="heading-section-pricing">
                    <h1 className="heading-table-price">{brandInfo.name.uppercase} CLUB</h1>
                    <p>Only applicable on adHoc service</p>
                    <SubscriptionData activeSubscription={uS.getActiveSubscription()} />
                </div>
            </div>
        </div>
    </>
}
export default Subscription