import React, { useState } from 'react'
import {UserService, MiscService} from '../../../service'
import {base} from '../../../constant/Data'
import {Button, Img} from '../../../element'
const uS = new UserService(),
miS = new MiscService(),

AdminHeader = props => {
    
    const _toggleThemeMode = () => {
        if (miS.getThemeMode() === "theme-light") {
            miS.setThemeMode("theme-dark")
        } else miS.setThemeMode("theme-light")
    },
    _toggleNav = () => {
        miS.toggleBodyClass('', '', 'closed')
    },
    _cacheClean = () => {
        window.location.reload(true)
    },
    user = uS.getLoggedInUserData(),
    [showUserNav, setshowUserNav] = useState(false),
    userProfileImage = '',
    _toggleUserNav = () => {
        setshowUserNav(!showUserNav)
    },

    _logout = () => {
        setshowUserNav(false)
        uS.logout('/login')
    }

    return <div className="header">
        <Button onClick={_toggleNav} className="nav-btn" title="Toggle Nav">
            <i className="hi-nav"></i>
        </Button>
        <Button onClick={_toggleThemeMode} className="theme-btn" title="Toggle Theme">
            <i className="hi-bulb"></i>
        </Button>
        <Button onClick={_cacheClean} className="cache-btn" title="Clear cache & Reload">
            <i className="hi-refresh"></i>
        </Button>
        <nav>
            { uS.isUserLoggedIn() && <div className="profile-box" onClick={_toggleUserNav}>
                {
                    userProfileImage ?
                    <i className="hi-user1"></i>:
                    <Img className="user-profile-picture" src={base.imageBaseUrl+JSON.parse(user?.image)[0]} alt={user?.name} />
                }
                <span title={user?.name}>{user?.name}</span>
            </div>}
            {
                showUserNav &&
                <ul>
                    <li title="Profile">
                        <a href="/admin/account">
                            <i className='hi-user1'></i>
                            <span>Profile</span>
                        </a>
                    </li>
                    <li title="Visit Site">
                        <a href="/">
                            <i className='hi-network'></i>
                            <span>Visit Site</span>
                        </a>
                    </li>
                    <li title="Logout">
                        <i onClick={_logout}>
                            <i className='hi-logout'></i>
                            <span>Logout</span>
                        </i>
                    </li>
                </ul>
            }
        </nav>
    </div>
}

export default AdminHeader