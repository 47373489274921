import React, {Component} from 'react'
import * as XLSX from 'xlsx'
import {Button} from '../../../element'
import {Box, Table} from '../../../component'
import {fuelOption} from '../../../constant/Enum'
import {CarService, MiscService, RouterService, MetaService, DataService} from '../../../service'
const make_cols = refstr => {
	let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
	for(var i = 0; i < C; ++i) o[i] = {name:XLSX.utils.encode_col(i), key:i}
	return o
},
SheetJSFT = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function(x) { return "." + x; }).join(",")
const dS = new DataService('carModel'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
cS = new CarService()
export default class ImportCars extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: {},
            data: [],
            cols: [],
            excelCarsList: [],
            fileImported: false,
            fileSelected: false,
            renderSlug: true,
            mode: 'add',
            importCarData: [],
            tableData: [],
            status: 'publish',
            publishDate: miS.getFormattedDate(new Date()),
            showTable: false,
            addedCars: 0,
            updatedCars: 0,
            importDone: false,
            carModels: []
        }
    }
    componentDidMount () {
        mS.setTitle('Import Cars', 'admin')
        this._getAllCarsListData()
        this._getCarModelListData()
    }
    _handleChange = e => {
        const files = e.target.files;
        if (files && files[0]) this.setState({ file: files[0], fileSelected: true })
    }
    _getCarModelListData = async () => {
        const result = await dS.fetchAll()
        if (result.status) {
            this.setState({ carModels: result.data })
        }
    }
    _handleFile = () => {
        miS.showPreloader()
        this.setState({ showTable: true})
        let excelCarsList = [],
        reader = new FileReader(),
        rABS = !!reader.readAsBinaryString
        reader.onload = e => {
            let bstr = e.target.result,
            wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true, cellDates:true }),
            wsname = wb.SheetNames[0],
            ws = wb.Sheets[wsname],
            data = XLSX.utils.sheet_to_json(ws)
            this.setState({ data, cols: make_cols(ws['!ref']) }, () => {
                data.forEach(e => {
                    excelCarsList.push(e)
                })
                this.setState({ excelCarsList: excelCarsList, fileImported: true }, miS.hidePreloader)
            })
        }
        if (rABS) {
            reader.readAsBinaryString(this.state.file)
        } else {
            reader.readAsArrayBuffer(this.state.file)
        }
    }
    _processCars = () => {
        let _this = this
        this.setState({showTable: false}, () => {
            let { excelCarsList, tableData } = _this.state,
            mode = 'add', activeItem = null
            excelCarsList.forEach(e => {
                tableData.forEach(item => {
                    if (String(e.uid) === item.uid) {
                        mode = 'edit'
                        activeItem = item;
                    }
                })
                _this._addCar(e, mode, activeItem)
            })
        })
    }
    _getAllCarsListData = async () => {
        const result = await cS.getAllCars()
        if (result.status) {
            let tableData = result.data.reverse()
            this.setState({ tableData })
        }
    }
    _addCar = async (item, mode, oldCar = null) => {
        let error = false, carData = {},
        { excelCarsList, carModels } = this.state,
        errorMsg = ''
        fuelOption.forEach(el => {
            if(el.label === item.fuelType)
                item.fuelType = el
        })
        carModels.forEach(el => {
            if(item.modelKey === el.modelKey) {
                carData = el
                item.carData = el
            }
        });
        if(typeof(item.ownerId) === Number) {
            item.ownerId = String(item.ownerId)
        }
        let carObject = {
            ownerId: item.ownerId,
            uid: item.uid,
            registrationNo: item.registrationNo,
            registrationDate: item.registrationDate,
            chassisNo: item.chassisNo ?? '',
            engineNo: item.engineNo ?? '',
            ownerName: item.ownerName ?? '',
            vehicleClass: item.vehicleClass ?? carData.Segment,
            maker: item.maker ?? carData.name,
            fitness: item.fitness,
            mvTax: item.mvTax,
            insurance: item.insurance,
            pucc: item.pucc,
            fuelType: JSON.stringify(item.fuelType),
            emission: item.emission,
            rcStatus: item.rcStatus,
            financed: item.financed ?? false,
            rcImage: item.rcImage,
            status: item.status ?? 'active',
            shoot360: item.shoot360 ?? false,
            shoot360Url: item.shoot360Url ?? '',
            modelKey: item.modelKey,
            carData: JSON.stringify(item.carData)
        }
        if (!error) {
            try {
                if (mode === 'add') {
                    carObject.uid = new Date().getTime()
                    const result = await cS.addCar(carObject)
                    if (result.status) {
                        miS.showAlert({ type: 'success', msg: 'Car added successfully!' })
                        this.setState({ importCarData: result })
                    } else {
                        miS.showAlert({ type: 'error', msg: 'Unable to add car!' })
                    }
                    if (item.registrationNo === excelCarsList[excelCarsList.length-1].registrationNo) {
                        this.setState({ showTable: false, importDone: true })
                    }
                } else {
                    if(typeof(oldCar.uid) === Number) {
                        oldCar.uid = String(oldCar.uid)
                    }
                    let data = {
                        query: JSON.stringify({uid: oldCar.uid}),
                        carData: JSON.stringify(carObject)
                    }
                    const result = await cS.updateCar(data)
                    if (result.status) {
                        miS.showAlert({ type: 'success', msg: 'Car updated successfully!' })
                        this.setState({ importCarData: result })
                    } else {
                        miS.showAlert({ type: 'error', msg: 'Unable to update car!' })
                    }
                    if (item.registrationNo === excelCarsList[excelCarsList.length-1].registrationNo) {
                        this.setState({ showTable: false, importDone: true })
                    }
                }
            } catch (error) {
                console.log('error', error);
            }
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item ) => {
            let e = {
                ownerId: item.ownerId,
                uid: item.uid,
                registrationNo: item.registrationNo,
                registrationDate: item.registrationDate,
                chassisNo: item.chassisNo,
                engineNo: item.engineNo,
                ownerName: item.ownerName,
                vehicleClass: item.vehicleClass,
                maker: item.maker,
                fitness: item.fitness,
                mvTax: item.mvTax,
                insurance: miS.getFormattedDate(item.insurance),
                pucc: item.pucc,
                fuelType: item.fuelType,
                emission: item.emission,
                rcStatus: item.rcStatus,
                financed: item.financed,
                rcImage: item.rcImage,
                status: item.status,
                shoot360: item.shoot360,
                shoot360Url: item.shoot360Url,
                modelKey: item.modelKey
            }
            data.push(e)
        })
        console.log('data', data)
        return data
    }
    render() {
        let { fileSelected, fileImported, excelCarsList, showTable, importDone, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'ownerId',
                    key: 'ownerId'
                },
                {
                    label: 'uid',
                    key: 'uid'
                },
                {
                    label: 'registrationNo',
                    key: 'registrationNo'
                },
                {
                    label: 'registrationDate',
                    key: 'registrationDate'
                },
                {
                    label: 'chassisNo',
                    key: 'chassisNo'
                },
                {
                    label: 'engineNo',
                    key: 'engineNo'
                },
                {
                    label: 'ownerName',
                    key: 'ownerName'
                },
                {
                    label: 'vehicleClass',
                    key: 'vehicleClass'
                },
                {
                    label: 'maker',
                    key: 'maker'
                },
                {
                    label: 'fitness',
                    key: 'fitness'
                },
                {
                    label: 'mvTax',
                    key: 'mvTax'
                },
                {
                    label: 'insurance',
                    key: 'insurance'
                },
                {
                    label: 'pucc',
                    key: 'pucc'
                },
                {
                    label: 'fuelType',
                    key: 'fuelType'
                },
                {
                    label: 'emission',
                    key: 'emission'
                },
                {
                    label: 'rcStatus',
                    key: 'rcStatus'
                },
                {
                    label: 'financed',
                    key: 'financed'
                },
                {
                    label: 'rcImage',
                    key: 'rcImage'
                },
                {
                    label: 'status',
                    key: 'status'
                },
                {
                    label: 'shoot360',
                    key: 'shoot360'
                },
                {
                    label: 'shoot360Url',
                    key: 'shoot360Url'
                },
                {
                    label: 'modelKey',
                    key: 'modelKey'
                },
            ],
            content: this._getTableData(excelCarsList)
        }
        return <>
                {
                    fileSelected?
                    <>
                        {
                            fileImported?
                            <>
                            {
                                !importDone &&
                                <Button type='submit' className="btn btn-primary mv20" onClick={this._processCars}>Import</Button>
                            }
                            </>:
                            <Button type='submit' className="btn btn-primary mv20" onClick={this._handleFile}>Parse</Button>
                        }
                    </>:
                    <Box title="Import Excel to Add Cars">
                        <input type="file" className="hidden form-control inputfile-1" id="file" accept={SheetJSFT} onChange={this._handleChange} />
                        <label className="btn btn-primary" htmlFor="file">
                            <i className="hi-upload-cloud"></i>
                            <span>Choose a file…</span>
                        </label>
                    </Box>
                }
                {
                    showTable?
                    <>
                        <Table
                            className="import-table"
                            data={tableContent}
                            pageSize={activeShownTotal}
                        />
                    </>
                    :<>
                        {
                            importDone &&
                            <>
                                <p>All Cars imported successfully</p>
                                <div className="flex">
                                    <a className="btn btn-primary" href="/admin/car/list/">Back to Cars List</a>
                                    <Button className="btn btn-outline ml20" onClick={() => rS.getLocationData().reload()}>Import More</Button>
                                </div>
                            </>
                        }
                    </>
                }
        </>
    }
}