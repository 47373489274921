import projectRoutes from './Project'
import offerRoutes from './Offer'
import chatbotRoutes from './Chatbot'
import bannerRoutes from './Banner'
import societyRoutes from './Society'
import productRoutes from './Product'
import orderRoutes from './Order'
import microServiceRoutes from './MicroService'
import serviceRoutes from './Service'
import campaignRoutes from './Campaign'
import carRoutes from './Car'
import garageRoutes from './Garage'
import taxClassRoutes from './TaxClass'
import userRoutes from './User'
import testimonialRoutes from './Testimonial'
import pageRoutes from './Page'
import postRoutes from './Post'
import notificationRoutes from './Notification'
import appRoutes from './App'
import settingRoutes from './Setting'

const adminRoutes = [
    ...settingRoutes,
    ...appRoutes,
    ...serviceRoutes,
    ...microServiceRoutes,
    ...projectRoutes,
    ...offerRoutes,
    ...chatbotRoutes,
    ...bannerRoutes,
    ...societyRoutes,
    ...productRoutes,
    ...orderRoutes,
    ...campaignRoutes,
    ...carRoutes,
    ...garageRoutes,
    ...taxClassRoutes,
    ...userRoutes,
    ...testimonialRoutes,
    ...pageRoutes,
    ...postRoutes,
    ...notificationRoutes,
    {
        "pathName": "/admin",
        "redirectTo": "/admin/dashboard",
        "mode": "static"
    },
    {
        "pathName": "/admin/dashboard",
        "component": "AdminDashboard",
        "type": "admin",
        "accessRoles": ["supervisor", "worker"],
        "mode": "static"
    },
    {
        "pathName": "/admin/report",
        "component": "Report",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/media",
        "component": "Media",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/complaint",
        "module": ["Complaint"],
        "component": "ComplaintAdmin",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/work/import",
        "module": ["Car"],
        "component": "ImportB2BWorkData",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/stock/import",
        "module": ["Car"],
        "component": "ImportB2BStockData",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/package/work/list",
        "module": ["Ecommerce"],
        "component": "PackageWorkListAdmin",
        "type": "admin",
        "accessRoles": ["worker", "supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/service/work/list",
        "module": ["Ecommerce"],
        "component": "ServiceWorkListAdmin",
        "type": "admin",
        "accessRoles": ["worker", "supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/company/work/list",
        "module": ["Ecommerce"],
        "component": "CompanyWorkListAdmin",
        "type": "admin",
        "accessRoles": ["worker", "supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/work/list",
        "module": ["Ecommerce"],
        "component": "WorkListAdmin",
        "type": "admin",
        "accessRoles": ["worker", "supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/work/list",
        "module": ["Ecommerce"],
        "component": "WorkListAdmin",
        "type": "admin",
        "accessRoles": ["worker", "supervisor"],
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/log/list",
        "module": ["Log"],
        "component": "LogList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/utils/sendmail",
        "component": "SendMail",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/faq/list",
        "module": ["Faq"],
        "component": "AdminFAQ",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/robots",
        "component": "Robots",
        "type": "admin",
        "mode": "static"
    }
]

export default adminRoutes