const FeedbackModel = [
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'rating',
        dataType: String
    },
    {
        key: 'orderId',
        dataType: String
    },
    {
        key: 'submitDate',
        dataType: String
    },
    {
        key: 'comments',
        dataType: String
    }
]
export default FeedbackModel