import React, {useState, useEffect} from 'react'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { mainUrl } from '../../constant/Data'
import {MiscService} from '../../service';
const miS = new MiscService(),
Share = props => {
    let {shareUrl} = props;
    let [url, seturl] = useState(mainUrl)
    useEffect(() => {
        seturl(shareUrl)
    }, [shareUrl, url])
    return <div className="share">
        <span className="share-text">{miS.translate('share')}</span>
        <div className="line-shape">
            <span className="bloget"></span>
        </div>
        <div className="share-icon">
            <ul className="icon-wrap">
                <li><FacebookShareButton url={mainUrl+shareUrl} children={<i className="hi-facebook3"></i>} /></li>
                <li><WhatsappShareButton url={mainUrl+shareUrl} children={<i className="hi-whatsapp"></i>} /></li>
                <li><TwitterShareButton url={mainUrl+shareUrl} children={<i classname="hi-twitter"></i>} /></li>
                <li><LinkedinShareButton url={mainUrl+shareUrl} children={<i classname="hi-linkedin"></i>} /></li>
            </ul>
        </div>
    </div>
}
export default Share