import React from 'react'
import { Button } from '../../cms/element'
import Otp from '../../cms/page/front/Otp'
import {MiscService, RouterService, UserService} from '../../cms/service'
const uS = new UserService(),
miS = new MiscService(),
rS = new RouterService()
Otp.prototype._resendOtp = async function(e) {
    let error = false,
    errorMsg = '',
    urlData = rS.urlToSplitData(rS.getLocationData().pathname),
    email= atob(urlData[2]).replace('new', '').replace('old', ''),
    userObject = {
        email
    }
    if (!error) {
        const result = await uS.sendOtp(userObject)
        if (result.status) {
            miS.showAlert({ type: 'info', msg: 'An OTP has been sent to your email address!' })
            let otpData = result.data[0]
            localStorage.setItem('otpData', JSON.stringify(otpData))
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to send OTP, try after some time.' })
    } else
        miS.showAlert({ type: 'error', msg: errorMsg })
}

Otp.prototype.render = function() {
    let { email } = this.state
    return <div className="login-page">
        <div className="left-box-login left-box-login-otp"></div>
        <div className="body-background flex">
            <div className="mauto p30 max-width-loginBox">
                <div className="loginIcon">
                    <i className='hi-keyboard'></i>
                </div>
                <h1 className="form-title tac">Please enter OTP received on your email {email}</h1>
                <form onSubmit={this._login} noValidate className="relative">
                    <div className="form-group">
                        <input autoFocus onChange={(e) => this.setState({ otp: e.target.value })} className="input" type="number" name="otp" placeholder="Enter OTP" maxLength={6} minLength={6} max={100000} min={999999} />
                    </div>
                    <Button type="submit " className="width-full btn btn-primary mt30">Proceed</Button>
                    <span className="link resend-otp" onClick={this._resendOtp}>Resend OTP</span>
                </form>
            </div>
            <i className="hi-arrow-circle-left footer-back-btn" onClick={() => {
                rS.navigateTo('/login')
            }}></i>
        </div>
    </div>
}