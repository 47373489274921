import React, {Component} from 'react'
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps'
const Markers = ({ places }) => {
    return places.map((place, index ) => {
        return <div key={index}>
            <Marker key={place.id} position={{ lat: place.lat, lng: place.lng }} />
        </div>
    })
},
Map = ({ places, zoom, center }) => {
    return <GoogleMap defaultZoom={zoom} defaultCenter={center}>
        <Markers places={places} />
    </GoogleMap>
}
class MapWithMarker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            places: []
        }
    }
    _addPlace = newPlace => {
        this.setState(prevState => ({
            places: [newPlace]
        }))
    }
    componentDidMount () {
        let props = this.props
        if (props.places.length !== 0) {
            this.setState({ places: props.places }, () => {
                this._addPlace(props.places[0])
            })
        }
    }
    componentDidUpdate (prevProps, prevState) {
        let props = this.props
        if (props.places.length !== this.state.places.length) {
            this.setState({ places: props.places }, () => {
                if (props.places.length !== 0) {
                    this._addPlace(props.places[0])
                }
            })
        }
    }
    render() {
        return <Map center={this.props.center} zoom={this.props.zoom} places={this.state.places} />
    }
}
export default withScriptjs(withGoogleMap(MapWithMarker))