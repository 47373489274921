import React, {Component} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {MiscService, DataService, RouterService, MetaService} from '../../../service'
import {Button, BackIcon} from '../../../element'
import {Box} from '../../../component'
const pS = new DataService('taxClass'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class AddTaxClass extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mode: 'Add',
            uid: '',
            className: '',
            amount: '',
            label: ''
        }
    }
    componentDidMount () {
        mS.setTitle('Add Tax Class', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getTaxClassData(query)
        }
    }
    _getTaxClassData = async query => {
        const result = await pS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let taxClassData = result.data[0]
            this.setState({
                mode: 'Edit',
                className: taxClassData.className,
                amount: taxClassData.amount,
                label: taxClassData.label,
                uid: query.uid
            })
        } else miS.showAlert({ type: 'error', msg: 'No taxClasss added yet!' })
    }
    _addTaxClass = async e => {
        e.preventDefault()
        let { mode, title, slug, className, amount, label, uid } = this.state,
        error = false,
        errorMsg = '',
        taxClassObject = {
            className,
            amount,
            label
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                taxClassObject.uid = new Date().getTime()
                const result = await pS.save(taxClassObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Tax Class added successfully!' })
                    rS.navigateTo('/admin/taxClass/list')
                } else miS.showAlert({ type: 'error', msg: 'Unable to add taxClass!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    taxClassData: JSON.stringify(taxClassObject)
                }
                const result = await pS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Tax Class updated successfully!' })
                    rS.navigateTo('/admin/taxClass/list')
                } else miS.showAlert({ type: 'error', msg: 'Unable to update taxClass!' })
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let {amount, className, mode, label } = this.state,
        pageTitle = mode + ' Tax Class'
        return <>
            <h1>
                <BackIcon backLink='/admin/taxClass/list' />
                {pageTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._addTaxClass}>
                <div className="left">
                    <Box title='Class Name' required>
                        <input autoFocus type="text" required name="className" value={className} onChange={e => this.setState({ className: e.target.value })} />
                    </Box>
                    <Box title='Amount' required>
                        <input type="number" minimum={1} required name="amount" value={amount} onChange={(e) => this.setState({ amount: e.target.value })} />
                    </Box>
                    <Box title='Label' required>
                        <input type="text" required name="label" value={label} onChange={e => this.setState({ label: e.target.value })} />
                    </Box>
                </div>
                <div className="right">
                    <Box title='Meta'>
                        <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                    </Box>
                </div>
            </form>
        </>
    }
}