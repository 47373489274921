import React, {Component} from 'react'
import {base} from '../../../constant/Data'
import {SubscriptionData} from '../../../component'
import {UserService, MiscService, DataService, MetaService} from '../../../service'
import {Img, Button} from '../../../element'
const dS = new DataService('media'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService()
export default class CompanyProfile extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            userData: this.user,
            confirmPassword: '',
            showProfileImageOptions: false,
            fileObj: [],
            fileArray: [],
            activeImage: base.imageBaseUrl+JSON.parse(this.user.image)[0]
        }
    }
    componentDidMount() {
        mS.setTitle('My Profile', 'public')
        this._getUserData()
    }
    _getUserData = async () => {
        if (['manager','representative'].includes(this.user.role)) {
            let query = {
                uid: String(this.user.parent?.value)
            }
            const result = await uS.getUser({ query: JSON.stringify(query) })
            if (result.status) {
                let userData = result.data[0]
                this.setState({ userData })
            }
        }
    }
    _selectFiles = e => {
        let _this = this, fileArray = [], fileObj = []
        fileObj.push(e.target.files)
        this.setState({ fileObj }, () => {
            for (let i = 0; i < _this.state.fileObj[0].length; i++) {
                miS.getBase64(_this.state.fileObj[0][i]).then(result => {
                    let fileData = {
                        url: URL.createObjectURL(_this.state.fileObj[0][i]),
                        data: result,
                        fileName: _this.state.fileObj[0][i].name
                    }
                    fileArray.push(fileData)
                    _this.setState({ file: fileArray, fileArray: fileArray, showProfileImageOptions: false }, this._uploadFiles)
                })
            }
        })
    }
    _uploadCall = async file => {
        let _this = this, error = false,
        { fileArray, userData } = this.state,
        errorMsg = ''
        if (!error) {
            file.uid = new Date().getTime()
            const fileResult = await dS.save(file)
            if (fileResult.status) {
                let userObject = {
                    image: '["'+fileArray[0].fileName+'"]'
                },
                data = {
                    query: JSON.stringify({uid: userData.uid}),
                    userData: JSON.stringify(userObject)
                }
                const result = await uS.updateUser(data)
                if (result.status) {
                    uS.login(userData).then(userObj=> {
                        if (userObj !== null) {
                            miS.showAlert({ type: 'success', msg: 'Profile image updated successfully!' })
                            _this.setState({ fileArray: [], file: [], activeImage: base.imageBaseUrl+JSON.parse(userObject.image)[0] }, () => {
                                userData.image = userObject.image
                                uS.login(userData)
                            })
                        }
                    })
                } else miS.showAlert({ type: 'error', msg: 'Unable to update profile image!' })
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to update profile image!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _uploadFiles = () => {
        let { file } = this.state
        for (let i = 0; i < file.length; i++) {
            this._uploadCall(file[i])
        }
    }
    _updateProfile = async e => {
        e.preventDefault()
        let { userData } = this.state,
        error = false,
        errorMsg = '',
        userObject = userData
        if (userData.email === '') {
            errorMsg = 'Please enter user email!'
            error = true
        } else if (userData.name === '') {
            errorMsg = 'Please enter the Name'
            error = true
        } else if (userData.mobile === '') {
            errorMsg = 'Please enter Mobile Number'
            error = true
        }
        delete userData._id
        if (!error) {
            let data = {
                query: JSON.stringify({uid: userData.uid}),
                userData: JSON.stringify(userObject)
            }
            const result = await uS.updateUser(data)
            if (result.status) {
                uS.login(userData).then(userObj=> {
                    if (userObj !== null)
                        miS.showAlert({ type: 'success', msg: 'Profile updated successfully!' })
                })
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to update profile!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _updatePassword = async e => {
        e.preventDefault()
        let { userData, confirmPassword } = this.state,
        error = false,
        errorMsg = '',
        userObject = userData
        if (userData.password === '') {
            errorMsg = 'Please enter the Password'
            error = true
        } else if (userData.password !== confirmPassword) {
            errorMsg = 'Confirm Password should be similar to Password'
            error = true
        }
        if (!error) {
            let data = {
                query: JSON.stringify({uid: userData.uid}),
                userData: JSON.stringify(userObject)
            }
            const result = await uS.updateUser(data)
            if (result.status) {
                uS.login(userData).then(userObj=> {
                    if (userObj !== null)
                        miS.showAlert({ type: 'success', msg: 'Profile updated successfully!' })
                })
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to update profile!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _profileImageOptions = () => {
        let { showProfileImageOptions } = this.state
        this.setState({ showProfileImageOptions: !showProfileImageOptions })
    }
    _viewPhoto = () => {
        this.setState({ showProfileImageOptions: false }, () => miS.openModal('preview-modal'))
    }
    render() {
        let { userData, showProfileImageOptions, activeImage } = this.state
        return <>
            <div className="right-side-profile-content">
                <h1>Profile</h1>
                <div className="profile-sec wrapper">
                    <div className="profile-name-section">
                        <div className="form-box">
                            <div className="profile-avatar">
                                <div className="profile-image">
                                    <Img src={activeImage} alt="active"/>
                                    <div className="profile-image-hover-shadow" onClick={this._profileImageOptions}>
                                        <div className="profile-image-hover-icon"><i className="hi-camera"></i></div>
                                        <div className="profile-image-hover-text">Change profile Photo</div>
                                    </div>
                                    {
                                        showProfileImageOptions &&
                                        <div className="profile-upload-option">
                                            <ul>
                                                <li onClick={this._viewPhoto}>View Image</li>
                                                <li><label htmlFor="choose-file">Upload Image</label></li>
                                            </ul>
                                            <input type="file" className="hidden" onChange={this._selectFiles} id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/png,image/webp,image/jpg" />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="name-sec">
                                <h4>{userData.name}</h4>
                                <p>{userData.email}</p>
                            </div>
                            {
                                uS.getActiveSubscription() !== null &&
                                <div className="subscription-dashbord">
                                    <p className="subs-heading">Active Subscription : </p>
                                    <span className="pointer" onClick={() => miS.openModal("get-subscription-modal")}>{uS.getActiveSubscription()}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="form-editable-section">
                        <div className="form-box">
                            <div className="account-detail">
                                <div className="col-9">
                                    <h3>Account Details</h3><hr/>
                                    {!['manager', 'company', 'branch', 'representative'].includes(userData.role) ? <form noValidate onSubmit={this._updateProfile}>
                                        <div className="form-group">
                                            <label className="col-3 control-label">Email <sup>*</sup></label>
                                            <div className="col-9 controls">
                                                <input placeholder="email@yourcompany.com" type="email" className="input" required name="email" value={userData.email||''} onChange={e => {
                                                    userData.email = e.target.value
                                                    this.setState({ userData: userData })
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label">Name <sup>*</sup></label>
                                            <div className="col-9 controls">
                                                <input placeholder="Full Name" type="text" className="input" required name="name" value={userData.name||''} onChange={e => {
                                                    userData.name = e.target.value
                                                    this.setState({ userData: userData })
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label">GSTIN</label>
                                            <div className="col-9 controls">
                                                <input placeholder="GSTIN" type="text" className="input" required name="gstin" value={userData.gstin||''} onChange={e => {
                                                    userData.gstin = e.target.value
                                                    this.setState({ userData: userData })
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label">Mobile Phone <sup>*</sup></label>
                                            <div className="col-9 controls">
                                                <p className="input">{userData.mobile}</p>
                                            </div>
                                        </div>
                                        <Button type="submit" className="btn-primary  btn btn-submit-profile">Submit</Button>
                                    </form>:
                                    <>
                                        <div>
                                            <label className="bold">Email: </label>
                                            <p>{userData.email}</p>
                                        </div>
                                        <div>
                                            <label className="bold">Name: </label>
                                            <p>{userData.name}</p>
                                        </div>
                                        <div>
                                            <label className="bold">GSTIN: </label>
                                            <p>{userData.gstin}</p>
                                        </div>
                                        <div>
                                            <label className="bold">Mobile: </label>
                                            <p>{userData.mobile}</p>
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal preview-modal" id="preview-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content">
                    <Button className="close" onClick={() => miS.closeModal("preview-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body">
                        <Img src={activeImage} className="mauto" alt="preview" />
                    </div>
                </div>
            </div>
            <div className="modal" id="get-subscription-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content big">
                    <Button className="close" onClick={() => miS.closeModal("get-subscription-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        <SubscriptionData activeSubscription={uS.getActiveSubscription()} />
                    </div>
                </div>
            </div>
        </>
    }
}