import React, { useState } from 'react'
import {adminNav as nav} from '../../../constant/Enum'
import {MiscService, RouterService, UserService} from '../../../service'
import {brandInfo} from '../../../constant/Data'
import {Img, Button} from '../../../element'
import routes from '../../../router'

const uS = new UserService(),
miS = new MiscService(),
rS = new RouterService()

const AdminSidebar = () => {
    const [navFilter, setnavFilter] = useState('')
    const user = uS.getLoggedInUserData()
    const adminNav = nav.filter((item) => {
        let allowed = false
        routes.forEach(e => {
            if (['admin', 'developer'].includes(user?.role)) {
                allowed = true
            } else if (e.type === "admin") {
                item.subNav?.forEach(s => {
                    if (s.subNav) {
                        s.subNav.forEach(c => {
                            if ('/admin'+miS.getPath(c.url) === e.pathName) {
                                if (e.accessRoles?.includes(user?.role))
                                    allowed = true
                                if (c.accessRoles) {
                                    if (c.accessRoles.includes(user?.role))
                                        allowed = true
                                } else if (c.rejectRoles) {
                                    if (!c.rejectRoles.includes(user?.role))
                                        allowed = true
                                }
                            }
                        })
                    } else if ('/admin'+miS.getPath(s.url) === e.pathName) {
                        if (e.accessRoles?.includes(user?.role))
                            allowed = true
                        if (s.accessRoles) {
                            if (s.accessRoles.includes(user?.role))
                                allowed = true
                        } else if (s.rejectRoles) {
                            if (!s.rejectRoles.includes(user?.role))
                                allowed = true
                        }
                    }
                })
            } else if ('/admin'+miS.getPath(item.url) === e.pathName) {
                if (e.accessRoles?.includes(user?.role))
                    allowed = true
            }
        });
        return allowed
    })

    const _toggleActiveClass = e => {
        e.target.parentElement.classList.toggle("active")
    }

    const _renderAdminSideBar = (adminNavList, navId = false) => {
        let activePath = rS.getLocationData().pathname
        return adminNavList.map(( item, index ) => {
            let hasActiveChild = false
            if (item.subNav) {
                item.subNav.forEach(e => {
                    hasActiveChild = activePath === '/admin'+e.url
                })
            }
            if (navId)
                index = navId+'-'+index
            return <li key={index} className={((activePath === '/admin'+item.url) || hasActiveChild)?'active': ''} id={'nav-id-'+index}>
                {
                    ((activePath === item.url) || hasActiveChild) && <span className="upper-link"></span>
                }
                {
                    item.subNav ?
                    <Button onClick={_toggleActiveClass} title={item.title}>
                        <i className={item.icon}></i>
                        <span>{item.label}</span>
                        <i className='hi-chevron-bottom'></i>
                    </Button>:
                    <a href={'/admin'+item.url} title={item.title}>
                        <i className={item.icon}></i>
                        <span>{item.label}</span>
                    </a>
                }
                {
                    item.subNav &&
                    <ul className="sub-nav">
                        {_renderAdminSideBar(item.subNav || [], index )}
                    </ul>
                }
                {
                    ((activePath === item.url) || hasActiveChild) && <span className="bottom-link"></span>
                }
            </li>
        })
    }
    const _filter = e => {
        return e.toLowerCase().includes(navFilter.toLowerCase())
    }
    const _renderAnywhereNav = (routes, parentIndex = false) => {
        return routes.map((item, index) => {
            return <li className='' key={ parentIndex ? parentIndex+'_'+index : index}>
                { _filter(item.label) && <>{parentIndex ? <i className="hi-subdirectory_arrow_right"></i>: ''}</>}
                { _filter(item.label) && <>{item.url ? <a href={item.url}>
                    { item.label }
                </a> : <span>{ item.label }</span>}</>}
                {item.subNav && <ul className='sub-nav'>
                    { _renderAnywhereNav(item.subNav || [], index) }
                </ul>
                }
            </li>
        })
    }
    return <div className="sidebar">
        <a href="/admin" className="logo-link">
            <Img alt="logo" src={brandInfo.logo} />
        </a>
        <nav className="sidebar-nav">
            <ul className="nav">
                <li id="nav-id-goto-0">
                    <Button onClick={() => miS.openModal("anywhere-modal")} title='Go to...'>
                        <i className='hi-search'></i>
                        <span>Go to...</span>
                    </Button>
                </li>
                { _renderAdminSideBar(adminNav) }
            </ul>
        </nav>
        <div className="modal" id="anywhere-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content medium">
                <div className="modal-header">
                    <h2 className="heading">
                        <i className="hi-search"></i>
                        Go to...
                    </h2>
                    <Button className="close" onClick={() => miS.closeModal("anywhere-modal")}><i className="hi-close"></i></Button>
                </div>
                <div className="modal-body p20">
                    <form className="search-form" noValidate onSubmit={e => e.preventDefault}>
                        <input type="text" required name="searchNavKey" value={navFilter} onChange={e => setnavFilter(e.target.value)} />
                    </form>
                    <ul className='anywhere-nav'>
                        { _renderAnywhereNav(adminNav) }
                    </ul>
                </div>
            </div>
        </div>
    </div>
}

export default AdminSidebar