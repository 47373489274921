import React, { useEffect, useState } from "react"
import {Button} from "../../element"

const Modal = ({
    id = new Date().getTime(),
    children = 'This is the default Modal Content',
    dismissable = true,
    showCancel = true,
    showSuccess = true,
    onSuccess = () => {
        // console.log('onSuccess modal')
    },
    onCancel = () => {
        // console.log('onCancel modal')
    },
    showModal = false
}) => {
    const [show, setshow] = useState(showModal),
    onClose = () => {
        // console.log('onClose modal')
    },
    onOpen = () => {
        // console.log('onOpen modal')
    }
    useEffect(() => {
        if (show)
            onOpen()
    },[])
    useEffect(() => {
        setshow(showModal)
    },[showModal])
    useEffect(() => {
        if (!show)
            onClose()
    },[show])
    return <div className={showModal ? "modal open" : "modal"} id={id}>
        <div className="modal-backdrop"></div>
        <div className="modal-content small">
            {dismissable && <Button className="close" onClick={() => {
                onCancel()
                setshow(false)
            }}><i className="hi-close"></i></Button>}
            <div className="modal-header"></div>
            <div className="modal-body">
                {children}
            </div>
            {(showSuccess || showCancel) && <div className="modal-footer">
                {showSuccess && <Button className="btn btn-primary" onClick={() => {
                    onSuccess()
                    setshow(false)
                }}>Delete</Button>}
                {showCancel && <Button className="btn btn-outline" onClick={() => {
                    onCancel()
                    setshow(false)
                }}>Cancel</Button>}
            </div>}
        </div>
    </div>
}

export default Modal