import React, {Component} from 'react'
import {base, brandInfo, mainUrl} from '../../../constant/Data'
import {orderOption} from '../../../constant/Enum'
import {OrderService, RouterService, MiscService, DataService, MetaService} from '../../../service'
import {Img, Button, Badge, BackIcon} from '../../../element'
import {Box, Table} from '../../../component'
const dS = new DataService('feedback'),
sWS = new DataService('serviceWork'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService()
export default class MyOrderDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderContent: null,
            note: '',
            status: '',
            invoiceData: [],
            feedbackContent: null,
            serviceWorkAwaitedStatus: false,
            serviceData: '',
            servicework: null
        }
    }
    componentDidMount () {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        query = {
            uid: urlData[3]
        }
        mS.setTitle('Order Details #'+urlData[3], 'public')
        this._getInvoice(urlData[3])
        this._getOrderData(query)
        this._getOrderFeedback(urlData[3])
    }
    _getInvoice = async orderId => {
        let query = {
            orderId: orderId
        }
        const result = await oS.getOrderInvoice({ query: JSON.stringify(query) })
        if (result.status)
            this.setState({ invoiceData: result.data })
        else
            miS.showAlert({ type: 'error', msg: 'No order placed yet!' })
    }
    _getOrderData = async query => {
        const result = await oS.getOrder({ query: JSON.stringify(query) })
        if (result.status) {
            let orderContent = result.data[0]
            this.setState({ orderContent })
        }
    }
    _getOrderFeedback = async orderId => {
        let query = {
            orderId: orderId
        }
        const result = await dS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length > 0) {
                let feedbackContent = result.data[0]
                this.setState({ feedbackContent })
            }
        }
    }
    _getTableData = filterData => {
        let data = [], { orderContent } = this.state
        filterData.forEach(( item ) => {
            let activeStatus = ''
            if (orderContent.orderNotes) {
                orderContent.orderNotes.forEach(e => {
                    if (e.tempUid === item.tempUid) {
                        orderOption.forEach(el => {
                            if (el.value === e.status) {
                                activeStatus = el.label
                            }
                        })
                    }
                })
            }
            let e = {
                image: <div className="product-thumbnail">
                {
                    item.type === 'servicePackage'?
                    <i className="hi-dropbox service-package-icon"></i>:
                    item.type === 'subscription'?
                    <i className="hi-block service-package-icon"></i>:
                    <Img src={base.imageBaseUrl+item.image} alt={item.title} />
                }
                </div>,
                title: <>{item.title}
                    {
                        item.type === 'service' &&
                        <p className="active-time-slot"><b>Time Slot:</b> {(item.activeTimeSlot).slot}</p>
                    }
                </>,
                price: <span className="Price-amount amount">₹ {Number(item.price).toFixed(2)}</span>,
                purchaseQuantity: <div className="quantity">
                    {item.purchaseQuantity}
                </div>,
                subTotal: <div className="product-subtotal" data-title="Subtotal">
                    ₹ {Number((item.taxAmount+item.price) * item.purchaseQuantity).toFixed(2)}
                    {
                        item.type === 'service' &&
                        <p className="status-btn" onClick={() => this._getCompletedWorkData(item.tempUid)}>Status</p>
                    }
                    {
                        activeStatus !== '' &&
                        <p className="status-box"><label>{activeStatus}</label></p>
                    }
                </div>
            }
            data.push(e)
        })
        return data
    }
    _renderNotes = orderNotes => {
        let { orderContent } = this.state
        return orderNotes.map(( item, index ) => {
            let activeLabel = '', orderData = null
            if (item.tempUid) {
                if (orderContent !== null)
                    orderData = JSON.parse(orderContent.orderData)
                if (orderData !== null) {
                    orderData.cartData.forEach(e => {
                        if (e.tempUid === item.tempUid)
                            activeLabel = e.title
                    })
                }
            }
            return <li rel="102" className="note system-note" key={index}>
                <div className="note-content">
                    {
                        activeLabel!=='' && <label>{activeLabel}</label>
                    }
                    <p>{item.note}</p>
                </div>
                <p className="note-meta">
                    <abbr className="exact-date">{miS.getFormattedDate(item.timing)}</abbr>
                </p>
                <p className="note-status">
                    <Badge item={item.status} />
                </p>
            </li>
        })
    }
    _getCompletedWorkData = async tempUid => {
		let query = {
			tempUid: String(tempUid)
		}
        const result = await sWS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length>0)
                this.setState({ serviceWorkAwaitedStatus: false, servicework: result.data[result.data.length-1] })
            else
                this.setState({ serviceWorkAwaitedStatus: true})
        }
        miS.openModal("car-service-status-modal")
    }
    _renderImages = serviceImages => {
        return serviceImages.map(( item, index ) => {
            return <li key={index}>
                <Img src={base.imageBaseUrl+item} alt={item} />
            </li>
        })
    }
    render() {
        let { feedbackContent, orderContent, invoiceData, servicework, serviceWorkAwaitedStatus, activeShownTotal } = this.state, addr = '', usr = '', orderData = null;
        if (orderContent !== null) {
            addr = JSON.parse(orderContent.activeAddress)
            usr = JSON.parse(orderContent.userData)
            orderData = JSON.parse(orderContent.orderData)
            if (typeof orderData.discount !== "number")
                orderData.discount = Number(orderData.discount)
        }
        let invoiceNo = ''
        if (orderContent !== null) {
            invoiceData.forEach(e => {
                if (e.orderId === orderContent.uid)
                    invoiceNo = e.invoiceNo
            })
        }
        let serviceData = null, workData = null
        if (servicework !== null) {
            serviceData = JSON.parse(servicework.activeCar)
            if (typeof workData !== 'undefined')
                workData =  JSON.parse(servicework.workData)
        }
        let tableContent
        if (orderData) {
            tableContent = {
                headers: [
                    {
                        template: <div className="product-thumbnail">Preview</div>,
                        key: 'preview'
                    },
                    {
                        template: <div className="product-name">Product</div>,
                        key: 'product'
                    },
                    {
                        template: <div className="product-price">Price</div>,
                        key: 'price'
                    },
                    {
                        template: <div className="product-quantity">Quantity</div>,
                        key: 'quantity'
                    },
                    {
                        template: <div className="product-subtotal">Total</div>,
                        key: 'total'
                    }
                ],
                content: this._getTableData(orderData.cartData),
                addonBodyContent: <>
                    <tr>
                        <td className="small-col" colSpan={4}>Total</td>
                        <td className="small-col"><b>₹ {orderData.cartTotal.toFixed(2)}</b></td>
                    </tr>
                    {
                        !isNaN(orderData.discount) && orderData.discount && orderData.discount !== "" && orderData.discount !== 0 && orderData.discount !== '0' ?
                        <>
                            <tr>
                                <td className="small-col" colSpan={4}>Discount</td>
                                <td className="small-col">₹ {orderData.discount.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="small-col" colSpan={4}>Net Payable</td>
                                <td className="small-col"><b>₹ {(orderData.cartTotal - orderData.discount).toFixed(2)}</b></td>
                            </tr>
                        </>:<></>
                    }
                </>
            }
        }
        return <>
            {
                orderContent !== null &&
                <div id="content-box" className="content">
                    <h1 className="flex">
                        <span>
                            <BackIcon backLink='/order/list' />
                            Order #{orderContent.uid} details</span>
                        {
                            (orderContent.status === 'paid' || orderContent.status === 'processing' || orderContent.status === 'shipped' || orderContent.status === 'delivered' || orderContent.status === 'completed') && invoiceNo !== '' &&
                            <a href={base.invoiceBaseUrl+"/invoice_"+invoiceNo+".pdf"} target="_blank" rel="noopener noreferrer" className="btn btn-primary mlauto mt40"><i className="hi-download-cloud"></i>Download Invoice</a>
                        }
                    </h1>
                    <div className="columns">
                        <div className="left">
                            <Box title="User Details">
                                <div className="form-group">
                                    <label>Name</label>
                                    <p>{usr.name}</p>
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <p>{usr.email}</p>
                                </div>
                                <div className="form-group">
                                    <label>Phone</label>
                                    <p>{usr.mobile}</p>
                                </div>
                                <div className="form-group">
                                    <label>Alternate Number</label>
                                    <p>{addr.alternateNo}</p>
                                </div>
                            </Box>
                            <Box title="Delivery Address">
                                {addr.building+', '+addr.locality+', '+addr.city+', '+addr.state+' - '+addr.pinCode}
                            </Box>
                            <Box title="Items" bodyClass='p0'>
                                <Table
                                    tableClass="shop-table shop-table_responsive cart cart-form__contents mb0 br0"
                                    data={tableContent}
                                    pageSize={activeShownTotal}
                                    showTotal={false}
                                />
                            </Box>
                        </div>
                        <div className="right">
                            <Box title="Active Status">
                                <Badge item={orderContent.status} />
                            </Box>
                            <Box title="Notes">
                                <ul className="notes">
                                    { this._renderNotes( orderContent.orderNotes||[] ) }
                                </ul>
                            </Box>
                            {
                                orderContent.status === 'completed' &&
                                <Box title="Feedback & Review">
                                    {
                                        feedbackContent === null?
                                        <a rel="noopener noreferrer" target='_blank' href={'/feedback/'+orderContent.uid} className="btn btn-primary">Submit Feedback</a>:
                                        <div className="rating-order-detail">
                                            {
                                                miS.renderRatingSystem(feedbackContent.rating ? Number(feedbackContent.rating) : 0)
                                            }
                                            <p>{feedbackContent.comments}</p>
                                        </div>
                                    }
                                </Box>
                            }
                        </div>
                    </div>
                </div>
            }
            <div className="modal" id="car-service-status-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content content medium">
                    <Button className="close" onClick={() => miS.closeModal("car-service-status-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        {
                            serviceWorkAwaitedStatus ?
                            <>
                            <div className="no-data-icon">
                                <i className="hi-hourglass_empty"></i>
                            </div>
                            <p className="awited-text">Work is still awaited for this service</p></>:
                            <div>
                                {
                                    servicework !== null &&
                                    <>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Title</label>
                                            <div className="col-9 controls ml20">
                                                    {servicework.title}
                                            </div>
                                        </div>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Service Date</label>
                                            <div className="col-9 controls ml20">
                                                {miS.getFormattedDate(new Date(servicework.serviceDate))}
                                            </div>
                                        </div>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Start Time</label>
                                            <div className="col-9 controls ml20">
                                                {miS.getFormattedTime(new Date(workData[0].startTime))}
                                            </div>
                                        </div>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">{brandInfo.name.capital} Id</label>
                                            <div className="col-9 controls ml20">
                                                {servicework.workerId}
                                            </div>
                                        </div>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Active Car</label>
                                            <ul className="col-9 controls ml20">
                                                <li className="my-packages-assign-car">
                                                    {
                                                        serviceData.image_path !== null && typeof serviceData.image_path !== 'undefined' && serviceData.image_path !== '' &&
                                                        <Img src={base.carImageBase+(JSON.parse(servicework.activeCar).image_path)} alt={(servicework.activeCar).name} />
                                                    }
                                                    <div className="ml10">
                                                        <p>{serviceData.name+' '+serviceData.Segment}</p>
                                                        {
                                                            serviceData.registrationNo && serviceData.registrationNo !== '' && serviceData.registrationNo !== null &&
                                                            <small>{serviceData.registrationNo}</small>
                                                        }
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Comments</label>
                                            <div className="col-9 controls ml20">
                                                {workData[0].comments}
                                            </div>
                                        </div>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Images</label>
                                            <div className="col-9 controls ml20">
                                                <ul className="media-box">
                                                    { this._renderImages(workData[0].carImages || []) }
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    }
}