import React, {Component} from 'react'
import {base} from '../../../constant/Data'
import DatePicker from 'react-datepicker'
import {CSVLink} from 'react-csv'
import Select from 'react-select'
import {OrderService, MiscService, MetaService} from '../../../service'
import {Img, Button} from '../../../element'
import {Table} from '../../../component'
const commonPageSize = 50
const filterTags = require('./filterTags.json'),
mS = new MetaService(),
miS = new MiscService(),
oS = new OrderService()
export default class ProductOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeFilterIndex: 0,
            tableData: [],
            activeItem: null,
            searchKey: '',
            startDate: '',
            endDate: '',
            showTrashConfirm: false,
            paymentFail: false,
            serviceData: [],
            orderDataTitle: [],
            checkedAll: false,
            orderStatusIndex: 0,
            activeShownTotal: commonPageSize,
            filterTags: [{
                id: '1',
                label: 'All',
                value: 'all'
            }],
            filteredList: [],
            searchList: [],
            orderStatus: [
                "initialised",
                "payment-fail",
                "paid",
                "processing",
                "shipped",
                "delivered",
                "completed"
            ],
            activeFilter: null
        }
    }
    componentDidMount () {
        mS.setTitle('Product Orders', 'admin')
        let {filterTags}= this.state
        this.setState({ activeFilter: filterTags[0] })
        this._getOrdersData()
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getOrdersData = async () => {
        let { activeFilterIndex } = this.state
        const result = await oS.getOrders()
        if (result.status) {
            let tableData = [];
            result.data.reverse().forEach((item) => {
                if (item.status !== 'initialised' && item.status !== 'payment-fail') {
                    let orderData = JSON.parse(item.orderData);
                    orderData.cartData.forEach(e => {
                        if (e.type === 'product') {
                            e.orderData = item
                            tableData.push(e)
                        }
                    })
                }
            })
            this.setState({ tableData, filteredList: tableData }, () => {
                let activefilterValue = 'all'
                filterTags.forEach(e => {
                    if (Number(e.id) === activeFilterIndex+1)
                        activefilterValue = e.value
                })
                this._filterItems( activefilterValue, activeFilterIndex)
            })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No order placed yet!' })
        } else
            miS.showAlert({ type: 'error', msg: 'No order placed yet!' })
    }
    _getTableData = filterData => {
        let data = [], { startDate , endDate, activeShownTotal } = this.state
        filterData.forEach(( item, index ) => {
            let orderTime = item.orderData.timing,
            startTime = startDate === ''?'':new Date(startDate),
            endTime = endDate === ''?new Date():new Date(endDate)
            if (index < activeShownTotal && orderTime >= startTime && orderTime <= endTime) {
                let e = {
                    image: <div className="product-thumbnail">
                        {
                            item.type === 'servicePackage'?
                            <i className="hi-dropbox service-package-icon"></i>:
                            item.type === 'subscription'?
                            <i className="hi-block service-package-icon"></i>:
                            <Img src={base.imageBaseUrl+item.image} alt={item.title} />
                        }
                    </div>,
                    title: item.title,
                    price: <>₹ {Number(item.price).toFixed(2)}</>,
                    orderId: <>#{item.orderData.uid}</>,
                    userName: JSON.parse(item.orderData.userData).name,
                    timing: <>
                        {miS.getFormattedDate(item.orderData.timing)}
                        <p className="status-btn" onClick={() => this._getProductTrackData(item)}>Status</p>
                    </>
                }
                data.push(e)
            }
        })
        return data
    }
    _getProductTrackData = serviceData => {
        let { orderStatus } = this.state,
        orderData = [], itemFound = false
        if (serviceData !== null) {
            orderData = serviceData.orderData
            if (orderData.orderNotes) {
                orderData.orderNotes.forEach(e => {
                    if (e.tempUid === serviceData.tempUid) {
                        itemFound = true
                        orderStatus.forEach((el, i ) => {
                            if (el === e.status)
                                this.setState({ orderStatusIndex: i, orderDataTitle: serviceData}, () => miS.openModal('product-status-modal'))
                        })
                    }
                })
            }
            if (!itemFound) {
                orderStatus.forEach(( e, i ) => {
                    if (e === orderData.status) {
                        this.setState({ orderStatusIndex: i, orderDataTitle: serviceData}, () => miS.openModal('product-status-modal'))
                    }
                })
            }
        }
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all') {
            filteredList = tableData
        } else {
            filteredList = tableData.filter(e => {
                return e.status === key
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _renderFilterTags = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>{item.item} ({
                (item.key === 'all') ? tableData.length :
                tableData.reduce(( count, e) => {
                    return item.key === e.status ? count + 1 : count
                }, 0) })
            </span>
        })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
            filteredList = [], tempTitle = ''
            tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = (JSON.parse(e.userData).name).toLowerCase()
            if (e.uid.search(searchKey) !== -1 || tempTitle.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList, activeFilterIndex: 0 })
    }
    render() {
        let { activeFilter, tableData, paymentFail, orderStatusIndex, filteredList, searchKey, startDate, endDate, orderDataTitle, activeShownTotal} = this.state,
        exportedData = [], cartTotalTax = 0
        filteredList.forEach(e => {
            // let orderData = JSON.parse(e.orderData)
            // if (!orderData.discount) {
            //     orderData.discount = 0
            // }
            // if (typeof orderData.cartTotal !== "number")
            //     orderData.cartTotal = Number(orderData.cartTotal)
            // if (typeof orderData.discount !== "number")
            //     orderData.discount = Number(orderData.discount)
            // cartTotalTax = 0
            // orderData.cartData.forEach(( c, i ) => {
            //     c.tax = Number(c.tax);
            //     c.price = Number(c.price).toFixed(2)
            //     c.taxAmount = (c.tax * c.price/(100+c.tax)).toFixed(2)
            //     cartTotalTax += c.taxAmount
            //     if (c.type === 'product' && e.status !== 'initialised' && e.status !== 'payment-fail') {
            //         let ex = {
            //             Customer_Id: (i === 0) ? JSON.parse(e.userData).uid: '',
            //             Order_Id: (i === 0) ? e.uid: '',
            //             Customer_Name: (i === 0) ? JSON.parse(e.userData).name: '',
            //             Mobile_No: (i === 0) ? JSON.parse(e.userData).mobile: '',
            //             Address: (e.activeAddress && e.activeAddress !== "undefined") ?((i === 0) ? parseAddress(JSON.parse(e.activeAddress)): ''): '',
            //             Pin_Code: (e.activeAddress && e.activeAddress !== "undefined")? ((i === 0) ? JSON.parse(e.activeAddress).pinCode: ''): '',
            //             Status: (i === 0) ? e.status: '',
            //             Product: c.type === 'product'?c.title: '',
            //             Total: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal).toFixed(2): ''): '',
            //             Discount: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?Number((orderData.discount).toFixed(2)): ''): '',
            //             Net_Payment: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal - Number((orderData.discount).toFixed(2))).toFixed(2): ''): '',
            //             GST: ( e.status === "initialised") ? '': (i === 0) ? cartTotalTax: ''
            //         }
            //         exportedData.push(ex)
            //     }
            // })
        })
        let exportedFileName = 'export_' + new Date().getDate() + '_' + new Date().getMonth() + '_' + new Date().getFullYear() + '.csv'
        let tableContent = {
            headers: [
                {
                    template: <div className="product-thumbnail">Preview</div>
                },
                {
                    template: <div className="product-name">Product</div>
                },
                {
                    template: <div className="product-price">Price</div>
                },
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Date',
                    key: 'date'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="flex title">
                Product Orders
                {
                    filteredList.length !== 0 &&
                    <span className="btn btn-primary btn-small ml20 export-btn">
                        <CSVLink data={exportedData} filename={exportedFileName} className="btn export-btn" target="_blank">Export</CSVLink>
                    </span>
                }
            </h1>
            <div className="filter-box">
                <div className="flex date-filter overflow-visible">
                    <div className="form-group">
                        <DatePicker
                            selected={startDate}
                            onChange={startDate => this.setState({ startDate })}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="datePicker-filter"
                            placeholderText="From:"
                        />
                    </div>
                    <div className="form-group">
                        <DatePicker
                            selected={endDate}
                            onChange={endDate => this.setState({ endDate })}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            className="datePicker-filter"
                            placeholderText="To:"
                        />
                    </div>
                <div className="form-group ml10">
                    <Select
                        value={activeFilter}
                        defaultValue={activeFilter}
                        onChange={(e, i) => this.setState({ activeFilter: e }, () => {
                            this._filterItems(e.value, i)
                        })}
                        options={filterTags}
                    />
                </div>
                </div>
                <div className="search-box mlauto">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                            if (e.target.value === '' || reg.test(e.target.value))
                                this.setState({ searchKey: e.target.value }, this._search)
                        }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><i className="hi-close"></i></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <div className="modal" id="product-status-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content content medium">
                    <Button className="close" onClick={() => miS.closeModal("product-status-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        <h2>{orderDataTitle.title}</h2>
                        <div className="track">
                            <div className={orderStatusIndex >= 0? "step active" : "step" }> <span className="icon"><i className="hi-refresh"></i></span> <span className="text">Initialised</span> </div>
                            {
                                paymentFail ?
                                <div className={orderStatusIndex >= 1? "step active" : "step" }> <span className="icon"> <i className='hi-info1'></i> </span> <span className="text"> Payment Fail</span> </div>:
                                <div className={orderStatusIndex >= 2? "step active" : "step"}> <span className="icon"> <i className="hi-money"></i> </span> <span className="text"> Paid </span> </div>
                            }
                            <div className={orderStatusIndex >= 3? "step active" : "step"}> <span className="icon"> <i className="hi-crop_rotate"></i></span> <span className="text">Processing</span> </div>
                            <div className={orderStatusIndex >= 4? "step active" : "step"}> <span className="icon"><i className="hi-delivery_dining"></i> </span> <span className="text">Shipped</span> </div>
                            <div className={orderStatusIndex >= 5? "step active" : "step"}> <span className="icon"> <i className="hi-gift"></i> </span> <span className="text">Delivered</span> </div>
                            <div className={orderStatusIndex >= 6? "step active" : "step"}> <span className="icon"> <i className="hi-check-circle"></i></span> <span className="text">Completed</span> </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}