const routes = [
    {
        "pathName": "/admin/testimonial/add",
        "module": ["Testimonial"],
        "component": "AddTestimonial",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/testimonial/edit",
        "module": ["Testimonial"],
        "component": "AddTestimonial",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/testimonial/list",
        "module": ["Testimonial"],
        "component": "TestimonialList",
        "type": "admin",
        "mode": "static"
    }
]

export default routes