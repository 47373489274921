const routes = [
    {
        "pathName": "/admin/garage/list",
        "module": ["Garage"],
        "component": "GarageList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/garage/add",
        "module": ["Garage"],
        "component": "AddGarage",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/garage/edit",
        "module": ["Garage"],
        "component": "AddGarage",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/trash/garage/list",
        "module": ["Garage"],
        "component": "GarageList",
        "type": "admin",
        "mode": "static"
    }
]

export default routes