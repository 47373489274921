const TaxClassModel = [
    {
        key: 'className',
        dataType: String
    },
    {
        key: 'amount',
        dataType: String
    },
    {
        key: 'label',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]
export default TaxClassModel