import React from 'react'
import { badge } from '../../constant/Enum';
import './style.scss';
const Badge = ({item, onClick = false}) => {
    const _onBadgeClick = () => {
        if (typeof onClick === 'function') {
            onClick()
        }
    }
    return <span className={"badge "+item} onClick={_onBadgeClick}>
        {item !== '' && <>
            <i className={badge[item]['icon']}></i>
            <span>{badge[item]['label']}</span>
        </>}
    </span>
}
export default Badge