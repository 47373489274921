import { brandInfo } from "../../constant/Data"

export default class MetaService {
    setTitle = (title, type='public') => {
        let siteTitle = brandInfo.name.capital
        let siteTagline = brandInfo.siteTagline
        title = type === 'admin'?title+' | '+siteTitle+' Admin':type === 'home'?title+' | '+siteTagline:title+' | '+siteTitle
        document.title = title
    }
    setDescription = (description, type='public') => {
        description = "Best Car Service providers in Jaipur and Mohali"
        document.querySelector('meta[name="description"]').setAttribute("content", description);
    }
    setKeywords = (keywords, type='public') => {
        keywords = "Tyre Puncture, Car Service, Car Wheel Repair, Car Denting, Car Painting, Car Wash Services Online, Car Revival Package, Car Washing & Cleaning, Car Dry Cleaning, Battery Replacement, Jump Start, Flat tyre, Tyre Rotation, Sanitization, Deodorization, Balancing and Alignment, Car Pick and Drop Service"
        document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
    }
}