import React, {Component} from 'react'
import {base} from '../../../constant/Data'
import QRCode from 'react-qr-code'
import ReactToPrint from 'react-to-print'
import {CarService, OrderService, MiscService, DataService, ValidationService, UserService, RouterService, MetaService, MicroServiceService} from '../../../service'
import {Img, Button, Badge} from '../../../element'
import Select from 'react-select'
import { activeDateFilter, carStatusOption, inactiveCarOption } from '../../../constant/Enum'
import makeAnimated from 'react-select/animated';
import './style.scss'
const animatedComponents = makeAnimated();
const dS = new DataService('log'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService(),
oS = new OrderService(),
cS = new CarService(),
coS = new DataService('companyOrder'),
micS = new MicroServiceService()
const commonPageSize = 20
export default class CarList extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            preApprovedMicroServices: [],
            activeDateFilter: activeDateFilter[0],
            activeBranches: [],
            branches: [],
            activeCar: null,
            activeCarStatus: carStatusOption[1].value,
            comments: '',
            shoot360: false,
            activeShownTotal: commonPageSize,
            keysData: [],
            status: carStatusOption[0],
            tableData: [],
            filteredList: [],
            userData: this.user,
            orgId: ''
        }
    }
    componentDidMount () {
        mS.setTitle('My Cars List', 'public')
        let orgId = false
        if (['representative'].includes(this.user?.role)) {
            orgId = this.user.parent?.value
            this._getCompanyBranches(orgId)
        } else if (['branch'].includes(this.user?.role)) {
            orgId = this.user.uid
            this._getCarsData()
        } else if (this.user?.role === 'company') {
            orgId = this.user.uid
            this._getCompanyBranches(orgId)
        } else {
            this._getCarsData()
        }
        this._getKeys()
        if (['manager', 'company', 'branch', 'representative'].includes(this.user?.role)) {
            this._getPreApprovedMicroServices(orgId)
        }
        document.addEventListener('scroll', this._trackScrolling)
        this.setState({orgId})
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getKeys = async () => {
        const result = await oS.getKeysList()
        if (result.status)
            this.setState({ keysData: result.data.reverse() })
    }
    _getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        this._getCarsData(activeBranchIds)
    }
    _getPreApprovedMicroServices = async v => {
        const query = {
            isAutoApproved: true
        },
        result = await micS.getMicroService({ query: JSON.stringify(query) })
        if (result.status) {
            this.setState({ preApprovedMicroServices: result.data })
        }
    }
    _getCompanyBranches = async v => {
        let query = {
            parentId: v
        }, branches = [], { activeDateFilter } = this.state
        const result = await uS.getCompanyBranches({ query: JSON.stringify(query) })
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            });
            let activeBranches = uS.getActiveBranches() === null ? uS.getActiveBranches() : branches
            this.setState({ branches, activeBranches }, () => {
                uS.setGlobalActiveBranches(activeBranches)
                this._getDashboardData(activeDateFilter, activeBranches)
            })
        }
    }
    _getCarsData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(this.user.role) ? this.user.parent?.value : this.user.uid
        }
        query.ownerId = {
            $in: [query.ownerId, Number(query.ownerId)]
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await cS.getCars({ query: JSON.stringify(query) })
        if (result.status)
            this.setState({ tableData: result.data, filteredList: result.data })
    }
    _trashCar = async uid => {
        let query = {
            uid
        }
        const result = await cS.deleteCar({ query: JSON.stringify(query) })
        if (result.status) {
            this._getCarsData()
            miS.showAlert({ type: 'success', msg: 'Car deleted successfully!' })
        } else miS.showAlert({ type: 'error', msg: 'Unable to delete car!' })
    }
    _viewCar = uid => {
        rS.navigateTo('/car/view/'+uid)
    }
    _updateCarData = async (props = false) => {
        let {userData, activeCar, activeCarStatus, comments} = this.state
        if (props && props.activeCar) {
            activeCar = props.activeCar
            activeCarStatus = props.activeCarStatus
            comments = 'Return'
        }
        let query = {
            uid: activeCar.uid
        }
        if (activeCar.statusComments && vS.isValidJson(activeCar.statusComments))
            activeCar.statusComments = JSON.parse(activeCar.statusComments)
        else
            activeCar.statusComments = []

        activeCar.statusComments.push({uid: new Date().getTime(), comments: comments.value})
        let carObject = {
            status: activeCarStatus,
            statusComments: JSON.stringify(activeCar.statusComments)
        },
        data = {
            query: JSON.stringify(query),
            carData: JSON.stringify(carObject)
        }
        const result = await cS.updateCar(data)
        if (result.status) {
            let logData = {
                log: carObject.name+' updated by '+userData.role+' with website',
                timing: new Date().getTime(),
                type: 'info',
                userId: carObject.uid,
                uid: new Date().getTime()
            }
            dS.save(logData)
            miS.showAlert({ type: 'success', msg: 'Car status updated successfully!' })
            miS.closeModal('confirm-modal')
            this.setState({ activeItem: null, showTrashConfirm: false }, this._getCarsData)
        } else miS.showAlert({ type: 'error', msg: 'Unable to update car!' })
    }
    _updateCarStatus = (carObject, status) => {
        if (!['company', 'representative'].includes(this.user.role)) {
            if (status === 'active')
                this._updateCarData({ activeCar: carObject, activeCarStatus: status })
            else {
                this.setState({ activeCar: carObject, activeCarStatus: status })
                miS.openModal('confirm-modal')
            }
        }
    }
    _carUpdateCancel = () => {
        this.setState({ activeCar: null, activeCarStatus: '' })
        miS.closeModal('confirm-modal')
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.registrationNo.toLowerCase()
            if (tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList })
    }
    _purchaseMicroService = async (item, car) => {
        item.uid = new Date().getTime()
        delete car.qrCode
        let purchaseData = {}, {orgId} = this.state
        purchaseData.recommendData = []
        purchaseData.comments = ''
        purchaseData.type = item.type ? item.type : 'service'
        purchaseData.recommendData[0] = item
        purchaseData.orderData = JSON.stringify(purchaseData.recommendData)
        delete purchaseData.recommendData
        purchaseData.carDetails = JSON.stringify(car)
        purchaseData.carImages = JSON.stringify([])
        purchaseData.ownerId = this.user.role === 'company' ? this.user.uid : orgId
        purchaseData.actionDate = new Date().getTime()
        purchaseData.uid = new Date().getTime()
        purchaseData.status = 'approved'
        const result = await coS.save(purchaseData)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'Service is booked and an order is generated for the same!' })
        }
    }
    _renderPreApprovedMicroServices = (car) => {
        let { preApprovedMicroServices } = this.state
        return preApprovedMicroServices.map((e, i) => {
            return <li key={i}>
                <span>{e.title}</span>
                <Button className='btn btn-primary btn-small' onClick={() => this._purchaseMicroService(e, car)}>Book</Button>
            </li>
        })
    }
    _renderCarList = carFiles => {
        let { preApprovedMicroServices, userData, keysData, activeShownTotal } = this.state
        return carFiles.map(( item, index ) => {
            let carQRData = JSON.stringify({
                user: userData.uid,
                car: item.uid
            }), keySubmitted = false
            keysData.forEach(e => {
                if (e.carId === item.uid && e.status === 'collected')
                    keySubmitted = true
            })
            if (index < activeShownTotal && this._filterItems(item)) {
                return <div className="form-box mt30 mb0" key= {index}>
                    <div className="account-detail service-single-item carlistdetail">
                        <div className="qr-code-box"></div>
                        <div className="col-9">
                            <div className="service-content">
                                <div className="title-wrap">
                                    {
                                        keySubmitted &&
                                        <i className="key-collect hi-key"></i>
                                    }
                                    <div className='image-box'>
                                        {item.carData && vS.isValidJson(item.carData) && <Img className="car-image" src={base.carImageBase+JSON.parse(item.carData).image_path} alt={item.maker+'('+item.vehicleClass+')'} />}
                                        {item.shoot360Url && item.shoot360Url !== '' && item.shoot360Url !== 'false' &&
                                            <span onClick={() => {
                                                if (item.shoot360Url && item.shoot360Url !== '' && item.shoot360Url !== 'false')
                                                    rS.navigateTo(item.shoot360Url, true)
                                            }}><Img className="shoot-icon" src={base.imageBaseUrl+'360-degree-icon.png'} alt={item.maker+'('+item.vehicleClass+')'} title="360 Shoot Completed" />
                                            </span>
                                        }
                                    </div>
                                    <div>
                                        <h3 className="title p0">{item.maker}</h3>
                                        <b className='fs18'>{item.registrationNo}</b>
                                    </div>
                                    {['manager', 'company', 'branch', 'representative'].includes(this.user?.role) && <div className="ml20 flex-box switch-box">
                                        <div title='Change Car Status' className='pointer mbauto' onClick={() => this._updateCarStatus(item, typeof item.status === "undefined" || item.status === 'active'?'inactive':'active')}><Badge item={typeof item.status === "undefined" || item.status === 'active' ?'active':'inactive'} /></div>
                                    </div>}
                                </div>
                                <div className="item-number" ref={el => (item.qrCode = el)}>
                                    <ReactToPrint
                                        trigger={() => {
                                            return <Button className='print-btn'>Print</Button>;
                                        }}
                                        content={() => item.qrCode}
                                    />
                                    <QRCode value={carQRData} size={100} />
                                    <div className="QR-help">
                                        <i className="hi-info1"></i>
                                        <span className="tooltip-qr-help">
                                            This QR code enables our team to fetch your car & package details in order to give you contactless service.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex">
                                { ['company', 'representative'].includes(this.user.role) && <a className="btn btn-outline btn-box" href={"/car/edit/"+item.uid}>Edit</a>}
                                <Button type="button" className="btn btn-primary btn-box ml20" onClick={() => this._trashCar(item.uid)}>Remove</Button>
                                {/* <Button type="button" className="btn btn-primary btn-box ml20" onClick={() => this._viewCar(item.uid)}>More</Button> */}
                            </div>
                        </div>
                        {preApprovedMicroServices.length !== 0 && ['manager', 'company', 'branch', 'representative'].includes(this.user?.role) && <div className='pre-approved-services-box'>
                            <h4>Book Services</h4>
                            <ul className='pre-approved-services'>
                                {this._renderPreApprovedMicroServices(item)}
                            </ul>
                        </div>}
                    </div>
                </div>
            }
        })
    }
    _filterItems = e => {
        let {shoot360, status} = this.state, check360 = true
        if (shoot360) {
            check360 = e.shoot360 && e.shoot360Url && e.shoot360Url !== '' && e.shoot360Url !== 'false'
        } else {
            check360 = !(e.shoot360 && e.shoot360Url && e.shoot360Url !== '' && e.shoot360Url !== 'false')
        }
        return (status.value === 'all' || (status.value === 'active'
                    ?(e.status === 'active' || e.status || typeof e.status === "undefined")
                    :(e.status === false || e.status === 'inactive'))) // Temp Filter
        // check360 &&
        // (status.value === 'all' || status.value === e.status)
    }
    render() {
        let { activeDateFilter, activeBranches, branches, comments, status, shoot360, filteredList, searchKey, tableData } = this.state
        return <>
            <div className="right-side-profile-content">
                <div className='flex overflow-visible'>
                    <h1 className="nowrap mr20">My Cars
                        {
                            !['company', 'representative'].includes(this.user.role) &&
                            <a className="btn btn-primary btn-small carbtn" href="/car/add">Add</a>
                        }
                    </h1>
                    {
                        ['company', 'representative'].includes(this.user.role) && branches.length > 0 &&
                        <Select
                            isClearable
                            closeMenuOnSelect={false}
                            component={animatedComponents}
                            placeholder='Select Company'
                            defaultValue={activeBranches}
                            onChange={e => {
                                this.setState({ activeBranches: e }, () => {
                                    uS.setGlobalActiveBranches(e)
                                    this._getDashboardData(activeDateFilter, e)
                                })
                            }}
                            options={branches}
                            isMulti
                        />
                    }
                </div>
                <div className="profile-sec wrapper">
                    <div className="form-editable-section" id="list-wrapper">
                        <div className="filter-box">
                            <div className="filters relative">
                                <Select
                                    value={status}
                                    defaultValue={status}
                                    onChange={e => this.setState({ status: e })}
                                    options={carStatusOption}
                                />
                            </div>
                            <div className='filters mrauto'>
                                <div className="flex-box switch-box">
                                    <label className="switch">
                                        <input type="checkbox" onChange={() => this.setState({ shoot360: !shoot360 })} checked={shoot360?'checked':false} />
                                        <span className="slider round m0"></span>
                                    </label>
                                    <span><Img className="shoot-icon" src={base.imageBaseUrl+'360-degree-icon.png'} title="360&deg; Shoot Completed" /> Done</span>
                                </div>
                            </div>
                            <div className="search-box">
                                <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                                    const reg = /^[A-Za-z0-9 ]+$/
                                    if (e.target.value === '' || reg.test(e.target.value))
                                        this.setState({ searchKey: e.target.value }, this._search)
                                }} />
                                <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><i className="hi-close"></i></Button>
                            </div>
                        </div>
                        { this._renderCarList(filteredList || []) }
                    </div>
                </div>
            </div>
            <div className="modal" id="confirm-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <Button className="close" onClick={this._carUpdateCancel}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        <i className='hi-info1'></i>
                        <span className="store-name">Please specify the reason to inactive car.</span>
                        <Select
                            value={comments}
                            defaultValue={comments}
                            onChange={e => this.setState({ comments: e })}
                            options={inactiveCarOption}
                        />
                        <div className="flex btn-box-order mt60">
                            <Button className="btn btn-primary btn-box mb0" onClick={this._updateCarData}>Save</Button>
                            <Button className="mlauto btn btn-box btn-outline mb0" onClick={this._carUpdateCancel}>Cancel</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}