import React, { useEffect } from 'react';
import {Img} from '../../element';
import {MiscService} from '../../service';
const miS = new MiscService(),
GuestCarQueryModal = () => {
    useEffect(() => {
        miS.openModal("first-time-modal")
    },[])
    return <div className="modal" id="first-time-modal">
        <div className="modal-backdrop"></div>
        <div className="modal-content small">
            <button className="close" onClick={() => miS.closeModal("first-time-modal")}><i className="hi-close"></i></button>
            <div className="modal-body p20">
                <div className="guest-modal-car-selector">
                    <div className="text-box-modal">
                        <h2>Get instant quotes for your car service, Product and accessories</h2>
                    </div>
                    <div className="cartoon-img">
                        <Img src={require('../../../assets/images/3-character-design.png')} />
                    </div>
                    <div className="select-car-box" onClick={() => {miS.closeModal("first-time-modal"); miS.openModal('car-select-modal')}}>
                        <span className="nav-link active selectCar">
                            <i className="hi-directions_car"></i>
                            <span>Select Your Car </span>
                        </span>
                    </div>
                    <div className="footer-guest-car-selector">
                        <ul>
                            <li>
                                <p className="rating-show-play-store dark">
                                    <i className="hi-star-o orange rate-play-stor"></i>
                                    4.3/<sub>5</sub>
                                </p>
                                <p className="gray">Based on 500+ <br />Feedbacks</p>
                            </li>
                            <li>
                                <p className="rating-show-play-store dark">
                                    <i className="hi-happy1 green rate-play-stor"></i>
                                    5,000+
                                </p>
                                <p className="gray">Happy <br />Customers</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default GuestCarQueryModal