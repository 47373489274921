import React, {Component} from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {base} from '../../../constant/Data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {fuelOption} from '../../../constant/Enum'
import {Img, Button, BackIcon} from '../../../element'
import {Box} from '../../../component'
import {MiscService, DataService, RouterService, MetaService, CarService} from '../../../service'
const dS = new DataService('media'),
pS = new DataService('carModel'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
cS = new CarService()
export default class AddCarModel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modelKey: '',
            vehicleClassListoptions: [],
            activeSegment: null,
            manufactureroptions: [],
            manufacturer: '',
            activeManufacturer: '',
            uploaded: false,
            year_of_manufacture: new Date ('2010'),
            name: "",
            image_path: "",
            Segment: "Sedan",
            fuel: fuelOption,
            end_year: new Date ('2020'),
            start_year: new Date ('2010'),
            uid: '',
            mode: 'Add'
        }
    }
    componentDidMount () {
        mS.setTitle('Add CarModel', 'admin')
        this._getVehicleClassList()
    }
    _getVehicleClassList = async () => {
        const result = await cS.getVehicleClassList()
        let vehicleClassListoptions = []
        if (result.status) {
            result.data.forEach(e => {
                e.label = e.name
                e.value = e.priceGroup
                vehicleClassListoptions.push(e)
            });
        }
        this.setState({ vehicleClassListoptions }, this._getManufacturerListData)
    }
    _uploadCall = async () => {
        let error = false, { image_path } = this.state,
        errorMsg = ''
        if (!error) {
            image_path.uid = new Date().getTime()
            const fileResult = await dS.save(image_path)
            if (fileResult.status) {
                this._addCarModel()
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to update profile image!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _getManufacturerListData = async () => {
        const result = await cS.getCarManufacturers()
        if (result.status) {
            let manufactureroptions = []
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No Car Manufacturers added yet!' })
            } else {
                result.data.forEach(e => {
                    e.value = e.slug
                    e.label = e.name
                    manufactureroptions.push(e)
                });
            }
            this.setState({ manufactureroptions }, () => {
                let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
                if (urlData[3] === 'edit') {
                    let query = {
                        uid: urlData[4]
                    }
                    this._getCarModelData(query)
                }
            })
        }
    }
    _getCarModelData = async query => {
        let {activeManufacturer, manufactureroptions, vehicleClassListoptions, activeSegment} = this.state
        const result = await pS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let carModelData = result.data[0]
            manufactureroptions.forEach(e => {
                if(e.label === carModelData.manufacturer)
                    activeManufacturer = e
            })
            vehicleClassListoptions.forEach(e => {
                if(e.label === carModelData.Segment)
                    activeSegment = e
            })
            this.setState({
                name: carModelData.name,
                activeManufacturer,
                activeSegment,
                mode: 'Edit',
                uploaded: true,
                modelKey: carModelData.modelKey,
                end_year: new Date(carModelData.end_year),
                Segment: carModelData.Segment,
                fuel: JSON.parse(carModelData.fuel),
                image_path: carModelData.image_path,
                start_year: new Date(carModelData.start_year),
                year_of_manufacture: new Date(carModelData.year_of_manufacture),
                manufacturer: carModelData.manufacturer,
                uid: carModelData.uid
            })
        } else miS.showAlert({ type: 'error', msg: 'No carModels added yet!' })
    }
    _selectFiles = async e => {
        let fileData = e.target.files[0]
        const result = await miS.getBase64(fileData)
        let image_path = {
            url: URL.createObjectURL(fileData),
            data: result,
            type: fileData.type,
            fileName: fileData.name,
            size: fileData.size
        }
        if (fileData.size <= 4000000)
            this.setState({ image_path, uploaded: true })
        else
            miS.showAlert({ type: 'error', msg: 'File is too large.' })
    }
    _addCarModel = async () => {
        let { mode, name, uid, image_path, fuel, Segment, end_year, modelKey, start_year, year_of_manufacture, manufacturer } = this.state,
        error = false,
        errorMsg = ''
        image_path = image_path.fileName ?? image_path
        modelKey = modelKey !== '' ? modelKey : (image_path.substring(0, image_path.lastIndexOf('.')) || image_path)
        let carModelObject = {
            end_year: String(new Date(end_year).getFullYear()),
            Segment,
            modelKey: modelKey,
            fuel: JSON.stringify(fuel),
            name,
            image_path,
            start_year: String(new Date(start_year).getFullYear()),
            year_of_manufacture: String(new Date(year_of_manufacture).getFullYear()),
            manufacturer
        }
        if (name === '') {
            errorMsg = 'Please enter the name'
            error = true
        } else if (image_path === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                carModelObject.uid = new Date().getTime()
                const result = await pS.save(carModelObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'CarModel added successfully!' })
                    rS.navigateTo('/admin/car-model/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add carModel!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    carModelData: JSON.stringify(carModelObject)
                }
                const result = await pS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'CarModel updated successfully!' })
                    rS.navigateTo('/admin/car-model/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update carModel!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _submitForm = e => {
        e.preventDefault()
        let {mode, image_path} = this.state
        if(mode === 'Edit' && !image_path.fileName) {
            this._addCarModel()
        } else {
            this._uploadCall()
        }
    }
    render() {
        let { modelKey, fuel, manufactureroptions, vehicleClassListoptions, activeSegment, activeManufacturer, uploaded, year_of_manufacture, mode, name, image_path, start_year, end_year } = this.state,
        pageTitle = mode + ' Car Model'
        return <>
            <h1>
                <BackIcon backLink='/admin/car-model/list' />
                {pageTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._submitForm}>
                <div className="left">
                    <Box title='Name' required>
                        <input type="text" required name="name" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </Box>
                    {mode === 'Edit' && <Box title='Model Key' required>
                        <input type="text" required name="modelKey" value={modelKey} onChange={(e) => this.setState({ modelKey: e.target.value })} />
                    </Box>}
                    <Box title='Fuel'>
                        <CreatableSelect
                            value={fuel}
                            onChange={e => { this.setState({ fuel: e }) }}
                            options={fuelOption}
                            isMulti
                        />
                    </Box>
                    <Box title='Year Of Manufacture'>
                        <DatePicker
                            showYearPicker
                            dateFormat="yyyy"
                            onChange={year_of_manufacture => this.setState({ year_of_manufacture })}
                            selected={year_of_manufacture}
                        />
                    </Box>
                    <Box title='Start Year'>
                        <DatePicker
                            showYearPicker
                            dateFormat="yyyy"
                            onChange={start_year => this.setState({ start_year })}
                            selected={start_year}
                        />
                    </Box>
                    <Box title='End Year'>
                        <DatePicker
                            showYearPicker
                            dateFormat="yyyy"
                            onChange={end_year => this.setState({ end_year })}
                            selected={end_year}
                        />
                    </Box>
                </div>
                <div className="right">
                    <Box title='Meta'>
                        <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                    </Box>
                    <Box title='Brand'>
                        <Select
                            value={activeManufacturer}
                            defaultValue={activeManufacturer}
                            onChange={e => this.setState({ manufacturer: e.label, activeManufacturer: e })}
                            options={manufactureroptions}
                        />
                    </Box>
                    <Box title='Vehicle Segment'>
                        <Select
                            value={activeSegment}
                            defaultValue={activeSegment}
                            onChange={e => this.setState({ Segment: e.label, activeSegment: e })}
                            options={vehicleClassListoptions}
                        />
                    </Box>
                    <Box title='Image' required>
                        { image_path !== '' && <Img className='input-img' src={ uploaded ? image_path.url ? image_path.url : image_path.fileName ? base.imageBaseUrl+image_path.fileName : base.imageBaseUrl+image_path : base.imageBaseUrl+image_path} />}
                        <label htmlFor="choose-file" className="btn btn-blank btn-small">Choose Files</label>
                        <div className='clearfix'></div>
                        <input type="text" className="hidden" placeholder="Please enter image" required name="image" value={image_path} onChange={(e) => {
                            modelKey = modelKey !== '' ? modelKey : (e.target.value.substring(0, e.target.value.lastIndexOf('.')) || e.target.value)
                            this.setState({ modelKey, image_path: e.target.value })
                        }} />
                        <input type="file" className="hidden" onChange={this._selectFiles} multiple id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/webp" />
                    </Box>
                </div>
            </form>
        </>
    }
}