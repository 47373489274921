import React, {Component} from 'react'
import {base} from '../../constant/Data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {RelatedProductSlider} from '../../component'
import {robotsOption,authorOption,statusOption} from '../../constant/Enum'
import {Button} from '../../element'
import {RouterService, CarService, ServiceService, MetaService, UserService, DataService, OrderService, MiscService} from '../../service'
const sbS = new DataService('subscription'),
uS = new UserService(),
sS = new ServiceService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService(),
cS = new CarService(),
timeSlot = [
    {
        id: '1',
        slot: '12:00 AM - 2:00 AM'
    },
    {
        id: '2',
        slot: '2:00 AM - 4:00 AM'
    },
    {
        id: '3',
        slot: '4:00 AM - 6:00 AM'
    },
    {
        id: '4',
        slot: '6:00 AM - 8:00 AM'
    },
    {
        id: '5',
        slot: '8:00 AM - 10:00 AM'
    },
    {
        id: '6',
        slot: '10:00 AM - 12:00 PM'
    },
    {
        id: '7',
        slot: '12:00 PM - 2:00 PM'
    },
    {
        id: '8',
        slot: '2:00 PM - 4:00 PM'
    },
    {
        id: '9',
        slot: '4:00 PM - 6:00 PM'
    },
    {
        id: '10',
        slot: '6:00 PM - 8:00 PM'
    },
    {
        id: '11',
        slot: '9:00 PM - 10:00 PM'
    },
    {
        id: '12',
        slot: '10:00 PM - 2:00 AM'
    }
]
export default class Service extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            serviceList: [],
            activeCar: {},
            activePrice: '',
            activeRobotsIndex: 0,
            activeStatusIndex: 0,
            activeAuthorIndex: 0,
            activeTimeSlot: timeSlot[0],
            prices: '[]',
            image: '[]',
            vehicleClassList: [],
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            content: '',
            excerpt: '',
            css: '',
            js: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            orderDate: '',
            author: '',
            publishDate: new Date(),
            status: '',
            category: '',
            tag: '',
            refreshCart: false,
            showDropdwon: false,
            selectCarType: false,
            activeCarType: null,
            chooseServiceTime: false,
            buyType: '',
            selectedCar: {},
            activeSubscription: null,
            subscriptionData: []
        }
    }
    componentDidMount () {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        query = {
            slug: urlData[2]
        }
        this._getServicesData()
        this._getSubscriptionData()
        this._getServiceData(query)
        this._getVehicleClassList()
    }
    _getVehicleClassList = async () => {
        const result = await cS.getVehicleClassList()
        if (result.status) {
            if (result.data.length !== 0) 
                this.setState({ vehicleClassList: result.data })
        }
    }
    _getServiceData = async query => {
        const result = await sS.getService({ query: JSON.stringify(query) })
        if (result.status) {
            let serviceData = result.data[0],
            metaKeyword = [],
            tag = [],
            category = [],
            activeRobotsIndex = 0,
            activeAuthorIndex = 0,
            activeStatusIndex = 0
            robotsOption.forEach(( e, i ) => {
                if (e.value === serviceData.robots)
                    activeRobotsIndex = i
            })
            authorOption.forEach(( e, i ) => {
                if (e.value === serviceData.robots)
                    activeAuthorIndex = i
            })
            statusOption.forEach(( e, i ) => {
                if (e.value === serviceData.robots)
                    activeStatusIndex = i
            })
            JSON.parse(serviceData.metaKeyword).forEach(e => {
                metaKeyword.push(e)
            })
            JSON.parse(serviceData.tag).forEach(e => {
                tag.push(e)
            })
            JSON.parse(serviceData.category).forEach(e => {
                category.push(e)
            })
            mS.setTitle(serviceData.title, 'public')
            this.setState({
                title: serviceData.title,
                mode: 'Edit',
                slug: serviceData.slug,
                content: serviceData.content,
                excerpt: serviceData.excerpt,
                css: serviceData.css,
                js: serviceData.js,
                canonical: serviceData.canonical,
                robots: serviceData.robots,
                metaDescription: serviceData.metaDescription,
                metaKeyword,
                author: serviceData.author,
                publishDate: new Date(serviceData.publishDate),
                status: serviceData.status,
                category,
                tag,
                uid: serviceData.uid,
                image: serviceData.image,
                activeRobotsIndex,
                activeAuthorIndex,
                activeStatusIndex,
                prices: JSON.parse(serviceData.prices),
                image: JSON.parse(serviceData.image)[0]
            })
        }
    }
    _getSubscriptionData = async () => {
        const result = await sbS.fetchAll()
        if (result.status) {
            if (result.data.length !== 0)
                this.setState({ subscriptionData: result.data })
        }
    }
    _renderCarSubscription = carData => {
        let { activeSubscription } = this.state
        return carData.map(( item, index ) => {
            return <div className="car-list" onClick={() => {
                this._selectSubscription(index)
                this.setState({ activeSubscription: item })
                }} key={index}>
                <div className="car-list-block">
                    <span className="carCheckbox">
                        {(activeSubscription === index) ?<i className="hi-checkbox orange"></i> : <i className="hi-checkbox-unchecked1"></i>}
                    </span>
                    <span className="car-name">{item.title}</span>
                </div>
                <div className="price">{item.discount}%</div>
            </div>
        })
    }
    _checkForActiveCar = () => {
        let activeCar = miS.getActiveCar(),
        { prices } = this.state, found = false
        prices = JSON.parse(prices)
        if (activeCar === null) {
            setTimeout(() => {
                this._checkForActiveCar()
            }, 3000)
        } else {
            prices.forEach(c => {
                if (activeCar.Segment.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    this._serviceAddedTemplate(c.price)
                }
            })
        }
    }
    _chooseActiveCar = () => {
        let activeCar = miS.getActiveCar(),
        { prices } = this.state, found = false
        prices = JSON.parse(prices)
        if (activeCar === null) {
            miS.openCarSelectModal()
            this._checkForActiveCar()
        } else {
            prices.forEach(c => {
                if (activeCar.Segment.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    this._serviceAddedTemplate(c.price)
                }
            })
        }
    }
    _serviceAddedTemplate = derivedPrice => {
        let { buyType, orderDate, selectedCar, image, title, activeTimeSlot, uid } = this.state,
        price = derivedPrice
        let service = {
            selectedCar: selectedCar,
            orderDate: orderDate,
            uid,
            buyType: buyType,
            activeTimeSlot: activeTimeSlot,
            activeCar: miS.getActiveCar(),
            title,
            image: JSON.parse(image)[0],
            price: price,
            purchaseQuantity: 1,
            type: 'service',
            tempUid: new Date().getTime()
        },
        prodcutAddedTemplate = '<div class="alert-msg"><i class="hi-checkbox-checked"></i><p>Service added to cart!</p></div>'+
        '<div class="service-box">'+
        '<img src="'+base.imageBaseUrl+JSON.parse(image)[0]+'" alt="'+title+'" class="service-image" />'+
        '<div class="service-content">'+
        '<p class="service-title">'+title+'</p>'+
        '<p class="service-price">₹ '+price+'</p>'+
        '</div>'+
        '</div>'
        oS.addToCart(service)
        miS.showAlert({ type: 'custom', template: prodcutAddedTemplate })
        this.setState({ activeCarType: null })
        setTimeout(() => {
            this.setState({ activeCarType: null, activeSubscription: null })
        }, 2000)
    }
    _selectSubscription = item => {
        let _this = this
        miS.closeModal("subscribe-modal")
        _this.setState({ activeSubscription: item}, _this._serviceAddedTemplate)
    }
    _toggleSubscribeDropDown = () => {
        let { showDropdwon } = this.state
        this.setState({ showDropdwon:!showDropdwon})
    }
    _subscribe = time => {
        this._toggleSubscribeDropDown()
    }
    _buyItem = buyType => {
        this.setState({ buyType: buyType }, () => {
            miS.openModal("add-to-cart-modal")
        })
    }
    _renderTimeSlots = timeSlot => {
        let { activeTimeSlot } = this.state
        return timeSlot.map(( item, index ) => {
            return <li className={ activeTimeSlot.slot === item.slot ? 'active': ''} onClick={() => {
                this.setState({ activeTimeSlot: item })
            }} key={index}>
                {
                    activeTimeSlot.slot === item.slot?
                    <i className="success hi-check_circle"></i>:<i className="hi-circle ashgray"></i>
                }
                {item.slot}
            </li>
        })
    }
    _getServicesData = async () => {
        const result = await sS.getServices()
        if (result.status) {
            if (result.data.length !== 0)
                this.setState({ serviceList: result.data })
        }
    }
    render() {
        let { subscriptionData, orderDate, title, content, excerpt, image, buyType, chooseServiceTime } = this.state,
        imagesList = JSON.parse(image),
        today = new Date(),
        tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        return <>
            <div className="service-banner">
                <div className="left-banner">
                    <div className="right-col jumbo-container">
                        <div className="service-details">
                            <h1 className="title">{title}</h1>
                            <div className="product-detail-description" dangerouslySetInnerHTML={{__html: excerpt}}></div>
                            <div className="wrapper1 mt40">
                                {(!uS.isUserLoggedIn() || (uS.isUserLoggedIn() && this.user?.role && !['manager', 'company', 'branch', 'representative'].includes(this.user?.role))) && <Button onClick= {() => this._buyItem('add-to-cart')} className="btn-primary btn add-to-cart add-to-cart-btn mr20">Add to Cart</Button>}
                            </div>
                        </div>
                    </div> </div>
                    <div className="right-banner">
                        <div className="right-banner-inner" style={{backgroundImage: 'url("'+base.imageBaseUrl+JSON.parse(image)[0]+'")'}}>
                    </div>
                </div>
            </div>
            <div className="productdetail-page">
                <div className="jumbo-container">
                    <section className="main-product-detail-page mb60">
                        <h2 className="title-bar">Gallery</h2>
                        <div className="left-col">
                            <div className="gallery">
                                <div className="images-section">
                                    {this._renderImages(imagesList || [] )}
                                </div>
                            </div>
                        </div>
                    </section>
                    <h2 className="title-bar">Description</h2>
                    <div className="content-detail pb40" dangerouslySetInnerHTML={{__html: content}}></div>
                </div>
                <RelatedProductSlider />
            </div>
            <div className="modal add-to-cart-modal" id="add-to-cart-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <Button className="close" onClick={() => miS.closeModal("add-to-cart-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body">
                        <div className="add-to-cart-modal-header">
                            <div className="modal-header">
                                <div className="heading">Select Service Date</div>
                            </div>
                        </div>
                        <div className="select-date">
                            <div className="datebox">
                                <label className="date-label">Select Service Date</label>
                                <DatePicker
                                    onChange={orderDate => this.setState({ orderDate, chooseServiceTime: true })}
                                    minDate={tomorrow}
                                    startDate={tomorrow}
                                    selected={orderDate}
                                />
                            </div>
                            {
                                chooseServiceTime && buyType === 'add-to-cart' &&
                                <div className="datebox">
                                    <label className="date-label">Select ServiceTime Slot</label>
                                    <ul className="time-slots">
                                        { this._renderTimeSlots(timeSlot||[]) }
                                    </ul>
                                    <Button className="btn btn-primary mt20" onClick={() => {
                                        miS.closeModal("add-to-cart-modal")
                                        this._chooseActiveCar()
                                    }}>Proceed</Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal add-to-cart-modal" id="subscribe-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <Button className="close" onClick={() => miS.closeModal("subscribe-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body">
                        <div className="add-to-cart-modal-header">
                            <div className="modal-header">
                                <h2 className="heading">Select Time</h2>
                            </div>
                        </div>
                        {this._renderCarSubscription(subscriptionData || [] )}
                    </div>
                </div>
            </div>
        </>
    }
}