const Box = ({
    children = '',
    title = '',
    required = false,
    bodyClass = '',
    boxClass = '',
    overflown = true
}) => {
    let boxBodyClass = "body "+bodyClass
    boxBodyClass += overflown?" overflown" : ''
    const _toggleClose = e => {
        e.target.parentElement.classList.toggle("closed")
    }
    return <div className={`box ${boxClass}`}>
        <h4 className="head" onClick={_toggleClose}>{title}{required && <sup>*</sup>}</h4>
        <div className={boxBodyClass}>
            {children}
        </div>
    </div>
}

export default Box