import React, {Component} from 'react'
import {Banner} from '../../../widget'
import {MicroServiceService, MiscService, OrderService, UserService, RouterService, MetaService} from '../../../service'
import {base} from '../../../constant/Data'
import {SubscriptionData} from '../../../component'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Img, Button} from '../../../element'
import { timeSlot } from '../../../constant/Enum'
const uS = new UserService(),
mS = new MetaService(),
micS = new MicroServiceService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService()
export default class MicroServices extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state= {
            microServiceList: [],
            activeMicroService: '',
            orderDate: '',
            chooseServiceTime: false,
            activeTimeSlot: timeSlot[0],
            selectPick: false,
            showPick: false
        }
    }
    componentDidMount () {
        mS.setTitle('Micro Services', 'public')
        this._getMicroServicesData()
    }
    _getMicroServicesData = async () => {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        const result = await micS.getMicroServices()
        if (result.status) {
            if (result.data.length !== 0) {
                let microServiceList = []
                result.data.reverse().forEach(e => {
                    if (typeof e.trash === "undefined")
                        microServiceList.push(e)
                    else if (!e.trash)
                        microServiceList.push(e)
                })
                microServiceList.sort( this._compareByPublishDate )
                this.setState({ microServiceList:microServiceList.reverse()}, () => {
                    if (urlData[2] !== null && urlData[2] !== '' && urlData[2]) {
                        let element = document.querySelector("#"+urlData[2])
                        this.setState({ activeMicroService: urlData[2] })
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                })
            }
        }
    }
    _compareByPublishDate = ( a, b ) => {
        if ( (new Date(a.publishDate)) < (new Date(b.publishDate)) )
            return -1
        if ( (new Date(a.publishDate)) > (new Date(b.publishDate)) )
            return 1
        return 0
    }
    _chooseActiveCar = () => {
        let activeCar = miS.getActiveCar(),
        { activeMicroService, microServiceList } = this.state,
        prices = '[]', activeMicroServiceData = null, price = 0, found = false
        microServiceList.forEach(e => {
            if (activeMicroService === e.slug) {
                activeMicroServiceData = e
            }
        })
        prices = JSON.parse(JSON.parse(activeMicroServiceData.prices))
        if (activeCar === null) {
            miS.openCarSelectModal()
            this._checkForActiveCar()
        } else {
            prices.forEach(c => {
                if (activeCar.Segment.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    if (typeof c.saleprice === "undefined" || c.saleprice === 0 || c.saleprice === '') {
                        c.saleprice = c.price
                        if (uS.getActiveSubscription() !== null) {
                            c.saleprice = c.saleprice*0.9
                        }
                    }
                    price = Number(c.saleprice)
                    price += price*Number(activeMicroServiceData.tax)*0.01
                    price = String(price.toFixed(2))
                    c.saleprice = Number(c.saleprice)
                    c.saleprice = c.saleprice + c.saleprice*Number(activeMicroServiceData.tax)*0.01
                    c.saleprice = String(c.saleprice.toFixed(2))
                    c.price = Number(c.price)
                    c.price = c.price + c.price*Number(activeMicroServiceData.tax)*0.01
                    c.price = String(c.price.toFixed(2))
                    this._serviceAddedTemplate(price, activeMicroServiceData, c.price, c.saleprice)
                }
            })
        }
    }
    _checkForActiveCar = () => {
        let activeCar = miS.getActiveCar(),
        { activeMicroService, microServiceList } = this.state,
        prices = '[]', activeMicroServiceData = null, price = 0, found = false
        microServiceList.forEach(e => {
            if (activeMicroService === e.slug) {
                activeMicroServiceData = e
            }
        })
        prices = JSON.parse(JSON.parse(activeMicroServiceData.prices))
        if (activeCar === null) {
            miS.openCarSelectModal()
            this._checkForActiveCar()
        } else {
            prices.forEach(c => {
                if (activeCar.Segment.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    if (typeof c.saleprice === "undefined" || c.saleprice === 0 || c.saleprice === '') {
                        c.saleprice = c.price
                        if (uS.getActiveSubscription() !== null)
                            c.saleprice = c.saleprice*0.9
                    }
                    price = Number(c.saleprice)
                    price += price*Number(activeMicroServiceData.tax)*0.01
                    price = String(price.toFixed(2))
                    c.saleprice = Number(c.saleprice)
                    c.saleprice = c.saleprice + c.saleprice*Number(activeMicroServiceData.tax)*0.01
                    c.saleprice = String(c.saleprice.toFixed(2))
                    c.price = Number(c.price)
                    c.price = c.price + c.price*Number(activeMicroServiceData.tax)*0.01
                    c.price = String(c.price.toFixed(2))
                    this._serviceAddedTemplate(price, activeMicroServiceData, c.price, c.saleprice)
                }
            })
        }
    }
    _serviceAddedTemplate = (derivedPrice, item, mrp, saleprice) => {
        let { orderDate, activeTimeSlot, selectPick, activeMicroService } = this.state,
        activeCar = miS.getActiveCar(),
        price = derivedPrice
        let service = {
            orderDate: orderDate,
            uid: item.uid,
            activeTimeSlot: activeTimeSlot,
            activeCar,
            title: activeCar.registrationNo ? item.title + ' ['+activeCar.registrationNo+']' : item.title,
            image: JSON.parse(item.image)[0],
            price: price,
            purchaseQuantity: 1,
            type: 'service',
            tempUid: new Date().getTime(),
            tax: item.tax,
            hsn: item.hsn,
            mrp: mrp,
            saleprice: saleprice,
            selectPick: selectPick,
            taxAmount: Number((price * (Number(item.tax))/100).toFixed(2))
        };
        service.displayPrice = (service.price + service.taxAmount).toFixed(2)
        let productAddedTemplate = '<div class="alert-msg"><i class="hi-checkbox-checked"></i><p>Micro Service added to cart!</p></div>'+
        '<div class="service-box">'+
        '<img src="'+base.imageBaseUrl+JSON.parse(item.image)[0]+'" alt="'+item.title+'" class="service-image" />'+
        '<div class="service-content">'+
        '<p class="service-title">'+item.title+'</p>'+
        '<p class="service-price">₹ '+price+'</p>'+
        '</div>'+
        '</div>'
        oS.addToCart(service)
        if (activeMicroService !== 'pick-drop' && selectPick) {
            this.setState({ activeMicroService: 'pick-drop' }, this._chooseActiveCar)
        } else {
            this.setState({ chooseServiceTime: false, activeMicroService: '', orderDate: '', activeTimeSlot: timeSlot[0], activeCar: '', selectPick: false, showPick: false })
        }
        miS.showAlert({ type: 'custom', template: productAddedTemplate })
    }
    _buyItem = (buyType, item) => {
        if (item.slug !== 'pick-drop') {
            this.setState({ showPick: true })
        }
        let activeCar = miS.getActiveCar()
        if (activeCar !== null) {
            this.setState({ activeMicroService: item.slug }, () => miS.openModal("add-to-cart-modal"))
        } else {
            miS.openCarSelectModal()
        }
    }
    _renderMicroServiceList = serviceFiles => {
        let { activeMicroService } = this.state,
        activeCar = miS.getActiveCar(),
        pricesItem = [], prices = [], activePrice = 0, found = false
        return serviceFiles.map(( item, index ) => {
            activePrice = 0
            found = false
            pricesItem = JSON.parse(item.prices)
            prices = JSON.parse(pricesItem)
            prices.forEach(c => {
                if (activeCar !== null) {
                    if (activeCar.Segment.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                        found = true
                        activePrice = c
                    }
                }
            })
            if (item.status === 'publish')
            return <div className={activeMicroService===item.slug?'service-box-new mb30 active': 'service-box-new mb30'} key={index} id={item.slug}>
                <div className="service-content flex">
                    <div className="image">
                        <Img src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                    </div>
                    <div className="title-box">
                        <div className="title flex">
                            <div className="title-item"><h1>{item.title}</h1></div>
                            <div className="hrsTaken"><i className="hi-alarm"></i> Service Time - {item.serviceTime} Hrs</div>
                        </div>
                        <div className="about">
                            <div dangerouslySetInnerHTML={{__html: item.userNote}}></div>
                        </div>
                        <div className="points" dangerouslySetInnerHTML={{__html: item.excerpt}}></div>
                        <div className="addToCartbtn-service-page">
                            {
                                activePrice !== 0?
                                <>
                                    {
                                        typeof activePrice.saleprice === "undefined" || activePrice.saleprice === 0 || activePrice.saleprice === '' || activePrice.saleprice === activePrice.price ?
                                        <div className="mrp">
                                            <span className="black">₹ {activePrice.price}</span>
                                        </div>:
                                        <>
                                            <div className="mrp">
                                                <span className="black">₹ {activePrice.saleprice}</span>
                                            </div>
                                            <div className="sale-price">
                                                <span className="black">₹ {activePrice.price}</span>
                                            </div>
                                            <div className="per-off">
                                                <span className="green">{((activePrice.price - activePrice.saleprice)/activePrice.price * 100).toFixed(0)} % off</span>
                                            </div>
                                        </>
                                    }
                                </>:<>
                                {
                                    activeCar === null &&
                                    <span className="get-price" onClick={miS.openCarSelectModal}><i className="hi-directions_car"></i> Get Price</span>
                                }
                                </>
                            }
                            {
                                uS.getActiveSubscription() === null &&
                                <p className="get-off" onClick={() => miS.openModal("get-subscription-modal")}>Get 10% off <i className="hi-info"></i> </p>
                            }
                            {(!uS.isUserLoggedIn() || (uS.isUserLoggedIn() && this.user?.role && !['manager', 'company', 'branch', 'representative'].includes(this.user?.role))) && <Button className="btn btn-primary" onClick= {() => this._buyItem('add-to-cart', item)}><i className="hi-cart"></i> Add to Cart</Button>}
                        </div>
                    </div>
                </div>
            </div>
            else return true
        })
    }
    _renderTimeSlots = timeSlot => {
        let { activeTimeSlot } = this.state
        return timeSlot.map(( item, index ) => {
            return <div className={activeTimeSlot.slot === item.slot ? 'timeSlotSingle active': 'timeSlotSingle'} onClick={() => {
                this.setState({ activeTimeSlot: item })
            }} key={index}>
                {
                    activeTimeSlot.slot === item.slot?
                    <i className="success hi-check_circle"></i>:
                    <i className="hi-circle ashgray"></i>
                }
                {item.slot}
            </div>
        })
    }
    render() {
        let { microServiceList, orderDate, selectPick, chooseServiceTime, showPick } = this.state,
        today = new Date(),
        tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        return <>
            <Banner title="Micro Services" image="yellow-back-1.jpg" />
            <div className="jumbo-container">
                <section className="service-offer-section pt30">
                    <div className="wrapper">
                        <div className="middle-setup">
                            <div className="section-title-wrap tac">
                                <div className="section-title pb30">
                                    <h2 className="heading-02 pb10">Best Micro Services</h2>
                                    <p className="mb0">WE PROMISE TO PROVIDE REALS FOR REAL WHEELS. Our one of a kind service include</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="jumbo-container">
                {this._renderMicroServiceList(microServiceList || [] )}
            </div>
            <div className="modal" id="get-subscription-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content big">
                    <Button className="close" onClick={() => miS.closeModal("get-subscription-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        <SubscriptionData /> 
                    </div>
                </div>
            </div>
            <div className="modal add-to-cart-modal" id="add-to-cart-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content overflown small">
                    <Button className="close" onClick={() => miS.closeModal("add-to-cart-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body">
                        <div className="add-to-cart-modal-header">
                            <div className="modal-header">
                                <div className="heading">Select Service Date</div>
                            </div>
                        </div>
                        <div className="select-date">
                            <div className="datebox">
                                <label className="date-label">Select Service Date</label>
                                <DatePicker
                                    onChange={orderDate => this.setState({ orderDate, chooseServiceTime: true })}
                                    minDate={today}
                                    startDate={today}
                                    selected={orderDate}
                                />
                            </div>
                            {
                                chooseServiceTime &&
                                <div className="datebox">
                                    <label className="date-label">Select ServiceTime Slot</label>
                                    <div className="time-slots">
                                        { this._renderTimeSlots(timeSlot||[]) }
                                    </div>
                                    {
                                        showPick && miS.isModuleEnabled(['Pick&Drop']) &&
                                        <div className="pick-and-drop" onClick={() => this.setState({ selectPick: !selectPick})}>
                                            {
                                                selectPick ?
                                                <i className="success hi-check_circle"></i>:
                                                <i className="hi-circle ashgray"></i>
                                            }
                                            <span>Pick & Drop</span>
                                        </div>
                                    }
                                    <Button className="btn btn-primary mt20" onClick={() => {
                                        miS.closeModal("add-to-cart-modal")
                                        this._chooseActiveCar()
                                    }}>Proceed</Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}