import React, {Component} from 'react'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react'
import {base,mainUrl} from '../../../constant/Data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CreatableSelect from 'react-select/creatable'
import {robotsOption, statusOption} from '../../../constant/Enum'
import {MiscService, DataService, RouterService, UserService, MetaService} from '../../../service'
import {Img, Button, BackIcon} from '../../../element'
import {Box, MediaBox} from '../../../component'
const pS = new DataService('project'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
keywordOptions =  [],
categoryOptions = [],
tagOptions = []
export default class AddProject extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeRobotsIndex: 0,
            activeStatusIndex: 0,
            image: '[]',
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            description: "",
            url: "",
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            releaseDate: new Date(),
            status: '',
            category: '',
            tag: '',
        }
    }
    componentDidMount () {
        mS.setTitle('Add Project', 'admin')
        let { activeStatusIndex } = this.state
        this.setState({ status: statusOption[activeStatusIndex].value })
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getProjectData(query)
        }
    }
    _getProjectData = async query => {
        const result = await pS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let projectData = result.data[0],
            metaKeyword = [],
            tag = [],
            category = [],
            activeRobotsIndex = 0,
            activeStatusIndex = 0
            robotsOption.forEach(( e, i ) => {
                if (e.value === projectData.robots) {
                    activeRobotsIndex = i
                }
            })
            statusOption.forEach(( e, i ) => {
                if (e.value === projectData.status) {
                    activeStatusIndex = i
                }
            })
            if (projectData.metaKeyword === '""' || projectData.metaKeyword === '') {
                metaKeyword = []
            } else {
                JSON.parse(projectData.metaKeyword).forEach(e => {
                    metaKeyword.push(e)
                })
            }
            if (projectData.category === '""' || projectData.category === '') {
                category = []
            } else {
                JSON.parse(projectData.category).forEach(e => {
                    category.push(e)
                })
            }
            if (projectData.tag === '""' || projectData.tag === '') {
                tag = []
            } else {
                JSON.parse(projectData.tag).forEach(e => {
                    tag.push(e)
                })
            }
            this.setState({
                title: projectData.title,
                mode: 'Edit',
                slug: projectData.slug,
                description: projectData.description,
                url: projectData.url,
                canonical: projectData.canonical,
                robots: projectData.robots,
                metaDescription: projectData.metaDescription,
                metaKeyword,
                releaseDate: new Date(projectData.releaseDate),
                status: projectData.status,
                tag,
                uid: projectData.uid,
                image: projectData.image,
                activeRobotsIndex,
                activeStatusIndex
            })
        } else miS.showAlert({ type: 'error', msg: 'No project added yet!' })
    }
    _addProject = async e => {
        e.preventDefault()
        let { mode, title, slug, description, url, canonical, robots, metaDescription, metaKeyword, releaseDate, status, category, tag, uid, image } = this.state,
        error = false,
        errorMsg = '',
        projectObject = {
            title,
            slug,
            description,
            url,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            releaseDate,
            status: status.value,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            image
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (description === '') {
            errorMsg = 'Please enter the description'
            error = true
        } else if (url === '') {
            errorMsg = 'Please enter the url'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                projectObject.uid = new Date().getTime()
                const result = await pS.save(projectObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Project added successfully!' })
                    rS.navigateTo('/admin/project/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add project!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    projectData: JSON.stringify(projectObject)
                }
                const result = await pS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Project updated successfully!' })
                    rS.navigateTo('/admin/project/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update project!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        {status, robots,  releaseDate, mode, renderSlug, title, slug, description, url, canonical, activeRobotsIndex, activeStatusIndex, metaDescription, metaKeyword, category, tag, image } = this.state,
        pageTitle = mode + ' Project'
        if (canonical === '') {
            canonical = mainUrl+ urlData[2]+'/'+slug
        }
        let activeRobots = robotsOption[activeRobotsIndex],
        activeStatus = statusOption[activeStatusIndex]
        if (mode === 'Edit') {
            statusOption.forEach(e => {
                if (e.value === status) {
                    activeStatus = e
                }
            })
            robotsOption.forEach(e => {
                if (e.value === robots) {
                    activeRobots = e
                }
            })
        }
        return <>
            <h1>
                <BackIcon backLink='/admin/project/list' />
                {pageTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._addProject}>
                <div className="left">
                    <Box title='Title' required>
                        <input autoFocus type="text" required name="title" value={title} onChange={e => {
                            let tempTitle = e.target.value, tempSlug = slug
                            if (renderSlug) {
                                tempSlug = miS.slugify(tempTitle)
                            }
                            this.setState({ title: tempTitle, slug: tempSlug})
                        }} />
                    </Box>
                    <Box title='Slug' required>
                        <input type="text" required name="slug" value={slug} onChange={(e) => this.setState({ slug: e.target.value })} />
                    </Box>
                    <Box title='Content' required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            value={description}
                            init={base.tinyMceEditorOptions}
                            onEditorChange={(description, editor) => {
                                this.setState({ description })
                            }}
                        />
                    </Box>
                    <Box title='Excerpt' required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={url}
                            onEditorChange={(url, editor) => {
                                this.setState({ url })
                            }}
                        />
                    </Box>
                    <Box title='Canonical URL'>
                        <input type="text" required name="canonical" value={canonical} onChange={(e) => this.setState({ canonical: e.target.value })} />
                    </Box>
                    <Box title='Robots'>
                        <Select
                            value={activeRobots}
                            defaultValue={activeRobots}
                            onChange={e => this.setState({ robots: e.value })}
                            options={robotsOption}
                        />
                    </Box>
                    <Box title='Meta Description'>
                        <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={(e) => this.setState({ metaDescription: e.target.value })}></textarea>
                    </Box>
                    <Box title='Meta Keywords'>
                        <CreatableSelect
                            isMulti
                            onChange={e => this.setState({ metaKeyword: e })}
                            options={keywordOptions}
                            value={metaKeyword}
                        />
                    </Box>
                </div>
                <div className="right">
                    <Box title='Meta'>
                        <p>
                            Render Slug:
                            <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={() => this.setState({ renderSlug:!renderSlug})}></span>
                        </p>
                        <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                    </Box>
                    <Box title='Release Date'>
                        <DatePicker
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeFormat="HH:mm"
                            onChange={releaseDate => this.setState({ releaseDate })}
                            selected={releaseDate}
                        /> 
                    </Box>
                    <Box title='Status'>
                        <Select
                            value={status}
                            // defaultValue={activeStatus}
                            onChange={e => this.setState({ status: e })}
                            options={statusOption}
                        />
                    </Box>
                    <Box title='Category'>
                        <CreatableSelect
                            isMulti
                            value={category}
                            onChange={e => this.setState({ category: e })}
                            options={categoryOptions}
                        />
                    </Box>
                    <Box title='Tags'>
                        <CreatableSelect
                            isMulti
                            value={tag}
                            onChange={e => this.setState({ tag: e })}
                            options={tagOptions}
                        />
                    </Box>
                    <MediaBox image={image} setimage={image => this.setState({image})} />
                </div>
            </form>
        </>
    }
}