import React, {Component} from 'react'
import {LocationService, MiscService, UserService, RouterService, MetaService} from '../../../service'
import {Button, BackIcon} from '../../../element'
const uS = new UserService(),
mS = new MetaService(),
locS = new LocationService(),
miS = new MiscService(),
rS = new RouterService()
export default class AddAddress extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            building: '',
            locality: '',
            pinCode: '',
            city: '',
            state: '',
            landmark: '',
            alternateNo: '',
            uid: '',
            mode: 'Add',
            societyData: [],
            activeSociety: null,
            showSocietyListBox: false,
            fromSociety: true,
            fullAddress: ''
        }
    }
    componentDidMount () {
        mS.setTitle('Add Address', 'public')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[2] === 'edit') {
            let query = {
                uid: urlData[3]
            }
            this._getAddressData(query)
        }
        if(miS.isModuleEnabled(["Society"])) this._getSocietiesListData()
    }
    _getSocietiesListData = async () => {
        const result = await locS.getSocities()
        if (result.status) {
            let societyData = result.data.reverse()
            this.setState({ societyData })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No society added yet!' })
        } else
            miS.showAlert({ type: 'error', msg: 'No society added yet!' })
    }
    _getAddressData = async query => {
        const result = await locS.getLocation({ query: JSON.stringify(query) })
        if (result.status) {
            let addressData = result.data[0]
            this.setState({
                mode: 'Edit',
                building: addressData.building,
                locality: addressData.locality,
                pinCode: addressData.pinCode,
                city: addressData.city,
                state: addressData.state,
                landmark: addressData.landmark,
                alternateNo: addressData.alternateNo,
                uid: addressData.uid,
                activeSociety: JSON.parse(addressData.activeSociety)
            })
        } else
            miS.showAlert({ type: 'error', msg: 'No address added yet!' })
    }
    _addLocation = async e => {
        e.preventDefault()
        let { building, locality, pinCode, city, state, landmark, alternateNo, mode, uid, activeSociety } = this.state,
        error = false,
        errorMsg = '',
        locationObject = {
            building,
            locality,
            pinCode,
            city,
            state,
            landmark,
            alternateNo,
            activeSociety: JSON.stringify(activeSociety)
        }
        if (building === '') {
            errorMsg = 'Please enter Building / Flat No.'
            error = true
        } else if (locality === '') {
            errorMsg = 'Please enter the Locality'
            error = true
        } else if (pinCode === '') {
            errorMsg = 'Please select the PinCode'
            error = true
        } else if (city === '') {
            errorMsg = 'Please select a City'
            error = true
        } else if (state === '') {
            errorMsg = 'Please select a State'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                locationObject.uid = new Date().getTime()
                locationObject.ownerId = ['manager','representative'].includes(this.user.role) ? this.user.parent?.value : this.user.uid
                const result = await locS.addLocation(locationObject)
                    if (result.status) {
                        miS.showAlert({ type: 'success', msg: 'Address added successfully!' })
                        rS.navigateTo('/address/list')
                    } else
                        miS.showAlert({ type: 'error', msg: 'Unable to add address!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    locationData: JSON.stringify(locationObject)
                }
                const result = await locS.updateLocation(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Address updated successfully!' })
                    rS.navigateTo('/address/list')
                } else miS.showAlert({ type: 'error', msg: 'Unable to update address!' })
            }
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _renderSocietyList = carList => {
        let { activeSociety } = this.state
        return carList.map(( item, index ) => {
            let activeClass = ''
            if (activeSociety !== null)
                activeClass = activeSociety.title === item.title? 'active': ''
            return <li key={index} className={activeClass} onClick={() => {
                this.setState({ activeSociety: item, showSocietyListBox: false }, () => {
                    let activeaddress = JSON.parse(item.addressDetail), pinCode ='', locality= '', city= '', state = ''
                    activeaddress.address_component?.forEach(e => {
                        if (e.types[0]==="postal_code")
                            pinCode = e.long_name
                        if (e.types[0]==="administrative_area_level_1")
                            state = e.long_name
                        if (e.types[0]==="administrative_area_level_2")
                            city = e.long_name
                        if (e.types[0]==="locality")
                            locality = e.long_name
                    })
                    this.setState({ locality, pinCode, city, state, fullAddress: activeaddress.formatted_address })
                })
            }}>
                <p>{item.title}</p>
            </li>
        })
    }
    render() {
        let { building, locality, pinCode, city, state, landmark, alternateNo, mode, societyData, activeSociety, showSocietyListBox, fromSociety, fullAddress } = this.state
        return <div className="right-side-profile-content">
            <div className="profile-sec wrapper">
                <div className="form-editable-section">
                    <div className="form-box">
                        <div className="account-detail">
                            <form className="col-9" noValidate onSubmit={this._addLocation}>
                                <h1>
                                    <BackIcon backLink='/address/list' />
                                    {mode} Address
                                </h1>
                                {
                                    miS.isModuleEnabled(["Society"]) && fromSociety && mode === 'Add' ?
                                    <div className="relative">
                                        <div className="form-group">
                                            <label className="col-3 control-label">Select your society</label>
                                            <div className="col-9 controls relative">
                                                {
                                                    activeSociety === null ?
                                                    <Button type="button" className="btn btn-primary" onClick={() => this.setState({ showSocietyListBox: !showSocietyListBox})}>Show Societies List</Button>
                                                    :<>
                                                        <div className="selected-society-type" onClick={() => this.setState({ showSocietyListBox: !showSocietyListBox})}>
                                                            <p>{activeSociety.title}</p>
                                                        </div>
                                                        <small className="ashgray">({ fullAddress })</small>
                                                    </>
                                                }
                                                {
                                                    showSocietyListBox &&
                                                    <ul className="scroll-box mt-10">
                                                        { this._renderSocietyList(societyData || []) }
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                        {
                                            activeSociety !== null &&
                                            <div className="filled-society-data">
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Address (Building/Flat) <sup>*</sup>: </label>
                                                    <div className="col-9 controls">
                                                        <input className="input" autoFocus placeholder="Address (Building/Flat)" type="text" required name="building" value={building||''} onChange={(e) => this.setState({ building: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <input className="input" placeholder="Locality (Street/Area)" type="text" name="locality" value={locality||''} onChange={(e) => this.setState({ locality: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <input className="input" placeholder="Pincode" type="number" disabled name="pinCode" value={pinCode||''} onChange={(e) => this.setState({ pinCode: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <input className="input" placeholder="City/District/Town" type="text" disabled name="city" value={city||''} onChange={(e) => this.setState({ city: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">State <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <input className="input" placeholder="State" type="text" disabled name="state" value={state||''} onChange={(e) => this.setState({ state: e.target.value })} />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Alternate Phone No. </label>
                                                    <div className="col-9 controls">
                                                        <input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={(e) => this.setState({ alternateNo: e.target.value })} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="link mt20" onClick={() => this.setState({ fromSociety: !fromSociety})}>Not living in above listed society? Click Here</div>
                                    </div>:
                                    <>
                                        <div className="form-group">
                                            <label className="col-3 control-label">Address (Building/Flat) <sup>*</sup>: </label>
                                            <div className="col-9 controls">
                                                <input className="input" autoFocus placeholder="Address (Building/Flat)" type="text" required name="building" value={building||''} onChange={(e) => this.setState({ building: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                            <div className="col-9 controls">
                                                <input className="input" placeholder="Locality (Street/Area)" type="text" required name="locality" value={locality||''} onChange={(e) => this.setState({ locality: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                            <div className="col-9 controls">
                                                <input className="input" placeholder="Pincode" type="number" required name="pinCode" value={pinCode||''} onChange={(e) => this.setState({ pinCode: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                            <div className="col-9 controls">
                                                <input className="input" placeholder="City/District/Town" type="text" required name="city" value={city||''} onChange={(e) => this.setState({ city: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label">State <sup>*</sup></label>
                                            <div className="col-9 controls">
                                                <input className="input" placeholder="State" type="text" required name="state" value={state||''} onChange={(e) => this.setState({ state: e.target.value })} />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="form-group">
                                            <label className="col-3 control-label">Landmark </label>
                                            <div className="col-9 controls">
                                                <input className="input" placeholder="Landmark" type="text" required name="landmark" value={landmark||''} onChange={(e) => this.setState({ landmark: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label">Alternate Phone No. </label>
                                            <div className="col-9 controls">
                                                <input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={(e) => this.setState({ alternateNo: e.target.value })} />
                                            </div>
                                        </div>
                                    </>
                                }
                                <Button className="btn btn-primary btn-small addlocationbtn" type="submit">Submit</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}