import React, {Component} from 'react'
import {Banner} from '../../../widget'
import Select from 'react-select'
import {base} from '../../../constant/Data'
import {ProductService, MiscService, OrderService, RouterService, MetaService} from '../../../service'
import {Img, Button} from '../../../element'
import { sortOption } from '../../../constant/Enum'
const commonPageSize = 50,
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService(),
prS = new ProductService()
export default class Shop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            derivedCategory: '',
            derivedTag: '',
            filterTags: [],
            productList: [],
            filteredProductsList: [],
            selectedCategory: [],
            selectedBrands: [],
            filterCategoryList: [],
            filterBrandList: [],
            maxPrice: 50000,
            minPrice: 0,
            filteredPrice: 50000,
            loadMoreBtn: true,
            activeShownTotal: commonPageSize,
            selectedFilter: sortOption[1]
        }
    }
    _handleChange = selectedFilter => {
        let { filteredProductsList } = this.state
        this.setState({ selectedFilter }, () => {
            if (selectedFilter.value === 'name')
            filteredProductsList.sort( this._compareByName )
            else
            filteredProductsList.sort( this._compareByPrice )
            this.setState({ filteredProductsList })
        })
    }
    componentDidMount () {
        mS.setTitle('Accessories', 'public')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname), derivedCategory = '', derivedTag = ''
        if (urlData[3])
            derivedTag = urlData[3]
        else if (urlData[2])
            derivedCategory = urlData[2]
        this.setState({ derivedCategory: derivedCategory, derivedTag: derivedTag }, () => {
            this._getProductCategoryData()
            this._getProductBrandData()
            this._getProductsData()
        })
        document.addEventListener('scroll', this._trackScrolling)
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredProductsList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredProductsList.length > 24 && activeShownTotal < filteredProductsList.length)
                this._showMore()
        }
    }
    _renderFilterTags = filterTags => {
        return filterTags.map(( item, index ) => {
            return <li onClick={() => {
                if (item.type === 'brand')
                    this._toggleSelectedBrands(item, 'pop')
                else
                    this._toggleSelectedCategory(item, 'pop')
            }} key={index}>{item.label} <i className="hi-close"></i></li>
        })
    }
    _getProductsData = async () => {
        let { selectedFilter, derivedCategory, derivedTag } = this.state
        const result = await prS.getProducts()
        if (result.status) {
            if (result.data.length !== 0) {
                let newProductList = result.data.reverse(), productList = [], tableData = []
                newProductList.forEach(e => {
                    let found = false
                    if (derivedTag !== '')
                        found = e.tag.includes(derivedTag)
                    else if (derivedCategory !== '') {
                        JSON.parse(e.category).forEach(c => {
                            if (c.value === derivedCategory)
                                found = true
                        })
                    } else
                        found = true
                    if (found)
                        productList.push(e)
                })
                productList.forEach(e => {
                    if (typeof e.trash === "undefined")
                        tableData.push(e)
                    else if (!e.trash)
                        tableData.push(e)
                })
                this.setState({ productList: tableData, filteredProductsList: tableData}, () => {
                    this._handleChange(selectedFilter)
                })
            }
        }
    }
    _getProductCategoryData = async () => {
        const result = await prS.getProductCategory()
        if (result.data.length !== 0)
            this.setState({ filterCategoryList: result.data })
    }
    _getProductBrandData = async () => {
        const result = await prS.getProductBrand()
        if (result.data.length !== 0) {
            this.setState({ filterBrandList: result.data })
        }
    }
    _productAddedTemplate = item => {
        let price = item.price
        if ( item.saleprice && item.saleprice !== 0 && item.saleprice !== '' && item.saleprice !== item.mrp ) {
            price = item.saleprice
        }
        let prodcutAddedTemplate = '<div class="alert-msg"><i class="hi-checkbox-checked"></i><p>Product added to cart!</p></div>'+
        '<div class="product-box">'+
        '<img src="'+base.imageBaseUrl+item.image+'" alt="'+item.title+'" class="product-image" />'+
        '<div class="product-content">'+
        '<p class="product-title">'+item.title+'</p>'+
        '<p class="product-price">₹ '+item.displayPrice+'</p>'+
        '</div>'+
        '</div>'
        miS.showAlert({ type: 'custom', template: prodcutAddedTemplate })
        this.setState({ activeCarType: null })
        setTimeout(() => {
            this.setState({ activeCarType: null, activeSubscriptionType: null })
        }, 2000)
    }
    _renderShopProducts = shopFiles => {
        let { activeShownTotal } = this.state
        return shopFiles.map(( item, index ) => {
            let averageRating = 0
            if (item.feedback) {
                if (item.feedback.length>0) {
                    item.feedback.forEach(e => {
                        averageRating += e.rating
                    })
                    averageRating = averageRating/item.feedback.length
                }
            }
            if (item.status === 'publish' && index < activeShownTotal)
                return <div className="grid-list-column-item" key={index}>
                    {
                        item.isAssured &&
                        <Img className="assuredImg" src={base.imageBaseUrl+'assured.png'} alt={item.title} />
                    }
                    <div className="thumb">
                        {
                            <Img src={base.imageBaseUrl+(item.image === '' || item.image === '[]' || typeof item.image === "undefined"?'product-placeholder.png':JSON.parse(item.image)[0])} alt={item.title} />
                        }
                    </div>
                    <h5 className="title pt10"><a href={"/product/"+item.slug}>{item.title}</a></h5>
                    {
                        item.feedback &&
                        <div className="common-rating-style">
                            { miS.renderRatingSystem(Math.ceil(averageRating)) }
                        </div>
                    }
                    <div className="common-price-style">
                        {
                            typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' || item.saleprice === item.mrp ?
                            <div className="mrp">
                                <span className="black">₹ {item.price}</span>
                            </div>:
                            <>
                                <div className="mrp">
                                    <span className="black">₹ {item.saleprice}</span>
                                </div>
                                <div className="sale-price">
                                    <span className="black">₹ {item.price}</span>
                                </div>
                                <div className="per-off">
                                    <span className="green">{(( item.price - item.saleprice)/item.price * 100).toFixed(0)} % off</span>
                                </div>
                            </>
                        }
                    </div>
                    <div className="main-btn-wrap pt20">
                        {
                            item.stock === 0 || item.stock === '0' || typeof item.stock === "undefined"?
                            <Button type="button" disabled className="btn-gray btn add-to-cart mauto" title="Out Of Stock">Out Of Stock</Button>:
                            <Button className="btn btn-primary btn-box mauto" onClick={() => this._addToCart(item)}>Add To Cart</Button>
                        }
                    </div>
                </div>
            else return true
        })
    }
    _addToCart = item => {
        let price = item.price, mrp = price, saleprice = item.saleprice
        if ( item.saleprice && item.saleprice !== 0 && item.saleprice !== '' && item.saleprice !== item.mrp )
            price = saleprice
        let product = {
            uid: item.uid,
            title: item.title,
            image: JSON.parse(item.image)[0],
            purchaseQuantity: 1,
            type: 'product',
            tempUid: new Date().getTime(),
            mrp: Number(mrp),
            saleprice: saleprice,
            tax: item.tax,
            hsn: item.hsn,
            sku: item.sku,
            taxAmount: Number((price * Number(item.tax)/(100+Number(item.tax))).toFixed(2))
        };
        product.price = price - product.taxAmount
        product.displayPrice = price
        oS.addToCart(product)
        this._productAddedTemplate(product)
    }
    _renderFeaturedProductsList = productFiles => {
        return productFiles.map(( item, index ) => {
            let averageRating = 0
            if (item.feedback) {
                if (item.feedback.length>0) {
                    item.feedback.forEach(e => {
                        averageRating += e.rating
                    })
                    averageRating = averageRating/item.feedback.length
                }
            }
            return <li className="single-recent-post-item grid-list-column-item" key={index}>
                {
                    item.isAssured &&
                    <Img className="assuredImg" src={base.imageBaseUrl+'assured.png'} alt={item.title} />
                }
                <div className="thumb">
                    {
                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                        <Img src={base.imageBaseUrl+'product-placeholder.png'} alt={item.title} />:
                        <Img className="pointer" onClick={() => rS.navigateTo('/product/'+item.slug)} src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                    }
                </div>
                <div id="content-box" className="content">
                    <a href={"/product/"+item.slug}>{item.title}</a>
                    <div className="common-rating-style left">
                    { miS.renderRatingSystem(Math.ceil(averageRating)) }
                    </div>
                    <div className="common-price-style priceProductPage">
                        {
                            typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' || item.saleprice === item.mrp ?
                            <div className="mrp">
                                <span className="black">₹ {item.price}</span>
                            </div>:
                            <>
                                <div className="mrp">
                                    <span className="black">₹ {item.saleprice}</span>
                                </div>
                                <div className="sale-price">
                                    <span className="black">₹ {item.price}</span>
                                </div>
                                <div className="per-off">
                                    <span className="green">{(( item.price - item.saleprice)/item.price * 100).toFixed(0)} % off</span>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </li>
        })
    }
    _toggleSelectedCategory = (category, action) => {
        let { derivedTag, selectedCategory, filteredProductsList, productList, filterTags } = this.state, newFilteredProductsList = []
        if (action === 'push') {
            let cat = {
                label: category.catName,
                value: category.catSlug,
                type: 'category'
            }
            filterTags.push(cat)
            selectedCategory.push(cat)
        } else {
            let newSelectedCategory = [], newFilterTags = []
            selectedCategory.forEach(e => {
                if (category.catSlug) {
                    if (e.value !== category.catSlug)
                        newSelectedCategory.push(e)
                } else {
                    if (e.value !== category.value)
                        newSelectedCategory.push(e)
                }
            })
            filterTags.forEach(e => {
                if (category.catSlug) {
                    if (e.value !== category.catSlug)
                        newFilterTags.push(e)
                } else {
                    if (e.value !== category.value)
                        newFilterTags.push(e)
                }
            })
            filterTags = newFilterTags
            selectedCategory = newSelectedCategory
        }
        if (selectedCategory.length === 0) {
            filteredProductsList = productList
        } else {
            productList.forEach(e => {
                let found = false
                if (derivedTag !== '')
                    found = e.tag.includes(derivedTag)
                JSON.parse(e.category).forEach(c => {
                    selectedCategory.forEach(sc => {
                        if (c.value === sc.value)
                            found = true
                    })
                })
                if (found)
                    newFilteredProductsList.push(e)
                filteredProductsList = newFilteredProductsList
            })
        }
        this.setState({ selectedCategory, filteredProductsList, filterTags: filterTags })
    }
    _toggleSelectedBrands = (brands, action) => {
        let { filterTags, selectedBrands, filteredProductsList, productList } = this.state, newFilteredProductsList = []
        if (action === 'push') {
            let brand = {
                label: brands.brandName,
                value: brands.brandSlug,
                type: 'brand'
            }
            filterTags.push(brand)
            selectedBrands.push(brand)
        } else {
            let newSelectedBrands = [], newFilterTags = []
            selectedBrands.forEach(e => {
                if (brands.brandSlug) {
                    if (e.value !== brands.brandSlug)
                        newSelectedBrands.push(e)
                } else {
                    if (e.value !== brands.value)
                        newSelectedBrands.push(e)
                }
            })
            filterTags.forEach(e => {
                if (brands.brandSlug) {
                    if (e.value !== brands.brandSlug)
                        newFilterTags.push(e)
                } else {
                    if (e.value !== brands.value)
                        newFilterTags.push(e)
                }
            })
            filterTags = newFilterTags
            selectedBrands = newSelectedBrands
        }
        if (selectedBrands.length === 0) {
            filteredProductsList = productList
        } else {
            productList.forEach(e => {
                let found = false
                if (e.brand && e.brand !== null && e.brand !== {} && e.brand !== '') {
                    selectedBrands.forEach(sc => {
                        let brand = JSON.parse(e.brand)
                        if (brand !== null && brand) {
                            if (sc.value === brand.value)
                                found = true
                        }
                    })
                    if (found)
                        newFilteredProductsList.push(e)
                    filteredProductsList = newFilteredProductsList
                }
            })
        }
        this.setState({ selectedBrands: selectedBrands, filteredProductsList, filterTags: filterTags })
    }
    _renderSubCtegories = (filterCategoryList, parentName) => {
        let { selectedCategory } = this.state
        return filterCategoryList?.map(( item, index ) => {
            let isChecked = false
            selectedCategory.forEach(e => {
                if (e.value === item.catSlug)
                    isChecked = true
            })
            if (item.parentCat === parentName) {
                return <div className="select-category-box select-subcategory-box" key={index}>
                    {
                        isChecked ?
                        <i className="hi-checkbox orange" onClick={() => this._toggleSelectedCategory(item, 'pop')}></i>:
                        <i className="hi-checkbox-unchecked1" onClick={() => this._toggleSelectedCategory(item, 'push')}></i>
                    }
                    <span>{item.catName}</span>
                </div>
            } else return true
        })
    }
    _renderFilterCategoryList = filterCategoryList => {
        let { selectedCategory, derivedCategory } = this.state
        return filterCategoryList?.map(( item, index ) => {
            let isChecked = false
            selectedCategory.forEach(e => {
                if (e.value === item.catSlug)
                    isChecked = true
            })
            if (derivedCategory === '') {
                if (item.parentCat === '')
                    return <div className="select-category-box" key={index}>
                        {
                            isChecked ?
                            <i className="hi-checkbox orange" onClick={() => this._toggleSelectedCategory(item, 'pop')}></i>:
                            <i className="hi-checkbox-unchecked1" onClick={() => this._toggleSelectedCategory(item, 'push')}></i>
                        }
                        <a href={"/accessories/"+item.catSlug}>{item.catName}</a>
                        { this._renderSubCtegories(filterCategoryList || [], item.catName) }
                    </div>
                else
                return true
            } else {
                if (item.parentCat === '' && item.catSlug === derivedCategory) {
                    return <div className="select-category-box" key={index}>
                        <i className="hi-checkbox orange"></i>
                        <span>{item.catName}</span>
                        { this._renderSubCtegories(filterCategoryList || [], item.catName) }
                    </div>
                }
                else
                return true
            }
        })
    }
    _renderFilterBrandList = filterBrandList => {
        let { selectedBrands } = this.state
        return filterBrandList?.map(( item, index ) => {
            let isChecked = false
            selectedBrands.forEach(e => {
                if (e.value === item.brandSlug)
                    isChecked = true
            })
            return <div className="select-category-box" key={index}>
                {
                    isChecked ?
                    <i className="hi-checkbox orange" onClick={() => this._toggleSelectedBrands(item, 'pop')}></i>:
                    <i className="hi-checkbox-unchecked1" onClick={() => this._toggleSelectedBrands(item, 'push')}></i>
                }
                <span>{item.brandName}</span>
            </div>
        })
    }
    _compareByName = ( a, b ) => {
        if ( a.title < b.title )
            return -1
        if ( a.title > b.title )
            return 1
        return 0
    }
    _compareByPrice = ( a, b ) => {
        if ( Number(a.price) < Number(b.price) )
            return -1
        if ( Number(a.price) > Number(b.price) )
            return 1
        return 0
    }
    _filterByPrice = price => {
        let { selectedCategory, filteredProductsList, productList, derivedTag } = this.state, newFilteredProductsList = []
        this.setState({ filteredPrice: price }, () => {
            productList.forEach(e => {
                let found = false
                if (derivedTag !== '') {
                    found = e.tag.includes(derivedTag)
                } else if (selectedCategory.length !== 0) {
                    JSON.parse(e.category).forEach(c => {
                        selectedCategory.forEach(sc => {
                            if (c.value === sc.value) {
                                found = true
                            }
                        })
                    })
                } else
                    found = true
                if (Number(e.price) > Number(price))
                    found = false
                if (found)
                    newFilteredProductsList.push(e)
                filteredProductsList = newFilteredProductsList
            })
            this.setState({ selectedCategory, filteredProductsList })
        })
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        this.setState({ loadMoreBtn: false })
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ loadMoreBtn: true, activeShownTotal: activeShownTotal + 24})
        }, 500)
    }
    render() {
        const { derivedTag, derivedCategory, selectedFilter, filterBrandList, filterCategoryList, filteredProductsList, productList, minPrice, maxPrice, filteredPrice, filterTags } = this.state, featuredProductsList = []
        productList.forEach(( e, i ) => {
            if (featuredProductsList.length <= 10 && e.status === 'publish' && e.isFeatured)
                featuredProductsList.push(e)
        })
        let pageTitle = derivedCategory ===''?'Accessories': derivedCategory.replace(/-/g, ' ')
        return <>
            <Banner title={pageTitle} image="white-car-banner.jpg"/>
            <div onClick={() => miS.toggleBodyClass('cart-open')} className="backgroundFixedShopFilter"></div>
            <div className="product-page shop-page">
                <section className="product-page-section">
                    <div className="product-page-body">
                        <div className="product-right-wrapper pt40">
                            <Button className="btn btn-primary btn-small btn-filter-done" onClick={() => miS.toggleBodyClass('cart-open')}>Done</Button>
                            <h5 className="filters-heading">Filters</h5>
                            <div className="widget blog-bg">
                                <h5 className="widget-title border-bottom">Filter By Price</h5>
                                <div className="price-filter">
                                    <input type="range" step={500} min={minPrice} max={maxPrice} value={filteredPrice} onChange={e => {
                                        this._filterByPrice(e.target.value)
                                    }} />
                                    <div className="filterPrice">
                                        <span>₹ {minPrice}</span> <span>₹ {maxPrice}</span>
                                    </div>
                                    <div className="priceShow" style={{marginLeft:filteredPrice*100/maxPrice+'%' }}> {"₹"+ filteredPrice}</div>
                                </div>
                            </div>
                            <div className="widget blog-bg">
                                <h5 className="widget-title border-bottom">Filter By Category</h5>
                                <div className="price-filter-category">
                                    { this._renderFilterCategoryList(filterCategoryList || []) }
                                </div>
                            </div>
                            <div className="widget blog-bg">
                                <h5 className="widget-title border-bottom">Filter By Brands</h5>
                                <div className="price-filter-category">
                                    { this._renderFilterBrandList(filterBrandList || []) }
                                </div>
                            </div>
                            {/* <div class=Name"featured-group-sidebar">
                                <a href="#">
                                    <Img src={base.imageBaseUrl+"iftex-clean-D-02.jpg"} />
                                </a>
                                <a href="#">
                                    <Img src="{base.imageBaseUrl+"iftex-clean-G-02.jpg"} />
                                </a>
                            </div> */}
                            {miS.isModuleEnabled(["FeaturedProducts"]) && <div className="widget blog-bg style-01 recent-product">
                                <h5 className="widget-title border-bottom">Featured Products</h5>
                                <ul className="recent-post-item widget-scroll">
                                    { this._renderFeaturedProductsList(featuredProductsList || []) }
                                </ul>
                            </div>}
                        </div>
                        <div className="product-page-wrapper">
                            <div className="product-filtering-area pt40">
                                <div className="filter-left flex pointer" id="btnContainer">
                                    <span onClick={() => miS.toggleBodyClass('cart-open')} className="mr10 btn-filter-box-shop"><i className="hi-menu1 btn-menu-filter mr10"></i>Filters</span>
                                    <ul className="filter-tags">
                                        {
                                            derivedTag !== '' &&
                                            <li className="tagger" onClick={() => {
                                                rS.navigateTo('/accessories')
                                            }}>{derivedTag} <i className="hi-close"></i></li>
                                        }
                                        { this._renderFilterTags(filterTags||[]) }
                                    </ul>
                                </div>
                                <div className="filter-right">
                                    <span className="sorting-text">Sort by</span>
                                    <div className="custom-select-box">
                                        <Select
                                            value={selectedFilter}
                                            onChange={this._handleChange}
                                            options={sortOption}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="featured-group">
                                <div className="flex">
                                    <a href="#">
                                        <Img src={base.imageBaseUrl+""Iftex%20Clean%20System%20D%20banner.jpg"} />
                                    </a>
                                    <a href="#">
                                        <Img src={base.imageBaseUrl+""Iftex%20Clean%20System%20G%20banner.jpg"} />
                                    </a>
                                </div>
                            </div> */}
                            <div className="grid-list-wrapper pv40" id="list-wrapper">
                                { this._renderShopProducts(filteredProductsList || []) }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    }
}