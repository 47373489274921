
import { ProfileSideBar } from "../../component"
import {Header} from "../../widget"
import './style.scss'

const PostLogin = component => {
    if (component.props.template === 'blank')
        return component
    else
        return <>
            <Header />
            <div className="profile-page">
                <div className="profile-main-section wrapper1">
                    <ProfileSideBar />
                    <div className="right-content-section">
                        {component}
                    </div>
                </div>
            </div>
        </>
}

export default PostLogin