import React, {Component} from 'react'
import {Banner} from '../../../widget'
import {MiscService, OrderService, MetaService} from '../../../service'
import {base} from '../../../constant/Data'
import {Img, Button, Badge} from '../../../element'
import {Table} from '../../../component'
import './style.scss';
const mS = new MetaService(),
miS = new MiscService(),
oS = new OrderService()
export default class OrderTracking extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submitForm: false,
            orderContent: null,
            email: '',
            orderId: '',
            paymentFail: false,
            orderStatus: [ "initialised", "payment-fail", "paid", "processing", "shipped", "delivered", "completed" ]
        }
    }
    componentDidMount() {
        mS.setTitle('Track Order', 'public')
    }
    _getOrderData = async orderId => {
        if (orderId !== '') {
            let query = {
                uid: orderId
            }
            const result = await oS.getOrder({ query: JSON.stringify(query) })
            if (result.status) {
                if (result.data.length !== 0) {
                    let orderContent = result.data[0]
                    this.setState({ orderContent, submitForm: true })
                } else miS.showAlert({ type: 'error', msg: 'Order with given ID not found, please check the order ID.' })
            }
        }
    }
    _renderNotes = orderNotes => {
        let { orderContent } = this.state
        return orderNotes.map(( item, index ) => {
            let activeLabel = '', orderData = null
            if (item.tempUid) {
                if (orderContent !== null)
                    orderData = JSON.parse(orderContent.orderData)
                if (orderData !== null) {
                    orderData.cartData.forEach(e => {
                        if (e.tempUid === item.tempUid)
                            activeLabel = e.title
                    })
                }
            }
            return <li rel="102" className="note system-note" key={index}>
                <div className="note-content">
                    {
                        activeLabel!=='' && <label>{activeLabel}</label>
                    }
                    <p>{item.note}</p>
                </div>
                <p className="note-meta">
                    <abbr className="exact-date">{miS.getFormattedDate(item.timing)}</abbr>
                </p>
                <p className="note-status"><Badge item={item.status} /></p>
            </li>
        })
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item ) => {
            let e = {
                name: item.name,
                email: item.email,
                mobile: item.mobile,
                image: item.image?
                    <Img alt="Blank Placeholder" src={base.imageBaseUrl+JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>
            }
            data.push(e)
        })
        return data
    }
    _renderCartData = cartData => {
        let { paymentFail, orderContent , orderStatus } = this.state,
        orderStatusIndex = 0
        return cartData.map(( item, index ) => {
            if (orderContent !== null) {
                if (orderContent.status === 'payment-fail')
                    paymentFail = true
                orderStatus.forEach(( e, i ) => {
                    if (e === orderContent.status)
                        orderStatusIndex = i
                })
            }
            if (orderContent.orderNotes) {
                orderContent.orderNotes.forEach(e => {
                    if (e.tempUid === item.tempUid) {
                        orderStatus.forEach((el, i ) => {
                            if (el === e.status)
                                orderStatusIndex = i
                        })
                    }
                })
            }
            return <tbody key={index}>
                <tr className="cart-form-cart-item cart_item">
                    <td className="product-thumbnail">
                    {
                        item.type === 'servicePackage'?
                        <i className="hi-dropbox service-package-icon"></i>:
                        item.type === 'subscription'?
                        <i className="hi-block service-package-icon"></i>:
                        <Img src={base.imageBaseUrl+item.image} alt={item.title} />
                    }
                    </td>
                    <td className="product-name" data-title="Product">
                        {item.title}
                        {
                            item.type === 'service' &&
                            <p className="active-time-slot"><b>Time Slot:</b> {(item.activeTimeSlot).slot}</p>
                        }
                    </td>
                    <td className="product-price" data-title="Price">
                        <span className="Price-amount amount">₹ {Number(item.price).toFixed(2)}</span>
                    </td>
                    <td className="product-quantity" data-title="Quantity">
                        <div className="quantity">
                            {item.purchaseQuantity}
                        </div>
                    </td>
                    <td className="product-subtotal" data-title="Subtotal">
                        ₹ {Number(item.price * item.purchaseQuantity).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td colSpan='6' rowSpan="6">
                        <div className="track">
                            <div className={orderStatusIndex >= 0? "step active":"step" }> <span className="icon"><i className="hi-refresh"></i></span> <span className="text">Initialised</span> </div>
                            {
                                paymentFail ?
                                <div className={orderStatusIndex >= 1? "step active":"step" }> <span className="icon"><i className='hi-info1'></i> </span> <span className="text"> Payment Fail</span> </div>:
                                <div className={orderStatusIndex >= 2? "step active":"step"}> <span className="icon"><i clssName="hi-money"></i> </span> <span className="text"> Paid </span> </div>
                            }
                            <div className={orderStatusIndex >= 3? "step active":"step"}> <span className="icon"><i className="hi-crop_rotate"></i></span> <span className="text">Processing</span> </div>
                            <div className={orderStatusIndex >= 4? "step active":"step"}> <span className="icon"><i className="hi-delivery_dining"></i> </span> <span className="text">Shipped</span> </div>
                            <div className={orderStatusIndex >= 5? "step active":"step"}> <span className="icon"><i className="hi-gift"></i> </span> <span className="text">Delivered</span> </div>
                            <div className={orderStatusIndex >= 6? "step active":"step"}> <span className="icon"><i className="hi-check-circle"></i></span> <span className="text">Completed</span> </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        })
    }
    render() {
        let { orderStatus, submitForm, orderContent, orderId, activeShownTotal } = this.state,
        orderStatusIndex = 0
        if (orderContent !== null) {
            orderStatus.forEach(( e, i ) => {
                if (e === orderContent.status) {
                    orderStatusIndex = i
                }
            })
        }
        let tableContent = {
            
        }
        return <>
            <Banner title='Order Tracking' image="red-back-1.jpg"/>
            <div className="flex order-tracking">
                <div className="jumbo-container">
                    <article className="card m50">
                        {
                        !submitForm?
                        <div className="card-body pb50">
                            <div className="track-form mb50">
                                <div className="inputBoxTrackingOrder">
                                    <label>Enter your Order ID</label>
                                    <input value={orderId} onChange={(e) => this.setState({ orderId: e.target.value })} className="input" type="text" name="orderId" placeholder="Enter Order ID" />
                                </div>
                                <Button onClick={() => this._getOrderData(orderId)} className="btn btn-primary mauto">Submit</Button>
                            </div>
                        </div>
                        :
                        <div className="card-body pb50">
                            <h6>Order ID: #{orderContent.uid} <b>Order Status: </b>{orderStatus[orderStatusIndex]}</h6>
                            {
                                orderContent !== null &&
                                <>
                                    {/* <Table
                                        tableClass="shop-table shop-table_responsive cart cart-form__contents mb0 br0"
                                        data={tableContent}
                                        pageSize={activeShownTotal}
                                    /> */}
                                    <table className="shop-table shop-table_responsive cart cart-form__contents mb0 br0">
                                        <thead>
                                            <tr>
                                                <th className="product-thumbnail">Preview</th>
                                                <th className="product-name">Product</th>
                                                <th className="product-price">Price</th>
                                                <th className="product-quantity">Quantity</th>
                                                <th className="product-subtotal">Total</th>
                                            </tr>
                                        </thead>
                                        { this._renderCartData(JSON.parse(orderContent.orderData).cartData || []) }
                                        <tfoot>
                                            <tr>
                                                <td className="small-col" colSpan={4}>Total</td>
                                                <td className="small-col"><b>₹ {(JSON.parse(orderContent.orderData).cartTotal).toFixed(2)}</b></td>
                                            </tr>
                                            {
                                                !isNaN(orderContent.discount) && orderContent.discount && orderContent.discount !== "" && orderContent.discount !== 0 && orderContent.discount !== '0' &&
                                                <>
                                                    <tr>
                                                        <td className="small-col" colSpan={4}>Discount</td>
                                                        <td className="small-col">₹ {orderContent.discount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="small-col" colSpan={4}>Net Payable</td>
                                                            <td className="small-col"><b>₹ {(JSON.parse(orderContent.orderData).cartTotal - JSON.parse(orderContent.orderData).discount).toFixed(2)}</b></td>
                                                    </tr>
                                                </>
                                            }
                                        </tfoot>
                                    </table>
                                </>
                            }
                            {
                                orderContent.orderNotes &&
                                <div className="notes-box">
                                    <h3>Order Notes</h3>
                                    <ul className="notes">
                                        { this._renderNotes( orderContent.orderNotes.reverse()||[] ) }
                                    </ul>
                                </div>
                            }
                        </div>
                        }
                    </article>
                </div>
            </div>
        </>
    }
}