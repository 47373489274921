const TestimonialModel = [
    {
        key: 'content',
        dataType: String
    },
    {
        key: 'name',
        dataType: String
    },
    {
        key: 'content',
        dataType: String
    },
    {
        key: 'image',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]

export default TestimonialModel