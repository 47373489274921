const InvoiceModel = [
    {
        key: 'invoiceData',
        dataType: String
    },
    {
        key: 'invoiceNo',
        dataType: String
    },
    {
        key: 'orderId',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]
export default InvoiceModel