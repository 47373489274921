import { base } from '../../constant/Data'
import {Img} from '../../element'
const UserImage = ({user}) => {
    return <>
        {
            user.image ?
            <Img src={base.imageBaseUrl+JSON.parse(user.image)[0]} title={user.name} />:
            <i className="hi-user"></i>
        }
    </>
}

export default UserImage