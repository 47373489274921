import React, {Component} from 'react'
import DatePicker from 'react-datepicker'
import {PackagesData, Box, Table} from '../../../component'
import {base, brandInfo} from '../../../constant/Data'
import 'react-datepicker/dist/react-datepicker.css'
import {Img, Button, BackIcon} from '../../../element'
import {OrderService, DataService, RouterService, MiscService, MetaService, UserService} from '../../../service'
const dS = new DataService('packageWork'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService()
export default class MyPackageDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderContent: null,
            note: '',
            preventiveCheckList: [],
            packageId: null,
            orderDate: '' ,
            chosenServiceDates: [],
            chosenPreventiveCheckListDates: [],
            workedPackagedata: [],
            activeServiceDate: '',
            packageData: null,
            serviceWorkAwaitedStatus: false,
            serviceData: '',
            packageWork: null,
            upgradedData: null,
            partialPackage: '',
            upgraded: false
        }
    }
    componentDidMount () {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        orderData = rS.urlToSplitData(atob(urlData[3])),
        query = {
            uid: orderData[0]
        }
        this.setState({ packageId: orderData[1] }, () => {
            this._getServiceDates(orderData[1])
            this._getOrderData(query, orderData[1])
            this._checkForUpgrade({
                orderId: orderData[0],
                packageId: orderData[1]
            })
        })
        this._getPreventiveCheckList()
    }
    _checkForUpgrade = async query => {
        const result = await oS.getUpgradedPackage({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data !== '') {
                let upgradedData = result.data
                this.setState({ upgradedData: upgradedData, upgraded: true })
            }
        }
    }
    _getOrderData = async (query, packageId) => {
        const result = await oS.getOrder({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length > 0) {
                let orderContent = result.data[0],
                packageData = null,
                orderData = JSON.parse(orderContent.orderData)
                orderData.cartData.forEach(e => {
                    if (e.tempUid === Number(packageId)) {
                        packageData = e
                    }
                })
                this.setState({ orderContent, packageData }, () => this._getPackageWorkData(packageId, packageData))
            }
        }
    }
    _getPreventiveCheckList = async () => {
		const result = await miS.getPreventiveCheckList()
        if (result.status)
            this.setState({ preventiveCheckList: result.data })
        else
            miS.showAlert({ type: 'error', msg: 'Unable to get preventiveCheckList!' })
    }
    _renderPriventiveCheckList = preventiveQueries => {
        return preventiveQueries.map(( item, index ) => {
            return <div key={index}>
                <h3>{index+1}. {item.serviceTitle}</h3>
                { this._renderPreventiveQueriesHead(item.serviceQueries || [] )}
            </div>
        })
    }
    _renderPreventiveQueriesHead = queriesFiles => {
        let {activeShownTotal} = this.state
        return queriesFiles.map(( item, index ) => {
            let tableContent = {
                headers: [],
                addonBodyContent: this._renderPreventiveQueries(item.status || [] )
            }
            return <div key={index}>
                <p>{index+1}. {item.query}</p>
                <Table
                    data={tableContent}
                    pageSize={activeShownTotal}
                />
            </div>
        })
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item ) => {
            let e = {
                name: item.name,
                email: item.email,
                mobile: item.mobile,
                image: item.image?
                    <Img alt="Blank Placeholder" src={base.imageBaseUrl+JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>
            }
            data.push(e)
        })
        return data
    }
    _renderPreventiveQueries = statusFiles => {
        return statusFiles.map(( item, index ) => {
            return <tr key={index} className="query-list-status">
                <th>{item.type}</th>
                <td className="query-list-icon-Box">
                    {
                        item.answers[0] === 'Perfect' ?
                        <i className="success hi-check_circle"></i>:
                        item.answers[0] === 'Not Available' ?
                        <i className="hi-cross-circle error"></i>:
                        item.answers[0] === 'Need attention' ?
                        <i className="hi-cross-circle error"></i>:
                        item.answers[0] === 'Available' &&
                        <i className="success hi-check_circle"></i>
                    }
                    <span className="ml10">{item.answers[0]}</span>
                </td>
            </tr>
        })
    }
    _renderSocietyList = societyData => {
        let { activeSociety } = this.state
        return societyData.map(( item, index ) => {
            let activeClass = ''
            if (activeSociety !== null) {
                activeClass = activeSociety.title === item.title? 'active': ''
            }
            return <li key={index} className={activeClass} onClick={() => {
                this.setState({ activeSociety: item, showSocietyListBox: false }, () => {
                    let activeaddress = JSON.parse(item.addressDetail), pinCode ='', locality= '', city= '', state = ''
                    activeaddress.address_component.forEach(e => {
                        if (e.types[0]==="packageal_code") {
                            pinCode = e.long_name
                        }
                        if (e.types[0]==="administrative_area_level_1") {
                            state = e.long_name
                        }
                        if (e.types[0]==="administrative_area_level_2") {
                            city = e.long_name
                        }
                        if (e.types[0]==="locality") {
                            locality = e.long_name
                        }
                    })
                    this.setState({ locality, pinCode, city, state, fullAddress: activeaddress.formatted_address })
                })
            }}>
                <p>{item.title}</p>
            </li>
        })
    }
    _updatePackage = async () => {
        let { chosenServiceDates, packageId, activeServiceDate } = this.state,
        error = false,
        errorMsg = ''
        if (new Date(activeServiceDate).getDay() === 0) {
            error = true
            errorMsg = 'We do not serve on Sundays'
        }
        if (error) {
            miS.showAlert({ type: 'error', msg: errorMsg })
        } else {
            chosenServiceDates.push(miS.getFormattedDate(activeServiceDate))
            let serviceDateObject = {
                userId: uS.getLoggedInUserData().uid,
                tempUid: packageId,
                serviceDate: JSON.stringify(chosenServiceDates)
            }
            if (chosenServiceDates.length === 1) {
                serviceDateObject.uid = new Date().getTime()
                const result = await oS.addServiceDates(serviceDateObject)
                if (result.status) {
                    this._getServiceDates(packageId)
                    miS.showAlert({ type: 'success', msg: 'Car wash date submitted successfully' })
                    rS.getLocationData().reload()
                }
            } else {
                let data = {
                    query: JSON.stringify({tempUid: packageId}),
                    serviceDateData: JSON.stringify(serviceDateObject)
                }
                const result = await oS.updateServiceDates(data)
                if (result.status) {
                    this._getServiceDates(packageId)
                    miS.showAlert({ type: 'success', msg: 'Car wash date submitted successfully' })
                    rS.getLocationData().reload()
                }
            }
        }
    }
    _getServiceDates = async packageId => {
        let query = {
            tempUid: packageId
        }
        const result = await oS.getServiceDates({ query: JSON.stringify(query) })
        if (result.status) {
            let resultTable = result.data.reverse()
            if (resultTable.length > 0) {
                if (typeof resultTable[0].preventiveCheckListDates !== 'undefined' && resultTable[0].preventiveCheckListDates !== 'undefined') {
                    this.setState({ chosenPreventiveCheckListDates: JSON.parse(resultTable[0].preventiveCheckListDates) })
                }
                if (typeof resultTable[0].serviceDate !== 'undefined' && resultTable[0].serviceDate !== 'undefined') {
                    this.setState({ chosenServiceDates: JSON.parse(resultTable[0].serviceDate) })
                }
            }
        }
    }
    _getCompletedWorkData = serviceDate => {
        let found = false, { workedPackagedata } = this.state
        workedPackagedata.forEach(e => {
            if (miS.getFormattedDate(e.serviceDate) === miS.getFormattedDate(new Date(serviceDate))) {
                found = true
                let packageWork = e
                this.setState({ packageWork, serviceWorkAwaitedStatus: false })
            }
        })
        if (!found)
        this.setState({ serviceWorkAwaitedStatus: true })
        miS.openModal("car-service-status-modal")
    }
    _getPackageWorkData = async (packageId, packageData = false) => {
        let query = {
            tempUid: String(packageId)
        }
        const result = await dS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length > 0) {
                if (result.data[0].title.toLowerCase() !== packageData.title.toLowerCase()) {
                    query = {
                        title: packageData.title
                    }
                    let newResult = await dS.fetchByAttr({ query: JSON.stringify(query) })
                    if (newResult.status) {
                        if (newResult.data.length > 0) {
                            this.setState({ workedPackagedata: newResult.data })
                        }
                    }
                } else {
                    this.setState({ workedPackagedata: result.data })
                }
            }
        }
    }
    _getPreventiveAnswers = (date, mode = 'detail') => {
        let { preventiveCheckList, workedPackagedata } = this.state, activePreventiveData = null
        workedPackagedata.forEach(e => {
            if (miS.getFormattedDate(e.serviceDate) === miS.getFormattedDate(new Date(date))) {
                activePreventiveData = JSON.parse(e.workData)[0].preventiveWorkData
            }
        })
        if (activePreventiveData === null && mode === 'detail') {
            miS.showAlert({ type: 'info', msg: 'Preventive check is yet not done for this date.' })
        } else {
            preventiveCheckList.forEach(e => {
                e.serviceQueries.forEach(el => {
                    el.status.forEach(eq => {
                        if (mode === 'view') {
                            eq.answers[0] = '...'
                        } else {
                            activePreventiveData?.forEach(ed => {
                                if (ed.title === el.query && ed.query === eq.type) {
                                    eq.answers[0] = ed.status
                                }
                            })
                        }
                    })
                })
            })
            this.setState({ preventiveCheckList }, () => miS.openModal("preventive-checklist-modal"))
        }
    }
    _renderImages = serviceImages => {
        return serviceImages.map(( item, index ) => {
            return <li key={index}>
                <Img hasZoom={true} src={base.imageBaseUrl+item} alt={item} />
            </li>
        })
    }
    _renderServiceData = (packageData, todayOnly = false) => {
        let datesCollection = [], { chosenServiceDates, chosenPreventiveCheckListDates, upgradedData } = this.state,
        buildBilling = 30
        if (packageData.billing === 'quarterly') {
            buildBilling = 90
        }
        for (let i = 0; i < buildBilling; i++) {
            let newDate = new Date(packageData.startDate).getTime() + i * 1000 * 60 * 60 * 24,
            e = {
                date: miS.getFormattedDate(newDate),
                day: new Date(newDate).getDay()
            }
            if (upgradedData !== null && upgradedData !== '') {
                e.newUid = upgradedData.uid
            }
            datesCollection.push(e)
        }
        return datesCollection.map(( item, index ) => {
            let found = false, preventiveFound = false
            chosenServiceDates.forEach(e => {
                if (miS.getFormattedDate(new Date(e)) === miS.getFormattedDate(new Date(item.date)))
                found = true
            })
            chosenPreventiveCheckListDates.forEach(e => {
                if (miS.getFormattedDate(new Date(e)) === miS.getFormattedDate(new Date(item.date)))
                preventiveFound = true
            })
            if ((item.day !== 0 && !todayOnly) || (todayOnly && miS.getFormattedDate(new Date()) === miS.getFormattedDate(new Date(item.date)))) {
                return <Box key={index} title={<>{miS.getDay(item.day)} <span className="datePreventive-checklist">({item.date})</span></>}>
                    <ul className="daily-service-data">
                        { preventiveFound && <li><span onClick={() => this._getPreventiveAnswers(item.date)} className="link">Preventive Checklist</span> {todayOnly && <i className="checked-service hi-check_circle"></i>}</li> }
                        { found && <li>Car Wash {todayOnly && <i className="checked-service hi-check_circle"></i>}</li> }
                        <li>Car Cleaning (Waterless) {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>
                        {
                            upgradedData !== null && upgradedData !== '' ?
                            ([6, 22].includes(index) && upgradedData.uid === 'PACKAGE__002') ||
                            ([6, 14, 22].includes(index) && upgradedData.uid === 'PACKAGE__003')?
                            <li>Vacuuming {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>:
                            (index === 6 && packageData.uid === 'PACKAGE__001') ||
                            ([6, 22].includes(index) && packageData.uid === 'PACKAGE__002') ||
                            ([6, 14, 22].includes(index) && packageData.uid === 'PACKAGE__003')?
                            <li>Vacuuming {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>
                        }
                        <li>Air Pressure {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>
                        {
                            upgradedData !== null && upgradedData !== '' ?
                            ([6, 3].includes(item.day) && upgradedData.uid === 'PACKAGE__002') ||
                            ([6, 4, 2].includes(item.day) && upgradedData.uid === 'PACKAGE__003')?
                            <li>Interior Dusting {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>:
                            (item.day === 6 && packageData.uid === 'PACKAGE__001') ||
                            ([6, 3].includes(item.day) && packageData.uid === 'PACKAGE__002') ||
                            ([6, 4, 2].includes(item.day) && packageData.uid === 'PACKAGE__003')?
                            <li>Interior Dusting {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>
                        }
                    </ul>
                    {!todayOnly && <p className="view-service-status" onClick={() => this._getCompletedWorkData(item.date)}>Status</p>}
                </Box>
            } else return true
        })
    }
    _upgradeActivePackage = uid => {
        let partialPackage = uid
        this.setState({ partialPackage: partialPackage }, () => {
            miS.openModal("package-upgrade-modal")
        })
    }
    render() {
        let { upgradedData, upgraded, packageData, activeServiceDate, partialPackage, chosenServiceDates, preventiveCheckList, packageWork, serviceWorkAwaitedStatus } = this.state,
        carNotAvailable = false,
        needDate = true,
        today = new Date(),
        activeMonth = 0,
        activeYear = 0,
        activeDate = 0,
        tomorrow = new Date(today)
        activeMonth = today.getMonth() + 1
        activeYear = today.getFullYear()
        activeDate = today.getDate()
        tomorrow.setDate(tomorrow.getDate() + 1)
        if (packageData!== null) {
            if (miS.getFormattedDate(tomorrow) < miS.getFormattedDate(new Date(packageData.startDate))) {
                tomorrow = new Date(packageData.startDate)
            }
            if (packageData !== null) {
                if (packageData.billing === 'monthly') {
                    if (chosenServiceDates.length === 0 || (packageData.uid === 'PACKAGE__003' && chosenServiceDates.length < 2)) {
                    } else {
                        needDate = false
                    }
                } else {
                    if (chosenServiceDates.length === 0 || (((packageData.uid === 'PACKAGE__001' || packageData.uid === 'PACKAGE__002') && chosenServiceDates.length < 3)) || (packageData.uid === 'PACKAGE__003' && chosenServiceDates.length < 6)) {
                        if (packageData.uid === 'PACKAGE__001' || packageData.uid === 'PACKAGE__002') {
                            if (activeMonth === 12) {
                                activeYear++
                                activeServiceDate = new Date('01-01-'+activeYear)
                            } else {
                                activeMonth++
                                activeServiceDate = new Date(activeMonth+'-01-'+activeYear)
                            }
                        } else {
                            if (activeMonth === 12 && activeDate === 31) {
                                activeYear++
                                activeServiceDate = new Date('01-01-'+activeYear)
                            } else {
                                if (chosenServiceDates.length % 2 === 0) {
                                    activeMonth++
                                    activeServiceDate = new Date(activeMonth+'-01-'+activeYear)
                                } else {
                                    activeServiceDate = new Date(chosenServiceDates[chosenServiceDates.length - 1])
                                    activeServiceDate.setDate(activeServiceDate.getDate() + 1)
                                }
                            }
                        }
                    } else {
                        needDate = false
                    }
                }
            }
        }
        if (activeServiceDate === '')
            activeServiceDate = tomorrow
        let serviceData = null, workData = null
        if (packageWork !== null) {
            serviceData = JSON.parse(packageWork.activeCar)
            if (typeof workData !== 'undefined')
                workData =  JSON.parse(packageWork.workData)
            if (typeof workData[0].workedServices === 'undefined')
                carNotAvailable = true
        }
        let packageTitle = upgraded? upgradedData?.title:packageData?.title
        mS.setTitle(packageTitle+' Service Schedule', 'public')
        return <>
            {
                packageData !== null &&
                <div id="content-box" className="content">
                    <h1 className="flex">
                        <span>
                            <BackIcon backLink='/package/list' />
                            {packageTitle}</span>
                    </h1>
                    <div className="columns">
                        <div className="left">
                            { this._renderServiceData(packageData) }
                        </div>
                        <div className="right">
                            {
                                needDate &&
                                <Box title='Choose Car Wash Date' bodyClass="select-date-mypackages">
                                    {/* <div className="body select-date-mypackages"> */}
                                        <DatePicker
                                            onChange={activeServiceDate => this.setState({ activeServiceDate })}
                                            minDate={tomorrow}
                                            startDate={tomorrow}
                                            value={activeServiceDate}
                                            maxDate={new Date(packageData.endDate)}
                                            selected={activeServiceDate}
                                        />
                                        <Button className="btn-primary btn tac" onClick={this._updatePackage}>Submit</Button>
                                    {/* <div className="right">
                                        {
                                            needDate &&
                                            <Box title="Choose Car Wash Date" bodyClass="select-date-mypackages">
                                                <DatePicker
                                                    onChange={activeServiceDate => this.setState({ activeServiceDate })}
                                                    minDate={tomorrow}
                                                    startDate={tomorrow}
                                                    value={activeServiceDate}
                                                    maxDate={new Date(packageData.endDate)}
                                                    selected={activeServiceDate}
                                                />
                                            </Box>
                                        } */}
                                        {
                                            packageData !== null && !upgraded && packageData.uid !== "PACKAGE__003" && new Date(packageData.endDate).getTime() > new Date().getTime() &&
                                            <Box title="Upgrade Package" bodyClass="select-date-mypackages">
                                                <Button className="btn-primary btn tac" onClick={() => this._upgradeActivePackage(packageData.uid)}>Upgrade Package</Button>
                                            </Box>
                                        }
                                    {/* </div> */}
                                </Box>
                            }
                            <Box title="Preventive Checklist">
                                <span onClick={() => {
                                    this._getPreventiveAnswers(new Date().getTime(), 'view')
                                }} className="link">View Preventive Checklist</span>
                            </Box>
                        </div>
                    </div>
                </div>
            }
            <div className="modal" id="preventive-checklist-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <Button className="close" onClick={() => miS.closeModal("preventive-checklist-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        { this._renderPriventiveCheckList(preventiveCheckList || [] )}
                    </div>
                </div>
            </div>
            <div className="modal" id="car-service-status-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content content medium">
                    <Button className="close" onClick={() => miS.closeModal("car-service-status-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        {
                            serviceWorkAwaitedStatus ?
                            <>
                            <div className="no-data-icon">
                                <i className="hi-hourglass_empty"></i>
                            </div>
                            <p className="awited-text">Work is still awaited for the day for this service</p></>: 
                            <div>
                                {
                                    packageWork !== null &&
                                    <>
                                        {
                                            carNotAvailable?
                                            <>
                                                <div className="no-data-icon">
                                                    <i className="hi-directions_car"></i>
                                                </div>
                                                <p className="awited-text">Car Not Available</p>
                                            </>:
                                            <>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Worked Services</label>
                                                    <div className="col-9 controls ml20">
                                                        {
                                                            typeof workData[0].workedServices !== 'undefined' && workData[0].workedServices.length !== 0?
                                                            workData[0].workedServices.map((e, index) => {
                                                                return <p key={index}>{e} <i className="checked-service hi-check_circle"></i></p>
                                                            })
                                                            :this._renderServiceData(packageData, true)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Service Date</label>
                                                    <div className="col-9 controls ml20">
                                                        {miS.getFormattedDate(packageWork.serviceDate)}
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Start Time</label>
                                                    <div className="col-9 controls ml20">
                                                        {
                                                            workData[0].startTime?
                                                            miS.getFormattedTime(workData[0].startTime) :
                                                            miS.getFormattedTime(workData[0].time)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">{brandInfo.name.capital} Id</label>
                                                    <div className="col-9 controls ml20">
                                                        {packageWork.workerId}
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Active Car</label>
                                                    <div className="col-9 controls ml20">
                                                    <li className="my-packages-assign-car">
                                                        {
                                                            serviceData.image_path !== null && typeof serviceData.image_path !== 'undefined' && serviceData.image_path !== '' &&
                                                            <Img src={base.carImageBase+(JSON.parse(packageWork.activeCar).image_path)} alt={(packageWork.activeCar).name} />
                                                        }
                                                        <div className="ml10">
                                                            <p>{serviceData.name+' '+serviceData.Segment}</p>
                                                            {
                                                                serviceData.registrationNo && serviceData.registrationNo !== '' && serviceData.registrationNo !== null &&
                                                                <small>{serviceData.registrationNo}</small>
                                                            }
                                                        </div>
                                                    </li>
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Comments</label>
                                                    <div className="col-9 controls ml20">
                                                        {workData[0].comments}
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Images</label>
                                                    <div className="col-9 controls ml20">
                                                            <ul className="media-box">
                                                            { this._renderImages(workData[0].carImages||[]) }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="modal" id="package-upgrade-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content big">
                    <Button className="close" onClick={() => miS.closeModal("package-upgrade-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        {
                            packageData !== null &&
                            <PackagesData partialPackage={partialPackage} startDate={packageData.startDate} endDate={packageData.endDate} activeCar={packageData.activeCar} billing={packageData.billing} price={packageData.price} />
                        }
                    </div>
                </div>
            </div>
        </>
    }
}