const PaymentModel = [
    {
        key: 'activeAddress',
        dataType: String
    },
    {
        key: 'userData',
        dataType: String
    },
    {
        key: 'orderData',
        dataType: String
    },
    {
        key: 'status',
        dataType: String
    },
    {
        key: 'timing',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]
export default PaymentModel