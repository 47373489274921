import React, {Component} from 'react'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react'
import {base,mainUrl} from '../../../constant/Data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CreatableSelect from 'react-select/creatable'
import {robotsOption,emailOption,garageoption} from '../../../constant/Enum'
import {MiscService, DataService, RouterService, UserService, MetaService} from '../../../service'
import {Img, Button, BackIcon} from '../../../element'
import {Box, MediaBox} from '../../../component'
const pS = new DataService('garage'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
keywordOptions =  [],
categoryOptions = [],
tagOptions = []
export default class AddGarage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeRobotsIndex: 0,
            activeStatusIndex: 0,
            activeEmailIndex: 0,
            image: '[]',
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            address: '',
            businessinformation: "",
            css: '',
            js: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            email: '',
            phonenumber: '',
            yearestablised: new Date(),
            assignWorkers: [],
            status: '',
            category: '',
            tag: '',
        }
    }
    componentDidMount () {
        mS.setTitle('Add Garage', 'admin')
        let { activeStatusIndex } = this.state
        this.setState({ status: garageoption[activeStatusIndex].value })
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getGarageData(query)
        }
        this._getUsersData()
    }
    _getUsersData = async () => {
        let tableData = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    tableData.push({label: result.name, value: result.uid})
                })
                this.setState({ assignWorkers: tableData })
            }
        }
    }
    _getGarageData = async query => {
        const result = await pS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let garageData = result.data[0],
            metaKeyword = [],
            tag = [],
            category = [],
            activeRobotsIndex = 0,
            activeEmailIndex = 0,
            activeStatusIndex = 0
            robotsOption.forEach(( e, i ) => {
                if (e.value === garageData.robots)
                    activeRobotsIndex = i
            })
            emailOption.forEach(( e, i ) => {
                if (e.value === garageData.email)
                    activeEmailIndex = i
            })
            garageoption.forEach(( e, i ) => {
                if (e.value === garageData.status)
                    activeStatusIndex = i
            })
            if (garageData.metaKeyword === '""' || garageData.metaKeyword === '')
                metaKeyword = []
            else {
                metaKeyword = JSON.parse(garageData.metaKeyword)
            }
            if (garageData.tag === '""' || garageData.tag === '')
                tag = []
            else {
                tag = JSON.parse(garageData.tag)
            }
            this.setState({
                title: garageData.title,
                mode: 'Edit',
                slug: garageData.slug,
                address: garageData.address,
                businessinformation: garageData.businessinformation,
                css: garageData.css,
                js: garageData.js,
                canonical: garageData.canonical,
                robots: garageData.robots,
                metaDescription: garageData.metaDescription,
                metaKeyword,
                email: garageData.email,
                phonenumber: garageData.phonenumber,
                yearestablised: new Date(garageData.yearestablised),
                status: garageData.status,
                category,
                tag,
                uid: garageData.uid,
                image: garageData.image,
                activeRobotsIndex,
                activeEmailIndex,
                activeStatusIndex
            })
        } else
            miS.showAlert({ type: 'error', msg: 'No garage added yet!' })
    }
    _addGarage = async e => {
        e.preventDefault()
        let { mode, title, slug, address,phonenumber, businessinformation, css, js, canonical, robots, metaDescription, metaKeyword, email, yearestablised, status, category, tag, uid, image } = this.state,
        error = false,
        errorMsg = '',
        garageObject = {
            title,
            slug,
            address,
            businessinformation,
            css,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            email,
            phonenumber,
            yearestablised,
            status: status.value,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            image
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (businessinformation === '') {
            errorMsg = 'Please enter the business information'
            error = true
        } else if (address === '') {
            errorMsg = 'Please enter the garage address'
            error = true
        } else if (email === '') {
            errorMsg = 'Please select the email address'
            error = true
        } else if (phonenumber === '') {
            errorMsg = 'Please enter your phone number'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                garageObject.uid = new Date().getTime()
                const result = await pS.save(garageObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Garage added successfully!' })
                    rS.navigateTo('/admin/garage/list')
                } else
                    miS.showAlert({ type: 'error', msg: 'Unable to add garage!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    garageData: JSON.stringify(garageObject)
                }
                const result = await pS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Garage updated successfully!' })
                    rS.navigateTo('/admin/garage/list')
                } else
                    miS.showAlert({ type: 'error', msg: 'Unable to update garage!' })
            }
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        {status, robots, email, yearestablised, assignWorkers, mode, renderSlug, title, address, slug, businessinformation, css, js, canonical, activeRobotsIndex, activeStatusIndex, activeEmailIndex, metaDescription, metaKeyword, category, tag,phonenumber, image } = this.state,
        pageTitle = mode + ' Garage'
        if (canonical === '') {
            canonical = mainUrl+ urlData[2]+'/'+slug
        }
        let activeRobots = robotsOption[activeRobotsIndex],
        activeEmail = emailOption[activeEmailIndex],
        activeStatus = garageoption[activeStatusIndex]
        if (mode === 'Edit') {
            garageoption.forEach(e => {
                if (e.value === status) {
                    activeStatus = e
                }
            })
            robotsOption.forEach(e => {
                if (e.value === robots) {
                    activeRobots = e
                }
            })
            assignWorkers.forEach(e => {
                if (e.value === email) {
                    activeEmail = e
                }
            })
        }
        return <>
            <h1>
                <BackIcon backLink='/admin/garage/list' />
                {pageTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._addGarage}>
                <div className="left">
                    <Box title="Title" required>
                        <input autoFocus type="text" required name="title" value={title} onChange={e => {
                            let tempTitle = e.target.value, tempSlug = slug
                            if (renderSlug) {
                                tempSlug = miS.slugify(tempTitle)
                            }
                            this.setState({ title: tempTitle, slug: tempSlug})
                        }} />
                    </Box>
                    <Box title="Slug" required>
                        <input type="text" required name="slug" value={slug} onChange={(e) => this.setState({ slug: e.target.value })} />
                    </Box>
                    <Box title="Address" required>
                        <input type="text" required name="address" value={address} onChange={(e) => this.setState({ address: e.target.value })} />
                    </Box>
                    <Box title="Business Information" required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            value={businessinformation}
                            initialValue={businessinformation? businessinformation: "<p>This is the demo mail business information.</p>"}
                            init={base.tinyMceEditorOptions}
                            onEditorChange={(businessinformation, editor) => {
                                this.setState({ businessinformation: businessinformation })
                            }}
                        />
                    </Box>
                    <Box title="CSS">
                        <textarea className="description" placeholder=".selector {property:value}" required name="css" value={css} onChange={(e) => this.setState({ css: e.target.value })}></textarea>
                    </Box>
                    <Box title="JS">
                        <textarea className="description" placeholder="alert('Type your script here')" required name="js" value={js} onChange={(e) => this.setState({ js: e.target.value })}></textarea>
                    </Box>
                    <Box title="Canonical URL">
                        <input type="text" required name="canonical" value={canonical} onChange={(e) => this.setState({ canonical: e.target.value })} />
                    </Box>
                    <Box title="Robots">
                        <Select
                            value={activeRobots}
                            defaultValue={activeRobots}
                            onChange={e => this.setState({ robots: e.value })}
                            options={robotsOption}
                        />
                    </Box>
                    <Box title="Meta Description">
                        <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={(e) => this.setState({ metaDescription: e.target.value })}></textarea>
                    </Box>
                    <Box title="Meta Keywords">
                        <CreatableSelect
                            isMulti
                            onChange={e => this.setState({ metaKeyword: e })}
                            options={keywordOptions}
                            value={metaKeyword}
                        />
                    </Box>
                </div>
                <div className="right">
                    <Box title="Meta">
                            <p>
                                Render Slug:
                                <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={() => this.setState({ renderSlug:!renderSlug})}></span>
                            </p>
                            <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                    </Box>
                    <Box title="Email" required>
                        <input type="text" required name="email" value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                    </Box>
                    <Box title="Phone Number" required>
                        <input type="text" required name="phonenumber" value={phonenumber} onChange={(e) => this.setState({ phonenumber: e.target.value })} />
                    </Box>
                    <Box title="Year Establised">
                        <DatePicker
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeFormat="HH:mm"
                            onChange={yearestablised => this.setState({ yearestablised })}
                            selected={yearestablised}
                        /> 
                    </Box>
                    <Box title="Status">
                        <Select
                            value={status}
                            defaultValue={activeStatus}
                            onChange={e => this.setState({ status: e })}
                            options={garageoption}
                        />
                    </Box>
                    <Box title="Category">
                        <CreatableSelect
                            isMulti
                            value={category}
                            onChange={e => this.setState({ category: e })}
                            options={categoryOptions}
                        />
                    </Box>
                    <Box title="Tags">
                        <CreatableSelect
                            isMulti
                            value={tag}
                            onChange={e => this.setState({ tag: e })}
                            options={tagOptions}
                        />
                    </Box>
                    <MediaBox image={image} setimage={image => this.setState({image})} />
                </div>
            </form>
        </>
    }
}