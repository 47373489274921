import React, {useEffect, useRef, useState} from 'react';

const Run = props => {
    const obstacle = useRef()
    const player = useRef()
    const [gameState, setgameState] = useState('stop')

    useEffect(() => {
        setgameState('play')
        _runObstacles()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            _checkIfAlive(gameState)
        }, 10);
    }, [gameState, player])

    const _checkIfAlive = (gameState) => {
        let playerCoordinates
        let obstacleCoordinates
            if (gameState === 'play' && player && obstacle) {
                playerCoordinates = player.current.getBoundingClientRect()
                obstacleCoordinates = obstacle.current.getBoundingClientRect()
                    if (obstacleCoordinates.x - playerCoordinates.x + playerCoordinates.width < 2) {
                        _stopObstacles()
                        setgameState('pause')
                    }
            }
    }

    const _runObstacles = () => {
        if (obstacle?.current) {
            obstacle.current.className += ' run'
        }
    }
    const _stopObstacles = () => {
        if (obstacle?.current) {
            obstacle.current.className = obstacle.current.className.replace(' run', '')
        }
    }

    const _jump = () => {
        if (!player.current.className.includes('jump')) {
            player.current.className += ' jump'
            setTimeout(() => {
                player.current.className = player.current.className.replace(' jump', '')
            }, 700);
        }
    }
    
    return <>
        <div className='game-box'>
            <div className='control-panel' onClick={_jump}></div>
            <div className='display-board'></div>
            <div className='game-console'>
                <div className='floor'></div>
                <div className='player' ref={player}></div>
                <div className='obstacle' ref={obstacle}></div>
            </div>
        </div>
    </>
}

export default Run