import React, { useEffect, useState } from 'react'
import {Button} from '../../../element'
import {MiscService} from '../../../service'
const miS = new MiscService(),
TrashConfirmModal = props => {
    const [showTrashConfirm, setshowTrashConfirm] = useState(false),
    _trashOk = () => {
        props.trashOkAction()
    },
    _trashCancel = () => {
        miS.closeModal("trash-modal")
        props.trashCancelAction()
    }
    useEffect(() => {
        if (props.showTrashConfirm !== showTrashConfirm) {
            setshowTrashConfirm(props.showTrashConfirm)
            if (props.showTrashConfirm)
                miS.openModal('trash-modal')
            else
                _trashCancel()
        }
    }, [props])
    return <div className="modal" id="trash-modal">
        <div className="modal-backdrop"></div>
        <div className="modal-content small">
            {props.trashCancelAction && <Button className="close" onClick={_trashCancel}><i className="hi-close"></i></Button>}
            <div className="modal-body p20">
                <i className="hi-close"></i>
                <span className="store-name">
                    {
                        props.isMultipleDelete?
                        "Are you sure you want to delete selected items.":
                        "Are you sure you want to delete it."
                    }
                </span>
                <div className="flex btn-box-trash">
                    {props.trashOkAction && <Button className="btn btn-primary btn-box" onClick={_trashOk}>Delete</Button>}
                    {props.trashCancelAction && <Button className="btn btn-box btn-outline" onClick={_trashCancel}>Cancel</Button>}
                </div>
            </div>
        </div>
    </div>
}

export default TrashConfirmModal