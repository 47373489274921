import React, {cloneElement} from 'react'

// Style
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Services
import {RouterService, MiscService, UserService} from '../../service'

// Front Pages
import Run from '../../page/front/Run'
import Home from '../../page/front/Home'
import Login from '../../page/front/Login'
import Otp from '../../page/front/Otp'
import MicroServices from '../../page/front/MicroServices'
import Register from '../../page/front/Register'
import ErrorPage from '../../page/front/ErrorPage'
import Contact from '../../page/front/Contact'
import Thankyou from '../../page/front/Thankyou'
import Services from '../../page/front/Services'
import Cart from '../../page/front/Cart'
import Payment from '../../page/front/Payment'
import About from '../../page/front/About'
import Blog from '../../page/front/Blog'
import Profile from '../../page/front/Profile'
import Chat from '../../page/front/Chat'
import CompanyProfile from '../../page/front/CompanyProfile'
import DashBoard from '../../page/front/Dashboard'
import CompanyDashboard from '../../page/front/CompanyDashboard'
import CompanyWorkDashboard from '../../page/front/CompanyWorkDashboard'
import CompanyWorkDetailDashboard from '../../page/front/CompanyWorkDetailDashboard'
import Ludo from '../../page/front/Ludo'
import SnakeNLadder from '../../page/front/SnakeNLadder'
import UnderDevelopment from '../../page/front/UnderDevelopment'
import Checkout from '../../page/front/Checkout'
import MyOrderDetail from '../../page/front/MyOrderDetail'
import MyOrders from '../../page/front/MyOrders'
import CompanyManagers from '../../page/front/CompanyManagers'
import Notifications from '../../page/front/Notification'
import CarList from '../../page/front/CarList'
import AddCar from '../../page/front/AddCar'
import AddManager from '../../page/front/AddManager'
import ManageAddress from '../../page/front/ManageAddress'
import AddAddress from '../../page/front/AddAddress'
import Offers from '../../page/front/Offers'
import Shop from '../../page/front/Shop'
import Faq from '../../page/front/Faq'
import OrderTracking from '../../page/front/OrderTracking'
import Pricing from '../../page/front/Pricing'
import Subscription from '../../page/front/Subscription'
import MySubscription from '../../page/front/MySubscription'
import MyPackages from '../../page/front/MyPackages'
import Feedback from '../../page/front/Feedback'
import Complaint from '../../page/front/Complaint'
import MyPackageDetail from '../../page/front/MyPackageDetail'
import ViewCar from '../../page/front/ViewCar'

// Templates
import Post from '../../template/Post'
import Garage from '../../template/Garage'
import Product from '../../template/Product'
import Project from '../../template/Project'
import Page from '../../template/Page'
import Service from '../../template/Service'
import Event from '../../template/Event'
import Job from '../../template/Job'
import Business from '../../template/Business'

// Admin Pages
import AddCampaign from '../../page/admin/AddCampaign'
import CampaignList from '../../page/admin/CampaignList'
import AddOffer from '../../page/admin/AddOffer'
import AddNotifications from '../../page/admin/AddNotification'
import AddTestimonial from '../../page/admin/AddTestimonial'
import TestimonialList from '../../page/admin/TestimonialList'
import AddPage from '../../page/admin/AddPage'
import OfferList from '../../page/admin/OfferList'
import AddProduct from '../../page/admin/AddProduct'
import AddService from '../../page/admin/AddService'
import Settings from '../../page/admin/Settings'
import Report from '../../page/admin/Report'
import AdminNotifications from '../../page/admin/Notifications'
import Users from '../../page/admin/Users'
import OrderDetail from '../../page/admin/OrderDetail'
import Orders from '../../page/admin/Orders'
import JobsList from '../../page/admin/JobsList'
import CarModelManufacturerList from '../../page/admin/CarModelManufacturerList'
import CarModelList from '../../page/admin/CarModelList'
import AddCarModel from '../../page/admin/AddCarModel'
import PostList from '../../page/admin/PostList'
import ModuleList from '../../page/admin/ModuleList'
import AppList from '../../page/admin/AppList'
import GarageList from '../../page/admin/GaragesList'
import PageList from '../../page/admin/PagesList'
import ProductList from '../../page/admin/ProductsList'
import ServicesList from '../../page/admin/ServicesList'
import AddApp from '../../page/admin/AddApp'
import AddPost from '../../page/admin/AddPost'
import AddProject from '../../page/admin/AddProject'
import AddJob from '../../page/admin/AddJob'
import AddGarage from '../../page/admin/AddGarage'
import GenericChatBot from '../../page/admin/GenericChatBot'
import QueriesChatBot from '../../page/admin/QueriesChatBot'
import Robots from '../../page/admin/Robots'
import Media from '../../page/admin/Media'
import SendMail from '../../page/admin/SendMail'
import AddUser from '../../page/admin/AddUser'
import Societies from '../../page/admin/Societies'
import AddSociety from '../../page/admin/AddSociety'
import ProductCategory from '../../page/admin/ProductCategory'
import ServiceCategory from '../../page/admin/ServiceCategory'
import ImportProducts from '../../page/admin/ImportProducts'
import ProductBrands from '../../page/admin/ProductBrands'
import AdminFAQ from '../../page/admin/Faq'
import AppBanners from '../../page/admin/AppBanners'
import WebBanners from '../../page/admin/WebBanners'
import ComplaintAdmin from '../../page/admin/Complaint'
import EventDetail from '../../page/front/EventDetail'
import CarListAdmin from '../../page/admin/CarList'
import WorkListAdmin from '../../page/admin/WorkList'
import InvoiceListAdmin from '../../page/admin/InvoiceList'
import EventsList from '../../page/admin/EventList'
import EditUsersCarList from '../../page/admin/EditUsersCarList'
import ProductOrders from '../../page/admin/ProductOrders'
import ServiceOrders from '../../page/admin/ServiceOrders'
import PackageOrders from '../../page/admin/PackageOrders'
import KeyListAdmin from '../../page/admin/KeyList'
import PackageDetail from '../../page/admin/PackageDetail'
import MicroServicesList from '../../page/admin/MicroServicesList'
import AddMicroService from '../../page/admin/AddMicroService'
import MicroServiceCategory from '../../page/admin/MicroServiceCategory'
import LogList from '../../page/admin/LogList'
import FeedbackOrders from '../../page/admin/FeedbackOrders'
import TaxClassList from '../../page/admin/TaxClassList'
import AddTaxClass from '../../page/admin/AddTaxClass'
import AddEvent from '../../page/admin/AddEvent'
import AdminDashboard from '../../page/admin/AdminDashboard'
import AdminProjectList from '../../page/admin/AdminProjectList'
import ImportCars from '../../page/admin/ImportCars'
import ImportB2BWorkData from '../../page/admin/ImportB2BWorkData'
import ImportB2BStockData from '../../page/admin/ImportB2BStockData'
import CompanyStockDetailDashboard from '../../page/front/CompanyStockDetailDashboard'
import ProjectList from '../../page/front/ProjectList'
import PackageWorkListAdmin from '../../page/admin/PackageWorkList'
import ServiceWorkListAdmin from '../../page/admin/ServiceWorkList'
import CompanyWorkListAdmin from '../../page/admin/CompanyWorkList'

// Renders
import {Game, Admin, Public, PreLogin, PostLogin} from '../../render'
import { PreLoader } from '../../element'
import routes from '../../router'

const uS = new UserService(),
miS = new MiscService(),
rS = new RouterService()

const pageComponents = {
    "PackageWorkListAdmin": <PackageWorkListAdmin />,
    "ServiceWorkListAdmin": <ServiceWorkListAdmin />,
    "CompanyWorkListAdmin": <CompanyWorkListAdmin />,
    "ImportCars": <ImportCars />,
    "ImportB2BWorkData": <ImportB2BWorkData />,
    "ImportB2BStockData": <ImportB2BStockData />,
    "Login": <Login />,
    "Otp": <Otp />,
    "Register": <Register />,
    "Home": <Home />,
    "Chat": <Chat />,
    "Contact": <Contact />,
    "Shop": <Shop />,
    "Product": <Product />,
    "Thankyou": <Thankyou />,
    "Services": <Services />,
    "Cart": <Cart />,
    "ModuleList": <ModuleList />,
    "Subscription": <Subscription />,
    "Payment": <Payment />,
    "Ludo": <Ludo />,
    "SnakeNLadder": <SnakeNLadder />,
    "About": <About />,
    "Feedback": <Feedback />,
    "Complaint": <Complaint />,
    "Blog": <Blog />,
    "Project": <Project />,
    "ProjectList": <ProjectList />,
    "AdminProjectList": <AdminProjectList />,
    "AddProject": <AddProject />,
    "Post": <Post />,
    "Garage": <Garage />,
    "Event": <Event />,
    "Job": <Job />,
    "Business": <Business />,
    "EventDetail": <EventDetail />,
    "Page": <Page />,
    "OrderTracking": <OrderTracking />,
    "Faq": <Faq />,
    "Service": <Service />,
    "MicroServices": <MicroServices />,
    "Checkout": <Checkout />,
    "Offers": <Offers />,
    "ManageAddress": <ManageAddress />,
    "AddAddress": <AddAddress />,
    "Pricing": <Pricing />,
    "Profile": <Profile />,
    "CompanyProfile": <CompanyProfile />,
    "DashBoard": <DashBoard />,
    "MyOrders": <MyOrders />,
    "CompanyManagers": <CompanyManagers />,
    "MySubscription": <MySubscription />,
    "MyPackages": <MyPackages />,
    "MyPackageDetail": <MyPackageDetail />,
    "MyOrderDetail": <MyOrderDetail />,
    "ViewCar": <ViewCar />,
    "Notifications": <Notifications />,
    "CarList": <CarList />,
    "AddManager": <AddManager/>,
    "AddCar": <AddCar/>,
    "AdminDashboard": <AdminDashboard />,
    "CompanyDashboard": <CompanyDashboard />,
    "CompanyWorkDashboard": <CompanyWorkDashboard />,
    "CompanyWorkDetailDashboard": <CompanyWorkDetailDashboard />,
    "CompanyStockDetailDashboard": <CompanyStockDetailDashboard />,
    "Report": <Report />,
    "Settings": <Settings />,
    "Media": <Media />,
    "AppBanners": <AppBanners />,
    "ComplaintAdmin": <ComplaintAdmin />,
    "WebBanners": <WebBanners />,
    "AddUser": <AddUser />,
    "Societies": <Societies />,
    "AddSociety": <AddSociety />,
    "Orders": <Orders />,
    "ProductOrders": <ProductOrders />,
    "ServiceOrders": <ServiceOrders />,
    "PackageOrders": <PackageOrders />,
    "FeedbackOrders": <FeedbackOrders />,
    "PackageDetail": <PackageDetail />,
    "OrderDetail": <OrderDetail />,
    "AdminNotifications": <AdminNotifications />,
    "AddGarage": <AddGarage />,
    "AddApp": <AddApp />,
    "AddPost": <AddPost />,
    "AddJob": <AddJob />,
    "TaxClassList": <TaxClassList />,
    "AddTaxClass": <AddTaxClass />,
    "EditUsersCarList": <EditUsersCarList />,
    "CarListAdmin": <CarListAdmin />,
    "WorkListAdmin": <WorkListAdmin />,
    "InvoiceListAdmin": <InvoiceListAdmin />,
    "LogList": <LogList />,
    "KeyListAdmin": <KeyListAdmin />,
    "AddEvent": <AddEvent />,
    "SendMail": <SendMail />,
    "ProductList": <ProductList />,
    "ImportProducts": <ImportProducts />,
    "ProductCategory": <ProductCategory />,
    "ProductBrands": <ProductBrands />,
    "AddProduct": <AddProduct />,
    "ServiceCategory": <ServiceCategory />,
    "MicroServiceCategory": <MicroServiceCategory />,
    "AddMicroService": <AddMicroService />,
    "AddService": <AddService />,
    "GenericChatBot": <GenericChatBot />,
    "AdminFAQ": <AdminFAQ />,
    "QueriesChatBot": <QueriesChatBot />,
    "Robots": <Robots />,
    "AddNotifications": <AddNotifications />,
    "AddTestimonial": <AddTestimonial />,
    "TestimonialList": <TestimonialList />,
    "AddCampaign": <AddCampaign />,
    "CampaignList": <CampaignList />,
    "AddOffer": <AddOffer />,
    "AddPage": <AddPage />,
    "PostList": <PostList />,
    "AppList": <AppList />,
    "JobsList": <JobsList />,
    "GarageList": <GarageList />,
    "PageList": <PageList />,
    "ServicesList": <ServicesList />,
    "OfferList": <OfferList />,
    "EventsList": <EventsList />,
    "MicroServicesList": <MicroServicesList />,
    "Users": <Users />,
    "CarModelManufacturerList": <CarModelManufacturerList />,
    "CarModelList": <CarModelList />,
    "AddCarModel": <AddCarModel />,
    "Run": <Run />
}

const Content = ({
    subRoutes = []
}) => {
    let isMaintenanceModeOn = false
    return <>
        {/* <PreLoader /> */}
        {(localStorage.getItem('authUser') !=='yes' && isMaintenanceModeOn) ? <UnderDevelopment /> : <RouteParser subRoutes={subRoutes} />}
    </>
}
const RouteParser = ({
    subRoutes = []
}) => {
    miS.showPreloader()
    let activeRoute = rS.getLocationData()
    if (miS.isAdminUrl(activeRoute)) {
        document.getElementsByTagName('html')[0].style.overflow = 'hidden'
        require('../../../assets/scss/admin/styles.scss')
    } else {
        document.getElementById('root').classList = activeRoute.pathname.replace(/\//g,"-") + ' front'
        require('../../../assets/scss/styles.scss')
    }
    const _authorizationCheck = e => {
        let data = '', routeFound = true, user = uS.getLoggedInUserData()
        if (['admin', 'developer'].includes(user?.role) || e.type === 'public') {
            routeFound = true
        } else {
            if (e.rejectRoles)
                routeFound = uS.isUserLoggedIn() && !e.rejectRoles.includes(user.role)
            if (e.type === 'preLogin') {
                if (uS.isAllowedAdmin()) {
                    rS.navigateTo('/admin/dashboard')
                }
                if (uS.isUserLoggedIn()) rS.navigateTo('/user/profile')
            } else if (e.type === 'postLogin' && !uS.isUserLoggedIn())
                rS.navigateTo('/login')
            else if (uS.isUserLoggedIn() && e.accessRoles)
                routeFound = uS.isUserLoggedIn() && e.accessRoles.includes(user.role)
        }
        if (e.redirectTo)
            rS.navigateTo(e.redirectTo)
        else
            data = cloneElement( pageComponents[e.component], e );
        return { data, routeFound }
    }
    let data = '',
    routeFound = false,
    activePath = activeRoute.pathname.replace(/\/$/, '');
    [...routes, ...subRoutes].forEach(e => {
        if(!e.isNewRoot) {
            subRoutes.forEach(el => {
                if (el.pathName === e.pathName)
                    e = el
            })
        }
        if ((e.mode === "static" && activePath === e.pathName) || (e.mode === "dynamic" && activePath.substring(0, activePath.lastIndexOf('/')) === e.pathName)) {
            let c = _authorizationCheck(e)
            data = c.data
            if (c.routeFound)
                routeFound = c.routeFound
        }
    })
    if (!routeFound || !(miS.isModuleEnabled(data.props?.module)))
        data = <ErrorPage />
    setTimeout(() => miS.hidePreloader(), 500)
    if (data.props.type === 'admin' && !uS.isAllowedAdmin()) {
        localStorage.setItem('tempRedirectUrl', activeRoute);
        rS.navigateTo('/user/profile')
    } else if (data.props.type === 'postLogin' && !uS.isUserLoggedIn()) {
        localStorage.setItem('tempRedirectUrl', activeRoute);
        rS.navigateTo('/login')
    } else if (data.props.type === 'preLogin' && uS.isUserLoggedIn()) {
        rS.navigateTo('/user/profile')
    } else {
        if (data.props.type !== 'preLogin') {
            localStorage.removeItem('tempRedirectUrl');
        }
        switch (data.props.type) {
            case 'game':
                return Game(data)
            case 'admin':
                return Admin(data)
            case 'preLogin':
                return PreLogin(data)
            case 'public':
                return Public(data)
            case 'postLogin':
                return PostLogin(data)
            default:
                return Public(data)
        }
    }
}
export default Content