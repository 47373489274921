import React from 'react'
import {MiscService} from '../../cms/service'
import {Content} from '../../cms/widget'
import '../index'
import routes from '../routes.json'

const miS = new MiscService(),
App = () => {
	if (localStorage.getItem('activeLanguage') === null)
	miS.setActiveLanguage('en')
	return <Content subRoutes={routes} />
}
export default App