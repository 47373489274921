const routes = [
    {
        "pathName": "/admin/banners/app",
        "component": "AppBanners",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/banners/web",
        "component": "WebBanners",
        "type": "admin",
        "mode": "static"
    }
]

export default routes