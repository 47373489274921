import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../component'
import {faqCategory} from '../../../constant/Enum'
import Select from 'react-select'
import {Actor, Button} from '../../../element'
import {MiscService, DataService, MetaService} from '../../../service'
const dS = new DataService('faq'),
faqCats = [],
mS = new MetaService(),
miS = new MiscService()
faqCategory.forEach(e => {
    faqCats.push({
        label: e.label,
        value: e.value
    })
})
const commonPageSize = 50
export default class AdminFAQ extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeShownTotal: commonPageSize,
            faqList: [],
            query: '',
            activeCategoryIndex: 0,
            ans: '',
            uid: '',
            mode: 'Add',
            category: '',
            showTrashConfirm: false
        }
    }
    componentDidMount () {
        mS.setTitle('FAQs', 'admin')
        this._getFaqsData()
    }
    _getFaqsData = async () => {
        let faqList = []
        const result = await dS.fetchAll()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No faq added yet!' })
            } else {
                result.data.forEach(e => {
                    faqList.push(e)
                })
            }
            this.setState({ faqList:faqList })
        }
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _trashFaq = async () => {
        let { activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        const result = await dS.delete({ query: JSON.stringify(query) })
        if (result.status) {
            this._getFaqsData()
            miS.showAlert({ type: 'success', msg: 'Faq deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else miS.showAlert({ type: 'error', msg: 'Unable to delete faq!' })
    }
    _addFaq = async e => {
        e.preventDefault()
        let { query, ans, mode, uid, category } = this.state,
        error = false,
        errorMsg = '',
        faqObject = {
            query: query,
            ans: ans,
            category: JSON.stringify(category)
        }
        if (query === '' ) {
            errorMsg = 'Please enter Query!'
            error = true
        } else if (ans === '') {
            errorMsg = 'Please enter Answer'
            error = true
        } else if (category === '') {
            errorMsg = 'Please select faq category'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                faqObject.uid = new Date().getTime()
                const result = await dS.save(faqObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Faq added successfully!' })
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add faq!' })
                }
                this.setState({ query: '', ans: '', category: '', uid: '' })
                miS.closeModal("faq-modal")
                this._getFaqsData()
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    faqData: JSON.stringify(faqObject)
                }
                const result = await dS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Faq updated successfully!' })
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update faq!' })
                }
                this.setState({ query: '', ans: '', category: '', uid: '' })
                miS.closeModal("faq-modal")
                this._getFaqsData()
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item ) => {
            let activeCategoryIndex = 0
            let e = {
                data: <div className='mb40'>
                    <p className="query"><b>Query: </b>{item.query}</p>
                    <p className="ans"><b>Category: </b>{JSON.parse(item.category).label}</p>
                    <p className="ans"><b>Answer: </b>{item.ans}</p>
                </div>,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='edit' onClick={() => {
                        faqCats.forEach((e, i) => {
                            if (e.value === JSON.parse(item.category).value)
                                activeCategoryIndex = i
                        })
                        let category = faqCats[activeCategoryIndex]
                        this.setState({ mode: 'Edit', query: item.query, ans: item.ans, uid: item.uid, activeCategoryIndex: activeCategoryIndex, category: category }, () => miS.openModal("faq-modal"))
                    }} />
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    render() {
        let { activeShownTotal, faqList, ans, query, mode, showTrashConfirm, category } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Queries',
                    key: 'queries'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: this._getTableData(faqList)
        }
        return <>
            <h1 className="title flex">FAQ <Button onClick={() => {
                this.setState({ mode: 'Add' }, () => miS.openModal("faq-modal"))
            }} className="btn btn-primary btn-small ml20">Add</Button></h1>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={this._trashFaq} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} />
            <div className="modal faq-modal" id="faq-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <Button className="close" onClick={() => 
                    this.setState({ query: '', ans: '', category: '', uid: '' }, () => miS.closeModal("faq-modal"))}>
                <i className="hi-close"></i></Button>
                    <div className="modal-body">
                        <div className="faq-modal-header">
                            <div className="modal-header">
                                <div className="heading">{mode} Query</div>
                                <form noValidate onSubmit={this._addFaq}> 
                                    <div className="form-group mb20">
                                        <input type="text" className="input" required name="query" value={query} onChange={(e) => this.setState({ query: e.target.value }) } />
                                        <label>Query</label>
                                        <small className="msg text-danger">Please enter a Query</small>
                                    </div>
                                    <div className="form-group mb20">
                                        <Select
                                            value={category}
                                            defaultValue={category}
                                            onChange={category => this.setState({ category })}
                                            options={faqCats}
                                        />
                                        <label>Category</label>
                                        <small className="msg text-danger">Please enter a Query</small>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="" placeholder="Type the notification here" required name="ans" value={ans} onChange={(e) => this.setState({ ans: e.target.value })}></textarea>
                                        <label>Answer</label>
                                        <small className="msg text-danger">Please enter related Answer</small>
                                    </div>
                                    <div className="buttons">
                                        <Button type="submit" title="Click here to Add Faq" className="btn btn-primary btn-small">Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}