const ComplaintModel = [
    {
        key: 'name',
        dataType: String
    },
    {
        key: 'email',
        dataType: String
    },
    {
        key: 'message',
        dataType: String
    },
    {
        key: 'orderId',
        dataType: String
    },
    {
        key: 'number',
        dataType: String
    },
    {
        key: 'productName',
        dataType: String
    },
    {
        key: 'complaintType',
        dataType: String
    },
    {
        key: 'date',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'status',
        dataType: String
    }
]
export default ComplaintModel