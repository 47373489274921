import React, {Component} from 'react'
import {Banner} from '../../../widget'
import {MiscService, MetaService} from '../../../service'
import {Img, Button} from '../../../element'
import {base, brandInfo} from '../../../constant/Data'
const mS = new MetaService(),
miS = new MiscService()
export default class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            company: '',
            mobile: '',
            message: '',
            subject: ''
        }
    }
    componentDidMount() {
        mS.setTitle('Contact Us', 'public')
    }
    _sendContactRequest = async e => {
        e.preventDefault()
        let { name, email, message, mobile, subject, company } = this.state,
        error = false,
        errorMsg = '',
        userObject = {
            name,
            subject,
            company,
            email,
            mobile,
            message
        }
        if (name === '') {
            errorMsg = 'Please enter your Full Name'
            error = true
        }
        if (email === '') {
            errorMsg = 'Please enter your Email Address'
            error = true
        }
        if (mobile === '') {
            errorMsg = 'Please enter your Mobile Number'
            error = true
        }
        if (subject === '') {
            errorMsg = 'Please enter your Subject'
            error = true
        }
        if (message.length < 50) {
            errorMsg = 'Message length should be greated than 50 letters.'
            error = true
        }
        if (!error) {
            const result = await miS.getContactRequest(userObject)
            if (result.status) {
                miS.showAlert({ type: 'info', msg: 'Your contact request has been submitted. We\'ll contact you back in 24 hours.' })
                this.setState({ name: '', email: '', message: '', mobile: '', subject: '' })
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to send contact request.' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { name, email, message, mobile, subject, company } = this.state
        return <>
            <Banner title='Contact' image="contact-banner.jpg" />
            <div className="body-background" style={{minHeight:1}}>
                <div className="jumbo-container">
                    <div className="contact-page">
                        <div className="left-image-section">
                            <Img alt={"Contact " + brandInfo.name.capital} src={base.imageBaseUrl+'contact-us.png'} />
                        </div>
                        <form className="right-form-section" onSubmit={this._sendContactRequest} noValidate>
                            <div className="title-section">
                                <h1>We’d love to hear from you!</h1>
                                <p>Please fill out the form below, or email us at <a title={'Connect with '+brandInfo.name.capital} href={"mailto:"+brandInfo.mail}>{brandInfo.mail}</a></p>
                            </div>
                            <div className="contact-form-section">
                                <div className="form-group input-box">
                                    <input className="input" value={name} onChange={(e) => this.setState({ name: e.target.value })} type="text" name="Name" placeholder="Enter Full Name" />
                                </div>
                                <div className="form-group input-box">
                                    <input className="input" value={company} onChange={(e) => this.setState({ company: e.target.value })} type="text" name="Company" placeholder="Enter Company Name" />
                                </div>
                                <div className="form-group input-box">
                                    <input className="input" value={email} onChange={(e) => this.setState({ email: e.target.value })} type="email" name="Email" placeholder="Enter Email" />
                                </div>
                                <div className="form-group input-box">
                                    <input className="input" value={mobile} onChange={(e) => this.setState({ mobile: e.target.value })} type="tel" name="Mobile" placeholder="Enter Mobile" />
                                </div>
                                <div className="form-group">
                                    <input className="input" value={subject} onChange={(e) => this.setState({ subject: e.target.value })} type="text" name="subject" placeholder="Enter subject" />
                                </div>
                                <div className="form-group">
                                    <textarea className="input textarea" value={message} onChange={(e) => this.setState({ message: e.target.value })} name="message" placeholder="Enter Message" />
                                </div>
                                <Button className="btn btn-primary" type="submit">Submit</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    }
}