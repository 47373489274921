import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../component'
import {DataService, MiscService, MetaService} from '../../../service'
import {Button, Actor} from '../../../element'
const dS = new DataService('notification'),
mS = new MetaService(),
miS = new MiscService()
export default class AdminNotifications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            filteredList: [],
            showTrashConfirm: false,
            checkedAll: false,
            isMultipleDelete: false,
            checkedList: [],
            searchKey: ''
        }
    }
    componentDidMount () {
        mS.setTitle('Notifications', 'admin')
        this._getNotificationsData()
    }
    _getNotificationsData = async () => {
        const result = await dS.fetchAll()
        if (result.status) {
            let tableData = result.data.reverse()
            this.setState({ tableData, filteredList: tableData })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No notification added yet!' })
        } else miS.showAlert({ type: 'error', msg: 'No notification added yet!' })
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _deleteSelectNotification = () => {
        let { activeItem } = this.state
        this._deleteNotifications(activeItem.uid)
    }
    _deleteNotifications = async uid => {
        let query = {
            uid
        }
        const result = await dS.delete({ query: JSON.stringify(query) })
        if (result.status) {
            this._getNotificationsData()
            miS.showAlert({ type: 'success', msg: 'Notification deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else miS.showAlert({ type: 'error', msg: 'Unable to delete notification!' })
    }
    _toggleCheck = uid => {
        let {checkedList, filteredList} = this.state, newCheckedList = [], found = false
        checkedList.forEach(e => {
            if (e === uid)
                found = true
            else
                newCheckedList.push(e)
        })
        if (!found)
        newCheckedList.push(uid)
        this.setState({ checkedAll: (filteredList.length === newCheckedList.length), checkedList: newCheckedList })
    }
    _deleteChecked = () => {
        let { checkedList, checkedAll, filteredList } = this.state
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            this._deleteNotifications(e.uid)
        })
        this.setState({ checkedList: [], isMultipleDelete: false })
    }
    _getTableData = filterData => {
        let data = [], { checkedAll, checkedList } = this.state
        filterData.forEach(( item ) => {
            let e = {
                checkAction: <span className="check-all" onClick={() => this._toggleCheck(item.uid)}>
                    {
                        checkedAll || checkedList.includes(item.uid) ?
                        <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
                    }
                </span>,
                title: item.title,
                body: item.body,
                timing: miS.getFormattedTime(item.timing),
                publishedDate: miS.getFormattedTime(item.timing),
                action: <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.content.toLowerCase()
            if (tempTitle.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList })
    }
    render() {
        let { tableData, searchKey, checkedList, isMultipleDelete, checkedAll, filteredList, showTrashConfirm, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                        {
                            checkedAll || checkedList.length === filteredList.length?
                            <i className="orange hi-check-circle"></i>:
                            <i className="hi-circle"></i>
                        }
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Body',
                    key: 'body'
                },
                {
                    label: 'Time',
                    key: 'time'
                },
                {
                    label: 'Publish Date',
                    key: 'publishDate'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex">
                All Notifications
                <a className="btn btn-primary btn-small ml20" href="/admin/notification/add">Add</a>
                {
                    (checkedList.length !== 0 || checkedAll) &&
                    <Button type="button" onClick={() => {
                        this.setState({ isMultipleDelete:true, showTrashConfirm: true})
                    }} className="btn btn-outline btn-small">Delete</Button>
                }
            </h1>
            <div className="filter-box">
                <div className="filters">
                </div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><i className="hi-close"></i></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._deleteSelectNotification()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}