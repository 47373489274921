const B2BDailyStockModel = [
    {
        key: 'Date',
        dataType: String
    },
    {
        key: 'ownerId',
        dataType: String
    },
    {
        key: 'registrationNo',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'Yard name',
        dataType: String
    },
    {
        key: 'Vehicle no',
        dataType: String
    },
    {
        key: 'Vehicle model',
        dataType: String
    },
    {
        key: 'Inventory Status',
        dataType: String
    }
]

export default B2BDailyStockModel