import {postData,getData} from '../Ops'
export default class CarService {
    addCar = async data => {
        const formData = new FormData()
        formData.append('ownerId', data.ownerId)
        formData.append('uid', data.uid)
        formData.append('registrationNo', data.registrationNo)
        formData.append('registrationDate', data.registrationDate)
        formData.append('chassisNo', data.chassisNo)
        formData.append('engineNo', data.engineNo)
        formData.append('ownerName', data.ownerName)
        formData.append('vehicleClass', data.vehicleClass)
        formData.append('maker', data.maker)
        formData.append('fitness', data.fitness)
        formData.append('mvTax', data.mvTax)
        formData.append('insurance', data.insurance)
        formData.append('pucc', data.pucc)
        formData.append('fuelType', data.fuelType)
        formData.append('emission', data.emission)
        formData.append('rcStatus', data.rcStatus)
        formData.append('financed', data.financed)
        formData.append('carData', data.carData)
        formData.append('rcImage', data.rcImage)
        formData.append('status', data.status ?? 'active')
        formData.append('statusComments', data.statusComments)
        formData.append('shoot360', data.shoot360 ?? false)
        formData.append('shoot360Url', data.shoot360Url ?? false)
        formData.append('insuranceImage', data.insuranceImage)
        formData.append('manufacturer', data.manufacturer ?? '')
        formData.append('modelKey', data.modelKey ?? '')
        return await postData('car/save', formData)
    }
    updateCar = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        formData.append('carData', data.carData)
        return await postData('car/update', formData)
    }
    getCar = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('car/get', formData)
    }
    deleteCar = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('car/delete', formData)
    }
    getAllCars = async () => {
        return await getData('car/list/all')
    }
    getCars = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('car/list', formData)
    }
    getCarsStatusCount = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('car/status/count', formData)
    }
    getManufacturersList = async () => {
        return await getData('car/getmanufacturerslist')
    }
    getCarManufacturerCarsList = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('car/brand/list', formData)
    }
    getVehicleClassList = async () => {
        return await getData('car/getvehicleclasslist')
    }
    getCarManufacturers = async () => {
        return await getData('car/manufacturer/list')
    }
    addCarManufacturer = async brandObject => {
        const formData = new FormData()
        formData.append('name', brandObject.name)
        formData.append('slug', brandObject.slug)
        formData.append('uid', brandObject.uid)
        formData.append('image', brandObject.image)
        return await postData('car/manufacturer/add', formData)
    }
    deleteCarManufacturer = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('car/manufacturer/delete', formData)
    }
}