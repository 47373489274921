import {useEffect, useState, useRef} from 'react'
import {UserImage} from '../../../component'
import { base } from '../../../constant/Data'
import { Img } from '../../../element'
import {DataService, MetaService, MiscService, UserService} from '../../../service'
import './style.scss';
// import io from "socket.io-client";

const uS = new UserService(),
mS = new MetaService(),
msgS = new DataService('msg'),
miS = new MiscService(),
Chat = () => {
    // const socket = io.connect("https://chat.programmerindia.org");
    const sender = uS.getLoggedInUserData()
    const chatContentRef = useRef()
    const tabs = [
        {icon: 'hi-user1', activeIcon: 'people', label: 'Users', value: 'contacts'},
        {icon: 'hi-chat', activeIcon: 'chatbubbles', label: 'Chat', value: 'chat'},
        {icon: 'hi-fire', activeIcon: 'footsteps', label: 'What\'s Hot', value: 'steps'},
        {icon: 'hi-bell1', activeIcon: 'notification', label: 'Notifications', value: 'notification'}
    ]
    const [rightClass, setrightClass] = useState('right')
    const [chatPanelClass, setchatPanelClass] = useState('')
    const [activeRoom, setactiveRoom] = useState('')
    const [showContactFilter, setshowContactFilter] = useState(false)
    const [showSmileyBox, setshowSmileyBox] = useState(false)
    const [showMsgFilter, setshowMsgFilter] = useState(false)
    const [activeTab, setactiveTab] = useState(tabs[0].value)
    const [user, setuser] = useState([])
    const [msg, setmsg] = useState([])
    const [activeMsg, setactiveMsg] = useState('')
    const [receiver, setreceiver] = useState(null)
    const [msgfilter, setmsgfilter] = useState('')
    const [contactfilter, setcontactfilter] = useState('')

    useEffect(() => {
        mS.setTitle('Chat')
        _fetchUsers()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if(activeRoom !== '') {
                _fetchMessages(activeRoom)
            }
        }, 10000);
        return () => clearInterval(interval);
    }, [activeRoom]);

    // useEffect(() => {
    //     socket.on("receive_message", (data) => {
    //         setmsg((list) => [...list, data]);
    //     });
    // }, [socket]);

    const _addMsg = async (e) => {
        e.preventDefault()
        if (activeMsg !== "") {
            const messageData = {
                uid: new Date().getTime(),
                sender: sender.uid,
                receiver: receiver.uid,
                message: activeMsg,
                time: new Date().getTime(),
                room: activeRoom,
                type: 'text'
            };
            let result = await msgS.save(messageData)
            // await socket.emit("send_message", messageData);
            setmsg((msg) => [...msg, messageData]);
            setactiveMsg("");
            _scrollToBottom()
        }
    }

    const _compareByUid = ( a, b ) => {
        if ( Number(a.uid) < Number(b.uid) )
            return -1
        if ( Number(a.uid) > Number(b.uid) )
            return 1
        return 0
    }

    const _initiateChat = (receiver) => {
        let chatParties = [ sender, receiver ]
        chatParties.sort( _compareByUid )
        let newRoom = chatParties[0].uid+'_'+chatParties[1].uid
        _fetchMessages(newRoom)
        setreceiver(receiver)
        setrightClass('right active')
        setactiveRoom(newRoom)
        // socket.emit("join_room", newRoom);
    }

    const _fetchUsers = async () => {
        const result = await uS.getUsers()
        if (result.status) setuser(result.data)
    }

    const _fetchMessages = async room => {
        let query = { room }
        const result = await msgS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            setmsg(result.data)
        }
        _scrollToBottom()
    }

    const _renderTabs = () => {
        return tabs.map((item,index) => {
            let tabClass = activeTab === item.value ? 'pointer tab active':'pointer tab'
            return <li className={tabClass} key={index} onClick={() => setactiveTab(item.value)}>
                <i className={activeTab === item.value ? item.activeIcon : item.icon}></i>
                <span>{item.label}</span>
            </li>
        })
    }

    const _renderMgs = () => {
        return msg.map(( item, index ) => {
            item.msgSender = item.sender === sender.uid ? 'mine' : 'other'
            item.senderTime = miS.getFormattedTime(item.time)
            _filterMsg(item)
            return <li className="chat-container" key={index}>
                <div className={"msg "+item.msgSender}>
                    <span>{item.message}</span>
                    <small className='time'>{item.senderTime}</small>
                </div>
            </li>
        })
    }

    const _renderContacts = (contacts = false) => {
        let list = user
        if (contacts) list = contacts
        return list.filter(e => e.uid !== uS.getLoggedInUserData().uid).map(( item, index ) => {
            _filterContact(item)
            return <li className="contact-container flex pointer" key={index} onClick={() => _initiateChat(item)}>
                <div className='pointer img-box'>
                    <UserImage user={item} />
                    <span className={'liveStatus ' + item.liveStatus}></span>
                </div>
                <div className='detail-box'>
                    <h4 className='name'>{item.name ? item.name : item.mobile}</h4>
                    <span className='status'>{item.textStatus}</span>
                </div>
            </li>
        })
    }

    const _filterMsg = item => {
        return item.message.includes(msgfilter) || msgfilter === ''
    }

    const _filterContact = item => {
        return item.name.includes(contactfilter) || item.mobile.includes(contactfilter) || contactfilter === ''
    }

    const _scrollToBottom = () => {
        const chatBox = chatContentRef.current
        const oneScrollSize = (chatBox.scrollHeight - chatBox.scrollTop)/100
        for (let i = 0; i < 100; i++) {
            setTimeout(() => {
                chatBox.scrollTop += oneScrollSize
            }, i);
        }
    }
    return <>
        <div className={"chat-panel flex " + chatPanelClass}>
            <div className="left">
                <div className="header">
                    <div className='pointer img-box'>
                        <UserImage user={sender} />
                    </div>
                    <span className='icon-box mlauto' onClick={() => setshowContactFilter(!showContactFilter)}>
                        <i className="hi-search1"></i>
                    </span>
                    <span className='icon-box'>
                        <i className="hi-circle"></i>
                    </span>
                </div>
                <div className="content">
                    {showContactFilter && <form className='search-form'>
                        <input type='search' placeholder='Search...' className='search-bar' onChange={e => setcontactfilter(e.target.value)} value={contactfilter} />
                    </form>}
                    {
                        activeTab === 'contacts'?
                        <>
                            <ul className='contacts'>
                                {_renderContacts()}
                            </ul>
                        </>:
                        activeTab === 'chat'?
                        <>
                            <Img src={base.imageBaseUrl+'coming-soon.jpg'} />
                        </>:
                        activeTab === 'steps'?
                        <>
                            <Img src={base.imageBaseUrl+'coming-soon.jpg'} />
                        </>:
                        activeTab === 'notification'?
                        <>
                            <Img src={base.imageBaseUrl+'coming-soon.jpg'} />
                        </>:
                        <></>
                    }
                </div>
                <div className="footer">
                    <ul className='tabs flex'>
                        {_renderTabs()}
                    </ul>
                </div>
            </div>
            <div className={rightClass}>
                {
                    receiver === null ?
                    <div className="empty"></div>:
                    <>
                        <div className="header">
                            <span className='icon-box mr12' onClick={() => {
                                setrightClass('right')
                                setreceiver('')
                            }}>
                                <i className="hi-chevron-left"></i>
                            </span>
                            <div className='pointer img-box'>
                                <UserImage user={receiver} />
                            </div>
                            <h4 className='mrauto'>{receiver.name ? receiver.name : receiver.mobile}</h4>
                            <span className='icon-box' onClick={() => setshowMsgFilter(!showMsgFilter)}>
                                <i className="hi-search1"></i>
                            </span>
                            <span className='icon-box'>
                                <i className="hi-circle"></i>
                            </span>
                        </div>
                        <div className="content" ref={chatContentRef}>
                            <div className="messages">
                                {showMsgFilter && <form className='search-form'>
                                    <input type='search' placeholder='Search...' className='search-bar' onChange={e => setmsgfilter(e.target.value)} value={msgfilter} />
                                </form>}
                                <ul className='msg'>
                                    {_renderMgs()}
                                </ul>
                                <span className='icon-box' onClick={_scrollToBottom}>
                                    <i className="hi-chevron-bottom"></i>
                                </span>
                            </div>
                        </div>
                        <div className="footer">
                            <div className='smiley-box'>

                            </div>
                            <span className='icon-box' onClick={() => setshowSmileyBox(!showSmileyBox)}>
                                <i className='white hi-happy1'></i>
                            </span>
                            <span className='icon-box'>
                                <i className='hi-attachment'></i>
                            </span>
                            <form className='msg-form flex' onSubmit={_addMsg}>
                                <input type='text' placeholder='Message...' className='msg-bar' onChange={e => setactiveMsg(e.target.value)} value={activeMsg} />
                                <span className='icon-box'>
                                    <i className='white hi-mic'></i>
                                </span>
                            </form>
                            <span className='icon-box' onClick={_addMsg}>
                                <i className='hi-send white'></i>
                            </span>
                        </div>
                    </>
                }
            </div>
        </div>
    </>
}

export default Chat