const routes = [
    {
        "pathName": "/admin/taxClass/list",
        "module": ["Ecommerce"],
        "component": "TaxClassList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/taxClass/add",
        "module": ["Ecommerce"],
        "component": "AddTaxClass",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/taxClass/edit",
        "module": ["Ecommerce"],
        "component": "AddTaxClass",
        "type": "admin",
        "mode": "dynamic"
    }
]

export default routes