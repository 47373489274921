import React, {useState} from 'react'
import {ChatBot} from '../../component'
import {MiscService} from '../../service'
import {brandInfo, socialLinks, base} from '../../constant/Data'
import SubFooter from '../SubFooter'
import {Img, Button} from '../../element'
import { footerNav } from '../../constant/Enum'
import CopyrightSection from '../CopyrightSection'
import './style.scss'

const miS = new MiscService()
const Footer = () => {
    const [location, setlocation] = useState('')
    const [locationBox, setlocationBox] = useState(false)

    const socialMediaAccounts = [
        {link: socialLinks.fb, icon:"hi-facebook3"},
        {link: socialLinks.twitter, icon:"hi-twitter"},
        {link: socialLinks.insta, icon:"hi-instagram"},
        {link: socialLinks.linkedin, icon:"hi-linkedin"}
    ]

    const renderSocialIcons = () => {
        return <ul>
            {
                socialMediaAccounts.map((e, i) => {
                    return e.link !== '' ? <li key={i}><a className="icon" href={e.link} target="_blank" rel="noopener noreferrer"><i className={e.icon}></i></a></li> : ''
                })
            }
        </ul>
    }

    const renderLinks = links => {
        return <ul>
            {
                links.map((e, i) => {
                    if(miS.isModuleEnabled(e.module))
                        return <li key={i}><a href={e.link}>{e.label}</a></li>
                })
            }
        </ul>
    }

    const _backToTop = (duration = 1000) => {
        if (document.scrollingElement.scrollTop === 0) return
        const totalScrollDistance = document.scrollingElement.scrollTop
        let scrollY = totalScrollDistance, oldTimestamp = null
        function step (newTimestamp) {
            if (oldTimestamp !== null) {
                scrollY -= totalScrollDistance * (newTimestamp - oldTimestamp) / duration
                if (scrollY <= 0) return document.scrollingElement.scrollTop = 0
                document.scrollingElement.scrollTop = scrollY
            }
            oldTimestamp = newTimestamp
            window.requestAnimationFrame(step)
        }
        window.requestAnimationFrame(step)
    }

    return <>
        { miS.isModuleEnabled(['PopularSearches']) && <SubFooter />}
        <ChatBot />
        <div className="footer">
            <Button onClick={_backToTop} id="back-to-top" title="Go to top">
                <Img src={require('../../../assets/images/car-vect.png')} alt="back-to-top" />
            </Button>
            <section className="footer-top">
                <div className="jumbo-container">
                    <div className="pt180 pb45 footer-box-wrapper">
                        <div className="col-4">
                            <div className="footer-widget widget">
                                <div className="about_us_widget">
                                    <div className='flex'>
                                        <a href="/" className="footer-logo">
                                            <Img src={brandInfo.logo} alt="footer logo" />
                                        </a>
                                        {miS.isModuleEnabled(["CitySwitch"]) && <span className="cityModal">
                                            <li>
                                                <Img onMouseOut={() => setlocationBox(false)} onMouseOver={() => {setlocation('Jaipur'); setlocationBox(true)}} src={require('../../../assets/images/hawamahal.jpg')} alt="footer logo" />
                                                <Img onMouseOut={() => setlocationBox(false)} onMouseOver={() => {setlocation('Mohali'); setlocationBox(true)}} src={require('../../../assets/images/culture-1.jpg')} alt="footer logo" />
                                            </li>
                                            {
                                                locationBox &&
                                                <>{location === 'Jaipur' ? <span className="changeCity-box">Jaipur</span>:<span className="changeCity-box">Mohali</span>}</>
                                            }
                                        </span>}
                                    </div>
                                    <p>{brandInfo.aboutText}</p>
                                    <div className="footer-social-icon pt10">
                                        <div className="banner-header-follow-us">
                                            <h3 className="text">FOLLOW US</h3>
                                            <div className="banner-header-icon">
                                                {
                                                    renderSocialIcons()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 m-l-8">
                            <div className="footer-widget widget widget_nav_menu" id="company-footer">
                                <h3 onClick={() => miS.toggleActiveClass('company-footer')} className="widget-title">Company</h3>
                                {renderLinks(footerNav["company"])}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="footer-widget widget widget_nav_menu" id="useful-links-footer">
                                <h3 onClick={() => miS.toggleActiveClass('useful-links-footer')} className="widget-title">Useful Links</h3>
                                {renderLinks(footerNav["useful"])}
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="footer-widget widget">
                                <h3 className="widget-title">Contact Us</h3>
                                <div className="contact-area">
                                    <ul>
                                        <li><i className="icon flaticon-pin"></i><a href={brandInfo.addressLink}>{brandInfo.address}</a></li>
                                        <li><i className="icon flaticon-email"></i><a href={'mailto: '+brandInfo.mail}>{brandInfo.mail}</a></li>
                                        <li><i className="icon flaticon-call-answer"></i><a href={'tel: '+brandInfo.phone}>{brandInfo.phone}</a></li>
                                        <li><i className="icon flaticon-global"></i><a href="/">{brandInfo.siteName}</a></li>
                                        {miS.isModuleEnabled(["MobileApp"]) && <li className="appImgDiv">
                                            <ul className="flex">
                                                <li className="mr20"><a href={base.iosApp} target="_blank" rel="noopener noreferrer"> <Img src={require('../../../assets/images/apple.png')} alt={'Download '+ brandInfo.name.capital+ 'App for iPhone'} /></a></li>
                                                <li><a href={base.androidApp} target="_blank" rel="noopener noreferrer"><Img src={require('../../../assets/images/android.png')} alt={'Download '+ brandInfo.name.capital+ 'App for Android'} /></a></li>
                                            </ul>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CopyrightSection />
        </div>
    </>
}

export default Footer