import React, {Component} from 'react'
import Slider from 'react-slick'
import {base} from '../../constant/Data'
import {ProductService} from '../../service'
import {WOW} from 'wowjs'
import {Img} from '../../element'
const prS = new ProductService()
export default class HomeSliderProduct extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productSliderList: []
        }
    }
    componentDidMount () {
        this._getProductsData()
        const wow = new WOW({
            offset: 100,
            mobile: false,
            live: true
        })
        wow.init()
    }
    _getProductsData = async () => {
        const result = await prS.getProducts()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(( e, i ) => {
                if (typeof e.trash === "undefined" && e.isFeatured && i<10) {
                    tableData.push(e)
                } else if (!e.trash && i<10) {
                    tableData.push(e)
                }
            })
            this.setState({ productSliderList: tableData })
        }
    }
    _renderServiceSliderList = productSliderFiles => {
        return productSliderFiles.map(( item, index ) => {
            return <div className="slider-items" key={index}>
                <div className="slider-items-wrap">
                    <div className="slider-inner">
                        <div className="slider-inner-box">
                            <div className="slider-content">
                                <div className="slider-content-inner">
                                    <div className="section-title padding-bottom-10">
                                        <div className="image-cover-box mobile-image-home-banner-box">
                                            <Img src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} className="mobile-image-home-banner" />
                                        </div>
                                    </div>
                                    <div className="slider-title">
                                        <h1 className="heading-01 wow fadeInUp" data-wow-delay="0.3s">{item.title}</h1>
                                    </div>
                                    <div className="slider-paragraph padding-bottom-25 padding-top-20">
                                        <p  dangerouslySetInnerHTML={{__html: item.excerpt}}></p>
                                    </div>
                                    <div className="main-btn-wrap">
                                        <a href={"/product/"+item.slug} className="btn btn-primary btn-box">Programmer India</a>
                                    </div>
                                </div>
                            </div>
                            <div className="right-cut-box"></div>
                        </div>
                        <div className="slider-img">
                            <div className="image-cover-box">
                                {
                                    item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                                    <Img src={base.imageBaseUrl+'product-placeholder.png'} alt={item.title} />:
                                    <Img src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })
    }
    render() {
        let { productSliderList } = this.state,
        setting = {
            dots: true,
            infinite: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000
        }
        return <Slider {...setting}>
            {this._renderServiceSliderList(productSliderList || [] )}
        </Slider>
    }
}