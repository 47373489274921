import React, {Component} from 'react'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react'
import {MiscService, DataService, RouterService, MetaService, UserService} from '../../../service'
import {base,mainUrl} from '../../../constant/Data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CreatableSelect from 'react-select/creatable'
import {robotsOption,statusOption} from '../../../constant/Enum'
import {Img, Button, BackIcon} from '../../../element'
import {Box, MediaBox} from '../../../component'
const eS = new DataService('event'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
keywordOptions =  [],
categoryOptions = [],
tagOptions = []
export default class AddEvent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeRobotsIndex: 0,
            activeStatusIndex: 0,
            activeAuthorIndex: 0,
            image: '[]',
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            content: "",
            excerpt: "",
            css: '',
            js: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            publishDate: new Date(),
            assignWorkers: [],
            status: '',
            category: '',
            location: '',
            tag: '',
        }
    }
    componentDidMount () {
        mS.setTitle('Add Event', 'admin')
        let { activeStatusIndex } = this.state
        this.setState({ status: statusOption[activeStatusIndex].value })
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            this._getEventData(urlData[4])
        }
        this._getUsersData()
    }
    _getUsersData = async () => {
        let tableData = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No user added yet!' })
            else {
                result.data.forEach(result => {
                    tableData.push({label: result.name, value: result.uid})
                })
                this.setState({ assignWorkers: tableData })
            }
        }
    }
    _getEventData = async uid => {
        let query = {
            uid
        }
        const result = await eS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let eventData = result.data[0],
            metaKeyword = [],
            tag = [],
            category = [],
            activeRobotsIndex = 0,
            activeAuthorIndex = 0,
            activeStatusIndex = 0
            robotsOption.forEach(( e, i ) => {
                if (e.value === eventData.robots) {
                    activeRobotsIndex = i
                }
            })
            statusOption.forEach(( e, i ) => {
                if (e.value === eventData.status)
                    activeStatusIndex = i
            })
            if (eventData.metaKeyword === '""' || eventData.metaKeyword === '')
                metaKeyword = []
            else {
                metaKeyword = JSON.parse(eventData.metaKeyword)
            }
            if (eventData.tag === '""' || eventData.tag === '') {
                tag = []
            } else {
                tag = JSON.parse(eventData.tag)
            }
            if (eventData.category === '""' || eventData.category === '')
                category = []
            else {
                category = JSON.parse(eventData.category)
            }
            this.setState({
                title: eventData.title,
                mode: 'Edit',
                slug: eventData.slug,
                content: eventData.content,
                excerpt: eventData.excerpt,
                css: eventData.css,
                js: eventData.js,
                canonical: eventData.canonical,
                robots: eventData.robots,
                metaDescription: eventData.metaDescription,
                metaKeyword,
                publishDate: new Date(eventData.publishDate),
                status: eventData.status,
                category,
                tag,
                uid: eventData.uid,
                image: eventData.image,
                activeRobotsIndex,
                activeAuthorIndex,
                activeStatusIndex,
                location: (eventData.location)?eventData.location: '' })
        } else
            miS.showAlert({ type: 'error', msg: 'No event added yet!' })
    }
    _addEvent = async e => {
        e.preventDefault()
        let { mode, title, slug, location, content, excerpt, css, js, canonical, robots, metaDescription, metaKeyword, publishDate, status, category, tag, uid, image } = this.state,
        error = false,
        errorMsg = '',
        eventObject = {
            title,
            slug,
            content,
            excerpt,
            css,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            publishDate,
            status: status,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            image,
            location: location
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (content === '') {
            errorMsg = 'Please enter the content'
            error = true
        } else if (excerpt === '') {
            errorMsg = 'Please enter the excerpt'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                const result = await eS.save(eventObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Event added successfully!' })
                    rS.navigateTo('/admin/event/list')
                } else miS.showAlert({ type: 'error', msg: 'Unable to add event!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    eventData: JSON.stringify(eventObject)
                }
                const result = await eS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Event updated successfully!' })
                    rS.navigateTo('/admin/event/list')
                } else miS.showAlert({ type: 'error', msg: 'Unable to update event!' })
            }
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        { status, robots, publishDate, mode, renderSlug, title, location, slug, content, excerpt, css, js, canonical, activeRobotsIndex, activeStatusIndex, metaDescription, metaKeyword, category, tag, image } = this.state,
        pageTitle = mode + ' Event',
        activeRobots = robotsOption[activeRobotsIndex],
        activeStatus = statusOption[activeStatusIndex]
        robotsOption.forEach(e => {
            if (e.value === robots)
                activeRobots = e
        })
        statusOption.forEach(e => {
            if (e.value === status)
                activeStatus = e
        })
        if (canonical === '')
            canonical = mainUrl+ urlData[2]+'/'+slug
        return <>
            <h1>
                <BackIcon backLink='/admin/event/list' />
                {pageTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._addEvent}>
                <div className="left">
                    <Box title="Title" required>
                        <input autoFocus type="text" required name="title" value={title} onChange={e => {
                            let tempTitle = e.target.value, tempSlug = slug
                            if (renderSlug) {
                                tempSlug = miS.slugify(tempTitle)
                            }
                            this.setState({ title: tempTitle, slug: tempSlug})
                        }} />
                    </Box>
                    <Box title="Slug" required>
                        <input type="text" required name="slug" value={slug} onChange={(e) => this.setState({ slug: e.target.value })} />
                    </Box>
                    <Box title="Content" required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={content}
                            onEditorChange={(content, editor) => {
                                this.setState({ content })
                            }}
                        />
                    </Box>
                    <Box title="Excerpt" required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={excerpt}
                            onEditorChange={(excerpt, editor) => {
                                this.setState({ excerpt })
                            }}
                        />
                    </Box>
                    <Box title="Event Location">
                        <input type="text" name="location" value={location} onChange={(e) => this.setState({ location: e.target.value })} />
                    </Box>
                    <Box title="CSS">
                        <textarea className="description" placeholder=".selector {property:value}" required name="css" value={css} onChange={(e) => this.setState({ css: e.target.value })}></textarea>
                    </Box>
                    <Box title="JS">
                        <textarea className="description" placeholder="alert('Type your script here')" required name="js" value={js} onChange={(e) => this.setState({ js: e.target.value })}></textarea>
                    </Box>
                    <Box title="Canonical URL">
                        <input type="text" required name="canonical" value={canonical} onChange={(e) => this.setState({ canonical: e.target.value })} />
                    </Box>
                    <Box title="Robots">
                        <Select
                            value={activeRobots}
                            defaultValue={activeRobots}
                            onChange={e => this.setState({ robots: e.value })}
                            options={robotsOption}
                        />
                    </Box>
                    <Box title="Meta Description">
                        <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={(e) => this.setState({ metaDescription: e.target.value })}></textarea>
                    </Box>
                    <Box title="Meta Keywords">
                        <CreatableSelect
                            isMulti
                            onChange={e => this.setState({ metaKeyword: e })}
                            options={keywordOptions}
                            value={metaKeyword}
                        />
                    </Box>
                </div>
                <div className="right">
                    <Box title="Meta">
                        <p>
                            Render Slug:
                            <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={() => this.setState({ renderSlug:!renderSlug})}></span>
                        </p>
                        <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                    </Box>
                    <Box title="Event Date">
                        <DatePicker
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeFormat="HH:mm"
                            onChange={publishDate => this.setState({ publishDate })}
                            selected={publishDate}
                        /> 
                    </Box>
                    <Box title="Status">
                        <Select
                            value={activeStatus}
                            defaultValue={activeStatus}
                            onChange={e => this.setState({ status: e.value })}
                            options={statusOption}
                        />
                    </Box>
                    <Box title="Category">
                        <CreatableSelect
                            isMulti
                            value={category}
                            onChange={e => this.setState({ category: e })}
                            options={categoryOptions}
                        />
                    </Box>
                    <Box title="Tags">
                        <CreatableSelect
                            isMulti
                            value={tag}
                            onChange={e => this.setState({ tag: e })}
                            options={tagOptions}
                        />
                    </Box>
                    <MediaBox image={image} setimage={image => this.setState({image})} />
                </div>
            </form>
        </>
    }
}