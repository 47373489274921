import React, {Component} from 'react'
import {base} from '../../../constant/Data'
import {TrashConfirmModal, Table} from '../../../component'
import Select from 'react-select'
import {DataService, MiscService, RouterService, MetaService} from '../../../service'
import {Img, Button, Actor} from '../../../element'
import { statusOption } from '../../../constant/Enum'
const dS = new DataService('project'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
const commonPageSize = 50
const filterTags = statusOption
export default class AdminProjectList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            image: '',
            filteredList: [],
            checkedList: [],
            searchKey: '',
            activeFilterIndex: 0,
            showTrashConfirm: false,
            trashAction: false,
            isMultipleDelete: false,
            checkedAll: false,
            searchList: [],
            activeShownTotal: commonPageSize,
            activeFilter: null
        }
    }
    componentDidMount () {
        mS.setTitle('Projects List', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        this.setState({ trashAction: urlData[2] === 'trash', activeFilter: filterTags[0] }, this._getListData)
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getListData = async () => {
        let { activeFilterIndex, trashAction } = this.state
        const result = await dS.fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            this.setState({ tableData, filteredList: tableData }, () => {
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1) {
                    activefilterValue = e.value
                }
            })
            this._filterItems( activefilterValue, activeFilterIndex)
        })
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No project added yet!' })
            }
        } else
            miS.showAlert({ type: 'error', msg: 'No project added yet!' })
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _deleteSelected = () => {
        let { activeItem } = this.state
        this._delete(activeItem.uid)
    }
    _delete = async (uid, task = 'trash') => {
        let query = {
            uid
        }, { trashAction, tableData } = this.state
        if (trashAction && task === 'trash') {
            const result = await dS.delete({ query: JSON.stringify(query) })
            if (result.status) {
                this._getListData()
                miS.showAlert({ type: 'success', msg: 'Project deleted successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to delete project!' })
        } else {
            let projectObject = null
            tableData.forEach(e => {
                if (e.uid === uid) {
                    projectObject = e
                }
            })
            projectObject.trash = task === 'trash'
            delete projectObject._id
            let data = {
                query: JSON.stringify({uid}),
                projectData: JSON.stringify(projectObject)
            }
            const result = await dS.update(data)
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Project '+task+'d successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to '+task+' project!' })
        }
    }
    _toggleCheck = uid => {
        let {checkedList, filteredList} = this.state, newCheckedList = [], found = false
        checkedList.forEach(e => {
            if (e === uid)
                found = true
            else
                newCheckedList.push(e)
        })
        if (!found)
            newCheckedList.push(uid)
        this.setState({ checkedAll: (filteredList.length === newCheckedList.length), checkedList: newCheckedList })
    }
    _deleteChecked = () => {
        let { checkedList, checkedAll, filteredList } = this.state
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            this._delete(e.uid)
        })
        this.setState({ checkedList: [], isMultipleDelete: false })
    }
    _cloneProject = async item => {
        let projectObject = {...item, projectObject: item.slug+'-copy'}
        projectObject.uid = new Date().getTime()
        const result = await dS.save(projectObject)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'Project clonsed successfully!' })
            this._getListData()
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to add project!' })
    }
    _getTableData = filterData => {
        let data = [], { checkedAll, checkedList, trashAction } = this.state
        filterData.forEach(( item, index ) => {
            let e = {
                checkAction: <span className='check-all' onClick={() => this._toggleCheck(item.uid)}>
                    {
                        checkedAll || checkedList.includes(item.uid) ?
                        <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
                    }
                </span>,
                title: item.title,
                image: item.image?
                    <Img alt="Blank Placeholder" src={base.imageBaseUrl+JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>,
                releaseDate: miS.getFormattedDate(new Date(item.releaseDate)),
                status: item.status,
                action: trashAction?
                <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    <Actor type='undo' onClick={() => this._delete(item.uid, 'restore')} />
                </div>:
                <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    <Actor type='view' onClick={() => rS.navigateTo('/project/'+item.slug)} />
                    <Actor type='edit' onClick={() => rS.navigateTo('/admin/project/edit/'+item.uid)} />
                    <Actor type='copy' onClick={() => this._cloneProject(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _renderFilterTags = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={ index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>{item.item} ({
                (item.key === 'all') ? tableData.length:
                tableData.reduce(( count, e) => {
                return item.key === e.status ? count+1:count
            },0) })</span>
        })
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all')
            filteredList = tableData
        else {
            filteredList = tableData.filter(e => {
                return e.status === key
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.title.toLowerCase()
            if (tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList })
    }
    render() {
        let { activeFilter, tableData, showTrashConfirm, trashAction, checkedList, checkedAll, isMultipleDelete, filteredList, searchKey, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    template:<span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                    {
                        checkedAll || checkedList.length === filteredList.length?
                        <i className="orange hi-check-circle"></i>:
                        <i className="hi-circle"></i>
                    }
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Release Date',
                    key: 'releaseDate'
                },
                {
                    label: 'Project Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex mb50">
                {
                    trashAction?'Trashed': 'All'
                } Projects
                {
                    !trashAction &&
                    <>
                    <a className="btn btn-primary btn-small ml20" href="/admin/project/add">Add</a>
                    {
                        (checkedList.length !== 0 || checkedAll) &&
                        <Button type="button" onClick={() => {
                            this.setState({ isMultipleDelete:true, showTrashConfirm: true})
                        }} className="btn btn-outline btn-small">Delete</Button>
                    }
                    </>
                }
            </h1>
            <div className="filter-box">
                <div className="filters relative">
                <label className="filter-label-add">Status: </label>
                    <Select
                        value={activeFilter}
                        defaultValue={activeFilter}
                        onChange={(e, i) => this.setState({ activeFilter: e }, () => {
                            this._filterItems(e.value, i)
                        })}
                        options={filterTags}
                    />
                </div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                            const reg = /^[A-Za-z0-9 ]+$/
                            if (e.target.value === '' || reg.test(e.target.value))
                                this.setState({ searchKey: e.target.value }, this._search)
                        }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><i className="hi-close"></i></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._deleteSelected()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}