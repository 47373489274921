import React, {Component} from 'react'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react'
import {base,mainUrl} from '../../../constant/Data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CreatableSelect from 'react-select/creatable'
import {robotsOption,authorOption,statusOption} from '../../../constant/Enum'
import {MiscService, CarService, DataService, ServiceService, RouterService, UserService, MetaService} from '../../../service'
import {Img, Button, BackIcon} from '../../../element'
import {Box, MediaBox} from '../../../component'
const tS = new DataService('taxClass'),
sS = new ServiceService(),
uS = new UserService(),
miS = new MiscService(),
mS = new MetaService(),
rS = new RouterService(),
cS = new CarService(),
keywordOptions =  [],
tagOptions = []
export default class AddService extends Component {
    constructor(props) {
        super(props)
        this.state = {
            company: null,
            companies: [],
            activeCar: null,
            activePrice: '',
            saleprice: '',
            activeRobotsIndex: 0,
            activeStatusIndex: 2,
            activeAuthorIndex: 0,
            searchKey: '',
            tableData: [],
            prices: '[]',
            image: '[]',
            icon: '[]',
            categoryOptions: [],
            showCarListBox: false,
            vehicleClassList: [],
            assignWorkers: [],
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            content: "",
            excerpt: "",
            css: '',
            js: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            author: '',
            publishDate: new Date(),
            status: '',
            category: '',
            sku: '',
            hsn: '',
            tag: '',
            tax: '18',
            userNote: '',
            serviceTime: '',
            mediaFiles: [],
            isFeatured: false,
            isAutoApproved: false,
            selectedCarList: true,
            inclusiveTax: false,
            taxClassData: []
        }
    }
    componentDidMount () {
        mS.setTitle('Add Service', 'admin')
        this._getServiceCategoryData()
        this._getTaxClassesListData()
        let { activeStatusIndex } = this.state
        this.setState({ status: statusOption[activeStatusIndex].value })
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getServiceData(query)
        }
        this._getVehicleClassList()
        this._getUsersData()
    }
    _getTaxClassesListData = async () => {
        const result = await tS.fetchAll()
        if (result.status) {
            let taxClassData = []
            result.data.forEach(e => {
                taxClassData.push({
                    label: e.label,
                    value: e.amount
                })
            })
            this.setState({ taxClassData: taxClassData})
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No taxClass added yet!' })
            }
        } else miS.showAlert({ type: 'error', msg: 'No taxClass added yet!' })
    }
    _getVehicleClassList = async () => {
        const result = await cS.getVehicleClassList()
        if (result.status) {
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No car manufacturers added yet!' })
            else
                this.setState({ vehicleClassList: result.data })
        }
    }
    _getUsersData = async () => {
        let companies = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No companies added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'company')
                        companies.push({ label: result.name, value: result.uid })
                })
                this.setState({ companies })
            }
        }
    }
    _getServiceData = async query => {
        const result = await sS.getService({ query: JSON.stringify(query) })
        if (result.status) {
            let serviceData = result.data[0],
            metaKeyword = [],
            tag = [],
            category = [],
            activeRobotsIndex = 0,
            activeAuthorIndex = 0,
            activeStatusIndex = 0
            robotsOption.forEach(( e, i ) => {
                if (e.value === serviceData.robots) {
                    activeRobotsIndex = i
                }
            })
            authorOption.forEach(( e, i ) => {
                if (e.value === serviceData.author) {
                    activeAuthorIndex = i
                }
            })
            statusOption.forEach(( e, i ) => {
                if (e.value === serviceData.status) {
                    activeStatusIndex = i
                }
            })
            if (serviceData.metaKeyword === '""' || serviceData.metaKeyword.includes('"\\"') || serviceData.metaKeyword === null || serviceData.metaKeyword === 'null') {
                metaKeyword = []
            } else {
                JSON.parse(serviceData.metaKeyword).forEach(e => {
                    metaKeyword.push(e)
                })
            }
            if (serviceData.tag === '""' || serviceData.tag.includes('"\\"') || serviceData.tag === null || serviceData.tag === 'null') {
                tag = []
            } else {
                JSON.parse(serviceData.tag).forEach(e => {
                    tag.push(e)
                })
            }
            if (serviceData.category === '""' || serviceData.category?.includes('"\\"') || serviceData.category === null || serviceData.category === 'null') {
                category = []
            } else {
                JSON.parse(serviceData.category).forEach(e => {
                    category.push(e)
                })
            }
            this.setState({
                title: serviceData.title,
                mode: 'Edit',
                slug: serviceData.slug,
                content: serviceData.content,
                excerpt: serviceData.excerpt,
                css: serviceData.css,
                js: serviceData.js,
                canonical: serviceData.canonical,
                robots: serviceData.robots,
                metaDescription: serviceData.metaDescription,
                metaKeyword,
                author: serviceData.author,
                publishDate: new Date(serviceData.publishDate),
                status: serviceData.status,
                category,
                tag,
                serviceTime: serviceData.serviceTime,
                sku: (serviceData.sku)?serviceData.sku: '',
                hsn: (serviceData.hsn)?serviceData.hsn: '',
                tax: (serviceData.tax)?serviceData.tax: '',
                isFeatured: serviceData.isFeatured,
                isAutoApproved: serviceData.isAutoApproved ? serviceData.isAutoApproved : false,
                inclusiveTax: serviceData.inclusiveTax,
                uid: serviceData.uid,
                userNote: serviceData.userNote,
                image: serviceData.image,
                icon: serviceData.icon ? serviceData.icon : '[]',
                activeRobotsIndex,
                activeAuthorIndex,
                activeStatusIndex,
                prices: JSON.parse(serviceData.prices)
            })
            if (serviceData.status === 'private' && serviceData.company)
                this.setState({ company: serviceData.company })
        } else miS.showAlert({ type: 'error', msg: 'No service added yet!' })
    }
    _addService = async e => {
        e.preventDefault()
        let { company, isAutoApproved, isFeatured, userNote, serviceTime, inclusiveTax, sku, tax, mode, hsn, title, slug, content, excerpt, css, js, canonical, robots, metaDescription, metaKeyword, prices, author, publishDate, status, category, tag, uid, image, icon } = this.state,
        error = false,
        errorMsg = '',
        serviceObject = {
            title,
            slug,
            content,
            excerpt,
            css,
            sku,
            hsn,
            tax,
            userNote,
            serviceTime,
            isFeatured,
            isAutoApproved,
            inclusiveTax,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            author,
            publishDate,
            status: status,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            prices: JSON.stringify(prices),
            image,
            icon
        }
        if (status.value === 'private' && company) {
            serviceObject.company = company
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        } else if (icon === '') {
            errorMsg = 'Please select icon'
            error = true
        } else if (tax === '') {
            errorMsg = 'Please enter the tax'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                serviceObject.uid = new Date().getTime()
                const result = await sS.addService(serviceObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Service added successfully!' })
                    rS.navigateTo('/admin/service/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add service!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    serviceData: JSON.stringify(serviceObject)
                }
                const result = await sS.updateService(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Service updated successfully!' })
                    rS.navigateTo('/admin/service/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update service!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _renderCarList = carList => {
        let { activePrice, activeCar } = this.state
        return carList.map(( item, index ) => {
            let activeClass = ''
            if (activeCar !== null)
                activeClass = activeCar.slug === item.slug? 'active': ''
            if (item.slug === item.priceGroup)
                return <li key={index} className={activeClass} onClick={() => {
                    item.price = activePrice
                    this.setState({ activeCar: item, selectedCarList: false, showCarListBox: false })
                }}>
                    <Img alt={item.name} src={base.carImageBase + item.image} />
                    <p>{item.name}</p>
                </li>
            else return false
        })
    }
    _renderPriceList = priceList => {
        return priceList.map(( item, index ) => {
            return <li key={index}>
                <Img alt={item.name} src={base.carImageBase + item.image} />
                <p className="car-name">{item.name}</p>
                <p className="service-price">₹ {item.price}</p>
                <p className="service-price">₹ {item.saleprice}</p>
                <i className="hi-trash remove" onClick={() => this._removePrice(item)}></i>
            </li>
        })
    }
    _removePrice = item => {
        let { prices } = this.state, newPrices = []
        prices = JSON.parse(prices)
        prices.forEach(e => {
            if (e.uid !== item.uid)
                newPrices.push(e)
        })
        this.setState({ prices: JSON.stringify(newPrices) })
    }
    _getServiceCategoryData = async () => {
        let tableData = []
        const result = await sS.getServiceCategory()
        if (result.data.length !== 0) {
            result.data.forEach(e => {
                tableData.push({label: e.catName, value: e.catSlug})
            })
            this.setState({ categoryOptions: tableData })
        }
    }
    _showCarList = () => {
        let { showCarListBox } = this.state
        this.setState({ showCarListBox: !showCarListBox})
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.fileName.toLowerCase()
            if (tempTitle.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ mediaFiles: filteredList })
    }
    render() {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        { company, companies, taxClassData, robots,status, searchKey, tableData, saleprice, showCarListBox, categoryOptions, userNote, serviceTime, activeCar,isFeatured, isAutoApproved, inclusiveTax, sku, tax, hsn, activePrice, mode, renderSlug, title, slug, content, excerpt, css, js, canonical, activeRobotsIndex, activeStatusIndex, metaDescription, metaKeyword, category, tag, image, mediaFiles, prices, vehicleClassList } = this.state,
        serviceTitle = mode + ' Service'
        prices= JSON.parse(prices)
        let activeRobots = robotsOption[activeRobotsIndex],
        activeStatus = statusOption[activeStatusIndex]
        statusOption.forEach(e => {
            if (e.value === status) {
                activeStatus = e
            }
        })
        robotsOption.forEach(e => {
            if (e.value === robots) {
                activeRobots = e
            }
        })
        if (canonical === '') {
            canonical = mainUrl+ urlData[2]+'/'+slug
        }
        let selectedTaxClass = {}
        if (taxClassData.length>0 && tax === '') {
            selectedTaxClass = taxClassData[0]
        }
        taxClassData.forEach(e => {
            if (e.value === tax) {
                selectedTaxClass = e
            }
        })
        return <>
            <h1>
                <BackIcon backLink='/admin/service/list' />
                {serviceTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._addService}>
                <div className="left">
                    <Box title='Title' required>
                        <input autoFocus type="text" required name="title" value={title} onChange={e => {
                            let tempTitle = e.target.value, tempSlug = slug
                            if (renderSlug) {
                                tempSlug = miS.slugify(tempTitle)
                            }
                            this.setState({ title: tempTitle, slug: tempSlug })
                        }} />
                    </Box>
                    <Box title='Slug' required>
                        <input type="text" required name="slug" value={slug} onChange={(e) => this.setState({ slug: e.target.value })} />
                    </Box>
                    <Box title='SKU'>
                        <input type="text" name="sku" value={sku} onChange={(e) => this.setState({ sku: e.target.value })} />
                    </Box>
                    <Box title='HSN Code'>
                        <input type="text" name="hsn" value={hsn} onChange={(e) => this.setState({ hsn: e.target.value })} />
                    </Box>
                    <Box title='Content' required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={content}
                            onEditorChange={(content, editor) => {
                                this.setState({ content })
                            }}
                        />
                    </Box>
                    <Box title='User Note'>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={userNote}
                            onEditorChange={(userNote, editor) => {
                                this.setState({ userNote })
                            }}
                        />
                    </Box>
                    <Box title='Excerpt' required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            initialValue={excerpt}
                            init={base.tinyMceEditorOptions}
                            value={excerpt}
                            onEditorChange={(content, editor) => {
                                this.setState({ excerpt: content })
                            }}
                        />
                    </Box>
                    <Box title='Prices' required bodyClass='relative'>
                        <div className="form-inline">
                            {
                                activeCar === null ?
                                <Button type="button" className="showCarList" onClick={this._showCarList}>Show Car List</Button>:
                                <div className="selected-car-type" onClick={this._showCarList}>
                                    <Img alt={activeCar.name} src={base.carImageBase + activeCar.image} />
                                    <p>{activeCar.name}</p>
                                </div>
                            }
                            {
                                showCarListBox &&
                                <ul className="scroll-box">
                                    { this._renderCarList(vehicleClassList || []) }
                                </ul>
                            }
                            <input className="input" type="number" required name="activePrice" value={activePrice||''} placeholder="Enter MRP" onChange={e => {
                                this.setState({ activePrice: e.target.value })
                            }} />
                            <input className="input" type="number" required name="saleprice" value={saleprice||''} placeholder="Enter Sale Price" onChange={e => {
                                this.setState({ saleprice: e.target.value })
                            }} />
                            <span className="btn-icon" type="button" onClick={() => {
                                let car = activeCar
                                if (activePrice !== '' && activeCar !== null) {
                                    car.price = activePrice;
                                    car.saleprice = saleprice;
                                    car.uid = new Date().getTime()
                                    prices.push(car)
                                    this.setState({ prices: JSON.stringify(prices), activePrice: '', saleprice: '', activeCar: null })
                                }
                            }}><i className="hi-add-circle"></i></span>
                        </div>
                        {
                            prices.length !== 0 &&
                            <ul className="prices-list">
                                { this._renderPriceList(prices||[]) }
                            </ul>
                        }
                        <input type="text" className="hidden" required name="prices" value={JSON.stringify(prices)||'[]'} onChange={e => {
                            this.setState({ prices: e.target.value })
                        }} />
                    </Box>
                    {status.value === 'private' && <><Box title='CSS'>
                        <textarea className="description" placeholder=".selector {property:value}" required name="css" value={css} onChange={(e) => this.setState({ css: e.target.value })}></textarea>
                    </Box>
                    <Box title='JS'>
                        <textarea className="description" placeholder="alert('Type your script here')" required name="js" value={js} onChange={(e) => this.setState({ js: e.target.value })}></textarea>
                    </Box>
                    <Box title='Canonical URL'>
                        <input type="text" required name="canonical" value={canonical} onChange={(e) => this.setState({ canonical: e.target.value })} />
                    </Box>
                    <Box title='Robots'>
                        <Select
                            value={activeRobots}
                            defaultValue={activeRobots}
                            onChange={e => this.setState({ robots: e.value })}
                            options={robotsOption}
                        />
                    </Box>
                    <Box title='Meta Description'>
                        <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={(e) => this.setState({ metaDescription: e.target.value })}></textarea>
                    </Box>
                    <Box title='Meta Keywords'>
                        <CreatableSelect
                            isMulti
                            onChange={e => this.setState({ metaKeyword: e })}
                            options={keywordOptions}
                            value={metaKeyword}
                        />
                    </Box></>}
                </div>
                <div className="right">
                    <Box title='Meta'>
                        <p>
                            Render Slug:
                            <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={() => this.setState({ renderSlug:!renderSlug})}></span>
                        </p>
                        <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                    </Box>
                    {
                        taxClassData.length>0 &&
                        <Box title='Tax % (Tax Class)' required>
                            <Select
                                value={selectedTaxClass}
                                defaultValue={selectedTaxClass}
                                onChange={e => this.setState({ tax: e.value })}
                                options={taxClassData}
                            />
                        </Box>
                    }
                    <Box title='Service Time'>
                        <input type="text" required name="serviceTime" min={0} value={serviceTime||''} onChange={(e) => this.setState({ serviceTime: e.target.value })} />
                    </Box>
                    <Box title='Publish Date'>
                        <DatePicker
                            onChange={publishDate => this.setState({ publishDate })}
                            selected={this.state.publishDate}
                        />
                    </Box>
                    <Box title='Price Meta' required>
                        <span className="pointer isFeatured" onClick={() => this.setState({ inclusiveTax: !inclusiveTax})}>
                            {
                                inclusiveTax ?
                                <i className="icon-check orange hi-check-circle"></i>:
                                <i className="hi-circle icon-check"></i> 
                            }
                            Inclusive of all taxes </span> 
                    </Box>
                    <Box title='Featured'>
                        <span className="pointer isFeatured" onClick={() => this.setState({ isFeatured: !isFeatured})}>
                            {
                                isFeatured ?
                                <i className="icon-check orange hi-check-circle"></i>:
                                <i className="hi-circle icon-check"></i> 
                            }
                            Is Featured </span>
                    </Box>
                    <Box title='Auto Approved'>
                        <span className="pointer isAutoApproved" onClick={() => this.setState({ isAutoApproved: !isAutoApproved})}>
                            {
                                isAutoApproved ?
                                <i className="icon-check orange hi-check-circle"></i>:
                                <i className="hi-circle icon-check"></i> 
                            }
                            Is Auto Approved </span>
                    </Box>
                    <Box title='Status'>
                        <Select
                            value={activeStatus}
                            defaultValue={activeStatus}
                            onChange={e => this.setState({ status: e.value })}
                            options={statusOption}
                        />
                    </Box>
                    {status === 'private' && <Box title='Company' required>
                        <Select
                            value={company}
                            defaultValue={company}
                            onChange={e => this.setState({ company: e })}
                            options={companies}
                        />
                    </Box>}
                    <Box title='Category'>
                        <Select
                            value={category}
                            onChange={e => { this.setState({ category: e }) }}
                            options={categoryOptions}
                            isMulti
                        />
                    </Box>
                    <Box title='Tags'>
                        <CreatableSelect
                            isMulti
                            value={tag}
                            onChange={e => this.setState({ tag: e })}
                            options={tagOptions}
                        />
                    </Box>
                    <MediaBox image={image} setimage={image => this.setState({image})} />
                </div>
            </form>
            <div className="modal media-modal" id="media-modal-icon">
                <div className="modal-backdrop"></div>
                <div className="modal-content">
                    <Button className="close" onClick={() => miS.closeModal("media-modal-icon")}>
                        <i className="hi-close"></i>
                    </Button>
                    <div className="modal-body filter-box">
                        <div className="modal-header">
                            <h2 className="heading">Select Icon</h2>
                            <div className="search-box">
                                <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                                    const reg = /^[A-Za-z0-9 ]+$/
                                    if (e.target.value === '' || reg.test(e.target.value))
                                        this.setState({ searchKey: e.target.value }, this._search)
                                    }} />
                                <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><i className="hi-close"></i></Button>
                            </div>
                        </div>
                        <div className="modal-box">
                            <ul className="media-box">
                                {
                                    mediaFiles.length !== 0 &&
                                    this._renderIcons(mediaFiles||[])
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}