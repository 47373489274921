import React from 'react'
import '../../../../assets/scss/styles.scss'
import {Button} from '../../../element'
import {RouterService, MetaService} from '../../../service'
import './style.scss';
const mS = new MetaService(),
rS = new RouterService()
const UnderDevelopment = () => {
    mS.setTitle('Website is under development', 'public')
    const _authenticate = () => {
        if (document.getElementById('authKey').value === 'Pk@9462678919') {
            localStorage.setItem('authUser', 'yes')
            rS.navigateTo('/home')
        }
    }
    return <div className="body-background">
        <div className="error-page auth-page">
            <h2 className='fs32 white'>Under Development</h2>
            <p>Please enter auth key to visit the page.</p>
            <div className="form-group">
                <input type="text" id="authKey" className="input" style={{height: '40px', padding: '10px 20px'}} />
            </div>
            <Button className="btn-primary btn" onClick={_authenticate}>Enter</Button>
        </div>
    </div>
}
export default UnderDevelopment