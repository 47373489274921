import React from 'react';
import './style.scss'

const Game = component => {
    return <>
        <div className="game-page">
            {component}
        </div>
    </>
}

export default Game