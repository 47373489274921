import React, {Component} from 'react'
import {MiscService, OrderService} from '../../service'
import {base} from '../../constant/Data'
import {Img} from '../../element'
const miS = new MiscService(),
oS = new OrderService()
export default class SideCart extends Component {
    constructor(props) {
        super(props)
        this.props = props
        this.state = {
            cartData: [],
            cartTotal: 0
        }
    }
    componentDidMount () {
        this._getCartData()
    }
    _getCartData = () => {
        let cartData = oS.getCartData(),
        cartTotal = 0
        cartData.forEach(e => {
            if (!e.taxAmount) {
                e.taxAmount = Number((e.price * Number(e.tax)/(100+Number(e.tax))).toFixed(2))
            }
            cartTotal += Number(e.displayPrice) * Number(e.purchaseQuantity)
        })
        localStorage.setItem('cartData', JSON.stringify(cartData))
        this.setState({ cartData, cartTotal })
    }
    _removeProduct = async item => {
        await oS.removeFromCart(item)
        this._getCartData()
    }
    _renderCartData = cartData => {
        return cartData.map(( item, index ) => {
            return <div className="cart-single-item" key={index}>
                <div className="item-left">
                    {
                        item.type === 'servicePackage'?
                        <i className="hi-dropbox service-package-icon"></i>:
                        item.type === 'subscription'?
                        <i className="hi-block service-package-icon"></i>:
                        <Img src={base.imageBaseUrl+item.image} alt={item.title} />
                    }
                </div>
                <div className="item-right">
                    <div className="item-text">
                        <h5 className="heading-05">{item.title}</h5>
                        <div className="price priceProductPage">
                            {
                                typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' || item.saleprice === item.mrp ?
                                <div className="mrp">
                                    <p className="black m0">₹ {Number(item.price).toFixed(2)} </p>
                                    <p className="gray fs10 mt-5 mb0"> + ₹ {Number(item.taxAmount).toFixed(2)} (tax)</p>
                                </div>:
                                <>
                                    <div className="mrp">
                                        <p className="black m0">₹ {Number(item.price).toFixed(2)} </p>
                                        <p className="gray fs10 mt-5 mb0"> + ₹ {Number(item.taxAmount).toFixed(2)} (tax)</p>
                                    </div>
                                    <div className="sale-price">
                                        <p className="black">₹ {Number(item.mrp).toFixed(2)} </p>
                                    </div>
                                    <div className="per-off">
                                        <p className="green m0">{((Number(item.mrp) - Number(item.saleprice))/Number(item.mrp) * 100).toFixed(0)} % off</p>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="price"><span className="quantity_text"> Quantity :</span> {item.purchaseQuantity} </div>
                    </div>
                </div>
                <div className="cart-item-close-btn" onClick={() => {this._removeProduct(item)}}>×</div>
            </div>
        })
    }
    render() {
        let { cartData, cartTotal } = this.state,
        props = this.props
        return <>
            <div className="cart-title-wrap">
                <div className="title">
                    <h3 className="heading-03">Cart</h3>
                </div>
                <div className="show-cart">
                    <span onClick={() => {
                        props.toggleCart()
                    }} className="closebtn" title="Close Overlay"><i className="hi-close"></i></span>
                </div>
            </div>
            <div className="cart-content">
                <div className="cart-item-wrap">
                    {
                        cartData.length === 0?
                        <div className="empty-cart">
                            <Img src={require('../../../assets/images/emptycart.png')} alt="empty-cart" />
                            <p className="tac">No items in the cart yet!</p>
                        </div>:
                        this._renderCartData(cartData || [])
                    }
                </div>
            </div>
            {
                cartTotal !== 0 &&
                <div className="calculate-checkout">
                    <div className="cart-single-item subtotal">
                        <div className="item-left">
                            <h5 className="heading-05">Total:</h5>
                        </div>
                        <div className="item-right amount">₹ {cartTotal.toFixed(2)}</div>
                    </div>
                    <div className="main-btn-wrap  p20">
                        <a href="/cart" className="btn black btn-block btn-primary">VIEW CART</a>
                    </div>
                    <div className="main-btn-wrap pt0 p20">
                        <p onClick={miS.proceedToCheckOut} className="btn black btn-block btn-outline">CHECKOUT</p>
                    </div>
                </div>
            }
        </>
    }
}