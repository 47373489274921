import React, { useEffect, useState } from 'react'
import { Box } from '../../../component'
import Select from 'react-select'
import { BackIcon, Button } from '../../../element'
import { DataService, MetaService, MiscService, RouterService, UserService } from '../../../service'

const AddApp = (props) => {
    const mS = new MetaService(),
    aS = new DataService('app'),
    rS = new RouterService(),
    uS = new UserService(),
    miS = new MiscService(),
    user = uS.getLoggedInUserData(),
    [activeBuilder, setactiveBuilder] = useState(null),
    [mode, setmode] = useState('Add'),
    [uid, setuid] = useState(new Date().getTime()),
    [id, setid] = useState(miS.generateRandomString(8 + Math.ceil(Math.random() * 8))),
    [secret, setsecret] = useState(miS.generateRandomString(24 + Math.ceil(Math.random() * 8))),
    [allowedApis, setallowedApis] = useState([]),
    [builders, setbuilders] = useState([]),
    [status, setstatus] = useState('active'),
    [title, settitle] = useState(''),
    [brandKey, setbrandKey] = useState(''),
    [description, setdescription] = useState('')
    let [author, setauthor] = useState(["admin", "developer"].includes(user.role) ? '' : user.uid)

    useEffect(() => {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            setmode('Edit')
            mS.setTitle('Edit App', 'admin')
            let query = {
                uid: urlData[4]
            }
            _getAppData(query)
        } else {
            if (["admin", "developer"].includes(user.role)) {
                _getBuilders()
            }
            mS.setTitle('Add App', 'admin')
        }
    }, [])

    const _getAppData = async (query) => {
        const result = await aS.fetchByAttr({ query: JSON.stringify(query) })
        if(result.status && result.data.length > 0) {
            let app = result.data[0]
            setid(app.id)
            setuid(app.uid)
            setsecret(app.secret)
            setallowedApis(app.allowedApis)
            setstatus(app.status)
            setauthor(app.author)
            settitle(app.title)
            setbrandKey(app.brandKey)
            setdescription(app.description)
            if (["admin", "developer"].includes(user.role)) {
                _getBuilders(app.author)
            }
        }
    },
    _getBuilders = async (author = false) => {
        let query = {
            role: 'builder'
        }
        const result = await uS.getUser({ query: JSON.stringify(query) })
        let c = []
        if (result.status) {
            result.data.forEach(e => {
                let el = {
                    label: e.name,
                    value: e.uid
                }
                c.push(el)
                if (author) {
                    if (author === e.uid) {
                        setactiveBuilder(el)
                    }
                }
            });
            setbuilders(c)
        }
    },
    _addApp = async e => {
        e.preventDefault()
        let error = false,
        errorMsg = ''
        if (activeBuilder !== null) {
            author = activeBuilder.value
        }
        let appObject = {
            uid,
            title,
            brandKey,
            id,
            secret,
            description,
            author,
            status,
            allowedApis: JSON.stringify(allowedApis)
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (brandKey === '') {
            errorMsg = 'Please select the Brand Key'
            error = true
        } else if (author === '') {
            errorMsg = 'Please select the author'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                appObject.uid = new Date().getTime()
                const result = await aS.save(appObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'app added successfully!' })
                    rS.navigateTo('/admin/app/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add app!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    appData: JSON.stringify(appObject)
                }
                const result = await aS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'app updated successfully!' })
                    rS.navigateTo('/admin/app/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update app!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    },
    _assignBuilder = (e) => {
        setauthor(e.value)
        setactiveBuilder(e)
    },
    _toggleStatus = () => {
        if (status === 'active') {
            setstatus('inactive')
        } else {
            setstatus('active')
        }
    }

    return <>
        <h1>
            <BackIcon backLink='/admin/app/list' />
            { mode + ' App' }
        </h1>
        <form className="columns" noValidate onSubmit={_addApp}>
            <div className="left">
                <Box title="Title" required>
                    <input autoFocus type="text" required name="title" value={title} onChange={e => settitle(e.target.value)} />
                </Box>
                <Box title="Description">
                    <textarea className="description" placeholder="Type the App here" required name="description" value={description} onChange={(e) => setdescription(e.target.value)}></textarea>
                </Box>
                <Box title="App Id">
                    <input type="text" readOnly name="id" value={id} onChange={e => setid(e.target.value)} />
                </Box>
                <Box title="App Secret">
                    <input type="text" readOnly name="secret" value={secret} onChange={e => setsecret(e.target.value)} />
                </Box>
            </div>
            <div className="right">
                <Box title='Status'>
                    <div className="flex-box switch-box">
                        <span>Inactive</span>
                        <label className="switch">
                            <input type="checkbox" onChange={_toggleStatus} checked={status === 'active'?'checked':false} />
                            <span className="slider round"></span>
                        </label>
                        <span>Active</span>
                    </div>
                </Box>
                <Box title="Brand Key">
                    <input type="text" name="brandKey" value={brandKey} onChange={e => setbrandKey(e.target.value)} />
                </Box>
                {
                    ["admin", "developer"].includes(user.role) &&
                    <Box title='Builder'>
                        <Select
                            value={activeBuilder}
                            defaultValue={activeBuilder}
                            onChange={_assignBuilder}
                            options={builders}
                        />
                    </Box>
                }
                <Box title="Meta">
                    <Button className="btn-primary btn" type="submit" title="Click here to Add App">Submit</Button>
                </Box>
            </div>
        </form>
    </>
}

export default AddApp
