import React, { useEffect, useState } from 'react'
import {MiscService, MetaService, UserService, DataService} from '../../../service'
import {Actor, Button, Img} from '../../../element'
import { dashboardWidgetOption } from '../../../constant/Enum'
import { base } from '../../../constant/Data'
import './style.scss'
import DashboardWidget from '../../../widget/admin/DashboardWidget'

const uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
sS = new DataService('setting')
const paidStatus = [
    'completed',
    'paid',
    'processing',
    'shipped',
    'delivered'
]

const AdminDashboard = () => {
    let user = uS.getLoggedInUserData()
    const [widget, setwidget] = useState([]),
    [mode, setmode] = useState('edit')

    useEffect(() => {
        mS.setTitle('Dashboard', 'admin')
        _getDashboardData()
    }, [])

    const _getDashboardData = async () => {
        let query = {
            key: 'admin-dashboard-widgets',
            userId: user.uid
        }
        const result = await sS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length === 0) {
                setmode('add')
            } else {
                let widgetData = JSON.parse(result.data[0].value)
                setwidget(widgetData)
            }
        }
    }

    const _openWidgetMonitor = () => {
        miS.openModal('widget-modal')
    }

    const _addWidget = (e) => {
        miS.closeModal('widget-modal')
        let widgetData = [...widget, {...e, uid: new Date().getTime()}]
        _saveDashboardData(widgetData)
        setwidget(widgetData)
    }

    const _saveDashboardData = async (widgetData) => {
        let settingObject = {
            key: 'admin-dashboard-widgets',
            userId: user.uid,
            value: JSON.stringify(widgetData)
        }
        if (mode === 'add') {
            settingObject.uid = new Date().getTime()
            sS.save(settingObject)
        } else {
            let data = {
                query: JSON.stringify({
                    key: 'admin-dashboard-widgets',
                    userId: user.uid
                }),
                settingData: JSON.stringify({value: settingObject.value})
            }
            sS.update(data)
        }
    }

    const _renderDashboardWidgetOptions = () => {
        return <ul className='dashboard-widgets p20'>
            {
                dashboardWidgetOption.map((e, i) => {
                    return <li key={i} onClick={() => _addWidget(e)}>
                        <p>{e.title}</p>
                        <Img src={base.imageBaseUrl+e.screenshot} />
                    </li>
                })
            }
        </ul>
    }

    const _deleteWidget = (widgetId) => {
        let widgetData = []
        widget.forEach((e) => {
            if (e.uid !== widgetId) {
                widgetData.push(e)
            }
        })
        _saveDashboardData(widgetData)
        setwidget(widgetData)
    }

    const _configureWidget = (widgetId) => {
        let widgetData = []
        widget.forEach((e) => {
            if (e.uid === widgetId) {
                e.configure = true
            }
            widgetData.push(e)
        })
        setwidget(widgetData)
    }

    const _updateWidget = (activeWidgetData) => {
        let widgetData = []
        widget.forEach(e => {
            if(e.uid === activeWidgetData.uid) {
                e = activeWidgetData
            }
            widgetData.push(e)
        })
        _saveDashboardData(widgetData)
        setwidget(widgetData)
    }

    const _renderWidgetsGrid = () => {
        return widget.map((e, i) => {
            return <div key={i} className='dash-box'>
                <div className='action-bar'>
                    <Actor type='setting' onClick={() => _configureWidget(e.uid)} />
                    <Actor type='close' onClick={() => _deleteWidget(e.uid)} />
                </div>
                <DashboardWidget data={e} action={{updateWidget: _updateWidget}} />
            </div>
        })
    }

    return <>
        <h1 className="title flex">
            Dashboard
            <Button className='btn btn-small btn-primary ml20' onClick={_openWidgetMonitor}>Add New Widget</Button>
        </h1>
        <div className="grid-area-section">
            {_renderWidgetsGrid()}
        </div>
        <div className="modal widget-modal" id="widget-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={() => miS.closeModal("widget-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    <div className="modal-header">
                        <h2 className="heading">Select Widget</h2>
                    </div>
                    {_renderDashboardWidgetOptions()}
                </div>
            </div>
        </div>
    </>
}

export default AdminDashboard