import React, {Component} from 'react'
import {base, mainUrl} from '../../../constant/Data'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import {orderOption} from '../../../constant/Enum'
import {OrderService, RouterService, MiscService, ValidationService, LocationService, UserService, DataService, MetaService} from '../../../service'
import {Img, Button, Badge, BackIcon} from '../../../element'
import {Box, CarSelector} from '../../../component'
const dS = new DataService('feedback'),
lS = new DataService('log'),
uS = new UserService(),
mS = new MetaService(),
locS = new LocationService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService(),
oS = new OrderService()
export default class OrderDetail extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            registrationNo: '',
            orderContent: null,
            note: '',
            activeProductTempUid: '',
            activeAuthorIndex: 0,
            assignWorkers: [],
            customersData: [],
            assignCustomers: [],
            status: '',
            invoiceData: [],
            assignCustomer: '',
            assignWorker: '',
            feedbackContent: [],
            editAddress: false,
            datepicker: false,
            startDate: new Date(),
            order: '',
            building: '',
            locality: '',
            pinCode: '',
            city: '',
            state: '',
            landmark: '',
            alternateNo: '',
            uid: '',
            societyData: [],
            activeSociety: null,
            showSocietyListBox: false,
            fromSociety: true,
            fullAddress: ''
        }
    }
    componentDidMount () {
        mS.setTitle('Order Details', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        query = {
            uid: urlData[4]
        }
        this._getOrderData(query)
        this._getUsersData()
        this._getOrderFeedback(urlData[4])
        this._getInvoice(urlData[4])
        this._getSocietiesListData()
    }
    _getInvoice = async orderId => {
        let query = {
            orderId: orderId
        }
        const result = await oS.getOrderInvoice({ query: JSON.stringify(query) })
        if (result.status)
            this.setState({ invoiceData: result.data })
        else miS.showAlert({ type: 'error', msg: 'No order placed yet!' })
    }
    _getUsersData = async () => {
        let tableData = [], customersData = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'worker') {
                        tableData.push({label: result.name, value: result.uid})
                    } else {
                        customersData.push({label: result.name, value: result.uid})
                    }
                })
                this.setState({ assignWorkers: tableData, assignCustomers: customersData, customersData: result.data })
            }
        }
    }
    _getOrderData = async query => {
        const result = await oS.getOrder({ query: JSON.stringify(query) })
        if (result.status) {
            let orderContent = result.data[0]
            this.setState({ editAddress: false, orderContent }, () => {
                if (orderContent && orderContent.assignWorker)
                    this.setState({ assignWorker: JSON.parse(orderContent.assignWorker) })
            })
        } else miS.showAlert({ type: 'error', msg: 'No product added yet!' })
    }
    _updateOrderContent = async orderContent => {
        delete orderContent._id
        let data = {
            query: JSON.stringify({uid: orderContent.uid}),
            orderData: JSON.stringify(orderContent)
        }
        const result = await oS.updateOrder(data)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'Order data updated successfully.' })
            setTimeout(() => {
                rS.getLocationData().reload()
            }, 2000)
        } else miS.showAlert({ type: 'error', msg: 'Unable to update order! Please try after some time.' })
    }
    _assignCar = tempUid => {
        let { orderContent, registrationNo } = this.state, newCartData = [],
        orderData = JSON.parse(orderContent.orderData)
        orderData.cartData.forEach(e => {
            if (tempUid === e.tempUid) {
                e.title += ' ['+registrationNo+']'
                e.activeCar.registrationNo = registrationNo
            }
            newCartData.push(e)
        })
        orderData.cartData = newCartData
        orderContent.orderData = JSON.stringify(orderData)
        this._updateOrderContent(orderContent)
    }
    _updateCar = (tempUid, activeCar) => {
        let { orderContent } = this.state, newCartData = [],
        orderData = JSON.parse(orderContent.orderData)
        orderData.cartData.forEach(e => {
            if (tempUid === e.tempUid) {
                activeCar.registrationNo = e.activeCar.registrationNo
                e.activeCar = activeCar
            }
            newCartData.push(e)
        })
        orderData.cartData = newCartData
        orderContent.orderData = JSON.stringify(orderData)
        this._updateOrderContent(orderContent)
    }
    _renderCartData = cartData => {
        let { orderContent, datepicker, startDate, registrationNo } = this.state
        return cartData.map(( item, index ) => {
            let activeStatus = '', noCar = false, activeCar = item.activeCar
            if (orderContent.orderNotes) {
                orderContent.orderNotes.forEach(e => {
                    if (e.tempUid === item.tempUid) {
                        orderOption.forEach(el => {
                            if (el.value === e.status)
                                activeStatus = el.label
                        })
                    }
                })
            }
            if (['servicePackage', 'service'].includes(item.type))
            if (typeof item.activeCar.registrationNo === "undefined")
            noCar = true
            return <tr className="cart-form-cart-item cart_item priceProductPage" key={index}>
                <td className="product-thumbnail">
                {
                    item.type === 'servicePackage'?
                    <i className="hi-dropbox service-package-icon"></i>:
                    item.type === 'subscription'?
                    <i className="hi-block service-package-icon"></i>:
                    <Img src={base.imageBaseUrl+item.image} alt={item.title} />
                }
                </td>
                <td className="product-name" data-title="Product">
                    {item.title}
                    {
                        activeCar && <>
                            <CarSelector getSelectedCar={(c) => this._updateCar(item.tempUid, c)} getNewCars={true} activeCar={activeCar} />
                        </>
                    }
                    {
                        item.type === 'service' &&
                        <p className="active-time-slot"><b>Time Slot:</b> {(item.activeTimeSlot).slot}</p>
                    }
                    {
                        noCar &&
                        <div className="guest-car-box">
                            <div className="form-group">
                                <div className="col-9 controls mt20">
                                    <input className="input uppercase" placeholder="Registration No" type="text" required name="registrationNo" value={registrationNo || ''} onChange={(e) => this.setState({ registrationNo: e.target.value })} />
                                    <small className="help-text ashgray">e.g. RJ14 AB 1234 --OR-- RNX AB 1234</small>
                                </div>
                                <Button className="btn btn-primary" onClick={() => {
                                    if (!vS.isValidVehicleRegistrationNumber(registrationNo)) {
                                        miS.showAlert({ type: 'error', msg: 'Please enter a valid car Registration Number!' })
                                    } else {
                                        this._assignCar(item.tempUid)
                                    }
                                }}>Submit</Button>
                            </div>
                        </div>
                    }
                </td>
                {
                    item.type === "servicePackage" ?
                    <>
                        <td>
                            <p className="nowrap">{miS.getFormattedDate(new Date(item.startDate))}</p>
                            {
                                datepicker ?
                                <div className="">
                                    <DatePicker
                                        placeholderText="New Start Date"
                                        className="mt10"
                                        dateFormat="d, MMMM, yyyy"
                                        onChange={startDate => {
                                            this.setState({ startDate }, () => this._updateStartDate(item.tempUid, startDate))
                                        }}
                                        selected={startDate}
                                    />
                                </div>:
                                <Button onClick={() => this.setState({ datepicker: true })} className="btn btn-primary">Edit</Button>
                            }
                        </td>
                        <td>
                            <p className="nowrap">{miS.getFormattedDate(new Date(item.endDate))}</p>
                        </td>
                    </>:<td colSpan="2"></td>
                }
                <td className="product-price" data-title="Price">
                    {
                        typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' || item.saleprice === item.mrp ?
                        <div className="mrp">
                            <p className="black m0">₹ {Number(item.price).toFixed(2)} </p>
                            <p className="gray fs10 mt-5 mb0"> + ₹ {Number(item.taxAmount).toFixed(2)} (tax)</p>
                        </div>:
                        <>
                            <div className="mrp">
                                <p className="black m0">₹ {Number(item.price).toFixed(2)} </p>
                                <p className="gray fs10 mt-5 mb0"> + ₹ {Number(item.taxAmount).toFixed(2)} (tax)</p>
                            </div>
                            <div className="sale-price">
                                <p className="black stroke">₹ {Number(item.mrp).toFixed(2)} </p>
                            </div>
                            <div className="per-off">
                                <p className="green m0">{((Number(item.mrp) - Number(item.saleprice))/Number(item.mrp) * 100).toFixed(0)} % off</p>
                            </div>
                        </>
                    }
                </td>
                <td className="product-quantity" data-title="Quantity">
                    <div className="quantity">
                        {item.purchaseQuantity}
                    </div>
                </td>
                <td className="product-subtotal" data-title="Subtotal">
                    ₹ {Number((item.taxAmount+item.price) * item.purchaseQuantity).toFixed(2)}
                    {
                        activeStatus !== '' && <p className="status-box"><label>{activeStatus}</label></p>
                    }
                </td>
            </tr>
        })
    }
    _addNote = e => {
        e.preventDefault()
        let { status, note, orderContent, activeProductTempUid } = this.state,
        orderNotes = typeof orderContent.orderNotes === 'undefined'? []: orderContent.orderNotes,
        activeNote = {
            status,
            note,
            timing: new Date().getTime()
        }
        if (activeProductTempUid !== 'order') {
            activeNote.tempUid = activeProductTempUid
        } else {
            orderContent.status = status
        }
        orderNotes.push(activeNote)
        orderContent.orderNotes = orderNotes
        this._updateOrderContent(orderContent)
    }
    _renderNotes = orderNotes => {
        let { orderContent } = this.state
        return orderNotes.map(( item, index ) => {
            let activeLabel = '', orderData = null
            if (item.tempUid) {
                if (orderContent !== null) {
                    orderData = JSON.parse(orderContent.orderData)
                }
                if (orderData !== null) {
                    orderData.cartData.forEach(e => {
                        if (e.tempUid === item.tempUid)
                            activeLabel = e.title
                    })
                }
            }
            return <li rel="102" className="note system-note" key={index}>
                <div className="note-content">
                    {
                        activeLabel!=='' && <label>{activeLabel}</label>
                    }
                    <p>{item.note}</p>
                </div>
                <p className="note-meta">
                    <abbr className="exact-date">{miS.getFormattedDate(item.timing)}</abbr>
                </p>
                <p className="note-status">
                    <Badge item={item.status} />
                </p>
            </li>
        })
    }
    _assignWorkers = () => {
        let { orderContent, assignWorker } = this.state
        orderContent.assignWorker = JSON.stringify(assignWorker)
        let logData = {
            log: this.user.name+' assign order Id #'+orderContent.uid + ' to '+ assignWorker.label,
            timing: new Date().getTime(),
            type: 'info',
            userId: this.user.uid,
            uid: new Date().getTime()
        }
        lS.save(logData)
        this._updateOrderContent(orderContent)
    }
    _assignCustomer = () => {
        let { orderContent, assignCustomer, customersData } = this.state, userData = JSON.parse(orderContent.userData)
        customersData.forEach(e => {
            if (e.uid === assignCustomer.value)
                userData = e
        })
        orderContent.userData = JSON.stringify(userData)
        this._updateOrderContent(orderContent)
    }
    _generateInvoice = async () => {
        let {orderContent} = this.state,
        data = {
            orderId: orderContent.uid
        }
        const result = await oS.forceRegenrateInvoice(data)
        if (result.status)
            miS.showAlert({ type: 'success', msg: 'Invoice generated successfully!' })
        else miS.showAlert({ type: 'error', msg: 'Unable to generate Invoice!' })
    }
    _getOrderFeedback = async orderId => {
        let query = {
            orderId: orderId
        }
        const result = await dS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length > 0)
                this.setState({ feedbackContent: result.data[0] })
        }
    }
    _getSocietiesListData = async () => {
        const result = await locS.getSocities()
        if (result.status) {
            let societyData = result.data.reverse()
            this.setState({ societyData })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No society added yet!' })
        } else miS.showAlert({ type: 'error', msg: 'No society added yet!' })
    }
    _renderSocietyList = carList => {
        let { activeSociety } = this.state
        return carList.map(( item, index ) => {
            let activeClass = ''
            if (activeSociety !== null) {
                activeClass = activeSociety.title === item.title? 'active': ''
            }
            return <li key={index} className={activeClass} onClick={() => {
                this.setState({ activeSociety: item, showSocietyListBox: false }, () => {
                    let activeaddress = JSON.parse(item.addressDetail), pinCode ='', locality= '', city= '', state = ''
                    activeaddress.address_component.forEach(e => {
                        if (e.types[0]==="postal_code")
                            pinCode = e.long_name
                        if (e.types[0]==="administrative_area_level_1")
                            state = e.long_name
                        if (e.types[0]==="administrative_area_level_2")
                            city = e.long_name
                        if (e.types[0]==="locality")
                            locality = e.long_name
                    })
                    this.setState({ locality, pinCode, city, state, fullAddress: activeaddress.formatted_address })
                })
            }}>
                <p>{item.title}</p>
            </li>
        })
    }
    _addLocation = e => {
        e.preventDefault()
        let { building, locality, pinCode, city, state, landmark, alternateNo, orderContent, activeSociety } = this.state,
        error = false,
        errorMsg = '',
        locationObject = {
            building,
            locality,
            pinCode,
            city,
            state,
            landmark,
            alternateNo,
            activeSociety: JSON.stringify(activeSociety)
        }
        if (building === '') {
            errorMsg = 'Please enter Building / Flat No.'
            error = true
        } else if (locality === '') {
            errorMsg = 'Please enter the Locality'
            error = true
        } else if (pinCode === '') {
            errorMsg = 'Please select the PinCode'
            error = true
        } else if (city === '') {
            errorMsg = 'Please select a City'
            error = true
        } else if (state === '') {
            errorMsg = 'Please select a State'
            error = true
        }
        if (!error) {
            orderContent.activeAddress = JSON.stringify(locationObject)
            this._updateOrderContent(orderContent)
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _updateStartDate = (tempUid, date) => {
        let { orderContent } = this.state, newCartData = [],
        orderData = JSON.parse(orderContent.orderData), totalDays = 0, endDate = date, startDate = date
        orderData.cartData.forEach(e => {
            if (tempUid === e.tempUid) {
                e.startDate = startDate
                totalDays = e.billing === 'monthly' ? 30 : 90
                e.endDate = new Date(endDate.getTime() + (totalDays * 24 * 60 * 60 * 1000))
            }
            newCartData.push(e)
        })
        orderData.cartData = newCartData
        orderContent.orderData = JSON.stringify(orderData)
        this._updateOrderContent(orderContent)
    }
    render() {
        let { note, orderContent, feedbackContent, editAddress, assignWorker, assignCustomer, assignCustomers, assignWorkers, invoiceData, fromSociety, activeSociety, showSocietyListBox, societyData, fullAddress, building, locality, pinCode, city, state, alternateNo, landmark } = this.state, addr = '', usr = '', orderData = null
        if (orderContent && orderContent !== null) {
            if (orderContent.activeAddress !== "undefined" && orderContent.activeAddress)
                addr = JSON.parse(orderContent.activeAddress)
            usr = JSON.parse(orderContent.userData)
            orderData = JSON.parse(orderContent.orderData)
        }
        let invoiceNo = '', order = []
        order.push({value: 'order', label: 'Whole Order' })
        if (orderContent !== null) {
            invoiceData.forEach(e => {
                if (e.orderId === orderContent.uid)
                    invoiceNo = e.invoiceNo
            })
        }
        let cartDataType = []
        if (orderData !== null) {
            orderData.cartData.forEach(e => {
                cartDataType.push(e.type)
                order.push({value: e.tempUid, label: e.title })
            })
        }
        return <>
            {
                orderContent && orderContent !== null &&
                    <>
                    <h1 className="order-detail-h1">
                        <BackIcon backLink='/admin/order/list' />
                        <span className="ml10">Order #{orderContent.uid} </span>
                        {
                            (orderContent.status === 'paid'
                            || orderContent.status === 'processing'
                            || orderContent.status === 'shipped'
                            || orderContent.status === 'delivered'
                            || orderContent.status === 'completed') && invoiceNo !== '' &&
                            <a href={base.invoiceBaseUrl+"/invoice_"+invoiceNo+".pdf"} download={"invoice_"+invoiceNo+".pdf"} target="_blank" className="btn btn-primary mlauto" rel="noreferrer">
                                <i className="hi-download-cloud"></i>
                            Download Invoice</a>
                        }
                        {
                            // ((invoiceNo === '' && (orderContent.status === 'paid'
                            // || orderContent.status === 'processing'
                            // || orderContent.status === 'shipped'
                            // || orderContent.status === 'delivered'
                            // || orderContent.status === 'completed')) || (this.user.role === 'developer')) &&
                            <Button className="btn btn-primary  mlauto " onClick={this._generateInvoice}>Generate Invoice</Button>
                        }
                    </h1>
                    <div className="columns">
                        <div className="left">
                            <Box title='User Details'>
                                <div className="form-group">
                                    <label>Name</label>
                                    <p>{usr.name}</p>
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <p>{usr.email}</p>
                                </div>
                                <div className="form-group">
                                    <label>Phone</label>
                                    <p>{usr.mobile}</p>
                                </div>
                                <div className="form-group">
                                    <label>Alternate Number</label>
                                    <p>{addr.alternateNo}</p>
                                </div>
                        </Box>
                            <Box title='Delivery Address'>
                                {miS.parseAddress(addr)}
                                {
                                    !editAddress && <Button onClick={() => this.setState({ editAddress: true })} className="btn btn-primary mlauto">Edit</Button>
                                }
                                {
                                    editAddress &&
                                    <form className="col-9" noValidate onSubmit={this._addLocation}>
                                        <h1>
                                            Edit Address
                                        </h1>
                                        {
                                            miS.isModuleEnabled(["Society"]) && fromSociety ?
                                            <div className="relative">
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Select your society</label>
                                                    <div className="col-9 controls relative">
                                                        {
                                                            activeSociety === null ?
                                                            <Button type="button" className="btn btn-primary" onClick={() => this.setState({ showSocietyListBox: !showSocietyListBox})}>Show Societies List</Button>
                                                            :<>
                                                                <div className="selected-society-type" onClick={() => this.setState({ showSocietyListBox: !showSocietyListBox})}>
                                                                    <p>{activeSociety.title}</p>
                                                                </div>
                                                                <small className="ashgray">({ fullAddress })</small>
                                                            </>
                                                        }
                                                        {
                                                            showSocietyListBox &&
                                                            <ul className="scroll-box mt-10">
                                                                { this._renderSocietyList(societyData || []) }
                                                            </ul>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    activeSociety !== null &&
                                                    <div className="filled-society-data">
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">Address (Building/Flat) <sup>*</sup>: </label>
                                                            <div className="col-9 controls">
                                                                <input className="input" autoFocus placeholder="Address (Building/Flat)" type="text" required name="building" value={building||''} onChange={(e) => this.setState({ building: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                                            <div className="col-9 controls">
                                                                <input className="input" placeholder="Locality (Street/Area)" type="text" name="locality" value={locality||''} onChange={(e) => this.setState({ locality: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                                            <div className="col-9 controls">
                                                                <input className="input" placeholder="Pincode" type="number" disabled name="pinCode" value={pinCode||''} onChange={(e) => this.setState({ pinCode: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                                            <div className="col-9 controls">
                                                                <input className="input" placeholder="City/District/Town" type="text" disabled name="city" value={city||''} onChange={(e) => this.setState({ city: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">State <sup>*</sup></label>
                                                            <div className="col-9 controls">
                                                                <input className="input" placeholder="State" type="text" disabled name="state" value={state||''} onChange={(e) => this.setState({ state: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">Alternate Phone No. </label>
                                                            <div className="col-9 controls">
                                                                <input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={(e) => this.setState({ alternateNo: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="link mt20" onClick={() => this.setState({ fromSociety: !fromSociety })}>Not living in above listed society? Click Here</div>
                                            </div>:
                                            <>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Address (Building/Flat) <sup>*</sup>: </label>
                                                    <div className="col-9 controls">
                                                        <input className="input" autoFocus placeholder="Address (Building/Flat)" type="text" required name="building" value={building||''} onChange={(e) => this.setState({ building: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <input className="input" placeholder="Locality (Street/Area)" type="text" required name="locality" value={locality||''} onChange={(e) => this.setState({ locality: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <input className="input" placeholder="Pincode" type="number" required name="pinCode" value={pinCode||''} onChange={(e) => this.setState({ pinCode: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <input className="input" placeholder="City/District/Town" type="text" required name="city" value={city||''} onChange={(e) => this.setState({ city: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">State <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <input className="input" placeholder="State" type="text" required name="state" value={state||''} onChange={(e) => this.setState({ state: e.target.value })} />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Landmark </label>
                                                    <div className="col-9 controls">
                                                        <input className="input" placeholder="Landmark" type="text" required name="landmark" value={landmark||''} onChange={(e) => this.setState({ landmark: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Alternate Phone No. </label>
                                                    <div className="col-9 controls">
                                                        <input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={(e) => this.setState({ alternateNo: e.target.value })} />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="flex">
                                            <Button className="btn btn-outline btn-small addlocationbtn mr20" onClick={() => this.setState({ editAddress: false })}>Cancel</Button>
                                            <Button className="btn btn-primary btn-small " type="submit">Submit</Button>
                                        </div>
                                    </form>
                                }
                            </Box>
                            <Box title='Items'>
                                <table className="shop-table overflown shop-table_responsive cart cart-form__contents mb0 br0">
                                    <thead>
                                        <tr>
                                            <th className="product-thumbnail">Preview</th>
                                            <th className="product-name" colSpan={cartDataType.includes('servicePackage') ? "2": "3"}>Product</th>
                                            {
                                                cartDataType.includes('servicePackage') &&
                                                <>
                                                    <th className="product-subtotal">Start Date</th>
                                                    <th className="product-subtotal">End Date</th>
                                                </>
                                            }
                                            <th className="product-price">Price</th>
                                            <th className="product-quantity">Quantity</th>
                                            <th className="product-subtotal">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this._renderCartData(orderData.cartData || []) }
                                        <tr>
                                            <td className="small-col" colSpan="6">Total</td>
                                            <td className="small-col"><b>₹ {(orderData.cartTotal).toFixed(2)}</b></td>
                                        </tr>
                                        {
                                            !isNaN(orderData.discount) && orderData.discount && orderData.discount !== "" && orderData.discount !== 0 && orderData.discount !== '0' ?
                                            <>
                                                <tr>
                                                    <td className="small-col" colSpan="6">Discount</td>
                                                    <td className="small-col">₹ {orderData.discount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="small-col" colSpan="6">Net Payable</td>
                                                    <td className="small-col"><b>₹ {(orderData.cartTotal - orderData.discount).toFixed(2)}</b></td>
                                                </tr>
                                            </>:<></>
                                        }
                                    </tbody>
                                </table>
                            </Box>
                        </div>
                        <div className="right">
                            <Box title='Active Status'>
                                {orderContent.status}
                            </Box>
                            {
                                (cartDataType.includes('servicePackage') || cartDataType.includes('product') || cartDataType.includes('service')) && orderContent.status !== 'initialised' && orderContent.status !== 'payment-fail' && orderContent.status !== 'completed' && (typeof addr.activeSociety === "undefined" || addr.activeSociety === "null" || addr.activeSociety === null || addr.activeSociety === "undefined") &&
                                <Box title='Assign Workers'>
                                    <Select
                                        value={assignWorker}
                                        defaultValue={assignWorker}
                                        onChange={e => this.setState({ assignWorker: e })}
                                        options={assignWorkers}
                                        isMulti
                                    />
                                    <Button type="button" className="btn btn-primary mt20" onClick={this._assignWorkers}>Assign Workers</Button>
                                </Box>
                            }
                            {
                                uS.isDeveloper() &&
                                <Box title='Assign Customer'>
                                    <Select
                                        value={assignCustomer}
                                        defaultValue={assignCustomer}
                                        onChange={e => this.setState({ assignCustomer: e })}
                                        options={assignCustomers}
                                    />
                                    <Button type="button" className="btn btn-primary mt20" onClick={this._assignCustomer}>Assign Customer</Button>
                                </Box>
                            }
                            {
                                orderContent.status === 'completed' &&
                                <Box title='Feedback & Review'>
                                    {
                                        feedbackContent !== null &&
                                        <div className="rating-order-detail">
                                            { miS.renderRatingSystem(Math.ceil(feedbackContent.rating))}
                                            <p>{feedbackContent.comments}</p>
                                        </div>
                                    }
                                </Box>
                            }
                            <Box title='Notes'>
                                <ul className="notes">
                                    { this._renderNotes( orderContent.orderNotes||[] ) }
                                </ul>
                            </Box>
                            <Box title='Add Notes'>
                                <form onSubmit={this._addNote}>
                                    <textarea className="mb20" placeholder="Type the note here" required value={note} name="content" onChange={(e) => this.setState({ note: e.target.value })}></textarea>
                                    <p>Status</p>
                                    <Select
                                        onChange={e => this.setState({ status: e.value })}
                                        options={orderOption}
                                    />
                                    <p>Products</p>
                                    <Select
                                        onChange={e => this.setState({ activeProductTempUid: e.value })}
                                        options={order}
                                    />
                                    <Button type="submit" className="btn btn-primary">Add</Button>
                                </form>
                            </Box>
                        </div>
                    </div>
                </>
            }
        </>
    }
}