import React, {Component, useEffect, useState} from 'react'
import Select from 'react-select'
import {base, mainUrl} from '../../../constant/Data'
import {TrashConfirmModal, Table} from '../../../component'
import {Img, Button, Badge, Actor} from '../../../element'
import { b2bService } from '../../../constant/Enum'
import {DataService, MiscService, RouterService, MetaService, ProductService, ServiceService, MicroServiceService, ValidationService, UserService} from '../../../service'
const dS = new DataService('media'),
reS = new DataService('recommendation'),
pwS = new DataService('packageWork'),
uS = new UserService(),
sS = new ServiceService(),
mS = new MetaService(),
miS = new MiscService(),
micS = new MicroServiceService(),
vS = new ValidationService(),
rS = new RouterService(),
prS = new ProductService()
const commonPageSize = 50
const PackageWorkListAdmin = () => {
    const [searchKey, setsearchKey] = useState(''),
    [tableData, settableData] = useState([]),
    [filteredList, setfilteredList] = useState([]),
    [activeShownTotal, setactiveShownTotal] = useState(commonPageSize),
    _search = key => {
        const reg = /^[A-Za-z0-9 ]+$/
        if (key === '' || reg.test(key)) {
            setsearchKey(key);
        }
        let tempTitle = '', fList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.title.toLowerCase()
            if (tempTitle.search(searchKey) !== -1)
                fList.push(e)
        })
        setfilteredList(fList)
    },
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item, index ) => {
            let e = {
            //     checkAction: <span className='check-all' onClick={() => _toggleCheck(item.uid)}>
            //         {
            //             checkedAll || checkedList.includes(item.uid) ?
            //             <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
            //         }
            //     </span>,
            //     title: item.title,
            //     image: item.image?
            //         <Img alt="Blank Placeholder" src={base.imageBaseUrl+JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
            //     <p>No Image</p>,
            //     releaseDate: miS.getFormattedDate(new Date(item.releaseDate)),
            //     status: item.status,
            //     action: trashAction?
            //     <div className="action-bar">
            //         <Actor type='trash' onClick={() => setState({ activeItem: item, showTrashConfirm: true })} />
            //         <Actor type='undo' onClick={() => _delete(item.uid, 'restore')} />
            //     </div>:
            //     <div className="action-bar">
            //         <Actor type='trash' onClick={() => setState({ activeItem: item, showTrashConfirm: true })} />
            //         <Actor type='view' onClick={() => rS.navigateTo('/project/'+item.slug)} />
            //         <Actor type='edit' onClick={() => rS.navigateTo('/admin/project/edit/'+item.uid)} />
            //         <Actor type='copy' onClick={() => _cloneProject(item)} />
            //     </div>
            }
            data.push(e)
        })
        return data
    },
    _fetch = async () => {
        const result = await pwS.fetchAll()
        if (result.status) {
            console.log('result.data', result.data)
            let td = result.data.reverse()
            settableData(td)
            setfilteredList(td)
        }
    }
    useEffect(() => {
        _fetch()
    }, [])
    let tableContent = {
        headers: [],
        content: _getTableData(filteredList)
    }
    return <>
        <h1 className="title flex">Daily Package Work</h1>
        <div className="filter-box">
            <div className="search-box mlauto">
                <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => _search(e.target.value)} />

            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
    </>
}

export default PackageWorkListAdmin