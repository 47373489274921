import {getData,postData} from '../Ops'
export default class ProductService {
    addProduct = async data => {
        const formData = new FormData()
        formData.append('title', data.title)
        formData.append('slug', data.slug)
        formData.append('content', data.content)
        formData.append('excerpt', data.excerpt)
        formData.append('css', data.css)
        formData.append('js', data.js)
        formData.append('canonical', data.canonical)
        formData.append('robots', data.robots)
        formData.append('metaDescription', data.metaDescription)
        formData.append('metaKeyword', data.metaKeyword)
        formData.append('publishDate', data.publishDate)
        formData.append('status', data.status)
        formData.append('category', data.category)
        formData.append('brand', data.brand)
        formData.append('tag', data.tag)
        formData.append('uid', data.uid)
        formData.append('image', data.image)
        formData.append('isFeatured', data.isFeatured)
        formData.append('isAssured', data.isAssured)
        formData.append('inclusiveTax', data.inclusiveTax)
        formData.append('sku', data.sku)
        formData.append('hsn', data.hsn)
        formData.append('stock', data.stock)
        formData.append('tax', data.tax)
        formData.append('saleprice', data.saleprice)
        formData.append('price', data.price)
        if (data.company)
            formData.append('company', JSON.stringify(data.company))
        return await postData('product/save', formData)
    }
    updateProduct = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        formData.append('productData', data.productData)
        return await postData('product/update', formData)
    }
    getProduct = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('product/get', formData)
    }
    deleteProduct = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('product/delete', formData)
    }
    getProducts = async () => {
        return await getData('product/list')
    }
    getProductCategory = async () => {
        return await getData('product/category/list')
    }
    addProductCategory = async categoryObject => {
        const formData = new FormData()
        formData.append('catName', categoryObject.catName)
        formData.append('catSlug', categoryObject.catSlug)
        formData.append('parentCat', categoryObject.parentCat)
        formData.append('uid', categoryObject.uid)
        return await postData('product/category/add', formData)
    }
    deleteProductCategory = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('product/category/delete', formData)
    }
    getProductBrand = async () => {
        return await getData('product/brand/list')
    }
    addProductBrand = async brandObject => {
        const formData = new FormData()
        formData.append('brandName', brandObject.brandName)
        formData.append('brandSlug', brandObject.brandSlug)
        formData.append('uid', brandObject.uid)
        return await postData('product/brand/add', formData)
    }
    deleteProductBrand = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('product/brand/delete', formData)
    }
}