import React, {Component} from 'react'
import {PackagesData, Table} from '../../../component'
import {base} from '../../../constant/Data'
import {Img, Button, Actor} from '../../../element'
import {OrderService, MiscService, RouterService, MetaService, UserService} from '../../../service'
const uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService()
export default class MyPackages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            activePackage: ''
        }
    }
    componentDidMount () {
        mS.setTitle('My Packages List', 'public')
        this._getPackagesData()
    }
    _getPackagesData = async () => {
        let query = {
            uid: uS.getLoggedInUserData().uid
        }
        const result = await oS.getUserOrders({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length !== 0) {
                let tableData = []
                result.data.reverse().forEach((item, index) => {
                    let cartData = JSON.parse(item.orderData).cartData, unpgradedPackages = [],
                    found = false, packagesData = []
                    if (item.status === 'paid' || item.status === 'processing' || item.status === 'shipped' || item.status === 'delivered' || item.status === 'completed') {
                        cartData.forEach(e => {
                            if (e.type === 'servicePackage') {
                                found = true
                                e.orderId = item.uid
                                if (e.previousPackageId)
                                    unpgradedPackages.push(e)
                                else
                                    packagesData.push(e)
                            }
                        })
                        packagesData.forEach(el => {
                            unpgradedPackages.forEach(e => {
                                if (e.previousPackageId === String(el.tempUid))
                                    el.title = e.title
                            })
                            if (found)
                                tableData.push(el)
                        })
                    }
                })
                this.setState({ tableData })
            }
        }
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item, index ) => {
            if (item.status !== "initialised" && typeof item.previousOrderId === "undefined" && typeof item.previousPackageId === "undefined") {
                let el = {
                    title: <span className="package-name">{item.title}</span>,
                    startDate: miS.getFormattedDate(new Date(item.startDate)),
                    endDate: miS.getFormattedDate(new Date(item.endDate)),
                    car: <li className="my-packages-assign-car">
                        <Img src={base.carImageBase+item.activeCar.image_path} alt={item.activeCar.name+' '+item.activeCar.Segment} />
                        <div className="ml10">
                            <p>{item.activeCar.name+' '+item.activeCar.Segment}</p>
                            {
                                item.activeCar.registrationNo && item.activeCar.registrationNo !== '' && item.activeCar.registrationNo !== null &&
                                <small>{item.activeCar.registrationNo}</small>
                            }
                        </div>
                    </li>,
                    action: <div className="action-bar">
                        <Actor type='view' onClick={() => rS.navigateTo('/package/view/' + btoa(item.orderId + '/' + item.tempUid))} />
                    </div>
                }
                data.push(el)
            }
        })
        return data
    }
    render() {
        let { tableData, activePackage, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Start Date',
                    key: 'startDate'
                },
                {
                    label: 'End Date',
                    key: 'endDate'
                },
                {
                    label: 'Assign Car',
                    key: 'assignCar'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(tableData)
        }
        return <>
            <div id="content-box" className="content">
                <h1>My Packages</h1>
                <div className="package-table">
                    <Table
                        data={tableContent}
                        pageSize={activeShownTotal}
                    />
                </div>
            </div>
            <div className="modal" id="get-packages-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content big">
                    <Button className="close" onClick={() => miS.closeModal("get-packages-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        <PackagesData activePackage={activePackage} />
                    </div>
                </div>
            </div>
        </>
    }
}