const routes = [
    {
        "pathName": "/subscription",
        "module": ["Subscription"],
        "component": "Subscription",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/subscription/list",
        "module": ["Ecommerce"],
        "component": "MySubscription",
        "type": "postLogin",
        "mode": "static"
    }
]
export default routes