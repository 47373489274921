import React, {useEffect, useRef, useState} from 'react';

const singleRowBloack = 18

const starBlocks = [37, 25, 70, 10, 2, 45, 33, 62]

const gameBoxSize = 0.5625 * window.innerHeight

const blockSize = gameBoxSize / 15

const players = require('./players.json')

const Ludo = props => {
    const activePlayer = useRef()
    const [gameState, setgameState] = useState('play')

    useEffect(() => {
    }, [])

    useEffect(() => {
    }, [gameState, activePlayer])

    const _renderPlayerMen = (e, i) => {
        return e.state.map((p, i) => {
            console.log('p', p)
            let playerMenClass = 'player-men '
            if (p.position === -1) playerMenClass += 'locked'
            return <div className={playerMenClass} key={i}>
                
            </div>
        })
    }

    const _renderPlayHomes = () => {
        return players.map((e, i) => {
            return <div className={'player-home ' + e.id} key={i}>
                <div className='player-men-box'>
                    {_renderPlayerMen(e, i)}
                </div>
            </div>
        })
    }

    const _checkCollide = () => {
        starBlocks.includes()
    }

    const _rollDice = () => {

    }

    const _movePlayer = (p) => {

    }

    const _renderPlayBoxes = () => {
        return new Array(players.length * singleRowBloack).fill().map((_, i) => {
            let boxStyle = {}, playerBoxClass = 'play-box '
            if (i < 6) {
                boxStyle = {
                    left: 6 * blockSize,
                    top: i * blockSize
                }
            } else if (i < 12) {
                boxStyle = {
                    left: 6 * blockSize,
                    top: (i + 3) * blockSize
                }
            } else if (i < 18) {
                boxStyle = {
                    left: 7 * blockSize,
                    top: (i % 6) * blockSize
                }
            } else if (i < 24) {
                boxStyle = {
                    left: 7 * blockSize,
                    top: (i % 6 + 9) * blockSize
                }
            } else if (i < 30) {
                boxStyle = {
                    left: 8 * blockSize,
                    top: (i % 6) * blockSize
                }
            } else if (i < 36) {
                boxStyle = {
                    left: 8 * blockSize,
                    top: (i % 6 + 9) * blockSize
                }
            } else if (i < 42) {
                boxStyle = {
                    left: (i % 6) * blockSize,
                    top: 6 * blockSize
                }
            } else if (i < 48) {
                boxStyle = {
                    left: (i % 6 + 9) * blockSize,
                    top: 6 * blockSize
                }
            } else if (i < 54) {
                boxStyle = {
                    left: (i % 6) * blockSize,
                    top: 7 * blockSize
                }
            } else if (i < 60) {
                boxStyle = {
                    left: (i % 6 + 9) * blockSize,
                    top: 7 * blockSize
                }
            } else if (i < 66) {
                boxStyle = {
                    left: (i % 6) * blockSize,
                    top: 8 * blockSize
                }
            } else if (i < 72) {
                boxStyle = {
                    left: (i % 6 + 9) * blockSize,
                    top: 8 * blockSize
                }
            }
            players.forEach(e => {
                if (i === e.start) playerBoxClass += e.id + ' start '
                if (e.homeZone.includes(i)) playerBoxClass += e.id + ' home-zone '
                if (starBlocks.includes(i)) playerBoxClass += ' star '
            })
            return <span className={playerBoxClass} key={i} style={boxStyle}></span>
        })
    }

    const _renderHome = () => {
        return <span className='home'></span>
    }

    return <>
        <div className='game-box ludo'>
            <div className='board'>
                {_renderPlayHomes()}
                {_renderPlayBoxes()}
                {_renderHome()}
            </div>
        </div>
    </>
}

export default Ludo