import {postData,getData} from '../Ops'
export default class LocationService {
    addLocation = async data => {
        const formData = new FormData()
        formData.append('ownerId', data.ownerId)
        formData.append('activeSociety', data.activeSociety)
        formData.append('uid', data.uid)
        formData.append('building', data.building)
        formData.append('locality', data.locality)
        formData.append('pinCode', data.pinCode)
        formData.append('city', data.city)
        formData.append('state', data.state)
        formData.append('landmark', data.landmark)
        formData.append('alternateNo', data.alternateNo)
        return await postData('location/save', formData)
    }
    updateLocation = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        formData.append('locationData', data.locationData)
        return await postData('location/update', formData)
    }
    getLocation = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('location/get', formData)
    }
    deleteLocation = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('location/delete', formData)
    }
    getLocations = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('location/list', formData)
    }
    getSocities = async () => {
        return await getData('location/society/list')
    }
    addSociety = async data => {
        const formData = new FormData()
        formData.append('title', data.title)
        formData.append('address', data.address)
        formData.append('addressDetail', data.addressDetail)
        formData.append('supervisor', data.supervisor)
        formData.append('assignWorker', data.assignWorker)
        formData.append('uid', data.uid)
        return await postData('location/society/add', formData)
    }
    deleteSociety = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('location/society/delete', formData)
    }
    getSociety = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('location/society/get', formData)
    }
    updateSociety = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        formData.append('societyData', data.societyData)
        return await postData('location/society/update', formData)
    }
}