import React, {Component} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {MiscService, ValidationService, CarService, RouterService, UserService, MetaService} from '../../../service'
import {Button, BackIcon} from '../../../element'
import { fuelOption, packagesOption } from '../../../constant/Enum'
import { base } from '../../../constant/Data'
import {Box} from '../../../component'
const uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService(),
cS = new CarService()
export default class EditUsersCarList extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            shoot360Url: '',
            shoot360: false,
            registrationNo: '',
            registrationDate: '',
            chassisNo: '',
            engineNo: '',
            ownerName: this.user.name,
            vehicleClass: '',
            activeFuelType: 0,
            fuelType: fuelOption[0],
            packageType: packagesOption[0],
            activePackageType: packagesOption[0],
            maker: '',
            fitness: '',
            mvTax: '',
            insurance: '',
            pucc: '',
            emission: '',
            rcStatus: '',
            financed: '',
            showForm: false,
            additionalCarInfo: false,
            carData: null,
            uid: '',
            showInsurance: true,
            showRc: true,
            rcImage: '',
            insuranceImage: '',
            activeImage: base.imageBaseUrl+JSON.parse(this.user.image)[0]
        }
    }
    componentDidMount () {
        mS.setTitle('Edit User Cars List', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        let query = {
            uid: urlData[4]
        }
        this._getCarData(query)
    }
    _getCarData = async query => {
        let { showInsurance, showRc, activeFuelType } = this.state
        const result = await cS.getCar({ query: JSON.stringify(query) })
        if (result.status) {
            let carData = result.data[0]
            if (typeof carData.rcImage === 'undefined' || carData.rcImage === '' ) {
                showRc = false
                carData.rcImage = '[]'
            }
            if (typeof carData.insuranceImage === 'undefined' || carData.insuranceImage === '') {
                showInsurance = false
                carData.insuranceImage = '[]'
            }
            this.setState({
                mode: 'Edit',
                shoot360Url: carData.shoot360Url,
                shoot360: carData.shoot360,
                registrationNo: carData.registrationNo,
                registrationDate: carData.registrationDate,
                chassisNo: carData.chassisNo,
                engineNo: carData.engineNo,
                ownerName: carData.ownerName,
                vehicleClass: carData.vehicleClass,
                maker: carData.maker,
                fitness: carData.fitness,
                mvTax: carData.mvTax,
                insurance: carData.insurance,
                pucc: carData.pucc,
                fuelType: typeof carData.fuelType === "object"? JSON.parse(carData.fuelType) : fuelOption[activeFuelType],
                emission: carData.emission,
                rcStatus: carData.rcStatus,
                financed: carData.financed,
                carData: JSON.parse(carData.carData),
                uid: carData.uid,
                showForm: true,
                rcImage: carData.rcImage,
                insuranceImage: carData.insuranceImage,
                showRc: showRc,
                showInsurance: showInsurance
            })
        }
    }
    _handleChange = fuelType => {
        this.setState({ fuelType })
    }
    _handleChangePackage = packageType => {
        this.setState({ packageType: packageType, activePackageType: packageType })
    }
    _addCar = async e => {
        e.preventDefault()
        let { shoot360Url, shoot360, uid, rcImage, insuranceImage, registrationNo, registrationDate, chassisNo, engineNo, ownerName, vehicleClass, maker, fitness, mvTax, insurance, pucc, emission, rcStatus, financed, carData, fuelType } = this.state,
            error = false,
            errorMsg = '',
            carObject = {
                shoot360Url: shoot360Url,
                shoot360: shoot360,
                registrationNo: registrationNo,
                registrationDate: registrationDate,
                chassisNo: chassisNo,
                engineNo: engineNo,
                ownerName: ownerName,
                vehicleClass: vehicleClass,
                maker: maker,
                fitness: fitness,
                mvTax: mvTax,
                insurance: insurance,
                pucc: pucc,
                fuelType: JSON.stringify(fuelType),
                emission: emission,
                rcImage: rcImage,
                insuranceImage: insuranceImage,
                rcStatus: rcStatus,
                financed: financed,
                carData: JSON.stringify(carData)
            }
        if (registrationNo === '') {
            errorMsg = 'Please enter car Registration Number!'
            error = true
        } else if (fuelType === '') {
            errorMsg = 'Please select car fuel'
            error = true
        } else if (!vS.isValidVehicleRegistrationNumber(registrationNo)) {
            errorMsg = 'Please enter a valid car Registration Number!'
            error = true
        }
        if (!error) {
            let data = {
                query: JSON.stringify({uid}),
                carData: JSON.stringify(carObject)
            }
            const result = await cS.updateCar(data)
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Car updated successfully!' })
                rS.navigateTo('/admin/car/list')
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to update car!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { shoot360, shoot360Url, fuelType, registrationNo, registrationDate, chassisNo, engineNo, ownerName, vehicleClass, maker, fitness, mvTax, insurance, pucc, emission, rcStatus, financed } = this.state
        return <>
            <h1>
                <BackIcon backLink='/admin/car/list' />
                Edit Car
            </h1>
            <form  className="columns" noValidate onSubmit={this._addCar}>
                <div className="left">
                    <div className="form-group box">
                        <div>
                            <label className="col-3 control-label p0">Registration No <sup>*</sup></label>
                            <span>{registrationNo}</span>
                        </div>
                        <div>
                            <label className="col-3 control-label p0">Owner Name </label>
                            <span>{ownerName}</span>
                        </div>
                        <div>
                            <label className="col-3 control-label p0">Vehicle Class </label>
                            <span>{vehicleClass}</span>
                        </div>
                        <div>
                            <label className="col-3 control-label p0">Fuel <sup>*</sup></label>
                            <span>{ fuelType && fuelType !== "" && fuelType !== null? fuelType.label: '' }</span>
                        </div>
                        <div>
                            <label className="col-3 control-label p0">Maker / Model</label>
                            <span>{maker}</span>
                        </div>
                    </div>
                    <hr />
                    <div className="form-group box">
                        <div className="flex-box switch-box">
                            <label className="switch">
                                <input type="checkbox" onChange={() => this.setState({ shoot360: !shoot360 })} checked={shoot360?'checked':false} />
                                <span className="slider round"></span>
                            </label>
                            <span>360&deg; Shoot Completed</span>
                        </div>
                    </div>
                    <div className="form-group box">
                        <label className="col-3 control-label p0">360&deg; Shoot Url</label>
                        <div className="col-9 controls">
                            <input className="input" placeholder="360&deg; Shoot Url" type="text" required name="shoot360Url" value={shoot360Url || ''} onChange={(e) => this.setState({ shoot360Url: e.target.value })} />
                        </div>
                    </div>
                    <div className="form-group box">
                        <label className="col-3 control-label p0">Registration Date</label>
                        <div className="col-9 controls">
                            <input className="input" placeholder="registration date" type="text" required name="registrationDate" value={registrationDate || ''} onChange={(e) => this.setState({ registrationDate: e.target.value })} />
                        </div>
                    </div>
                    <div className="form-group box">
                        <label className="col-3 control-label p0">Chassis No</label>
                        <div className="col-9 controls">
                            <input className="input" placeholder="chassis no" type="text" required name="chassisNo" value={chassisNo || ''} onChange={(e) => this.setState({ chassisNo: e.target.value })} />
                        </div>
                    </div>
                    <div className="form-group box">
                        <label className="col-3 control-label p0">Engine No</label>
                        <div className="col-9 controls">
                            <input className="input" placeholder="engine no" type="text" required name="engineNo" value={engineNo || ''} onChange={(e) => this.setState({ engineNo: e.target.value })} />
                        </div>
                    </div>
                    <div className="form-group box">
                        <label className="col-3 control-label p0">Fitness/REGN Upto</label>
                        <div className="col-9 controls">
                            <input className="input" placeholder="fitness/REGN upto" type="text" required name="fitness" value={fitness || ''} onChange={(e) => this.setState({ fitness: e.target.value })} />
                        </div>
                    </div>
                    <div className="form-group box">
                        <label className="col-3 control-label p0">MV Tax upto</label>
                        <div className="col-9 controls">
                            <input className="input" placeholder="MV Tax upto" type="text" required name="mvTax" value={mvTax || ''} onChange={(e) => this.setState({ mvTax: e.target.value })} />
                        </div>
                    </div>
                    <div className="form-group box">
                        <label className="col-3 control-label p0">Insurance Upto</label>
                        <div className="col-9 controls">
                            <input className="input" placeholder="insurance Upto" type="text" required name="insurance" value={insurance || ''} onChange={(e) => this.setState({ insurance: e.target.value })} />
                        </div>
                    </div>
                    <div className="form-group box">
                        <label className="col-3 control-label p0">PUCC Upto</label>
                        <div className="col-9 controls">
                            <input className="input" placeholder="PUCC upto" type="text" required name="pucc" value={pucc || ''} onChange={(e) => this.setState({ pucc: e.target.value })} />
                        </div>
                    </div>
                    <div className="form-group box">
                        <label className="col-3 control-label p0">Emission norms</label>
                        <div className="col-9 controls">
                            <input className="input" placeholder="emission norms" type="text" required name="emission" value={emission || ''} onChange={(e) => this.setState({ emission: e.target.value })} />
                        </div>
                    </div>
                    <div className="form-group box">
                        <label className="col-3 control-label p0">RC Status</label>
                        <div className="col-9 controls">
                            <input className="input" placeholder="RC status" type="text" required name="rcStatus" value={rcStatus || ''} onChange={(e) => this.setState({ rcStatus: e.target.value })} />
                        </div>
                    </div>
                    <div className="form-group box">
                        <label className="col-3 control-label p0">Financed</label>
                        <div className="col-9 controls">
                            <input className="input" placeholder="financed" type="text" required name="financed" value={financed || ''} onChange={(e) => this.setState({ financed: e.target.value })} />
                        </div>
                    </div>
                </div>
                <Box title="Submit">
                    <Button className="btn btn-primary btn-small addcarbtn" type="submit">Submit</Button>
                </Box>
            </form>
        </>
    }
}