import React, {Component} from 'react'
import {MetaService} from '../../../service'
import {brandInfo} from '../../../constant/Data'
const mS = new MetaService()
export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: []
        }
    }
    componentDidMount () {
        mS.setTitle(brandInfo.name.capital, 'home')
    }
    render() {
        return <div className="main-section">
            Home
        </div>
    }
}