import React, {Component} from 'react'
import {MiscService, OrderService, CarService, RouterService} from '../../service'
import {Button} from '../../element'
const miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService(),
cS = new CarService()
export default class PackagesData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            carList: [],
            vehicleClassList: null,
            billedQuarterly: false,
            activePackage: '',
            partialPackage: '',
            bestFriendPackagePrice: '',
            friendPackagePrice: '',
            buddyPackagePrice: ''
        }
    }
    componentDidMount () {
        this._getVehicleClassList()
    }
    _getVehicleClassList = async () => {
        const result = await cS.getVehicleClassList()
        if (result.status)
            this.setState({ vehicleClassList: result.data })
    }
    _purchase = (purchaseType, packageName) => {
        let activeCar = miS.getActiveCar(),
        { vehicleClassList, billedQuarterly } = this.state,
        props = this.props,
        partialPackage = props.partialPackage ? props.partialPackage: '',
        startDate = props.startDate ? props.startDate: new Date(),
        endDate = props.endDate ? props.endDate: new Date(),
        oldPrice = props.price ? props.price: '',
        billing = props.billing ? props.billing: '',
        todayDate = new Date(),
        remainingDays = 0,
        perDayOldPrice = 0,
        totalDays = 0,
        urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        found = false
        if (partialPackage !== "") {
            activeCar = props.activeCar
            totalDays = billing === 'monthly'?30:90
            perDayOldPrice = Number(oldPrice)/totalDays
            let spentTime = Math.abs(todayDate - new Date(startDate)),
            spentDays = Math.ceil(spentTime / (1000 * 60 * 60 * 24))
            remainingDays = totalDays - spentDays
            miS.closeModal('package-upgrade-modal')
            billedQuarterly = billing !== 'monthly'
        } else {
            startDate.setDate(new Date().getDate() + 1)
            endDate.setDate(endDate.getDate() + billedQuarterly ? 90 : 30)
        }
        if (activeCar === null)
            miS.openCarSelectModal()
        else {
            vehicleClassList.forEach(c => {
                if (activeCar.Segment.toLowerCase().includes(c.priceGroup) && !found) {
                    found = true
                    c.pricing.forEach(e => {
                        if (e.type === purchaseType) {
                            let price = !billedQuarterly? e.price : (e.price * 3 * c.quarterlyDiscount).toFixed(2) // 10% off on 3 months payment
                            if (partialPackage !== "") {
                                let perDayNewPrice = Number(price)/totalDays,
                                newTotalPrice = remainingDays * perDayNewPrice - remainingDays * perDayOldPrice
                                price = newTotalPrice
                            }
                            let mrp = price, saleprice = price
                            let servicePackage = {
                                uid: 'PACKAGE__00' + e.id,
                                title: activeCar.registrationNo ? packageName+ ' Package ['+activeCar.registrationNo+']' : packageName+ ' Package',
                                billing: !billedQuarterly?'monthly': 'quarterly',
                                purchaseQuantity: 1,
                                type: 'servicePackage',
                                tempUid: new Date().getTime(),
                                mrp,
                                saleprice,
                                tax: 18,
                                hsn: '',
                                sku: '',
                                startDate,
                                endDate,
                                activeCar,
                                taxAmount: Number((price * 18/100).toFixed(2))
                            }
                            servicePackage.price = price - servicePackage.taxAmount
                            servicePackage.displayPrice = price
                            if (partialPackage !== "") {
                                let orderData = rS.urlToSplitData(atob(urlData[3]))
                                servicePackage.previousOrderId = orderData[0]
                                servicePackage.previousPackageId = orderData[1]
                                servicePackage.purchaseDate = new Date()
                            }
                            oS.addToCart(servicePackage)
                            miS.showAlert({ type: 'success', msg: packageName+ ' Package added to cart successfully' })
                            if (partialPackage !== "") {
                                setTimeout(() => {
                                    miS.proceedToCheckOut()
                                }, 2000)
                            }
                        }
                    })
                }
            })
        }
    }
    render() {
        let { vehicleClassList, bestFriendPackagePrice, friendPackagePrice, buddyPackagePrice, billedQuarterly, activePackage, partialPackage } = this.state, props = this.props
        if (typeof props.activePackage !== 'undefined')
            activePackage = props.activePackage
        if (typeof props.partialPackage !== 'undefined' || props.partialPackage === '')
            partialPackage = props.partialPackage
        let activeCar = miS.getActiveCar()
        vehicleClassList !== null && vehicleClassList.forEach(c => {
            if (activeCar !== null) {
                if (activeCar.Segment.toLowerCase().includes(c.priceGroup.toLowerCase())) {
                    c.pricing.forEach(e => {
                        if (e.type === 'BASIC') {
                            let price = !billedQuarterly? e.price : (e.price * 3 * c.quarterlyDiscount).toFixed(2) // 10% off on 3 months payment
                            buddyPackagePrice = price
                        } else if (e.type === 'ADVANCE') {
                            let price = !billedQuarterly? e.price : (e.price * 3 * c.quarterlyDiscount).toFixed(2) // 10% off on 3 months payment
                            friendPackagePrice = price
                        } else if (e.type === 'PREMIUM') {
                            let price = !billedQuarterly? e.price : (e.price * 3 * c.quarterlyDiscount).toFixed(2) // 10% off on 3 months payment
                            bestFriendPackagePrice = price
                        }
                    })
                }
            }
        })
        return <div className="pricing-table-background">
            {
                activePackage === '' && partialPackage === '' &&
                <>
                    <div className="flex-box switch-box">
                        <span>Billed Monthly</span>
                        <label className="switch">
                            <input type="checkbox" onChange={() => this.setState({ billedQuarterly: !billedQuarterly})} checked={billedQuarterly?'checked':false} />
                            <span className="slider round"></span>
                        </label>
                        <span>Billed Quaterly</span><br />
                    </div>
                    <p className="tac"><b>Note : </b>10% Off on Quarterly Payments</p>
                </>
            }
            <div className="pricing-table-grid-box mt20 mb20">
                <div className="pricing-detail-box">
                    <div className="grid-Box">
                        <div className="upper-part">
                            <h1 className="plan-heading">SERVICES</h1>
                            <p>*4 days weekly off in a month</p>
                        </div>
                        <div className="middle-part">
                            <div className="features"> Preventive Checks <i className="hi-arrow-circle-right"></i></div>
                            <div className="features"> Car Wash <i className="hi-arrow-circle-right"></i></div>
                            <div className="features"> <span>Car Cleaning <span className="subService-name">(Waterless)</span></span> <i className="hi-arrow-circle-right"></i></div>
                            <div className="features"> Vacuuming <i className="hi-arrow-circle-right"></i></div>
                            <div className="features"> Air Pressure <i className="hi-arrow-circle-right"></i></div>
                            <div className="features"> Interior Dusting <i className="hi-arrow-circle-right"></i></div>
                            {activeCar && <div className="features"><b>Pricing</b> <i className="hi-arrow-circle-right"></i></div>}
                        </div>
                    </div>
                </div>
                <div className="pricing-table-grid">
                    {
                        activePackage === '' && partialPackage === '' &&
                        <>
                            <div className="grid-Box">
                                <div className="upper-part">
                                    <h1 className="plan-heading">Buddy</h1>
                                </div>
                                <div className="middle-part">
                                    <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Monthly</div></div>
                                    <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Month</div></div>
                                    <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                    <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Month</div></div>
                                    <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                    <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Week </div></div>
                                    {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">₹{buddyPackagePrice}</span> </div></div>}
                                </div>
                                <div className="bottom-part">
                                    <Button className="btn btn-primary btn-box" onClick={() => this._purchase('BASIC', 'Buddy')}>Avail Now</Button>
                                </div>
                            </div>
                            <div className="grid-Box grid-box-center">
                                <div className="upper-part">
                                    <h1 className="plan-heading">Friend</h1>
                                </div>
                                <div className="middle-part">
                                    <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Monthly </div></div>
                                    <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                    <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                    <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                    <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily </div></div>
                                    <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Week </div></div>
                                    {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">₹{friendPackagePrice}</span> </div></div>}
                                </div>
                                    <div className="bottom-part">
                                        <Button className="btn btn-primary btn-box" onClick={() => this._purchase('ADVANCE', 'Friend')}>Avail Now</Button>
                                    </div>
                                <div className="most-popular">Most Popular</div>
                            </div>
                            <div className="grid-Box">
                                <div className="upper-part">
                                    <h1 className="plan-heading">Best Friend</h1>
                                </div>
                                <div className="middle-part">
                                    <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Fortnightly</div></div>
                                    <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                    <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                    <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 3 / Month</div></div>
                                    <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                    <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> Alternate Day </div></div>
                                    {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">₹{bestFriendPackagePrice}</span> </div></div>}
                                </div>
                                <div className="bottom-part">
                                    <Button className="btn btn-primary btn-box" onClick={() => this._purchase('PREMIUM', 'Best Friend')}>Avail Now</Button>
                                </div>
                            </div>
                        </>
                    }
                    {
                        activePackage === 'Buddy' ?
                        <div className="grid-Box">
                            <div className="upper-part">
                                <h1 className="plan-heading">Buddy</h1>
                            </div>
                            <div className="middle-part">
                                <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i>Monthly</div></div>
                                <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Month</div></div>
                                <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Month</div></div>
                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Week </div></div>
                                {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">₹{buddyPackagePrice}</span> </div></div>}
                            </div>
                            {
                                activePackage === '' &&
                                <div className="bottom-part">
                                    <Button className="btn btn-primary btn-box" onClick={() => this._purchase('BASIC', 'Buddy')}>Avail Now</Button>
                                </div>
                            }
                        </div>
                        :activePackage === 'Friend' || partialPackage === 'PACKAGE__001'?
                        <div className="grid-Box grid-box-center">
                            <div className="upper-part">
                                <h1 className="plan-heading">Friend</h1>
                            </div>
                            <div className="middle-part">
                                <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Monthly </div></div>
                                <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily </div></div>
                                <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Week </div></div>
                                {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">₹{friendPackagePrice}</span> </div></div>}
                            </div>
                            {
                                activePackage === '' &&
                                <div className="bottom-part">
                                    <Button className="btn btn-primary btn-box" onClick={() => this._purchase('ADVANCE', 'Friend')}>Avail Now</Button>
                                </div>
                            }
                            <div className="most-popular">Most Popular</div>
                        </div>
                        :activePackage === 'Best Friend' &&
                        <div className="grid-Box">
                            <div className="upper-part">
                                <h1 className="plan-heading">Best Friend</h1>
                            </div>
                            <div className="middle-part">
                                <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Fortnightly</div></div>
                                <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 3 / Month</div></div>
                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily </div></div>
                                <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> Alternate Day </div></div>
                                {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">₹{bestFriendPackagePrice}</span> </div></div>}
                            </div>
                            {
                                activePackage === '' &&
                                <div className="bottom-part">
                                    <Button className="btn btn-primary btn-box" onClick={() => this._purchase('PREMIUM', 'Best Friend')}>Avail Now</Button>
                                </div>
                            }
                        </div>
                    }
                    {
                        (partialPackage === 'PACKAGE__002' || partialPackage === 'PACKAGE__001') &&
                        <div className="grid-Box">
                            <div className="upper-part">
                                <h1 className="plan-heading">Best Friend</h1>
                            </div>
                            <div className="middle-part">
                                <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Fortnightly</div></div>
                                <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 3 / Month</div></div>
                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily </div></div>
                                <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> Alternate Day </div></div>
                                {activeCar && <div className="features box10"> <div className="feature-details"> <span className="package-price">₹{bestFriendPackagePrice}</span> </div></div>}
                            </div>
                            {
                                activePackage === '' &&
                                <div className="bottom-part">
                                    <Button className="btn btn-primary btn-box" onClick={() => this._purchase('PREMIUM', 'Best Friend')}>Avail Now</Button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    }
}