import React, {Component} from 'react'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react'
import {base,mainUrl} from '../../../constant/Data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CreatableSelect from 'react-select/creatable'
import {robotsOption,authorOption,statusOption} from '../../../constant/Enum'
import {MiscService, DataService, RouterService, UserService, MetaService} from '../../../service'
import {Img, Button, BackIcon} from '../../../element'
import {Box, MediaBox} from '../../../component'
const pS = new DataService('page'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
keywordOptions =  [],
tagOptions = []
export default class AddPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeRobotsIndex: 0,
            activeStatusIndex: 0,
            activeAuthorIndex: 0,
            image: '[]',
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            content: "",
            excerpt: "",
            css: '',
            js: '',
            assignWorkers: [],
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            author: '',
            publishDate: new Date(),
            status: '',
            tag: '',
            activeFile: null
        }
    }
    componentDidMount () {
        mS.setTitle('Add Page', 'admin')
        let { activeStatusIndex } = this.state
        this.setState({ status: statusOption[activeStatusIndex].value })
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getPageData(query)
        }
        this._getUsersData()
    }
    _getUsersData = async () => {
        let tableData = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    tableData.push({label: result.name, value: result.uid})
                })
                this.setState({ assignWorkers: tableData })
            }
        }
    }
    _getPageData = async query => {
        const result = await pS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let pageData = result.data[0],
            metaKeyword = [],
            tag = [],
            activeRobotsIndex = 0,
            activeAuthorIndex = 0,
            activeStatusIndex = 0
            robotsOption.forEach(( e, i ) => {
                if (e.value === pageData.robots) {
                    activeRobotsIndex = i
                }
            })
            authorOption.forEach(( e, i ) => {
                if (e.value === pageData.author) {
                    activeAuthorIndex = i
                }
            })
            statusOption.forEach(( e, i ) => {
                if (e.value === pageData.status) {
                    activeStatusIndex = i
                }
            })
            if (pageData.metaKeyword) {
                if (pageData.metaKeyword === '""' || pageData.metaKeyword === '' || pageData.metaKeyword === null || pageData.metaKeyword === 'null') {
                    metaKeyword = []
                } else {
                    JSON.parse(pageData.metaKeyword).forEach(e => {
                        metaKeyword.push(e)
                    })
                }
            } else {
                metaKeyword = []
            }
            if (pageData.tag) {
                if (pageData.tag === '""' || pageData.tag === '' || pageData.tag === null || pageData.tag === 'null') {
                    tag = []
                } else {
                    JSON.parse(pageData.tag).forEach(e => {
                        tag.push(e)
                    })
                }
            } else {
                tag = []
            }
            this.setState({
                title: pageData.title,
                mode: 'Edit',
                slug: pageData.slug,
                content: pageData.content,
                excerpt: pageData.excerpt,
                css: pageData.css,
                js: pageData.js,
                canonical: pageData.canonical,
                robots: pageData.robots,
                metaDescription: pageData.metaDescription,
                metaKeyword,
                author: pageData.author,
                publishDate: new Date(pageData.publishDate),
                status: pageData.status,
                tag,
                uid: pageData.uid,
                image: pageData.image,
                activeRobotsIndex,
                activeAuthorIndex,
                activeStatusIndex
            })
        } else miS.showAlert({ type: 'error', msg: 'No page added yet!' })
    }
    _addPage = async e => {
        e.preventDefault()
        let { mode, title, slug, content, excerpt, css, js, canonical, robots, metaDescription, metaKeyword, author, publishDate, status, tag, uid, image } = this.state,
        error = false,
        errorMsg = '',
        pageObject = {
            title,
            slug,
            content,
            excerpt,
            css,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            author,
            publishDate,
            status: status,
            tag: JSON.stringify(tag),
            image
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (content === '') {
            errorMsg = 'Please enter the content'
            error = true
        } else if (excerpt === '') {
            errorMsg = 'Please enter the excerpt'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                pageObject.uid = new Date().getTime()
                const result = await pS.save(pageObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Page added successfully!' })
                    rS.navigateTo('/admin/page/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add page!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    pageData: JSON.stringify(pageObject)
                }
                const result = await pS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Page updated successfully!' })
                    rS.navigateTo('/admin/page/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update page!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        { status, robots, mode, activeFile, renderSlug, title, slug, content, excerpt, css, js, canonical, activeRobotsIndex, activeStatusIndex, metaDescription, metaKeyword, tag, image } = this.state,
        pageTitle = mode + ' Page',
        activeStatus = statusOption[activeStatusIndex],
        activeRobots = robotsOption[activeRobotsIndex]
        if (canonical === '') {
            canonical = mainUrl+ urlData[2]+'/'+slug
        }
        robotsOption.forEach(e => {
            if (e.value === robots) {
                activeRobots = e
            }
        })
        statusOption.forEach(e => {
            if (e.value === status) {
                activeStatus = e
            }
        })
        return <>
            <h1>
                <BackIcon backLink='/admin/page/list' />
                {pageTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._addPage}>
                <div className="left">
                    <Box title='Title' required>
                        <input autoFocus type="text" required name="title" value={title} onChange={e => {
                            let tempTitle = e.target.value, tempSlug = slug
                            if (renderSlug) {
                                tempSlug = miS.slugify(tempTitle)
                            }
                            this.setState({ title: tempTitle, slug: tempSlug })
                        }} />
                    </Box>
                    <Box title='Slug' required>
                        <input type="text" required name="slug" value={slug} onChange={(e) => this.setState({ slug: e.target.value })} />
                    </Box>
                    <Box title='Content' required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={content}
                            onEditorChange={(content, editor) => {
                                this.setState({ content })
                            }}
                        />
                    </Box>
                    <Box title='Excerpt' required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={excerpt}
                            onEditorChange={(excerpt, editor) => {
                                this.setState({ excerpt })
                            }}
                        />
                    </Box>
                    <Box title='CSS'>
                        <textarea className="description" placeholder=".selector {property:value}" required name="css" value={css} onChange={(e) => this.setState({ css: e.target.value })}></textarea>
                    </Box>
                    <Box title='JS'>
                        <textarea className="description" placeholder="alert('Type your script here')" required name="js" value={js} onChange={(e) => this.setState({ js: e.target.value })}></textarea>
                    </Box>
                    <Box title='Canonical URL'>
                        <input type="text" required name="canonical" value={canonical} onChange={(e) => this.setState({ canonical: e.target.value })} />
                    </Box>
                    <Box title='Robots'>
                        <Select
                            value={activeRobots}
                            defaultValue={activeRobots}
                            onChange={e => this.setState({ robots: e.value })}
                            options={robotsOption}
                        />
                    </Box>
                    <Box title='Meta Description'>
                        <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={(e) => this.setState({ metaDescription: e.target.value })}></textarea>
                    </Box>
                    <Box title='Meta Keywords'>
                        <CreatableSelect
                            isMulti
                            onChange={e => this.setState({ metaKeyword: e })}
                            options={keywordOptions}
                            value={metaKeyword}
                        />
                    </Box>
                </div>
                <div className="right">
                    <Box title='Meta'>
                        <p>
                            Render Slug:
                            <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={() => this.setState({ renderSlug:!renderSlug})}></span>
                        </p>
                        <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                    </Box>
                    <Box title='Publish Date'>
                        <DatePicker
                            onChange={publishDate => this.setState({ publishDate })}
                            selected={this.state.publishDate}
                        />
                    </Box>
                    <Box title='Status'>
                        <Select
                            value={activeStatus}
                            defaultValue={activeStatus}
                            onChange={e => this.setState({ status: e.value })}
                            options={statusOption}
                        />
                    </Box>
                    <Box title='Tags'>
                        <CreatableSelect
                            isMulti
                            value={tag}
                            onChange={e => this.setState({ tag: e })}
                            options={tagOptions}
                        />
                    </Box>
                    <MediaBox image={image} setimage={image => this.setState({image})} />
                </div>
            </form>
        </>
    }
}