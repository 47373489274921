import React, {Component} from 'react'
import {Banner} from '../../../widget'
import {MiscService, ValidationService, DataService, MetaService} from '../../../service'
import {Button} from '../../../element'
const dS = new DataService('complaint'),
mS = new MetaService(),
miS = new MiscService(),
vS = new ValidationService()
export default class Complaint extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            message: '',
            orderId: '',
            number: '',
            productName: '',
            date: '',
            uid: '',
            status: '',
            complaintType: 'product'
        }
    }
    componentDidMount() {
        mS.setTitle('Complaint', 'public')
    }
    _sendComplaintRequest = async e => {
        e.preventDefault()
        let { name, email, message, orderId, number, productName, complaintType } = this.state,
        error = false,
        errorMsg = '',
        userObject = {
            name,
            email,
            message: message,
            orderId: orderId,
            number: number,
            productName: productName,
            complaintType: complaintType,
            date: new Date(),
            uid: new Date().getTime(),
            status: 'raised'
        }
        if (name === '') {
            errorMsg = 'Please enter your Full Name'
            error = true
        } else if (email === '') {
            errorMsg = 'Please enter your Email Address'
            error = true
        } else if (number === '' ) {
            errorMsg = 'Please enter your Email Address'
            error = true
        } else if (!vS.isValidMobile(number)) {
            errorMsg = 'Please enter a valid Mobile Number'
            error = true
        } else if (message.length < 50) {
            errorMsg = 'Message length should be greated than 50 letters.'
            error = true
        }
        if (!error) {
            const result = await dS.save(userObject)
            if (result.status) {
                miS.showAlert({
                    type: 'info',
                    msg: 'Your contact request has been submitted. We\'ll contact you back in 24 hours.' })
                this.setState({
                    name: '',
                    email: '',
                    message: '',
                    orderId: '',
                    productName: '',
                    number: '',
                    date: '',
                    uid: '' })
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to send contact request.' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { name, email, complaintType, message, orderId, productName, number } = this.state
        return <>
            <Banner title='Complaint' color="dark" image="complaint.jpg" />
            <div className="body-background" style={{minHeight:1}}>
                <div className="jumbo-container">
                    <div className="contact-page complaint-page">
                        <form className="right-form-section" onSubmit={this._sendComplaintRequest} noValidate>
                            <div className="title-section">
                                <h1>We are here to assist you!</h1>
                                <p>Please complete the form below for your complaints.</p>
                            </div>
                            <div className="contact-form-section">
                                <div className="form-group">
                                    <label>Full Name <sup>*</sup></label>
                                    <input className="input" value={name} onChange={(e) => this.setState({ name: e.target.value })} type="text" name="Name" placeholder="Enter Full Name" />
                                </div>
                                <div className="form-group">
                                        <label>Email <sup>*</sup></label>
                                    <input className="input" value={email} onChange={(e) => this.setState({ email: e.target.value })} type="email" name="Email" placeholder="Enter Email" />
                                </div>
                                <div className="form-group">
                                        <label>Mobile No. <sup>*</sup></label>
                                    <input className="input" value={number} onChange={(e) => this.setState({ number: e.target.value })} type="number" name="number" placeholder="Enter Mobile number" />
                                </div>
                                {miS.isModuleEnabled(["Ecommerce"]) && <div className="form-group">
                                    <label>Order Id</label>
                                    <input className="input" value={orderId} onChange={(e) => this.setState({ orderId: e.target.value })} type="orderId" name="OrderId" placeholder="Enter Order Id" />
                                </div>}
                                <div className="form-group complaint-type-check">
                                    <span onClick={() => this.setState({ complaintType: 'product' })}>
                                        {
                                            complaintType === 'product' ?
                                            <i className="orange hi-check_circle"></i>:
                                            <i className="hi-circle"></i>
                                        }
                                    <label>Product</label>
                                    </span>
                                    <span onClick={() => this.setState({ complaintType: 'service' })}>
                                        {
                                            complaintType === 'service' ?
                                            <i className="orange hi-check_circle"></i>:
                                            <i className="hi-circle"></i>
                                        }
                                        <label>Service</label>
                                    </span>
                                    <input className="input" value={productName} onChange={(e) => this.setState({ productName: e.target.value })} type="productName" name="ProductName" placeholder="Enter Product/service Name" />
                                </div>
                                <div className="form-group">
                                    <label>Message <sup>*</sup></label>
                                    <textarea className="input textarea" value={message} onChange={(e) => this.setState({ message: e.target.value })} name="message" placeholder="Enter Message" />
                                </div>
                                <Button className="btn btn-primary" type="submit">Submit</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    }
}