import React, {Component} from 'react'
import {base} from '../../constant/Data'
import {DataService} from '../../service'
import Slider from 'react-slick'
import {Img} from '../../element'
import './style.scss';
const dS = new DataService('testimonial')
export default class TestimonialWeb extends Component {
    constructor(props) {
        super(props)
        this.state = {
            testimonialList: []
        }
    }
    componentDidMount () {
        this._getTestimonialData()
    }
    _getTestimonialData = async () => {
        const result = await dS.fetchAll()
        if (result.status)
            this.setState({ testimonialList: result.data })
    }
    _renderTestimonialList = testimonialFiles => {
        return testimonialFiles.map(( item, index ) => {
            return <div className="testimonial" key={index}>
                <div className="right-content-sec">
                    <div className="items">
                        <i className='quote'>&#8221;</i>
                        <p>{item.content}</p>
                        <h5 className="honor-name">{item.name}</h5>
                    </div>
                </div>
                <div className="left-img-sec">
                    <Img src={
                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                        base.imageBaseUrl+'product-placeholder.png':
                        base.imageBaseUrl+JSON.parse(item.image)[0]
                    } alt={item.title} />
                </div>
            </div>
        })
    }
    render() {
        let { testimonialList } = this.state,
        setting = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    setting: {
                        arrows: false,
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 991,
                    setting: {
                        arrows: false,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 560,
                    setting: {
                        arrows: false,
                        slidesToShow: 1
                    }
                }
            ]
        }
        return <>
            {
                testimonialList.length !== 0 &&
                <div className='pb50 mb60'>
                    <div className="jumbo-container">
                        <h2 className="title-bar">Happy Client Feedback</h2>
                    </div>
                    <section className="testimonial">
                        <div className="jumbo-container">
                            <Slider {...setting}>
                                { this._renderTestimonialList(testimonialList || []) }
                            </Slider>
                        </div>
                    </section>
                </div>
            }
        </>
    }
}