const routes = [
    {
        "pathName": "/admin/key/list",
        "module": ["Car"],
        "component": "KeyListAdmin",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/car-model/list",
        "module": ["Car"],
        "component": "CarModelList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/car-model/add",
        "module": ["Car"],
        "component": "AddCarModel",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/car-model/edit",
        "module": ["Car"],
        "component": "AddCarModel",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/car-model/manufacturer/list",
        "module": ["Car"],
        "component": "CarModelManufacturerList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/car/edit",
        "module": ["Car"],
        "component": "EditUsersCarList",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/car/import",
        "module": ["Car"],
        "component": "ImportCars",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/car/list",
        "module": ["Car"],
        "component": "CarListAdmin",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/car/list",
        "module": ["Car"],
        "component": "CarListAdmin",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/trash/car-model/list",
        "module": ["Car"],
        "component": "CarModelList",
        "type": "admin",
        "mode": "static"
    }
]

export default routes