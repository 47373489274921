import './style.scss';
const SnakeNLadder = () => {
    const _renderBoard = () => {
        return <div className="board">
            {_renderRows()}
        </div>
    },
    _renderRows = () => {
        return <div className="game-row">
            {_renderColumns()}
        </div>
    },
    _renderColumns = () => {
        return [...Array(100)].map((_, i) => {
            return <div className="game-column" key={i}>
                <span className='box-number'>{i + 1}</span>
            </div>
        })
    }
    return <div className="game-screen">
        {_renderBoard()}
    </div>
}

export default SnakeNLadder