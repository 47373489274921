const MediaModel = [
    {
        key: 'url',
        dataType: String
    },
    {
        key: 'data',
        dataType: String
    },
    {
        key: 'fileName',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'type',
        dataType: String
    },
    {
        key: 'caption',
        dataType: String
    },
    {
        key: 'description',
        dataType: String
    },
    {
        key: 'usage',
        dataType: String
    }
]
export default MediaModel