import React, {Component} from 'react'
import {base, mainUrl} from '../../../constant/Data'
import {TrashConfirmModal, Table} from '../../../component'
import {DataService, MiscService, MetaService, RouterService} from '../../../service'
import {Actor, Button} from '../../../element'
const dS = new DataService('event')
const iS = new DataService('invoice'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class InvoiceListAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            image: '',
            filteredList: [],
            activeFilterIndex: 0,
            showTrashConfirm: false,
            searchList: []
        }
    }
    componentDidMount () {
        mS.setTitle('Invoice List', 'admin')
        this._getInvoicesData()
    }
    _getInvoicesData = async () => {
        const result = await iS.fetchAll()
        if (result.status) {
            let tableData = result.data.reverse()
            this.setState({ tableData, filteredList: tableData, invoiceList: tableData })
        }
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _trashEvent = async () => {
        let { activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        const result = await dS.delete({ query: JSON.stringify(query) })
        if (result.status) {
            this._getInvoicesData()
            miS.showAlert({ type: 'success', msg: 'Event deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to delete event!' })
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item, index ) => {
            let e = {
                srNo: index + 1,
                invoiceNo: item.invoiceNo,
                orderId: <a target="_blank" href={"/admin/order/view/"+item.orderId} rel="noreferrer">#{item.orderId}</a>,
                action: <div className="action-bar">
                    <Actor type='download' onClick={() => rS.navigateTo(base.invoiceBaseUrl+"/invoice_"+item.invoiceNo+".pdf", true)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        orderId = '', filteredList = [], invoiceNo = ''
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            orderId = e.orderId ?? ''
            invoiceNo = e.invoiceNo ?? ''
            if (orderId.search(searchKey) !== -1 || invoiceNo.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList })
    }
    render() {
        let { tableData, showTrashConfirm, filteredList, searchKey, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'S. No.',
                    key: 'srNo'
                },
                {
                    label: 'Invoice No.',
                    key: 'invoiceNo'
                },
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex">
                All Invoices
            </h1>
            <div className="filter-box">
                <div className="filters">
                </div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><i className="hi-close"></i></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={this._trashEvent} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} />
        </>
    }
}