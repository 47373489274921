import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../component'
import {MiscService, ProductService, MetaService} from '../../../service'
import {Button, Actor} from '../../../element'
const mS = new MetaService(),
miS = new MiscService(),
prS = new ProductService()
export default class ProductBrands extends Component {
    constructor(props) {
        super(props)
        this.state = {
            brandList: [],
            brandName: '',
            brandSlug: '',
            renderSlug: true,
            activeItem: '',
            uid: '',
            showTrashConfirm: false,
            category: ''
        }
    }
    componentDidMount () {
        mS.setTitle('Product Brands', 'admin')
        this._getProductBrandData()
    }
    _getProductBrandData = async () => {
        const result = await prS.getProductBrand()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No chats placed yet!' })
                this.setState({ brandList: [] })
            } else {
                let brandList = []
                result.data.forEach(e => {
                    brandList.push(e)
                })
                this.setState({ brandList: brandList })
            }
        }
    }
    _brandCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _trashBrand = async () => {
        let { activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        const result = await prS.deleteProductBrand({ query: JSON.stringify(query) })
        if (result.status) {
            this._getProductBrandData()
            miS.showAlert({ type: 'success', msg: 'Brand deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to delete chat!' })
    }
    _addProductBrand = async e => {
        e.preventDefault()
        let { brandName, brandSlug } = this.state,
        error = false,
        errorMsg = '',
        brandObject = {
            brandName: brandName,
            brandSlug: brandSlug
        }
        if (brandName === '' || brandSlug === '') {
            errorMsg = 'Please enter chat content!'
            error = true
        }
        if (!error) {
            brandObject.uid = new Date().getTime()
            const result = await prS.addProductBrand(brandObject)
            if (result.status)
                miS.showAlert({ type: 'success', msg: 'Brand added successfully!' })
            else
                miS.showAlert({ type: 'error', msg: 'Unable to add chat!' })
            this.setState({ brandName: '', brandSlug: '', uid: '' }, () => {
                miS.closeModal("brand-modal")
                this._getProductBrandData()
            })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item ) => {
            let e = {
                uid: <p className="ans mb40">{item.uid}</p>,
                brandName: <p className="query mb40">{item.brandName}</p>,
                brandSlug: <p className="ans mb40">{item.brandSlug}</p>,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    render() {
        let { brandList, brandSlug, renderSlug, brandName, showTrashConfirm, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Id',
                    key: 'id'
                },
                {
                    label: 'Brand Name',
                    key: 'brandName'
                },
                {
                    label: 'Slug',
                    key: 'slug'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(brandList)
        }
        return <>
            <h1 className="title flex">Brand <Button onClick={() => {
                this.setState({ mode: 'Add' }, () => miS.openModal("brand-modal"))
            }} className="btn btn-primary btn-small ml20">Add</Button></h1>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={this._trashBrand} trashCancelAction={this._brandCancel} showTrashConfirm={showTrashConfirm} />
            <div className="modal brand-modal" id="brand-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <Button className="close" onClick={() => 
                    this.setState({ brandName: '', brandSlug: '', uid: '' }, () => miS.closeModal("brand-modal"))}>
                    <i className="hi-close"></i></Button>
                    <div className="modal-body">
                        <div className="brand-modal-header">
                            <div className="modal-header">
                                <div className="heading">Add</div>
                                <form noValidate onSubmit={this._addProductBrand}> 
                                    <div className="form-group mb40">
                                        <input type="text" className="input" placeholder="Please enter a Brand Name" required name="brandName" value={brandName}
                                        onChange={e => {
                                            let tempTitle = e.target.value, tempSlug = brandSlug
                                            if (renderSlug) {
                                                tempSlug = miS.slugify(tempTitle)
                                            }
                                            this.setState({ brandName: tempTitle, brandSlug: tempSlug})
                                        }} />
                                        <label>Brand</label>
                                        <small className="msg text-danger">Please enter a Brand</small>
                                    </div>
                                    <div className="form-group mb40">
                                        <input type="text" className="input" placeholder="Please enter slug" required name="brandSlug" value={brandSlug} onChange={(e) => this.setState({ brandSlug: e.target.value }) } />
                                        <label>Slug</label>
                                        <small className="msg text-danger">Please enter slug</small>
                                    </div>
                                    <div className="buttons">
                                        <Button type="submit" title="Click here to Add Brand" className="btn btn-primary btn-small">Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}