import React, {useEffect, useState} from 'react'
import {base, brandInfo, mainUrl} from '../../constant/Data'
import {DataService, MiscService, RouterService, MetaService} from '../../service'
import {Img} from '../../element'
import {Share} from '../../component'
const dS = new DataService('event'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
const Event = () => {
    const [eventList, seteventList] = useState([])
    useEffect(() => {
        mS.setTitle('Events', 'public')
        _getEventData()
    }, [])
    const _getEventData = async () => {
        const result = await dS.fetchAll()
        if (result.status)
            seteventList(result.data)
    }
    const _renderEventList = eventFiles => {
        return eventFiles.map(( item, index ) => {
            if (item.status === 'publish')
            return <div className="blog-single-item event-page width-full" key={index}>
                <div className="wrapper width-full">
                    <div className="col-4">
                        <div className="thumb-area-wrap">
                            <div className="thumb-area">
                                <div className="thumb eventthumb">
                                    {
                                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                                        <Img src={base.imageBaseUrl+'thumb-medium.png'} alt={item.title} />:
                                        <Img className="pointer" onClick={() => rS.navigateTo('/event/'+item.slug)} src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                                    }
                                </div>
                                <div className="date">
                                    <span className="day">{new Date(item.publishDate).getDate()}</span>
                                    <span className="month">{miS.getMonthName(new Date(item.publishDate).getMonth())}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <div id="content-box" className="content">
                            <h4 className="title"><a href={"/event/"+item.slug}>{item.title}</a></h4>
                            <div className="post-meta event-meta">
                                <ul className="list-wrap">
                                    <li>
                                        <p className="author"><i className="hi-time"></i> {miS.getFormattedTime(new Date(item.publishDate))}</p>
                                    </li>
                                    <li>
                                        <p className="author"><i className="hi-location"></i> {item.location}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="pv30">
                            <div dangerouslySetInnerHTML={{__html: item.excerpt}}></div>
                            </div>
                            <div className="read-more-area">
                                <div className="read-more read-more-event">
                                    <a href={"/event/"+item.slug}>Read More</a>
                                </div>
                                <Share shareUrl={mainUrl+"/event/"+item.slug} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            else return true
        })
    }
    return <>
        <Img className="mt80 width-full" src={require('../../../assets/images/event.jpg')} alt={brandInfo.name.capital + ' Events'} />
        <div className="blog-page jumbo-container">
            <div className="blog-page-content pt40 pb40">
                <div className="event-box">
                    <div className="col-12 width-full">
                        { _renderEventList(eventList || []) }
                    </div>
                    <div className="event-logo flex">
                        <div className="logo-box-text">
                            <strong aria-level="4">{brandInfo.name.capital} logo and app icons</strong>
                            <p className="description mt20 mb20">The logotype for the web and apps represents our product.</p>
                            <a href={mainUrl+'/car-mitra.zip'} className="btn btn-big btn-primary"><i className="hi-download-cloud"></i> Download</a>href={mainUrl+'/media.zip'}
                        </div>
                        <div className="logo-box-image">
                            <Img alt={brandInfo.name.capital} src={brandInfo.logo} className="logo-black" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Event