import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../component'
import {UserService, MiscService, LocationService, RouterService, MetaService} from '../../../service'
import {Button, Actor} from '../../../element'
const uS = new UserService(),
mS = new MetaService(),
locS = new LocationService(),
miS = new MiscService(),
rS = new RouterService()
export default class Societies extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            address: '',
            tableData: props.param,
            showTrashConfirm: false,
            checkedAll: false,
            activeItem: null,
            filteredList: [],
            checkedList: [],
            isMultipleDelete: false,
            searchKey: ''
        }
    }
    componentDidMount () {
        mS.setTitle('Socities List', 'admin')
        this._getSocietiesListData()
    }
    _getSocietiesListData = async () => {
        const result = await locS.getSocities()
        if (result.status) {
            let tableData = result.data.reverse()
            this.setState({ tableData, filteredList: tableData })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No society added yet!' })
        } else miS.showAlert({ type: 'error', msg: 'No society added yet!' })
    }
    _getTableData = filterData => {
        let data = [], { checkedAll, checkedList } = this.state
        filterData.map(( item, index ) => {
            let found = this.user.role !== "supervisor"
            if (item.supervisor && !found) {
                item.supervisor = JSON.parse(item.supervisor)
                if (item.supervisor !== '""') {
                    found = item.supervisor.value === this.user.uid
                }
            }
            if (found) {
                let e = {
                    checkAction: <span className="check-all" onClick={() => this._toggleCheck(item.uid)}>
                        {
                            checkedAll || checkedList.includes(item.uid) ?
                            <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
                        }
                    </span>,
                    title: item.title,
                    address: item.address,
                    action: <div className="action-bar">
                        {
                            this.user.role !== 'supervisor' && <>
                                <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                                <Actor type='edit' onClick={() => rS.navigateTo('/admin/society/edit/'+item.uid)} />
                            </>
                        }
                    </div>
                }
                data.push(e)
            }
        })
        return data
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _deleteSelectSociety = () => {
        let { activeItem } = this.state
        this._deleteSocietys(activeItem.uid)
    }
    _deleteSocietys = async uid => {
        let query = {
            uid
        }
        const result = await locS.deleteSociety({ query: JSON.stringify(query) })
        if (result.status) {
            this._getSocietiesListData()
            miS.showAlert({ type: 'success', msg: 'Order deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else miS.showAlert({ type: 'error', msg: 'Unable to delete order!' })
    }
    _toggleCheck = uid => {
        let {checkedList, filteredList} = this.state, newCheckedList = [], found = false
        checkedList.forEach(e => {
            if (e === uid)
                found = true
            else
                newCheckedList.push(e)
        })
        if (!found)
            newCheckedList.push(uid)
        this.setState({ checkedAll: (filteredList.length === newCheckedList.length), checkedList: newCheckedList })
    }
    _deleteChecked = () => {
        let { checkedList, checkedAll, filteredList } = this.state
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            this._deleteSocietys(e.uid)
        })
        this.setState({ checkedList: [], isMultipleDelete: false })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.title.toLowerCase()
            if (tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList })
    }
    render() {
        let { tableData, checkedList, isMultipleDelete, showTrashConfirm, checkedAll, searchKey, filteredList, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    checkAction: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                        {
                            checkedAll || checkedList.length === filteredList.length?
                            <i className="orange hi-check-circle"></i>:
                            <i className="hi-circle"></i>
                        }
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Address',
                    key: 'address'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex">
                All Societies
                <a className="btn btn-primary btn-small ml20" href="/admin/society/add">Add</a>
                {
                    (checkedList.length !== 0 || checkedAll) &&
                    <Button type="button" onClick={() => {
                        this.setState({ isMultipleDelete:true, showTrashConfirm: true})
                    }} className="btn btn-outline btn-small">Delete</Button>
                }
            </h1>
            <div className="filter-box">
                <div className="filters"></div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                            const reg = /^[A-Za-z0-9 ]+$/
                            if (e.target.value === '' || reg.test(e.target.value))
                                this.setState({ searchKey: e.target.value }, this._search)
                        }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><i className="hi-close"></i></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._deleteSelectSociety()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}