import React, {Component} from 'react'
import {MiscService, DataService} from '../../service'
import {Button} from '../../element'
import { brandInfo } from '../../constant/Data'
import './style.scss';
const _chatbotToggle = () => {
    document.getElementById("chatbot").classList.toggle('is-active')
},
_scrolltoBottom = () => {
    let chatBox = document.getElementsByClassName("chatbot-wrapper")[0]
    setTimeout(() => {
        chatBox.scrollTop = chatBox.scrollHeight
    }, 1000)
}, dS = new DataService('chat'),
miS = new MiscService()
export default class ChatBot extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatData: [],
            genericMessages: [],
            queries: [],
            activeQuery: ''
        }
    }
    componentDidMount () {
        this._getChatsData()
    }
    _getChatsData = async () => {
        const result = await dS.fetchAll()
        if (result.status) {
            if (result.data.length !== 0) {
                let chatList = [],
                queries = []
                result.data.forEach(e => {
                    if (e.type === 'generic')
                        chatList.push(e)
                    else if (e.type === 'queries')
                        queries.push(e)
                })
                this.setState({ genericMessages:chatList, queries: queries })
            }
        } else
            this._addMsg({ msg: 'Unable to connect to server! Please try again after some time.', msgType: 'bot' })
    }
    _ansQuery = q => {
        let { queries, genericMessages } = this.state,
        msgFound = false
        genericMessages.forEach(e => {
            if (this._operateMessage(q) === this._operateMessage(e.question)) {
                if (e.answer !== '') {
                    this._addMsg({
                        msg: e.answer,
                        msgType: 'bot' })
                }
                msgFound = true
            }
        })
        if (!msgFound) {
            queries.forEach(e => {
                if (this._operateMessage(q) === this._operateMessage(e.question)) {
                    this._addMsg({ msg: e.answer, msgType: 'bot' })
                    msgFound = true
                }
            })
        }
        if (!msgFound)
            this._saveQuery(q)
    }
    _addMsg = msg => {
        let { chatData } = this.state
        chatData.push(msg)
        this.setState({ chatData:chatData}, _scrolltoBottom)
    }
    _saveQuery = async q => {
        let chatObject = {
            question: q,
            answer: '',
            type: 'queries'
        },
        error = false
        if (q === '') {
            error = true
        }
        if (!error) {
            chatObject.uid = new Date().getTime()
            const result = await dS.save(chatObject)
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Your query has been submitted. Will make to sure to answer the same next time!' })
                this._addMsg({ msg: 'Your query has been submitted. Will make to sure to answer the same next time!', msgType: 'bot' })
                this._getChatsData()
            }
        }
    }
    _askQuery = e => {
        e.preventDefault()
        let { chatData, activeQuery } = this.state
        this._addMsg({
            msg: activeQuery,
            msgType: 'mine'
        })
        this._ansQuery(activeQuery)
        this.setState({ chatData:chatData, activeQuery: '' })
    }
    _operateMessage = msg => {
        msg = msg.toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/aa|_/g,'a').replace(/bb|_/g,'b').replace(/cc|_/g,'c').replace(/dd|_/g,'d').replace(/ee|_/g,'e').replace(/ff|_/g,'f').replace(/gg|_/g,'g').replace(/hh|_/g,'h').replace(/ii|_/g,'i').replace(/jj|_/g,'j').replace(/kk|_/g,'k').replace(/ll|_/g,'l').replace(/mm|_/g,'m').replace(/nn|_/g,'n').replace(/oo|_/g,'o').replace(/pp|_/g,'p').replace(/qq|_/g,'q').replace(/rr|_/g,'r').replace(/ss|_/g,'s').replace(/tt|_/g,'t').replace(/uu|_/g,'u').replace(/vv|_/g,'v').replace(/ww|_/g,'w').replace(/xx|_/g,'x').replace(/yy|_/g,'y').replace(/zz|_/g,'z')
        return msg
    }
    _renderChat = recentMsgs => {
        return recentMsgs.map(( item, index ) => {
            return <div className="chat-container" key={index}>
                <div className={"msg "+item.msgType}>
                    <span>{item.msg}</span>
                </div>
            </div>
        })
    }
    render() {
        let { chatData, activeQuery } = this.state
        return <>
            <div className="chatboy">
                <a target="_blank" className="whats-app" href={`https://api.whatsapp.com/send?phone=91${brandInfo.phone}&amp;text=Hello%20${brandInfo.name.capital}!`} rel="noreferrer">
                    <i className="hi-whatsapp"></i>
                </a>
            </div>
            <div className="chatbot" id="chatbot">
                <div className="chat-header" onClick={_chatbotToggle}>
                    <i className="hi-chat msg-icon"></i>
                    <div className="title">{brandInfo.name.capital} Support</div>
                    <div className="close"><i className="hi-close"></i></div>
                </div>
                <div className="chatbot-wrapper">
                    { this._renderChat( chatData || [] ) }
                </div>
                <form className="msg-input" onSubmit={this._askQuery}>
                    <input type="text" placeholder="Type a message here" required name="activeQuery" value={activeQuery} onChange={(e) => this.setState({ activeQuery: e.target.value })} />
                    <Button className="send-msg" type="submit">
                        <i className="hi-send"></i>
                    </Button>
                </form>
            </div>
        </>
    }
}