import React, {Component} from 'react'
import {Banner} from '../../../widget'
import {base, brandInfo, mainUrl} from '../../../constant/Data'
import {ProjectSideBar, Share} from '../../../component'
import {DataService, MiscService, RouterService, MetaService} from '../../../service'
import {Img} from '../../../element'
const dS = new DataService('project'),
commonPageSize = 5,
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class ProjectList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projectList: [],
            activeShownTotal: commonPageSize,
            filteredList: []
        }
    }
    componentDidMount () {
        mS.setTitle('Project', 'public')
        this._getProjectsData()
        document.addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getProjectsData = async () => {
        const result = await dS.fetchAll()
        if (result.status) {
            let projectData = result.data.reverse()
            this.setState({ projectList: projectData, filteredList: projectData })
        }
    }
    _renderProjectList = projectFiles => {
        let {activeShownTotal} = this.state
        return projectFiles.map(( item, index ) => {
            if (index < activeShownTotal && item.status === 'release')
            return <div className="project-single-item" key={index}>
                <div>
                    <div>
                        <div className="thumb-area-wrap">
                            <div className="thumb-area">
                                <div className="thumb">
                                    {
                                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                                        <Img src={base.imageBaseUrl+'product-placeholder.png'} alt={item.title} />:
                                        <Img className="pointer" onClick={() => rS.navigateTo('/project/'+item.slug)} src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                                    }
                                </div>
                                <div className="date">
                                    <span className="day">{new Date(item.releaseDate).getDate()}</span>
                                    <span className="month">{miS.getMonthName(new Date(item.releaseDate).getMonth())}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div id="content-box" className="content">
                            <h4 className="title"><a href={"/project/"+item.slug}>{item.title}</a></h4>
                            <div className="project-meta">
                                <ul className="list-wrap">
                                    <li>
                                        <p className="author">{brandInfo.name.capital}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="pv30">
                            <div dangerouslySetInnerHTML={{__html: item.excerpt}}></div>
                            </div>
                            <div className="read-more-area">
                                <div className="read-more">
                                    <a href={"/project/"+item.slug}>Read More</a>
                                </div>
                                <Share shareUrl={mainUrl+"/project/"+item.slug} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            else return true
        })
    }
    render() {
        let { projectList } = this.state
        return <>
            <Banner title='Project' image="project.jpg" />
            <div className="blog-page jumbo-container">
                <div className="blog-page-content pt40 pb40">
                    { this._renderProjectList(projectList || []) }
                </div>
            </div>
        </>
    }
}