const FaqModel = [
    {
        key: 'query',
        dataType: String
    },
    {
        key: 'ans',
        dataType: String
    },
    {
        key: 'category',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]
export default FaqModel