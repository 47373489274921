import React, {Component} from 'react'
import { Editor } from '@tinymce/tinymce-react'
import {base} from '../../../constant/Data'
import {MiscService, MetaService} from '../../../service'
import {Button} from '../../../element'
import {Box} from '../../../component'
const mS = new MetaService(),
miS = new MiscService()
export default class SendMail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toEmail: '',
            subject: '',
            mailContent: '',
            selectAll: false
        }
    }
    componentDidMount() {
        mS.setTitle('Send Mail', 'admin')
    }
    _sendMail = async e => {
        e.preventDefault()
        let { toEmail, subject, mailContent, selectAll } = this.state,
        error = false,
        errorMsg = ''
        if (toEmail === '' && !selectAll) {
            errorMsg = 'Please enter receiver\'s email!'
            error = true
        } else if (subject === '') {
            errorMsg = 'Please enter the email subject'
            error = true
        } else if (mailContent === '') {
            errorMsg = 'Email content cannot be blank'
            error = true
        }
        if (!error) {
            const result = await miS.sendMail(toEmail, subject, mailContent, selectAll)
            if (result.status) {
                this.setState({ toEmail: '', subject: '', mailContent: '' })
                miS.showAlert({ type: 'success', msg: 'Email is sent successfully!' })
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to send email!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { toEmail, subject, mailContent, selectAll } = this.state
        return <>
            <h1>Send Mail</h1>
            <form className="columns" noValidate onSubmit={this._sendMail}>
                <div className="left">
                    <Box title={<span className="head flex">Receiver Email
                        <span className="mlauto" onClick={() => this.setState({ selectAll:!selectAll})}>
                            {
                                selectAll?
                                <i className="hi-check_circle"></i>:
                                <i className="hi-circle"></i>
                            }
                            All
                        </span>
                    </span>}>
                        {
                            !selectAll && <input autoFocus type="email" required name="to" value={toEmail} onChange={(e) => this.setState({ toEmail: e.target.value })} />
                        }
                    </Box>
                    <Box title='Subject'>
                        <input type="text" required name="subject" value={subject} onChange={(e) => this.setState({ subject: e.target.value })} />
                    </Box>
                    <Box title='Content'>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            initialValue={mailContent}
                            init={base.tinyMceEditorOptions}
                            value={mailContent}
                            onEditorChange={(mailContent, editor) => {
                                this.setState({ mailContent })
                            }}
                        />
                    </Box>
                </div>
                <div className="right">
                    <Box title='Meta'>
                        <Button className="btn btn-primary" type="submit" title="Click here to send mail">Send</Button>
                    </Box>
                </div>
            </form>
        </>
    }
}