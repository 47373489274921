const userRole = require('./userRole.json'),
footerNav = require('./footerNav.json'),
adminNav = require('./adminNav.json'),
orderOption = require('./orderOption.json'),
garageoption = require('./garageoption.json'),
robotsOption = require('./robotsOption.json'),
authorOption = require('./authorOption.json'),
emailOption = require('./emailOption.json'),
faqCategory = require('./faqCategory.json'),
timeSlot = require('./timeSlot.json'),
profileNav = require('./profileNav.json'),
headerNav = require('./headerNav.json'),
fuelOption = require('./fuelOption.json'),
packagesOption = require('./packagesOption.json'),
activeDateFilter = require('./activeDateFilter.json'),
statusOption = require('./statusOption.json'),
badge = require('./badge.json'),
actor = require('./actor.json'),
sortOption = require('./sortOption.json'),
carStatusOption = require('./carStatusOption.json'),
inactiveCarOption = require('./inactiveCarOption.json'),
chartColor = require('./chartColor.json'),
b2bService = require('./b2bService.json'),
featuredSetting = require('./featuredSetting.json'),
dataTable = require('./dataTable.json'),
usageOption = require('./usageOption.json'),
dashboardWidgetOption = require('./dashboardWidgetOption.json'),
paymentOption = require('./paymentOption.json')
export {
    usageOption,
    paymentOption,
    b2bService,
    featuredSetting,
    badge,
    actor,
    chartColor,
    inactiveCarOption,
    carStatusOption,
    sortOption,
    activeDateFilter,
    fuelOption,
    packagesOption,
    timeSlot,
    profileNav,
    headerNav,
    userRole,
    footerNav,
    adminNav,
    orderOption,
    statusOption,
    robotsOption,
    authorOption,
    faqCategory,
    emailOption,
    garageoption,
    dataTable,
    dashboardWidgetOption
}