import React, {Component} from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import {base} from '../../../constant/Data'
import Select from 'react-select'
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete'
import {Map, Box} from '../../../component'
import {Button, BackIcon} from '../../../element'
import {RouterService, UserService, LocationService, MetaService, MiscService} from '../../../service'
const uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
locS = new LocationService
export default class AddSociety extends Component {
    constructor(props) {
        super(props)
        this.state = {
            address: '',
            assignWorker: '',
            supervisor: '',
            supervisorOptions: [],
            mode: 'Add',
            assignWorkers: [],
            places: [],
            showSuggestions: true,
            addressDetail: '',
            title: '',
            uid: '',
            locality: '',
            pinCode: '',
            city: '',
            state: ''
        }
    }
    componentDidMount () {
        mS.setTitle('Add Society', 'admin')
        this._getUsersData()
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getSocietyData(query)
        }
    }
    _getSocietyData = async query => {
        const result = await locS.getSociety({ query: JSON.stringify(query) })
        if (result.status) {
            let societyData = result.data[0], pinCode = '', state = '', city = '', locality = '',
            addressDetail = JSON.parse(societyData.addressDetail)
            addressDetail.address_component.forEach(e => {
                if (e.types[0]==="postal_code")
                    pinCode = e.long_name
                if (e.types[0]==="administrative_area_level_1")
                    state = e.long_name
                if (e.types[0]==="administrative_area_level_2")
                    city = e.long_name
                if (e.types[0]==="locality")
                    locality = e.long_name
            })
            this.setState({
                title: societyData.title,
                mode: 'Edit',
                address: societyData.address,
                supervisor: societyData.supervisor !== 'undefined' && typeof societyData.supervisor !== 'undefined' && societyData.supervisor !== ''? JSON.parse(societyData.supervisor): '',
                assignWorker: societyData.assignWorker !== 'undefined' && typeof societyData.assignWorker !== 'undefined' && societyData.assignWorker !== ''? JSON.parse(societyData.assignWorker): '',
                addressDetail: addressDetail,
                uid: societyData.uid,
                locality,
                pinCode,
                city,
                state
            })
        }
    }
    _addSociety = async e => {
        e.preventDefault()
        let { locality, state, city, pinCode, supervisor, address, assignWorker, addressDetail, title, uid, mode } = this.state,
        error = false, foundPinCode = false,
        errorMsg = ''
        addressDetail.address_component.forEach(e => {
            if (e.types[0]==="postal_code") {
                foundPinCode = true
                e.long_name = pinCode
            }
            if (e.types[0]==="administrative_area_level_1")
                e.long_name = state
            if (e.types[0]==="administrative_area_level_2")
                e.long_name = city
            if (e.types[0]==="locality")
                e.long_name = locality
        })
        if (!foundPinCode) {
            addressDetail.address_component.push({
                long_name: pinCode,
                types: ["postal_code"]
            })
        }
        let societyObject = {
            title,
            address,
            addressDetail: JSON.stringify(addressDetail),
            supervisor: JSON.stringify(supervisor),
            assignWorker: JSON.stringify(assignWorker)
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (address === '') {
            errorMsg = 'Please enter the address'
            error = true
        } else if (addressDetail === '') {
            errorMsg = 'Please enter the addressDetail'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                societyObject.uid = new Date().getTime()
                const result = await locS.addSociety(societyObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Society added successfully!' })
                    rS.navigateTo('/admin/society/list')
                } else
                    miS.showAlert({ type: 'error', msg: 'Unable to add society!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    societyData: JSON.stringify(societyObject)
                }
                const result = await locS.updateSociety(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Society updated successfully!' })
                    rS.navigateTo('/admin/society/list')
                } else
                    miS.showAlert({ type: 'error', msg: 'Unable to update society!' })
            }
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _getUsersData = async () => {
        let tableDataSupervisor = [], tableDataWorkers = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'supervisor') {
                        tableDataSupervisor.push({label: result.name, value: result.uid})
                        this.setState({ supervisorOptions: tableDataSupervisor })
                    }
                    else if (result.role === 'worker') {
                        tableDataWorkers.push({label: result.name, value: result.uid})
                        this.setState({ assignWorkers: tableDataWorkers })
                    }
                })
            }
        }
    }
    _handleChange = address => {
        this.setState({ address, places: [], showSuggestions: true })
    }
    _parseAddress = (address_component) => {
        let pinCode ='', locality= '', city= '', state = ''
        address_component.forEach(e => {
            if (e.types[0]==="postal_code")
                pinCode = e.long_name
            if (e.types[0]==="administrative_area_level_1")
                state = e.long_name
            if (e.types[0]==="administrative_area_level_2")
                city = e.long_name
            if (e.types[0]==="locality")
                locality = e.long_name
        })
        this.setState({ locality, pinCode, city, state })
    }
    _saveSuggestion = async addressSuggestion => {
        let results = await geocodeByPlaceId(addressSuggestion.placeId)
        this.setState({ address: results[0].formatted_address, addressDetail: results[0] }, () => {
            this._handleChange(results[0].formatted_address)
            this._handleSelect(results[0].formatted_address)
            this._parseAddress(results[0].address_component)
        })
    }
    _handleSelect = async address => {
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
            let places = []
            places.push(latLng)
            if (latLng !== null) {this.setState({ places: places, showSuggestions: false })}
        })
        .catch(e => console.log('error', e))
    }
    render() {
        let { supervisor, locality, state, city, pinCode, mode, supervisorOptions, assignWorker, assignWorkers, places, address, showSuggestions, title } = this.state,
        urlData = rS.urlToSplitData(rS.getLocationData().pathname), _this = this
        if (urlData[3] === 'edit') {
            mode = 'Edit'
        }
        let pageTitle = mode + ' Society'
        return <>
            <h1>
                <BackIcon backLink='/admin/society/list' />
                {pageTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._addSociety}>
                <div className="left">
                    <Box title='Title' required>
                        <input type="text" name="title" value={title} onChange={(e) => this.setState({ title: e.target.value })} />
                    </Box>
                    <Box title='Address' required>
                        <PlacesAutocomplete
                            value={address}
                            onChange={this._handleChange}
                            onSelect={this._handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places . . .',
                                            className: 'location-search-input input',
                                        })}
                                    />
                                    {
                                        showSuggestions &&
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion, index ) => {
                                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                                                return <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className
                                                    })}
                                                    key={index} onClick={() =>_this._saveSuggestion(suggestion)}>{suggestion.description}
                                                </div>
                                            })}
                                        </div>
                                    }
                                </>
                            )}
                        </PlacesAutocomplete>
                    </Box>
                    <div className="mv20">
                        {
                            places.length !== 0 &&
                            <>
                                <Map
                                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+base.googleApiKey}
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `400px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    center={places[0]}
                                    zoom={14}
                                    places={places}
                                />
                                <Box tile='Locality' required>
                                    <input type="text" name="locality" value={locality} onChange={(e) => this.setState({ locality: e.target.value })} />
                                </Box>
                                <Box title='City' required>
                                    <input type="text" name="city" value={city} onChange={(e) => this.setState({ city: e.target.value })} />
                                </Box>
                                <Box title='State' required>
                                    <input type="text" name="state" value={state} onChange={(e) => this.setState({ state: e.target.value })} />
                                </Box>
                                <Box title='Pincode' required>
                                    <input type="text" name="pinCode" value={pinCode} onChange={(e) => this.setState({ pinCode: e.target.value })} />
                                </Box>
                            </>
                        }
                    </div>
                </div>
                <div className="right">
                    <Box title='Meta'>
                        <Button className="btn-primary btn" type="submit" title="Click here to Add Societies">Submit</Button>
                    </Box>
                    <Box title='Assign Supervisor'>
                        <Select
                            value={supervisor}
                            defaultValue={supervisor}
                            onChange={e => {
                                this.setState({ supervisor: e})
                            }}
                            options={supervisorOptions}
                        />
                    </Box>
                    <Box title='Assign Workers'>
                        <Select
                            value={assignWorker}
                            defaultValue={assignWorker}
                            onChange={e => {
                                if (e === null) {
                                    this.setState({ assignWorker: e})
                                // } else if (e.length > 2) {
                                //     this.setState({ assignWorker: assignWorker })
                                //     miS.showAlert({
                                //         type: 'error',
                                //         msg: 'You can not assign more than 2 workers to an order'
                                //     })
                                } else {
                                    this.setState({ assignWorker: e})
                                }
                            }}
                            options={assignWorkers}
                            isMulti
                        />
                    </Box>
                </div>
            </form>
        </>
    }
}