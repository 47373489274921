import { useEffect, useState } from "react"
import { DashboardWidgetService, MiscService } from "../../../../service"
import { Button } from "../../../../element"
import Select from "react-select"
import { statusOption } from "../../../../constant/Enum"
const dWS = new DashboardWidgetService(),
mS = new MiscService()
const MicroServiceTotal = ({
    data = {},
    action = {}
}) => {
    const defaultQuery = {
        table: 'microService',
        filter: {
            date: {
                start: mS.getFormattedDate(new Date('01-01-1990')),
                end: mS.getFormattedDate(new Date())
            }
        },
        attr: {}
    },
    [count, setcount] = useState(0),
    [status, setstatus] = useState(null),
    [title, settitle] = useState(data.title),
    [query, setquery] = useState(Object.keys(data.setting).length ? data.setting : defaultQuery),
    _fetchCount = async () => {
        let requestData = {
            query: JSON.stringify(query)
        }
        let result = await dWS.fetch('count', requestData)
        if (result.status)
            setcount(result.data)
    },
    _applyFilters = async (e) => {
        e.preventDefault()
        let queryData = query
        queryData.attr = {status: status.value}
        await setquery(queryData)
        _fetchCount()
        action.updateWidget({...data, title, configure: false, setting: queryData})
    },
    _renderFilters = () => {
        return <form onSubmit={_applyFilters}>
            <input onChange={e => settitle(e.target.value)} type="text" value={title} placeholder="Widget Title" />
            <Select
                defaultValue={status}
                onChange={e => setstatus(e)}
                options={statusOption}
                placeholder='Select Status'
            />
            <Button type='submit' className='btn btn-primary hi-check-circle'></Button>
        </form>
    }

    useEffect(() => {
        _fetchCount()
    }, [])

    return <>
        { data.configure ? _renderFilters():
            <>
                <i className="widget-icon hi-stack"></i>
                {count}
            </>
        }
    </>
}

export default MicroServiceTotal