import React, {Component} from 'react'
import Slider from 'react-slick'
import {MiscService, DataService} from '../../service'
import {base, brandInfo} from '../../constant/Data'
import {Img} from '../../element'
import './style.scss';
const dS = new DataService('post'),
miS = new MiscService()
export default class LatestNews extends Component {
    constructor(props) {
        super(props)
        this.state = {
            postList: [],
            newLength: 0
        }
    }
    componentDidMount () {
        this._getPostsData()
    }
    _renderPostList = postFiles => {
        return postFiles.map(( item, index ) => {
            return item.status === 'publish' && !item.trash && <li className={index % 2 === 1? "box-banner-design" : "box-banner-design whitebox"} key={index}>
                <div className="tab-link-content mt-4">
                    <div className="inner-content">
                    <a href={"/post/" + item.slug}><Img src={base.imageBaseUrl + JSON.parse(item.image)[0]} alt={item.title} /></a>
                        <div className="tab-title-content fl-font-style-semi-bolt"><a href={"/post/" + item.slug}>{item.title}</a>
                            <div className="name-date">
                                <p className="author-name"><i className="icon flaticon-avatar"></i>{brandInfo.name.capital}</p>
                                <p className="publish-date"><i className="icon flaticon-small-calendar"></i>{miS.getFormattedDate(new Date(item.publishDate))}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        })
    }
    _getPostsData = async () => {
        const result = await dS.fetchAll()
        if (result.status)
            this.setState({ postList: result.data, newLength: result.data.length})
    }
    render() {
        let {newLength, postList} = this.state
        let newsSettings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: newLength === 3 ? 3: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1200,
                    setting: {
                        arrows: false,
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 991,
                    setting: {
                        arrows: false,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 560,
                    setting: {
                        arrows: false,
                        slidesToShow: 1
                    }
                }
            ]
        }
        return <section className="latest-news style-02 mb60-mbs40">
            <div className="jumbo-container">
                <div className="section-title">
                    <h2 className="heading-02 title-bar">Recent Post</h2>
                </div>
                <div className="col-12">
                    <div className="latest-news-slider-wrap padding-top-10">
                        <div className="latest-news-slider-active row">
                            <div className="header-item blog-item">
                                <ul>
                                    <Slider {...newsSettings}>
                                        {this._renderPostList(postList || [] )}
                                    </Slider>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}