const JobModel = [
    {
        key: 'title',
        dataType: String
    },
    {
        key: 'category',
        dataType: String
    },
    {
        key: 'vacancies',
        dataType: String
    },
    {
        key: 'minSalary',
        dataType: String
    },
    {
        key: 'maxSalary',
        dataType: String
    },
    {
        key: 'type',
        dataType: String
    },
    {
        key: 'experience',
        dataType: String
    },
    {
        key: 'tag',
        dataType: String
    },
    {
        key: 'deadline',
        dataType: String
    },
    {
        key: 'description',
        dataType: String
    },
    {
        key: 'companyId',
        dataType: String
    },
    {
        key: 'status',
        dataType: String
    },
    {
        key: 'email',
        dataType: String
    },
    {
        key: 'phone',
        dataType: String
    },
    {
        key: 'location',
        dataType: String
    },
    {
        key: 'userId',
        dataType: String
    },
    {
        key: 'gender',
        dataType: String
    },
    {
        key: 'industry',
        dataType: String
    },
    {
        key: 'qualification',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]
export default JobModel