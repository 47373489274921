import React, {Component} from 'react'
import {MiscService, DataService, RouterService, MetaService} from '../../../service'
import {base} from '../../../constant/Data'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {Img, Button, Actor} from '../../../element'
import { usageOption } from '../../../constant/Enum'
import { Table } from '../../../component'
const dS = new DataService('media'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
const commonPageSize = 50
const filterTags = require('./filterTags.json')
export default class Media extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewMode: 'grid',
            activeUsage: null,
            activeImage: false,
            imagesList: [],
            file: [],
            uploadCount: 0,
            activeFilterIndex: 0,
            fileObj: [],
            tableData: [],
            filteredList: [],
            fileArray: [],
            trashAction: false,
            searchKey: '',
            activeShownTotal: commonPageSize,
            activeFilter: null
        }
    }
    componentDidMount() {
        mS.setTitle('Media', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        this.setState({ activeFilter: filterTags[0] })
        if (urlData[2] === 'trash')
            this.setState({ trashAction: true})
        this._getMedias()
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _selectFiles = e => {
        let _this = this, fileArray = [], fileObj = []
        fileObj.push(e.target.files)
        this.setState({ fileObj }, async () => {
            for (let i = 0; i < _this.state.fileObj[0].length; i++) {
                const result = await miS.getBase64(_this.state.fileObj[0][i])
                let fileData = {
                    url: URL.createObjectURL(_this.state.fileObj[0][i]),
                    data: result,
                    type: _this.state.fileObj[0][i].type,
                    fileName: _this.state.fileObj[0][i].name,
                    size: _this.state.fileObj[0][i].size
                }
                if (fileData.size <= 4000000)
                    fileArray.push(fileData)
                else miS.showAlert({ type: 'error', msg: 'File is too large.' })
                _this.setState({ file: fileArray, fileArray: fileArray })
            }
        })
    }
    _uploadFiles = e => {
        e.preventDefault()
        let { file } = this.state
        for (let i = 0; i < file.length; i++) {
            this._uploadCall(file[i])
            setTimeout(() => {
                if (i === file.length-1)
                    this.setState({ file: [], fileArray: [] })
            }, 1000)
        }
    }
    _getMedias = async () => {
        let { activeFilterIndex } = this.state
        const result = await dS.fetchAll()
        if (result.status) {
            let tableData = result.data.reverse()
            tableData = tableData.filter(e=>{
                return (e.fileName.includes('.'))
            })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No media added yet!' })
            else {
                this.setState({
                    tableData, filteredList: tableData }, () => {
                    let activefilterValue = 'all'
                    filterTags.forEach(e => {
                        if (Number(e.id) === activeFilterIndex+1)
                            activefilterValue = e.value
                    })
                    this._filterItems( activefilterValue, activeFilterIndex)
                })
            }
        }
    }
    _uploadCall = async file => {
        let _this = this, error = false,
        { fileArray, uploadCount } = this.state,
        errorMsg = ''
        if (!error) {
            file.uid = new Date().getTime()
            const result = await dS.save(file)
            if (result.status) {
                uploadCount++
                _this.setState({ uploadCount }, () => {
                    this._getMedias()
                    if (uploadCount === fileArray.length)
                        this.setState({ fileArray: [], file: [], uploadCount: 0 })
                })
                miS.showAlert({ type: 'success', msg: 'Media added successfully!' })
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to add media!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _trash = async uid => {
        let query = {
            uid
        }
        const result = await dS.delete({ query: JSON.stringify(query) })
        if (result.status) {
            this._getMedias()
            miS.showAlert({ type: 'success', msg: 'Media deleted successfully!' })
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to delete media!' })
    }
    _updateMedia = async media => {
        delete media._id
        let data = {
            query: JSON.stringify({uid: media.uid}),
            mediaData: JSON.stringify(media)
        }
        const result = await dS.update(data)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'Media updated successfully!' })
            this.setState({ activeImage: null }, () => miS.closeModal('image-modal'))
            this._getMedias()
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to update media!' })
    }
    _settingOpen = item => {
        let activeUsage = null
        usageOption.forEach(e => {
            if (e.value === item.usage) activeUsage = e
        })
        this.setState({ activeImage: item, activeUsage })
        miS.openModal('image-modal')
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item, index ) => {
            let e = {
                srNo: index + 1,
                content: miS.hasImageExtension(item.fileName) || item.type === "image/webp" || item.type === "image/jpeg" || item.type === "image/jpg" || item.type === "image/png" || item.type === "image/x-png" || item.type === "image/gif" || typeof item.type=== 'undefined' || item.type === '' ?
                <Img alt={item.fileName} src={base.imageBaseUrl +item.fileName} hasZoom />:
                <video className="video-container video-container-overlay" autoPlay={false} loop>
                    <source src={base.imageBaseUrl+item.fileName} type={item.type} />
                </video>,
                fileName: item.fileName,
                uid: item.uid,
                action: <div className="action-bar">
                    <Actor type='trash' onClick={() => this._trash(item.uid)} />
                    <Actor type='setting' onClick={() => this._settingOpen(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _renderImagesGrid = imageFiles => {
        let { trashAction, activeShownTotal } = this.state
        return imageFiles.map(( item, index ) => {
            if (index < activeShownTotal)
            return <li key={index}>
                {
                    miS.hasImageExtension(item.fileName) || item.type === "image/webp" || item.type === "image/jpeg" || item.type === "image/jpg" || item.type === "image/png" || item.type === "image/x-png" || item.type === "image/gif" || typeof item.type=== 'undefined' || item.type === '' ?
                    <Img alt={item.fileName} src={base.imageBaseUrl +item.fileName} hasZoom />:
                    <video className="video-container video-container-overlay" autoPlay={false} loop>
                        <source src={base.imageBaseUrl+item.fileName} type={item.type} />
                    </video>
                }
                {
                    trashAction ?
                    <div className="action-bar">
                        <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                        <Actor type='undo' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    </div>:
                    <>
                        <i className="hi-cog setting" onClick={() => this._settingOpen(item)}></i>
                        <i className="hi-trash1 trash" onClick={() => this._trash(item.uid)}></i>
                    </>
                }
                <p title={item.fileName}>{item.fileName}</p>
            </li>
            else return true
        })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.fileName.toLowerCase()
            if (tempTitle.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList })
    }
    _renderFilterTags = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={ index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>{item.item} ({
            (item.key === 'all') ? tableData.length:
            tableData.reduce(( count, e) => {
                if (item.key === 'image') {
                    return typeof e.type === "undefined"?count+1:count
                } else {
                    return e.type?((e.type).includes(item.key) ? count+1:count):count
                }
            },0) })</span>
        })
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all') {
            filteredList = tableData
        } else {
            filteredList = tableData.filter(e => {
                if (key === 'image') {
                    return typeof e.type === "undefined" || (e.type).includes(key)
                } else {
                    return e.type && (e.type).includes(key)
                }
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    render() {
        let { activeShownTotal, viewMode, activeUsage, activeImage, activeFilter, filteredList, trashAction, fileArray, searchKey, tableData } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Sr No',
                    key: 'srNo'
                },
                {
                    label: 'Content',
                    key: 'content'
                },
                {
                    label: 'Name',
                    key: 'fileName'
                },
                {
                    label: 'Id',
                    key: 'uid'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex">
                Media
                {
                    !trashAction &&
                    <label htmlFor="choose-file" className="btn btn-primary btn-small ml20">Choose Files</label>
                }
            </h1>
            <div className="filter-box">
                <div className="filters">
                    <Select
                        value={activeFilter}
                        defaultValue={activeFilter}
                        onChange={(e, i) => this.setState({ activeFilter: e }, () => {
                            this._filterItems(e.value, i)
                        })}
                        options={filterTags}
                    />
                </div>
                <div className="filters">
                    <ul className='view-tabs' role='tab'>
                        <li onClick={() => this.setState({ viewMode: 'grid' })} className={viewMode === 'grid' ? 'active' : ''}><i className='hi-grid_view'></i></li>
                        <li onClick={() => this.setState({ viewMode: 'list' })} className={viewMode === 'list' ? 'active' : ''}><i className='hi-list'></i></li>
                    </ul>
                </div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><i className="hi-close"></i></Button>
                </div>
            </div>
            <div className="form-group">
                <input type="file" className="hidden" onChange={this._selectFiles} multiple id="choose-file" accept="image/x-png,image/gif,image/jpeg, video/mp4, video/mov, video/mkv, video/avi, video/webm," />
            </div>
            {
                fileArray.length !== 0 &&
                <div className="upload-box">
                    <ul className="media-box">
                        {(fileArray || []).map((file, index ) => (
                            <li key={index}>
                                {
                                    miS.hasImageExtension(file.fileName) || file.type === "image/webp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png" || file.type === "image/x-png" || file.type === "image/gif" || typeof file.type=== 'undefined' || file.type === '' ?
                                    <Img src={file.url} alt="..." />:
                                    <video className="video-container video-container-overlay" autoPlay={false} loop>
                                        <source  src={file.url} type="video/mp4" />
                                    </video>
                                }
                            </li>
                        ))}
                    </ul>
                    <Button type="button" className="btn-primary btn btn-small ml0" onClick={this._uploadFiles}>Upload</Button>
                </div>
            }
            <ul className={"media-box " + viewMode} id="list-wrapper">
                {
                    viewMode === 'grid' ?
                    this._renderImagesGrid(filteredList):
                    <Table
                        data={tableContent}
                        pageSize={activeShownTotal}
                    />
                }
            </ul>
            <div className="modal image-modal" id="image-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content big">
                    <Button className="close" onClick={() => miS.closeModal("image-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body">
                        {
                            activeImage ?
                            <div className='flex p20'>
                                <div className='preview-box'>
                                    <Img src={base.imageBaseUrl+activeImage.fileName} />
                                </div>
                                <div className='setting-box'>
                                    <div className=''>
                                        <p>Caption</p>
                                        <textarea onChange={e => {
                                            this.setState({ activeImage: {...activeImage, caption: e.target.value} })
                                        }} value={activeImage.caption ?? ''}></textarea>
                                    </div>
                                    <div className=''>
                                        <p>Description</p>
                                        <textarea onChange={e => {
                                            this.setState({ activeImage: {...activeImage, description: e.target.value} })
                                        }} value={activeImage.description ?? ''}></textarea>
                                    </div>
                                    <div className='mb20'>
                                        <p>Usage</p>
                                        <CreatableSelect
                                            value={activeUsage}
                                            onChange={e => this.setState({ activeImage: {...activeImage, usage: e.value}, activeUsage: e })}
                                            options={usageOption}
                                        />
                                    </div>
                                    <div className=''>
                                        <p><b>URL:</b> {base.imageBaseUrl+activeImage.fileName}</p>
                                    </div>
                                    <div className=''>
                                        <p><b>Name: </b>{activeImage.fileName}</p>
                                    </div>
                                    <div className=''>
                                        <p><b>Type: </b>{activeImage.type}</p>
                                    </div>
                                    <div className=''>
                                        <p><b>Upload Date: </b>{miS.getFormattedDate(activeImage.uid)}</p>
                                    </div>
                                </div>
                            </div>:
                            ''
                        }
                    </div>
                    <div className="modal-footer">
                        <Button className="btn btn-outline" onClick={() => this._trash(activeImage.uid)}>Delete</Button>
                        <Button className="btn btn-primary" onClick={() => this._updateMedia(activeImage)}>Update</Button>
                    </div>
                </div>
            </div>
        </>
    }
}