import React, {Component} from 'react'
import {MiscService, DataService, ProductService} from '../../service'
import {base} from '../../constant/Data'
import {Button, Img} from '../../element'
const dS = new DataService('post'),
miS = new MiscService(),
prS = new ProductService(),
initialState = {
    recentPost: [],
    blogList: [],
    searchList: [],
    filteredList: [],
    filteredPostList: [],
    searchPostKey: '',
    searchKey: ''
}
export default class BlogSideBar extends Component {
    constructor(props) {
        super(props)
        this.state = initialState
    }
    componentDidMount () {
        this._getPostsData()
        this._getProductsData()
    }
    _getPostsData = async () => {
        const result = await dS.fetchAll()
        if (result.status)
            this.setState({ blogList: result.data, recentPost: result.data, filteredPostList: result.data })
    }
    _getProductsData = async () => {
        const result = await prS.getProducts()
        if (result.status) {
            if (result.data.length !== 0)
                this.setState({ searchList: result.data, filteredList: result.data })
        }
    }
    _renderRecentPostList = recentPostFiles => {
        return recentPostFiles.map(( item, index ) => {
            if (item.status === 'publish')
                return <li className="single-recent-post-item" key={index}>
                    <div className="thumb">
                        {
                            item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                            <Img src={base.imageBaseUrl+'product-placeholder.png'} alt={item.title} />:
                            <Img src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                        }
                    </div>
                    <div id="content-box" className="content">
                        <h5 className="title"><a href={"/post/"+item.slug}>{item.title}</a></h5>
                        <span className="time">{miS.getFormattedDate(new Date(item.publishDate))}</span>
                    </div>
                </li>
            else return true
        })
    }
    _searchProduct = () => {
        let { searchKey, searchList } = this.state,
        tempTitle = '', filteredList = [], tempSku = ''
        searchList.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.title.toLowerCase()
            tempSku = e.sku.toLowerCase()
            if (tempSku.search(searchKey) !== -1 || tempTitle.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList })
    }
    _searchPost = () => {
        let { searchPostKey, blogList } = this.state,
        tempTitle = '', filteredPostList = []
        blogList.forEach(e => {
            searchPostKey = searchPostKey.toLowerCase()
            tempTitle = e.title.toLowerCase()
            if (tempTitle.search(searchPostKey) !== -1) {
                filteredPostList.push(e)
            }
        })
        this.setState({ filteredPostList: filteredPostList })
    }
    _renderFilteredList = filteredList => {
        return filteredList.map(( item, index ) => {
            let productimg = JSON.parse(item.image)
            return <li key={index} className="blog-product-search-box">
                <a href={"/product/" + item.slug} title={item.title} className="flex">
                <Img className="blog-product-search-img" src={
                    item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                    base.imageBaseUrl+'product-placeholder.png':
                    base.imageBaseUrl+productimg[0]} alt={item.title} />
                    <p className="ml10 mb0">
                        <p className="m0">{item.title}</p>
                        {item.status && <><span className="black"><b>Item Code: </b><span className="gray">{item.sku}</span> </span> <span className="black ml10"> <b>Stock: </b> <span className="gray">{item.stock} </span></span></>}
                    </p>
                </a>
            </li>
        })
    }
    render() {
        let { recentPost, filteredList, filteredPostList, searchKey, searchPostKey } = this.state
         return <div className="widget-area">
            {miS.isModuleEnabled(["Blog"]) && <div className="widget widget_search blog-bg">
                <form className="search-form" noValidate>
                    <h5 className="widget-title border-bottom">Search For Posts</h5>
                    <div className="form-group">
                        <input type="text" required name="searchPostKey" value={searchPostKey || ''} placeholder="Search..." onChange={e => {
                            const reg = /^[A-Za-z0-9 ]+$/
                            if (e.target.value === '' || reg.test(e.target.value))
                                this.setState({ searchPostKey: e.target.value }, this._searchPost)
                        }} className="form-control" />
                        <Button onClick={() => this.setState({ filteredPostList: [], searchPostKey: '' })} className="submit-btn" type="submit"><i className="flaticon-search"></i></Button>
                    </div>
                    <div className="search-result-blog">
                        <ul>
                            {
                                searchPostKey !== ''?
                                this._renderFilteredList(filteredPostList || []) : null
                            }
                        </ul>
                    </div>
                </form>
            </div>}
            {miS.isModuleEnabled(["Ecommerce"]) && <div className="widget widget_search blog-bg">
                <form className="search-form" noValidate onSubmit={this._searchProduct}>
                    <h5 className="widget-title border-bottom">Search For Products</h5>
                    <div className="form-group">
                        <input type="text" required name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                            const reg = /^[A-Za-z0-9 ]+$/
                            if (e.target.value === '' || reg.test(e.target.value))
                                this.setState({ searchKey: e.target.value }, this._searchProduct)
                        }} className="form-control" />
                        <Button onClick={() => this.setState({ filteredList: [], searchKey: '' })} className="submit-btn" type="submit"><i className="flaticon-search"></i></Button>
                    </div>
                    <div className="search-result-blog">
                        <ul>
                            {
                                searchKey !== ''?
                                this._renderFilteredList(filteredList || []): null
                            }
                        </ul>
                    </div>
                </form>
            </div>}
            {miS.isModuleEnabled(["Blog"]) && <div className="widget widget_recent_post blog-bg style-01">
                <h5 className="widget-title border-bottom">Recent Post</h5>
                <ul className="recent-post-item">
                    { this._renderRecentPostList(recentPost || []) }
                </ul>
            </div>}
        </div>
    }
}