import React from 'react'
import { base } from '../../cms/constant/Data'
import { Img } from '../../cms/element'
import Home from '../../cms/page/front/Home'
import {MiscService} from '../../cms/service'
import './style.scss'

const miS = new MiscService();

Home.prototype._renderRecentPosts = function() {
    let {post} = this.state
    return <ul className="recent-post">
        {
            post.map((e, i) => {
                return <li key={i}>
                    <Img src={base.imageBaseUrl+JSON.parse(e.image)[0]} />
                    <i className="hi-search"></i>
                    <p>{e.title}</p>
                </li>
            })
        }
    </ul>
}

Home.prototype.render = function() {
    return <div className="main-section">
        <section className="banner home">
            <div>
                <button className="btn-nav">
                    <span></span><span></span><span></span>
                </button>
            </div>
            <div className="data active">
                <h1>Hey! welcome to <span>Programmer India</span></h1>
                <p className="big">Do you want <b className="cursive">Awesome website / mobile app</b></p>
                <a href="/quick-query/" className="btn btn-primary btn-big">Yes, I do!</a>
            </div>
        </section>
        <section className="what-we-do">
            <div className="container">
                <h2>What We Do... We Do It <span className="primary">Fantastic</span></h2>
                <p>We offer a wide array of service to cater to any of your web, mobile, or digital marketing requirements. Be it through the creativity of our designs, usage of latest technologies or adherence to industry best practices, we always thrive to deliver world class solutions to our clients.</p>
            </div>
            <i className="icon hi-programmerindia"></i>
        </section>
        <section className="our-service">
            <div className="container flex">
                <div className="service half">
                    <div className="service">
                        <Img src={`${base.imageBaseUrl}web-development.jpg`} />
                        <p>Web Development</p>
                    </div>
                    <div className="service">
                        <Img src={`${base.imageBaseUrl}ux-design.jpg`} />
                        <p>UX Design</p>
                    </div>
                </div>
                <div className="service full">
                    <Img src={`${base.imageBaseUrl}mobile-development.jpg`} />
                    <p>Mobile Apps</p>
                </div>
                <div className="service full">
                    <Img src={`${base.imageBaseUrl}digital-marketing.jpg`} />
                    <p>Digital Marketing</p>
                </div>
                <div className="service half">
                    <div className="service">
                        <Img src={`${base.imageBaseUrl}ui-solutions.jpg`} />
                        <p>UI Solutions</p>
                    </div>
                    <div className="service">
                        <Img src={`${base.imageBaseUrl}emerging-technologies.jpg`} />
                        <p>Emerging Technologies</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="what-we-serve back-cream">
            <div className="container flex">
                <div className="tal">
                    <h2>What we serve is...<br />What the market needs</h2>
                    <p className="cursive">We provide you the exact service, you need for your business. Today's internet world is more than a huge place and has a thousands of technologies coming every now and then that are enough to confuse you. We let you know what is best for you in today's scenario and even in future.</p>
                </div>
                <Img src={`${base.imageBaseUrl}tech-1.png`} />
            </div>
        </section>
        <section className="cta-banner">
            <div className="container">
                <h2>Wanna see more? Would love to show you.</h2>
                <a href="/contact" className="btn">Contact Now</a>
            </div>
        </section>
        {miS.isModuleEnabled(['Blog']) && <section className="from-the-blog back-cream">
            <div className="container">
                <h2>Recent Posts</h2>
                {this._renderRecentPosts()}
                <div className="clear"></div>
            </div>
        </section>}
        <section className="lets-talk">
            <div className="container">
                <h2>Let's talk about what we can build for you</h2>
                <p>Whatever your requirement are - be it a simple website design, a complex data driven web application development, an ecommerce website, a native or cross platform mobile app development, a logo and brand identity design, a video production or a full fledged digital marketing campaign - in any of the scenario or more than that -  we have a better solution for you.</p>
                <a href="/quick-query" className="btn">Connect with us Now !</a>
            </div>
        </section>
        <section className="white-box">
            <div className="container flex">
                <Img src={`${base.imageBaseUrl}shaded.png`} />
                <p className="right-text cursive">We have spent all our lives as database architects, marketers, social media experts, designers, mobile development experts, developers, and marketing solution providers. “Curation”, “aggregation”, “amplification” are normal parts of our everyday vocabulary.</p>
                <div className="clear"></div>
            </div>
        </section>
    </div>
}