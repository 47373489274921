const routes = [
    {
        "pathName": "/admin/offer/add",
        "module": ["Ecommerce"],
        "component": "AddOffer",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/offer/list",
        "module": ["Ecommerce"],
        "component": "OfferList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/offer/edit",
        "module": ["Ecommerce"],
        "component": "AddOffer",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/trash/offer/list",
        "module": ["Ecommerce"],
        "component": "OfferList",
        "type": "admin",
        "mode": "static"
    }
]

export default routes