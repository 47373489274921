import { useEffect, useState } from "react"
import { DashboardWidgetService, MiscService } from "../../../../service"
import { Button } from "../../../../element"
const dWS = new DashboardWidgetService(),
mS = new MiscService()
const CarTotal = ({
    data = {},
    action = {}
}) => {
    const defaultQuery = {
        table: 'car',
        filter: {
            date: {
                start: mS.getFormattedDate(new Date('01-01-1990')),
                end: mS.getFormattedDate(new Date())
            }
        },
        attr: {}
    },
    [count, setcount] = useState(0),
    [title, settitle] = useState(data.title),
    [query, setquery] = useState(Object.keys(data.setting).length ? data.setting : defaultQuery),
    _fetchCount = async () => {
        let requestData = {
            query: JSON.stringify(query)
        }
        let result = await dWS.fetch('count', requestData)
        if (result.status)
            setcount(result.data)
    },
    _applyFilters = async (e) => {
        e.preventDefault()
        let queryData = query
        await setquery(queryData)
        _fetchCount()
        action.updateWidget({...data, title, configure: false, setting: queryData})
    },
    _renderFilters = () => {
        return <form onSubmit={_applyFilters}>
            <input onChange={e => settitle(e.target.value)} type="text" value={title} placeholder="Widget Title" />
            <Button type='submit' className='btn btn-primary hi-check-circle'></Button>
        </form>
    }

    useEffect(() => {
        _fetchCount()
    }, [])

    return <>
        { data.configure ? _renderFilters():
            <>
                <i className="widget-icon hi-car"></i>
                {count}
            </>
        }
    </>
}

export default CarTotal