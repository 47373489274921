import React, {Component} from 'react'
import {MiscService, ValidationService, DataService, RouterService, MetaService} from '../../../service'
import {base} from '../../../constant/Data'
import {Img, Button, BackIcon} from '../../../element'
import {Box, MediaBox} from '../../../component'
const nS = new DataService('notification'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
vS = new ValidationService()
export default class AddNotifications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: '',
            body: '',
            jsonData: '',
            uid: '',
            mode: 'Add',
            image: '[]',
            title: ''
        }
    }
    componentDidMount() {
        mS.setTitle('Add Notification', 'admin')
    }
    _addNotification = async e => {
        e.preventDefault()
        let { title, body, jsonData, image } = this.state,
        error = false,
        errorMsg = '',
        notificationObject = {
            title,
            body: body,
            jsonData: jsonData,
            timing: new Date().getTime(),
            image: typeof image === 'undefined'? '[]' : image
        }
        if (title === '') {
            errorMsg = 'Please enter notification title!'
            error = true
        } else if (body === '') {
            errorMsg = 'Please enter notification body!'
            error = true
        } else if (jsonData !== '') {
            if (!vS.isValidJson(jsonData)) {
                errorMsg = 'Please enter valid json Data for notification!'
                error = true
            }
        }
        if (!error) {
            notificationObject.uid = new Date().getTime()
            const result = await nS.save(notificationObject)
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Notification added successfully!' })
                rS.navigateTo('/admin/notification/list')
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to add notification!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { title, body, mode, jsonData, image } = this.state,
        pageTitle = mode + ' Notification'
        return <>
            <h1>
                <BackIcon backLink='/admin/notification/list' />
                { pageTitle }
            </h1>
            <form className="columns" noValidate onSubmit={this._addNotification}>
                <div className="left">
                    <Box title="Title" required>
                        <input autoFocus type="text" required name="title" value={title} onChange={e => this.setState({ title: e.target.value })} />
                    </Box>
                    <Box title="Box" required>
                        <textarea className="description" placeholder="Type the notification here" required name="body" value={body} onChange={(e) => this.setState({ body: e.target.value })}></textarea>
                    </Box>
                    <Box title="Data">
                        <textarea className="description" placeholder="Please enter valid Json data" required name="jsonData" value={jsonData} onChange={(e) => this.setState({ jsonData: e.target.value }) }></textarea>
                    </Box>
                </div>
                <div className="right">
                    <Box title="Meta">
                        <Button className="btn-primary btn" type="submit" title="Click here to Add notification">Submit</Button>
                    </Box>
                    <MediaBox image={image} setimage={image => this.setState({image})} />
                </div>
            </form>
        </>
    }
}