import React, { useEffect, useState } from 'react'
import {UserService, MiscService} from '../../service'
import { profileNav } from '../../constant/Enum'
import routes from '../../router'
const uS = new UserService(),
miS = new MiscService(),
ProfileSideBar = () => {
    const user = uS.getLoggedInUserData(),
    [temporaryUser, settemporaryUser] = useState(false),
    _logout = () => {
        uS.logout()
    },
    _renderProfileMenu = () => {
        return profileNav.map(( item, index ) => {
            if ((item.accessRoles?.includes(user.role) || (item.accessRoles?.includes('user') && ['admin', 'developer'].includes(user?.role))) && miS.isModuleEnabled(item.module))
                return <li title={item.title} key={index}><a href={item.link}><i className={item.icon}></i><span className="profile-sidebar-name">{item.title}</span></a></li>
            else return true
        })
    }
    // profileNav.forEach(e => {
    //     routes.forEach(r => {
    //         if (r.pathName === e.link)
    //             e.accessRoles = r.accessRoles
    //     })
    // })
    useEffect(() => {
        if (localStorage.getItem('tempLogin') === 'true') {
            settemporaryUser(true)
        }
    }, [])
    return <div className="left-menu-section">
        <div className="nav-list">
            <div className="menus">
                <ul>
                    { _renderProfileMenu() }
                    {!temporaryUser && <li title='Logout'><i onClick={_logout}><i className="hi-logout"></i><span className="profile-sidebar-name">Logout</span></i></li>}
                </ul>
            </div>
        </div>
    </div>
}
export default ProfileSideBar