export default class RouterService {
    navigateTo = (route, newTab = false, data = null,) => {
        if (newTab) window.open(route, '_blank');
        else this.getLocationData().href = route
        // else {
        //     const nextTitle = '';
        //     const nextState = { additionalInformation: 'Updated the URL with JS' };
        
        //     // This will create a new entry in the browser's history, without reloading
        //     window.history.replaceState(nextState, nextTitle, route);
        // }
    }
    getLocationData = () => {
        let currentUrl = window.location
        return currentUrl
    }
    URLToArray = (url) => {
        let request = {}, pairs = url.substring(url.indexOf('?') + 1).split('&')
        for (var i = 0; i < pairs.length; i++) {
            if (!pairs[i])
            continue
            let pair = pairs[i].split('=')
            request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
        }
        return request
    }
    urlToSplitData = (url) => {
        return url.split('/')
    }
    ArrayToURL = (array) => {
      var pairs = []
        for (var key in array)
        if (array.hasOwnProperty(key))
        pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(array[key]))
        return pairs.join('&')
    }
}