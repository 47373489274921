import React from 'react'
import './style.scss'
const service = [
    {url: '/service/car-revival-package', label: 'Car Revival Package'},
    {url: '/service/ac-disinfectant', label: 'AC disinfectant'},
    {url: '/service/battery-replacement', label: 'Battery Replacement'},
    {url: '/service/jump-start', label: 'Jump Start'},
    {url: '/service/flat-tyre', label: 'Flat tyre'},
    {url: '/service/tyre-rotation', label: 'Tyre Rotation'},
    {url: '/service/sanitization-deodorization', label: 'Car Sanitization'},
    {url: '/service/pick-drop', label: 'Pick & Drop'},
    {url: '/service/car-washing-cleaning', label: 'Car Washing'}
],
searches = [
    'Car Service In Jaipur',
    'Car Mechanic In Jaipur',
    'Car Workshop In Jaipur',
    'Car Repair In Jaipur',
    'Car Wash In Jaipur',
    'Car Denting & Painting Services In Jaipur',
    'Engine Oil Filter Service In Jaipur',
    'Car Wheel Alignment In Jaipur',
    'Car Parts & Repairs Service In Jaipur',
    'Car AC Service In Jaipur',
    'Car Dry Clean in Jaipur',
    'Car Tyre Shops In Jaipur',
    'Car Wheel Balancing In Jaipur',
    'Car Detailing Service in Jaipur',
    'Car Coating in Jaipur',
    'Car Rubbing & Polishing In Jaipur',
],
society = [
    {url: '/service', label: 'Car Service near ROYAL AVENUE SOCIETY'},
    {url: '/service', label: 'Car Service near KRISHNA KUNJ VILAS'},
    {url: '/service', label: 'Car Service near ABL SKY TERRACE'},
    {url: '/service', label: 'Car Service near Mangalam City'},
    {url: '/service', label: 'Car Service near Shakambharis Kohinoor Residency'},
    {url: '/service', label: 'Car Service near Dwarka Apartments'},
    {url: '/service', label: 'Car Service near Urbana jewels'},
    {url: '/service', label: 'Car Service near Sunrise City'},
    {url: '/service', label: 'Car Service near Dhanuka Sunshine Prime'},
    {url: '/service', label: 'Car Service near Anukampa Platina'},
    {url: '/service', label: 'Car Service near Royal Green City'}
],
_rederLinks = tags => {
    return tags.map((e, i) => {
        return <a href={e.url} title={e.label} key={i}>{e.label}</a>
    })
},
_rederWords = tags => {
    return tags.map((e, i) => {
        return <span className="link" title={e} key={i}>{e}</span>
    })
},
SubFooter = () => {
    return <div className="sub-footer">
        <div className="jumbo-container">
            <h2 className="title-bar heading-02">Popular Searches</h2>
            <section className="popular-service-section">
                <p>
                    <span className="bold sub-head">Popular Services:</span> {_rederLinks(service)}
                </p>
                <p>
                    <span className="bold sub-head">Popular Locations:</span> {_rederWords(searches)}{_rederLinks(society)}
                </p>
            </section>
        </div>
    </div>
}
export default SubFooter