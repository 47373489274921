import { useState } from "react"
import { base } from "../../../constant/Data"
import { Button, Img } from "../../../element"
import { DataService, MiscService } from "../../../service"
import Box from "../../Box"

const miS = new MiscService(),
dS = new DataService('media'),
MediaBox = props => {
    let {image, setimage, label='Add Image'} = props,
    [searchKey, setsearchKey] = useState('')
    const [mediaFiles, setmediaFiles] = useState([]),
    [tableData, settableData] = useState([]),
    _getMedias = async () => {
        const result = await dS.fetchAll()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No media added yet!' })
            } else {
                let media = result.data.reverse().filter(e=>{
                    return (e.fileName.includes('.'))
                })
                setmediaFiles(media)
                settableData(media)
            }
        }
    },
    _selectImage = file => {
        let images = document.getElementById('image-input').value === '' ? JSON.stringify([]) : document.getElementById('image-input').value
        if (!images.includes(file)) {
            images = JSON.parse(images)
            images.push(file)
        } else {
            images = JSON.parse(images)
            images.pop(file)
        }
        setimage(JSON.stringify(images))
    },
    _renderImages = mediaFiles => {
        return mediaFiles.map(( item, index ) => {
            return <li key={index} className={image.includes(item.fileName)?"selected": ''}>
                <Img alt={item.fileName} src={base.imageBaseUrl + item.fileName} />
                <i className="hi-check-circle select" onClick={() => _selectImage(item.fileName)}></i>
            </li>
        })
    },
    _search = () => {
        let tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.fileName.toLowerCase()
            if (tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        setmediaFiles(filteredList)
    },
    _renderUploadedImages = mediaFiles => {
        return mediaFiles.map(( item, index ) => {
            return <li key={index}>
                <Img alt={item.fileName} src={base.imageBaseUrl + item} hasZoom={true} />
                <i className="hi-trash" onClick={() => _trashSelectedImage(item)}></i>
            </li>
        })
    },
    _trashSelectedImage = item => {
        let newImages = []
        JSON.parse(image).forEach(i => {
            if (i !== item) {
                newImages.push(i)
            }
        })
        setimage(JSON.stringify(newImages))
    }
    return <>
        <Box title='Image' required>
            <Button type="button" className="btn-primary btn btn-small" title="Click here to show images" onClick={() => {
                _getMedias()
                miS.openModal("media-modal")
            }}>{label}</Button>
            <input className="hidden" type="text" required name="image" value={image||''} onChange={(e) => setimage(e.target.value) } id="image-input" />
            <div className="upload-box mt20 mb0">
                <ul className="media-box">
                    {
                        JSON.parse(image).length !== 0 &&
                        _renderUploadedImages(JSON.parse(image)||[])
                    }
                </ul>
            </div>
        </Box>
        <div className="modal media-modal" id="media-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={() => miS.closeModal("media-modal")}>
                    <i className="hi-close"></i>
                </Button>
                <div className="modal-body filter-box">
                    <div className="modal-header">
                        <h2 className="heading">Select Image</h2>
                        <div className="search-box">
                            <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                                    setsearchKey(e.target.value)
                                    _search()
                                }} />
                            <Button type="button" onClick={() => {
                                setmediaFiles(tableData)
                                searchKey('')
                                _search()
                            }}><i className="hi-close"></i></Button>
                        </div>
                    </div>
                    <div className="modal-box">
                        <ul className="media-box">
                            {
                                _renderImages(mediaFiles||[])
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default MediaBox