import React, {Component} from 'react'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react'
import {base,mainUrl} from '../../../constant/Data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CreatableSelect from 'react-select/creatable'
import {Img, Button, BackIcon} from '../../../element'
import { robotsOption, statusOption } from '../../../constant/Enum'
import {MiscService, DataService, ProductService, RouterService, MetaService, UserService} from '../../../service'
import {Box, MediaBox} from '../../../component'
const tS = new DataService('taxClass'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
prS = new ProductService(),
keywordOptions =  [],
tagOptions = []
export default class AddProduct extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companies: [],
            company: null,
            activeCar: {},
            activeRobotsIndex: 0,
            activeStatusIndex: 0,
            price: 0,
            image: '[]',
            uid: '',
            renderSlug: true,
            background: '#333',
            mode: 'Add',
            title: '',
            slug: '',
            content: "",
            excerpt: "",
            css: '',
            js: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            publishDate: new Date(),
            status: '',
            category: '',
            categoryOptions: [],
            brand: null,
            brandOptions: [],
            tag: '',
            sku: '',
            hsn: '',
            stock: '',
            tax: '',
            saleprice: '',
            isAssured: false,
            isFeatured: false,
            inclusiveTax: true,
            taxClassData: []
        }
    }
    componentDidMount () {
        mS.setTitle('Add Product', 'admin')
        this._getProductCategoryData()
        this._getProductBrandData()
        this._getTaxClassesListData()
        let { activeStatusIndex } = this.state
        this.setState({ status: statusOption[activeStatusIndex].value })
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getProductData(query)
        }
        this._getUsersData()
    }
    _getUsersData = async () => {
        let companies = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No companies added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'company')
                        companies.push({ label: result.name, value: result.uid })
                })
                this.setState({ companies })
            }
        }
}
    _getTaxClassesListData = async () => {
        const result = await tS.fetchAll()
        if (result.status) {
            let taxClassData = []
            result.data.forEach(e => {
                taxClassData.push({
                    label: e.label,
                    value: e.amount
                })
            })
            this.setState({ taxClassData: taxClassData})
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No taxClass added yet!' })
            }
        } else miS.showAlert({ type: 'error', msg: 'No taxClass added yet!' })
    }
    _getProductData = async query => {
        const result = await prS.getProduct({ query: JSON.stringify(query) })
        if (result.status) {
            let productData = result.data[0],
            metaKeyword = [],
            tag = [],
            category = [],
            activeRobotsIndex = 0,
            activeStatusIndex = 0
            robotsOption.forEach(( e, i ) => {
                if (e.value === productData.robots) {
                    activeRobotsIndex = i
                }
            })
            statusOption.forEach(( e, i ) => {
                if (e.value === productData.status) {
                    activeStatusIndex = i
                }
            })
            if (productData.metaKeyword === '""' || productData.metaKeyword === '') {
                metaKeyword = []
            } else {
                JSON.parse(productData.metaKeyword).forEach(e => {
                    metaKeyword.push(e)
                })
            }
            if (productData.tag === '""' || productData.tag === '' || productData.tag === 'null' || productData.tag === null) {
                tag = []
            } else {
                JSON.parse(productData.tag).forEach(e => {
                    tag.push(e)
                })
            }
            if (productData.category === '""' || productData.category === '' || productData.category === "null") {
                category = []
            } else {
                JSON.parse(productData.category).forEach(e => {
                    category.push(e)
                })
            }
            this.setState({
                title: productData.title,
                mode: 'Edit',
                slug: productData.slug,
                content: productData.content,
                excerpt: productData.excerpt,
                css: productData.css,
                js: productData.js,
                canonical: productData.canonical,
                robots: productData.robots,
                metaDescription: productData.metaDescription,
                metaKeyword,
                status: productData.status,
                category,
                brand: productData.brand !== null && typeof productData.brand && productData.brand !== 'undefined' ? JSON.parse(productData.brand) : null,
                publishDate: new Date(productData.publishDate),
                tag,
                isAssured: productData.isAssured,
                isFeatured: productData.isFeatured,
                inclusiveTax: productData.inclusiveTax,
                sku: productData.sku,
                hsn: productData.hsn,
                stock: productData.stock,
                tax: productData.tax,
                price: productData.price,
                saleprice: productData.saleprice,
                background: productData.background,
                uid: productData.uid,
                image: productData.image,
                activeRobotsIndex,
                activeStatusIndex
            })
            if (productData.status === 'private' && productData.company) {
                this.setState({ company: productData.company })
            }
        } else miS.showAlert({ type: 'error', msg: 'No product added yet!' })
    }
    _getProductCategoryData = async () => {
        let tableData = []
        const result = await prS.getProductCategory()
        if (result.data.length !== 0) {
            result.data.forEach(e => {
                tableData.push({label: e.catName, value: e.catSlug})
            })
            this.setState({ categoryOptions: tableData })
        }
    }
    _getProductBrandData = async () => {
        let tableData = []
        const result = await prS.getProductBrand()
        if (result.status) {
            if (result.data.length !== 0) {
                result.data.forEach(e => {
                    tableData.push({label: e.brandName, value: e.brandSlug})
                })
                this.setState({ brandOptions: tableData })
            }
        }
    }
    _addProduct = async e => {
        e.preventDefault()
        let { company, brand, isAssured, isFeatured, inclusiveTax, mode, title, sku, hsn, stock, tax, saleprice, slug, content, excerpt, css, js, canonical, robots, metaDescription, metaKeyword, price, publishDate, status, category, tag, uid, image } = this.state,
        error = false,
        errorMsg = '',
        productObject = {
            title,
            slug,
            content,
            excerpt,
            css,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            publishDate,
            status: status.value,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            price: price,
            brand: brand !== null ? JSON.stringify(brand) : null,
            sku,
            hsn,
            stock: stock,
            isFeatured,
            isAssured: isAssured,
            inclusiveTax,
            tax,
            saleprice: saleprice,
            image
        }
        if (status.value === 'private' && company) {
            productObject.company = company
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (stock === '') {
            errorMsg = 'Please enter the stock availablity'
            error = true
        } else if (content === '') {
            errorMsg = 'Please enter the content'
            error = true
        } else if (excerpt === '') {
            errorMsg = 'Please enter the excerpt'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        } else if (brand === '') {
            errorMsg = 'Please select brand'
            error = true
        } else if (price === 0) {
            errorMsg = 'Please enter price'
            error = true
        }
        if (saleprice !== '') {
            if (Number(price) <= Number(saleprice)) {
                errorMsg = 'Sale Price should be less than MRP'
                error = true
            }
        }
        if (!error) {
            if (mode === 'Add') {
                productObject.uid = new Date().getTime()
                const result = await prS.addProduct(productObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Product added successfully!' })
                    rS.navigateTo('/admin/product/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add product!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    productData: JSON.stringify(productObject)
                }
                const result = await prS.updateProduct(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Product updated successfully!' })
                    rS.navigateTo('/admin/product/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update product!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        { company, companies, taxClassData, isAssured, status, sku, categoryOptions, brand, brandOptions, isFeatured, inclusiveTax, hsn, stock, tax, price, saleprice, mode, renderSlug, title, slug, content, excerpt, css, js, canonical, activeRobotsIndex, metaDescription, metaKeyword, category, tag, image } = this.state,
        productTitle = mode + ' Product'
        if (canonical === '') {
            canonical = mainUrl+ urlData[2]+'/'+slug
        }
        let selectedTaxClass = {}
        if (taxClassData.length>0 && tax === '') {
            selectedTaxClass = taxClassData[0]
        }
        taxClassData.forEach(e => {
            if (e.value === tax) {
                selectedTaxClass = e
            }
        })
        return <>
            <h1>
                <BackIcon backLink='/admin/product/list' />
                {productTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._addProduct}>
                <div className="left">
                    <Box title='Title' required>
                        <input autoFocus type="text" required name="title" value={title} onChange={e => {
                            let tempTitle = e.target.value, tempSlug = slug
                            if (renderSlug) {
                                tempSlug = miS.slugify(tempTitle)
                            }
                            this.setState({ title: tempTitle, slug: tempSlug })
                        }} />
                    </Box>
                    <Box title='Slug' requirred>
                        <input type="text" required name="slug" value={slug} onChange={(e) => this.setState({ slug: e.target.value })} />
                    </Box>
                    <Box title='SKU'>
                        <input type="text" name="sku" value={sku} onChange={(e) => this.setState({ sku: e.target.value })} />
                    </Box>
                    <Box title='HSN Code'>
                        <input type="text" name="hsn" value={hsn} onChange={(e) => this.setState({ hsn: e.target.value })} />
                    </Box>
                    <Box title='Stock Availability' required>
                        <input type="number" required name="stock" value={stock} onChange={(e) => this.setState({ stock: e.target.value })} />
                    </Box>
                    <Box title='Content' required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={content}
                            onEditorChange={(content, editor) => {
                                this.setState({ content })
                            }}
                        />
                    </Box>
                    <Box title='Excerpt' required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={excerpt}
                            onEditorChange={(excerpt, editor) => {
                                this.setState({ excerpt })
                            }}
                        />
                    </Box>
                    {status.value === 'private' && <><Box title='CSS'>
                        <textarea className="description" placeholder=".selector {property:value}" required name="css" value={css} onChange={(e) => this.setState({ css: e.target.value })}></textarea>
                    </Box>
                    <Box title='JS'>
                        <textarea className="description" placeholder="alert('Type your script here')" required name="js" value={js} onChange={(e) => this.setState({ js: e.target.value })}></textarea>
                    </Box>
                    <Box title='Canonical URL'>
                        <input type="text" required name="canonical" value={canonical} onChange={(e) => this.setState({ canonical: e.target.value })} />
                    </Box>
                    <Box title='Robots'>
                        <Select
                            value={robotsOption[activeRobotsIndex]}
                            defaultValue={robotsOption[activeRobotsIndex]}
                            onChange={e => this.setState({ robots: e.value })}
                            options={robotsOption}
                        />
                    </Box>
                    <Box title='Meta Description'>
                        <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={(e) => this.setState({ metaDescription: e.target.value })}></textarea>
                    </Box>
                    <Box title='Meta Keywords'>
                        <CreatableSelect
                            isMulti
                            onChange={e => this.setState({ metaKeyword: e })}
                            options={keywordOptions}
                            value={metaKeyword}
                        />
                    </Box></>}
                </div>
                <div className="right">
                    <Box title='Meta'>
                        <p>
                            Render Slug:
                            <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={() => this.setState({ renderSlug:!renderSlug})}></span>
                        </p>
                        <Button className="btn-primary btn has-icon" type="submit" title="Click here to save">Save</Button>
                    </Box>
                    {
                        taxClassData.length>0 &&
                        <Box title='Tax % (Tax Class)' required>
                            <Select
                                value={selectedTaxClass}
                                defaultValue={selectedTaxClass}
                                onChange={e => this.setState({ tax: e.value })}
                                options={taxClassData}
                            />
                        </Box>
                    }
                    <Box title='MRP' required>
                        <input type="number" required name="price" min={0} value={price} onChange={(e) => this.setState({ price: e.target.value })} />
                    </Box>
                    <Box title='Sale Price'>
                        <input type="number" name="saleprice" min={0} value={saleprice} onChange={(e) => this.setState({ saleprice: e.target.value })} />
                    </Box>
                    <Box title='Price Meta' required>
                        <span className="pointer isFeatured" onClick={() => this.setState({ inclusiveTax: !inclusiveTax})}>
                            {
                                inclusiveTax ?
                                <i className="icon-check orange hi-check-circle"></i>:
                                <i className="hi-circle icon-check"></i> 
                            }
                            Inclusive of all taxes </span> 
                    </Box>
                    <Box title='Publish Date'>
                        <DatePicker
                            onChange={publishDate => this.setState({ publishDate })}
                            selected={this.state.publishDate}
                        />
                    </Box>
                    <Box title='Status'>
                        <Select
                            value={status}
                            defaultValue={status}
                            onChange={e => this.setState({ status: e })}
                            options={statusOption}
                        />
                    </Box>
                    {status === 'private' && <Box title='Company' required>
                        <Select
                            value={company}
                            defaultValue={company}
                            onChange={e => this.setState({ company: e })}
                            options={companies}
                        />
                    </Box>}
                    <Box title='Featured'>
                        <span className="pointer isFeatured" onClick={() => this.setState({ isFeatured: !isFeatured})}>
                            {
                                isFeatured ?
                                <i className="icon-check orange hi-check-circle"></i>:
                                <i className="hi-circle icon-check"></i> 
                            }
                            Is Featured
                        </span>
                    </Box>
                    <Box title='Assured'>
                        <span className="pointer isAssured" onClick={() => this.setState({ isAssured: !isAssured})}>
                            {
                                isAssured ?
                                <i className="icon-check orange hi-check-circle"></i>:
                                <i className="hi-circle icon-check"></i> 
                            }
                            Is Assured </span>
                    </Box>
                    <Box title='Category'>
                        <Select
                            value={category}
                            onChange={e => this.setState({ category: e })}
                            options={categoryOptions}
                            isMulti
                        />
                    </Box>
                    <Box title='Brand'>
                        <Select
                            value={brand}
                            onChange={e => this.setState({ brand: e })}
                            options={brandOptions}
                        />
                    </Box>
                    <Box title='Tags'>
                        <CreatableSelect
                            isMulti
                            value={tag}
                            onChange={e => this.setState({ tag: e })}
                            options={tagOptions}
                        />
                    </Box>
                    <MediaBox image={image} setimage={image => this.setState({image})} />
                </div>
            </form>
        </>
    }
}