const routes = [
    {
        "pathName": "/admin/page/list",
        "component": "PageList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/page/add",
        "component": "AddPage",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/page/edit",
        "component": "AddPage",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/trash/page/list",
        "component": "PageList",
        "type": "admin",
        "mode": "static"
    }
]

export default routes