import React, { useEffect, useState } from 'react'
import {Banner} from '../../../widget'
import {base} from '../../../constant/Data'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {Img, Button} from '../../../element'
import {MiscService, DataService, MetaService, UserService} from '../../../service'
const oS = new DataService('offer'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService()
const Offers = props => {
    const [offerList, setofferList] = useState([])
    const [activeOffer, setactiveOffer] = useState(null)
    useEffect(() => {
        mS.setTitle('Offers', 'public')
        _getOffersData()
    }, [])
    const _getOffersData = async () => {
        const result = await oS.fetchAll()
        if (result.status)
            setofferList(result.data.reverse())
    }
    const _renderOfferList = offerFiles => {
        return offerFiles.map(( item, index ) => {
            let foundUser = false
            if (item.offerForUser) {
                if (item.offerForUser !== 'null' && item.offerForUser !== null) {
                    JSON.parse(item.offerForUser).forEach(e => {
                        if (uS.getLoggedInUserData() && e.value === uS.getLoggedInUserData().uid)
                            foundUser = true
                    })
                }
            }
            if (
                item.offerRestriction &&
                (item.offerRestriction === 'public' || (item.offerRestriction === 'private' && foundUser )) &&
                item.status !== 'expired' &&
                new Date().getTime() < miS.addDays(new Date(item.expiryDate), 1).getTime() &&
                !item.trash
            )
                return <div className={( index % 2 !== 0 )?"offerBox offerBoxDark":"offerBox offerBoxOrange"} key={index}>
                    <div className="youget">
                        <p>You Get</p>
                        <span className="percentoff">{ item.discountType === "fixedprice" ? "₹"+item.discount:item.discount+'%'} Off</span>
                    </div>
                    <div className="imageSection">
                        {
                            item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                            <Img src={base.imageBaseUrl+'sale.png'} alt={item.title} />:
                            <Img src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                        }
                    </div>
                    <div className="textSection">
                        <h4>{item.content}</h4>
                        <div className="get-codebtn-holder" onClick={() => {
                            setactiveOffer(item)
                            miS.openModal("offer-modal")
                        }}>
                            <div className="get-offer-code">
                                <div className="offer-get-code-link cpnbtn">
                                    {item.code}
                                    <div className="p1"></div>
                                    <div className="p2">
                                        <div className="t1"></div>
                                        <div className="t1">
                                            <div className="t2"></div>
                                        </div>
                                    </div>
                                    <span>Coupon Code</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            else return true
        })
    }
    return <>
        <Banner title='Offers' image="white-car-banner.jpg"/>
        <div className="jumbo-container">
            <div className="offer-grid">
                { _renderOfferList(offerList || []) }
            </div>
        </div>
        <div className="modal" id="offer-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={() => miS.closeModal("offer-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    {
                        activeOffer !== null &&
                        <>
                            <div className="offer-header">
                                <div className="modal-header">
                                    <div className="offer-logo">
                                    {
                                        activeOffer.image === '' || activeOffer.image === '[]' || typeof activeOffer.image === "undefined"?
                                        <Img src={base.imageBaseUrl+'sale.png'} alt={activeOffer.title} />:
                                        <Img src={base.imageBaseUrl+JSON.parse(activeOffer.image)[0]} alt={activeOffer.title} />
                                    }
                                    </div>
                                    <div className="offer-description">
                                        <div className="title" title={activeOffer.content}>{activeOffer.content}</div>
                                    </div>
                                </div>
                                <div className="down-arrow"></div>
                            </div>
                            <div className="showcoupon">
                                <div className="popup-code-block">
                                    <span className="code-txt">{activeOffer.code}</span>
                                    <span className="copy-btn">
                                        <CopyToClipboard text={activeOffer.code}
                                            onCopy={() => {
                                                miS.showAlert({ type: 'success', msg: 'Copied!' })
                                            }}>
                                            <span>COPY CODE</span>
                                        </CopyToClipboard>
                                    </span>
                                </div>
                            </div>
                            <div className="coupon-bottom-text">
                                <span>On cart page paste your code at checkout</span>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    </>
}

export default Offers