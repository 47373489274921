import React, { useEffect, useState } from 'react';
import { DataService, MiscService, UserService } from '../../../service';
import { Modal, Table } from '../../../component';
import { Actor, Badge, Button } from '../../../element';

const sS = new DataService('setting'),
uS = new UserService(),
miS = new MiscService(),
ModuleList = () => {
    const user = uS.getLoggedInUserData(),
    [showModulesModal, setshowModulesModal] = useState(false),
    [activeModule, setactiveModule] = useState(''),
    [module, setmodule] = useState([]),
    [mode, setmode] = useState('edit'),
    _toggleModuleStatus = (e) => {
        let targetStatus = e.status === 'active' ? 'inactive' : 'active', moduleData = []
        module.forEach(i => {
            if(i.module === e.module) {
                i.status = targetStatus
            }
            moduleData.push(i)
        })
        setmodule(moduleData)
        _saveModulesData(moduleData)
    },
    _deleteModule = (e) => {
        let moduleData = []
        module.forEach(i => {
            if(i.module !== e.module) {
                moduleData.push(i)
            }
        })
        setmodule(moduleData)
        _saveModulesData(moduleData)
    },
    _fetchModules = async module => {
        let query = {
            key: 'active-modules'
        }
        const result = await sS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length === 0) {
                setmode('add')
            } else {
                let moduleData = JSON.parse(result.data[0].value)
                setmodule(moduleData)
            }
        }
    },
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item ) => {
            let e = {
                label: item.module,
                status: <Badge item={item.status} onClick={() => _toggleModuleStatus(item)} />,
                actions: <div className='action-bar'>
                    <Actor type='trash' onClick={() => _deleteModule(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    },
    _saveModulesData = async (moduleData) => {
        let settingObject = {
            key: 'active-modules',
            userId: user.uid,
            value: JSON.stringify(moduleData)
        }
        if (mode === 'add') {
            settingObject.uid = new Date().getTime()
            sS.save(settingObject)
        } else {
            let data = {
                query: JSON.stringify({
                    key: 'active-modules',
                    userId: user.uid
                }),
                settingData: JSON.stringify({value: settingObject.value})
            }
            sS.update(data)
        }
    },
    _addModule = (e) => {
        e.preventDefault()
        let moduleData = [...module, {module: activeModule, status: 'active'}]
        setmodule(moduleData)
        _saveModulesData(moduleData)
        setshowModulesModal(false)
        setactiveModule('')
    },
    _openModulesModal = () => {
        setshowModulesModal(true)
    }

    useEffect(() => {
        _fetchModules()
    }, [])

    let tableContent = {
        headers: [
            {
                label: 'Module',
                key: 'module'
            },
            {
                label: 'Status',
                key: 'status'
            },
            {
                label: 'Actions',
                key: 'actions'
            }
        ],
        content: _getTableData(module)
    }
    return <>
        <h1 className="title flex">Modules <Button onClick={_openModulesModal} className="btn btn-primary btn-small ml20">Add</Button></h1>
        <Table
            data={tableContent}
        />
        <Modal id="modules-modal" showCancel={false} showSuccess={false} onCancel={() => setshowModulesModal(false)} showModal={showModulesModal}>
            <div className='p20'>
                <form onSubmit={_addModule}>
                    <input type='text' value={activeModule} onChange={e => setactiveModule(e.target.value)} name="module" className='mb40' />
                    <div className='flex'>
                        <Button onClick={() => setshowModulesModal(false)} className="btn btn-outline">Cancel</Button>
                        <Button type="submit" className="btn btn-primary ml20">Submit</Button>
                    </div>
                </form>
            </div>
        </Modal>
    </>
}
export default ModuleList