const LogModel = [
    {
        key: 'log',
        dataType: String
    },
    {
        key: 'timing',
        dataType: String
    },
    {
        key: 'type',
        dataType: String
    },
    {
        key: 'userId',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]
export default LogModel