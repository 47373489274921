import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../component'
import {DataService, MiscService, RouterService, MetaService} from '../../../service'
import {Button, Actor} from '../../../element'
const dS = new DataService(['page']),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class PagesList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            filteredList: [],
            searchList: [],
            searchKey: '',
            showTrashConfirm: false,
            checkedAll: false,
            checkedList: [],
            isMultipleDelete: false,
            trashAction: false
        }
    }
    componentDidMount () {
        mS.setTitle('Pages List', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        this.setState({ trashAction: urlData[2] === 'trash'}, this._getPagesListData)
    }
    _clonePage = async item => {
        let pageObject = {
            title: item.title,
            slug: item.slug+'-copy',
            content: item.content,
            excerpt: item.excerpt,
            css: item.css,
            js: item.js,
            canonical: item.canonical,
            robots: item.robots,
            metaDescription: item.metaDescription,
            metaKeyword: item.metaKeyword,
            author: item.author,
            publishDate: item.publishDate,
            status: item.status,
            category: item.category,
            tag: item.tag,
            image: item.image
        }
        pageObject.uid = new Date().getTime()
        const result = await dS.save(pageObject)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'Page clone successfully!' })
            this._getPagesListData()
        } else miS.showAlert({ type: 'error', msg: 'Unable to clone page!' })
    }
    _getPagesListData = async () => {
        let { trashAction } = this.state
        const result = await dS.fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            this.setState({ tableData, filteredList: tableData })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No page added yet!' })
        } else miS.showAlert({ type: 'error', msg: 'No page added yet!' })
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _deleteSelectPage = () => {
        let { activeItem } = this.state
        this._deletePage(activeItem.uid)
    }
    _deletePage = async (uid, task = 'trash') => {
        let query = {
            uid
        }, { trashAction, tableData } = this.state
        if (trashAction && task === 'trash') {
            const result = await dS.delete({ query: JSON.stringify(query) })
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Page deleted successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getPagesListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to delete page!' })
        } else {
            let pageObject = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    pageObject = e
            })
            pageObject.trash = task === 'trash'
            delete pageObject._id
            let data = {
                query: JSON.stringify({uid}),
                pageData: JSON.stringify(pageObject)
            }
            const result = await dS.update(data)
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Page '+task+'d successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getPagesListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to '+task+' page!' })
        }
    }
    _toggleCheck = uid => {
        let {checkedList, filteredList} = this.state, newCheckedList = [], found = false
        checkedList.forEach(e => {
            if (e === uid) {
                found = true
            } else {
                newCheckedList.push(e)
            }
        })
        if (!found)
            newCheckedList.push(uid)
        this.setState({ checkedAll: (filteredList.length === newCheckedList.length), checkedList: newCheckedList })
    }
    _deleteChecked = () => {
        let { checkedList, checkedAll, filteredList } = this.state
        if (checkedAll) {
            checkedList = filteredList
        }
        checkedList.forEach(e => {
            this._deletePage(e.uid)
        })
        this.setState({ checkedList: [], isMultipleDelete: false })
    }
    _getTableData = filterData => {
        let data = [], { checkedAll, checkedList, trashAction } = this.state
        filterData.forEach(( item, index ) => {
            let e = {
                checkAction: <span className='check-all' onClick={() => this._toggleCheck(item.uid)}>
                    {
                        checkedAll || checkedList.includes(item.uid) ?
                        <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
                    }
                </span>,
                title: item.title,
                publishDate: miS.getFormattedDate(new Date(item.publishDate)),
                status: item.status,
                action: trashAction ?
                <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    <Actor type='undo' onClick={() => this._deletePage(item.uid, 'restore')} />
                </div>:
                <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    <Actor type='view' onClick={() => rS.navigateTo('/page/'+item.slug, true)} />
                    <Actor type='edit' onClick={() => rS.navigateTo('/admin/page/edit/'+item.uid)} />
                    <Actor type='copy' onClick={() => this._clonePage(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _renderFilterList = filterData => {
        return filterData.map(( item, index ) => {
            return <span className={ index===0 ? 'active link' : 'item link' } key={index}>{item.item } ({item.count})</span>
        })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.title.toLowerCase()
            if (tempTitle.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList })
    }
    render() {
        let { tableData, filterList, trashAction, checkedList, isMultipleDelete, checkedAll, filteredList, searchKey, showTrashConfirm, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                        {
                            checkedAll || checkedList.length === filteredList.length?
                            <i className="orange hi-check-circle"></i>:
                            <i className="hi-circle"></i>
                        }
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'PublishDate',
                    key: 'publishDate'
                },
                {
                    label: 'Page Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="flex title">
                {
                    trashAction?'Trashed': 'All'
                } Pages
                {
                    !trashAction &&
                    <>
                    <a className="btn btn-primary btn-small ml20" href="/admin/page/add">Add</a>
                    {
                        (checkedList.length !== 0 || checkedAll) &&
                        <Button type="button" onClick={() => this.setState({ isMultipleDelete:true, showTrashConfirm: true})} className="btn btn-outline btn-small">Delete</Button>
                    }
                    </>
                }
            </h1>
            <div className="filter-box">
                <div className="filters">
                {this._renderFilterList(filterList || [] )}
                </div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                        }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><i className="hi-close"></i></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._deleteSelectPage()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}