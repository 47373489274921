import React, {Component} from 'react'
import Slider from 'react-slick'
import {base} from '../../constant/Data'
import {Button, Img} from '../../element'
import {UserService, MiscService, OrderService, ProductService, RouterService} from '../../service'
const uS = new UserService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService(),
prS = new ProductService()
export default class RelatedProductSlider extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            featuredProducts: [],
            activeCarType: null,
            activeSubscriptionType: null
        }
    }
    componentDidMount () {
        this._getProductsData()
    }
    _getProductsData = async () => {
        const result = await prS.getProducts()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(( e, i ) => {
                if (typeof e.trash === "undefined" && i<10)
                    tableData.push(e)
                else if (!e.trash && i<10)
                    tableData.push(e)
            })
            this.setState({ featuredProducts: tableData })
        }
    }
    _renderFeatureProducts = featuredProducts => {
        return featuredProducts.map(( item, index ) => {
            let averageRating = 0
            if (item.feedback) {
                if (item.feedback.length>0) {
                    item.feedback.forEach(e => {
                        averageRating += e.rating
                    })
                    averageRating = averageRating/item.feedback.length
                }
            }
            return <div className="grid-list-column-item" key={index}>
                {
                    item.isAssured && <Img className="assuredImg" src={base.imageBaseUrl+'assured.png'} alt={item.title} />
                }
                <div className="thumb">
                    {
                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                        <Img src={base.imageBaseUrl+'product-placeholder.png'} alt={item.title} />:
                        <Img className="pointer" onClick={() => rS.navigateTo('/product/'+item.slug)} src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                    }
                </div>
                <h5 className="title pt10"><a href={"/product/"+item.slug}>{item.title}</a></h5>
                {
                    item.feedback && <div className="common-rating-style">
                        { miS.renderRatingSystem(Math.ceil(averageRating)) }
                    </div>
                }
                <div className="common-price-style">
                    {
                        typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' ?
                        <div className="mrp">
                            <span className="black">₹ {item.price}</span>
                        </div>:
                        <>
                            <div className="mrp">
                                <span className="black">₹ {item.saleprice}</span>
                            </div>
                            <div className="sale-price">
                                <span className="black">₹ {item.price}</span>
                            </div>
                            <div className="per-off">
                                <span className="green">{(( item.price - item.saleprice)/item.price * 100).toFixed(0)} % off</span>
                            </div>
                        </>
                    }
                </div>
                {(!uS.isUserLoggedIn() || (uS.isUserLoggedIn() && this.user?.role && !['manager', 'company', 'branch', 'representative'].includes(this.user?.role))) && <div className="main-btn-wrap pt20">
                    {
                        item.stock === 0 || item.stock === '0' || typeof item.stock === "undefined"?
                        <Button type="button" disabled className="btn-gray btn add-to-cart mauto" title="Out Of Stock">Out Of Stock</Button>:
                        <Button onClick={() => this._addToCart(item)} className="btn btn-primary btn-box mauto uppercase">Add to Cart</Button>
                    }
                </div>}
            </div>
        })
    }
    _productAddedTemplate = item => {
        let price = item.price
        if ( typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' ) {
        } else {
            price = item.saleprice
        }
        let prodcutAddedTemplate = '<div class="alert-msg"><i class="hi-checkbox-checked"></i><p>Product added to cart!</p></div>'+
        '<div class="product-box">'+
        '<Img src="'+base.imageBaseUrl+item.image+'" alt="'+item.title+'" class="product-image" />'+
        '<div class="product-content">'+
        '<p class="product-title">'+item.title+'</p>'+
        '<p class="product-price">₹ '+price+'</p>'+
        '</div>'+
        '</div>'
        miS.showAlert({
            type: 'custom',
            template: prodcutAddedTemplate
        })
        this.setState({ activeCarType: null })
        setTimeout(() => {
            this.setState({ activeCarType: null, activeSubscriptionType: null })
        }, 2000)
    }
    _addToCart = item => {
        let price = item.price, mrp = price, saleprice = item.saleprice
        if ( typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' ) {
        } else {
            price = saleprice
        }
        let product = {
            uid: item.uid,
            title: item.title,
            image: JSON.parse(item.image)[0],
            purchaseQuantity: 1,
            type: 'product',
            tempUid: new Date().getTime(),
            mrp: Number(mrp),
            saleprice: saleprice,
            tax: item.tax,
            hsn: item.hsn,
            sku: item.sku,
            taxAmount: Number((price * Number(item.tax)/(100+Number(item.tax))).toFixed(2))
        };
        product.price = price - product.taxAmount
        product.displayPrice = price
        oS.addToCart(product)
        this._productAddedTemplate(product)
    }
    render() {
        let { featuredProducts } = this.state,
        featuredSetting = {
            dots: true,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 768,
                    setting: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 560,
                    setting: {
                        slidesToShow: 1
                    }
                }
            ]
        }
        return <section className="featured-product-section mb60-mbs40">
            <h2 className="title-bar heading-02">Related Products</h2>
            <div className="wrapper">
                <div className="featured-product-slider">
                    <div className="product-slider-active">
                        <Slider {...featuredSetting}>
                            {this._renderFeatureProducts(featuredProducts || [] )}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    }
}