import React, {Component} from 'react'
import {base} from '../../constant/Data'
import ReactImageZoom from 'react-image-zoom'
import {RelatedProductSlider, Table} from '../../component'
import {MiscService, ProductService, OrderService, RouterService, UserService, MetaService} from '../../service'
import {Img, Button} from '../../element'
import { robotsOption, statusOption } from '../../constant/Enum'
const uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService(),
prS = new ProductService()
export default class Product extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            feedback: [],
            stock: 0,
            activeCar: {},
            activePrice: '',
            price: 0,
            image: '[]',
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            purchaseQuantity: 1,
            slug: '',
            content: '',
            excerpt: '',
            css: '',
            js: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            orderDate: '',
            author: '',
            publishDate: new Date(),
            status: '',
            category: '',
            brand: '',
            tag: '',
            saleprice: '',
            sku: '',
            tax: '',
            hsn: '',
            activeImage: '',
            showDropdwon: false,
            selectCarType: false,
            activeCarType: null,
            chooseServiceDate: false,
            buyType: '',
            selectedCar: {},
            activeSubscription: {},
            subscriptionData: [],
            rating: 0,
            comments: '',
            orderId: ''
        }
    }
    componentDidMount () {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        query = {
            slug: urlData[2]
        }
        this._getProductData(query)
    }
    _getProductData = async query => {
        const result = await prS.getProduct({ query: JSON.stringify(query) })
        if (result.status) {
            let productData = result.data[0],
            metaKeyword = [],
            tag = [],
            category = [],
            activeRobotsIndex = 0,
            activeStatusIndex = 0
            robotsOption.forEach(( e, i ) => {
                if (e.value === productData.robots)
                    activeRobotsIndex = i
            })
            statusOption.forEach(( e, i ) => {
                if (e.value === productData.status)
                    activeStatusIndex = i
            })
            if (productData.metaKeyword === '""' || productData.metaKeyword === '')
                metaKeyword = []
            else {
                JSON.parse(productData.metaKeyword).forEach(e => {
                    metaKeyword.push(e)
                })
            }
            if (productData.tag === '""' || productData.tag === '')
                tag = []
            else {
                JSON.parse(productData.tag).forEach(e => {
                    tag.push(e)
                })
            }
            if (!productData.category || productData.category === '""' || productData.category === '' || productData.category === "null")
                category = []
            else {
                JSON.parse(productData.category).forEach(e => {
                    category.push(e)
                })
            }
            mS.setTitle(productData.title, 'public')
            this.setState({
                title: productData.title,
                brand: JSON.parse(productData.brand),
                mode: 'Edit',
                slug: productData.slug,
                content: productData.content,
                excerpt: productData.excerpt,
                css: productData.css,
                js: productData.js,
                canonical: productData.canonical,
                robots: productData.robots,
                metaDescription: productData.metaDescription,
                metaKeyword,
                publishDate: new Date(productData.publishDate),
                status: productData.status,
                category,
                tag,
                isFeatured: productData.isFeatured,
                isAssured: productData.isAssured,
                inclusiveTax: productData.inclusiveTax,
                sku: productData.sku,
                hsn: productData.hsn,
                stock: productData.stock,
                tax: productData.tax,
                price: productData.price,
                saleprice: productData.saleprice,
                background: productData.background,
                uid: productData.uid,
                image: productData.image,
                activeRobotsIndex,
                activeStatusIndex,
                activeImage: JSON.parse(productData.image)[0],
                feedback: productData.feedback?productData.feedback: []
            })
        } else
            miS.showAlert({ type: 'error', msg: 'No product added yet!' })
    }
    _productAddedTemplate = () => {
        let { image, title, price, saleprice } = this.state
        if ( typeof saleprice === "undefined" || saleprice === 0 || saleprice === '' ) {
        } else
            price = saleprice
        let prodcutAddedTemplate = '<div class="alert-msg"><i class="hi-checkbox-checked"></i><p>Product added to cart!</p></div>'+
        '<div class="product-box">'+
        '<img src="'+base.imageBaseUrl+JSON.parse(image)[0]+'" alt="'+title+'" class="product-image" />'+
        '<div class="product-content">'+
        '<p class="product-title">'+title+'</p>'+
        '<p class="product-price">₹ '+price+'</p>'+
        '</div>'+
        '</div>'
        miS.showAlert({
            type: 'custom',
            template: prodcutAddedTemplate
        })
        setTimeout(() => {
            this.setState({ activeCarType: null, activeSubscriptionType: null })
        }, 2000)
    }
    _toggleSubscribeDropDown = () => {
        let { showDropdwon } = this.state
        this.setState({ showDropdwon:!showDropdwon})
    }
    _subscribe = time => {
        this._toggleSubscribeDropDown()
    }
    _buyItem = buyType => {
        miS.openModal("add-to-cart-modal")
        this.setState({ buyType: buyType})
    }
    _renderImages = imageList => {
        let { title } = this.state
        return imageList.map(( item, index ) => {
            return <div className="img-tem" key={index} onClick={() => {
                this.setState({ activeImage: item })
            }}>
                <Img src={base.imageBaseUrl+item} alt={title} />
            </div>
        })
    }
    _renderReviews = feedback => {
        return feedback.map(( item, index ) => {
            return <div className="review" key={index}>
                <div className="review-user-box">
                    {
                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                        <Img src={base.imageBaseUrl+'no-image-found.png'} alt={item.title} />:
                        <Img src={base.imageBaseUrl + JSON.parse(item.image)} alt={item.title} />
                    }
                    <p>{item.name}</p>
                </div>
                <div className="rating">{miS.renderRatingSystem(item.rating)}</div>
                <div className="reviwed-date">Reviewed on {miS.getFormattedDate(new Date(item.submitDate))}</div>
                <div className="review-content">
                    {item.comments}
                </div>
            </div>
        })
    }
    _addToCart = async (method='simple') => {
        miS.showPreloader()
        let { uid, title, image, price, purchaseQuantity, hsn, saleprice, tax, sku } = this.state,
        mrp = price
        if ( typeof saleprice === "undefined" || saleprice === 0 || saleprice === '' ) {
        } else
            price = saleprice
        let product = {
            uid,
            title,
            image: JSON.parse(image)[0],
            purchaseQuantity: purchaseQuantity,
            type: 'product',
            tempUid: new Date().getTime(),
            mrp: Number(mrp),
            saleprice: saleprice,
            tax,
            hsn,
            sku,
            taxAmount: Number((price * Number(tax)/(100+Number(tax))).toFixed(2))
        };
        product.price = price - product.taxAmount
        product.displayPrice = price
        await oS.addToCart(product)
        this._productAddedTemplate()
        miS.hidePreloader()
        if (method === 'quick')
            miS.proceedToCheckOut()
    }
    _feedBackSubmit = async e => {
        e.preventDefault()
        let { rating, comments, uid, feedback } = this.state,
        error = false,
        errorMsg = '',
        feedbackObject = {
            rating: rating,
            submitDate: new Date(),
            comments: comments,
            userId: this.user.uid,
            name: this.user.name,
            image: this.user.image,
            uid: new Date().getTime()
        }
        if (rating === '') {
            errorMsg = 'Please give a rating'
            error = true
        }
        if (!error) {
            feedback.push(feedbackObject)
            let productObject = {
                feedback: feedback
            }
            let data = {
                query: JSON.stringify({uid}),
                productData: JSON.stringify(productObject)
            }
            const result = await prS.updateProduct(data)
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Your review has been submited successfully.' })
                rS.getLocationData().reload()
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to submit your review' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { activeShownTotal, feedback, stock, title, comments, rating, brand, content, saleprice, isAssured, excerpt, price, image, activeImage } = this.state,
        imagesList = JSON.parse(image),
        today = new Date(),
        tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        let averageRating = 0
        if (feedback.length>0) {
            feedback.forEach(e => {
                averageRating += e.rating
            })
            averageRating = averageRating/feedback.length
        }
        let tableContent = {
            addonBodyContent: <tr className="backgroud-change">
                <td className="nowrap"><b>Price:</b></td>
                <td className="white priceProductPage">
                    {
                        typeof saleprice === "undefined" || saleprice === 0 || saleprice === '' ?
                        <div className="mrp">
                            <span className="black">₹ {price}</span>
                        </div>:
                        <>
                        <div className="mrp">
                            <span className="black">₹ {saleprice}</span>
                        </div>
                        <div className="sale-price">
                            <span className="black">₹ {price}</span>
                        </div>
                            <div className="per-off-product">
                            <span className="green">{((price - saleprice)/price * 100).toFixed(0)} % off</span>
                            </div>
                        </>
                    }
                    <small> (Inclusive of all taxes)</small>
                </td>
            </tr>
        }
        const props = {zoomWidth: 600, img: base.imageBaseUrl+activeImage}
        return <>
            <div className="productdetail-page mb60-mbs40">
                <div className="">
                    <section className="main-product-detail">
                        <div className="left-col-box">
                            <div className="left-col pt30">
                                <div className="gallery">
                                    <div className="images-section">
                                        {this._renderImages(imagesList || [] )}
                                    </div>
                                </div>
                                <div className="image-section">
                                    <div className="image-box">
                                        <ReactImageZoom {...props} />
                                    </div>
                                    { isAssured && <Img className="assuredImg" src={base.imageBaseUrl+'assured.png'} alt={title} /> }
                                </div>
                            </div>
                            {(!uS.isUserLoggedIn() || (uS.isUserLoggedIn() && this.user?.role && !['manager', 'company', 'branch', 'representative'].includes(this.user?.role))) && <div className="addtocart-btn-box flex overflow-hidden">
                                {
                                    stock === 0 || stock === '0' || typeof stock === "undefined"?
                                    <Button type="button" disabled className="btn-gray btn add-to-cart" title="Out Of Stock">Out Of Stock</Button>:
                                    <> 
                                        <Button onClick={() => this._addToCart('quick')} className="btn-outline btn-box btn add-to-cart">Buy Now</Button>
                                        <Button onClick={this._addToCart} className="btn-primary btn-box btn add-to-cart">Add to Cart</Button>
                                    </>
                                }
                            </div>}
                        </div>
                        <div className="right-col">
                            <div className="product-details">
                                <h1 className="title">{title}</h1>
                                {
                                    brand !== null && brand !== '' && typeof brand !== 'undefined' &&
                                    <div className="brand">
                                        <span className="customer-counting">
                                            by {brand.label}
                                        </span>
                                    </div>
                                }
                                {
                                    feedback.length !== 0 &&
                                    <div className="starer">
                                        <span className="common-rating-style">
                                            { miS.renderRatingSystem(Math.ceil(averageRating)) }
                                        </span>
                                        <span className="customer-counting link">
                                            {feedback.length} ratings
                                        </span>
                                    </div>
                                }
                                <hr className="mh0" />
                                <div id="price" className="a-section a-spacing-small">
                                    <Table
                                        data={tableContent}
                                        pageSize={activeShownTotal}
                                    />
                                </div>
                                <div className="product-trust">
                                    <div className="product-trust-item">
                                        <i className="hi-account_balance_wallet"></i>
                                        <span>Online Payment options</span>
                                    </div>
                                    <div className="product-trust-item">
                                        <i className="hi-replay_10"></i>
                                        <span>10 Days Replacement</span>
                                    </div>
                                    <div className="product-trust-item">
                                        <i className="hi-ticket"></i>
                                        <span>Genuine product</span>
                                    </div>
                                    <div className="product-trust-item">
                                        <i className="hi-delivery_dining"></i>
                                        <span>Free Shipping</span>
                                    </div>
                                </div>
                                {
                                    stock === 0 || stock === '0' || typeof stock === "undefined"?
                                    <p className="a-size-medium error">Out Of Stock</p>:
                                    <p className="a-size-medium success">In Stock</p>
                                }
                                <div dangerouslySetInnerHTML={{__html: excerpt}}></div>
                            </div>
                            <h2 className="title borderbottom">Description</h2>
                            <div className="content-detail pb40" dangerouslySetInnerHTML={{__html: content}}></div>
                        </div>
                    </section>
                </div>
                <div className="review-rating-box-background">
                    {
                    feedback.length !== 0 && typeof feedback !== 'undefined' &&
                    <div className="reviews-box pt50">
                        <div className="jumbo-container">
                            <h2 className="title-bar heading-02">Reviews</h2>
                            {this._renderReviews(feedback||[] )}
                        </div>
                    </div>
                    }
                    {
                        uS.isUserLoggedIn() &&
                        <div className="feedback-page product-review-box pb50 pt40">
                            <div className="jumbo-container">
                                <h2 className="title-bar heading-02">Write a Review</h2>
                                <div className="body-feedback mb20">
                                    <form onSubmit={this._feedBackSubmit}>
                                        <div className="rating-product">
                                            <span className={rating === 5?'active': ''} onClick={() => this.setState({ rating: 5})}>☆</span>
                                            <span className={rating === 4?'active': ''} onClick={() => this.setState({ rating: 4})}>☆</span>
                                            <span className={rating === 3?'active': ''} onClick={() => this.setState({ rating: 3})}>☆</span>
                                            <span className={rating === 2?'active': ''} onClick={() => this.setState({ rating: 2})}>☆</span>
                                            <span className={rating === 1?'active': ''} onClick={() => this.setState({ rating: 1})}>☆</span>
                                        </div>
                                        <textarea className="textarea-feedback" placeholder="Type something.." value={comments} onChange={(e) => this.setState({ comments: e.target.value })}></textarea>
                                        <Button type="submit" className="btn btn-primary">Submit</Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <RelatedProductSlider />
            </div>
        </>
    }
}