import React, {Component} from 'react'
import {Banner} from '../../widget'
import {base, brandInfo} from '../../constant/Data'
import {robotsOption, statusOption} from '../../constant/Enum'
import {MiscService, DataService, RouterService, MetaService} from '../../service'
import {BlogSideBar, Share} from '../../component'
import {Img} from '../../element'
import { mainUrl } from '../../constant/Data'
const dS = new DataService('project'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class Project extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeRobotsIndex: 0,
            activeStatusIndex: 0,
            image: '[]',
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            description: '',
            url: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            releaseDate: new Date(),
            status: '',
            tag: '',
        }
    }
    componentDidMount () {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        query = {
            slug: urlData[2]
        }
        this._getProjectData(query)
    }
    _getProjectData = async query => {
        const result = await dS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data[0].status !== 'release')
                rS.navigateTo('/error404')
            else {
                let projectData = result.data[0],
                metaKeyword = [],
                tag = [],
                activeRobotsIndex = 0,
                activeStatusIndex = 0
                robotsOption.forEach(( e, i ) => {
                    if (e.value === projectData.robots)
                        activeRobotsIndex = i
                })
                statusOption.forEach(( e, i ) => {
                    if (e.value === projectData.robots)
                        activeStatusIndex = i
                })
                if (projectData.metaKeyword === '""' || projectData.metaKeyword === '') {
                    metaKeyword = []
                } else {
                    JSON.parse(projectData.metaKeyword).forEach(e => {
                        metaKeyword.push(e)
                    })
                }
                if (projectData.tag === '""' || projectData.tag === '') {
                    tag = []
                } else {
                    JSON.parse(projectData.tag).forEach(e => {
                        tag.push(e)
                    })
                }
                mS.setTitle(projectData.title, 'public')
                this.setState({
                    title: projectData.title,
                    slug: projectData.slug,
                    description: projectData.description,
                    url: projectData.url,
                    canonical: projectData.canonical,
                    robots: projectData.robots,
                    metaDescription: projectData.metaDescription,
                    metaKeyword,
                    releaseDate: new Date(projectData.releaseDate),
                    status: projectData.status,
                    tag,
                    uid: projectData.uid,
                    image: projectData.image,
                    activeRobotsIndex,
                    activeStatusIndex
                })
            }
        }
    }
    render() {
        let { releaseDate, title, slug, description,  image } = this.state
        return <>
            <Banner title={title} image="white-car-banner.jpg" />
            <div className="blog-page jumbo-container">
                <div className="blog-page-content pt120 pb100">
                    <div className="blog-details-wrap">
                        <div className="blog-details-items">
                            <div className="content bg-none">
                                <h3 className="title">{title}</h3>
                                <div className="project-meta border-none pt20">
                                    <ul className="list-wrap">
                                        <li>
                                            <p className="author"><i className="hi-user1"></i>{brandInfo.name.capital}</p>
                                        </li>
                                        <li>
                                            <p className="author"><i className="hi-calendar1"></i> {miS.getFormattedDate(releaseDate)}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="thumb mt20">
                                <Img src={base.imageBaseUrl+JSON.parse(image)[0]} alt={title} />
                            </div>
                            <div className="blog-details-inner">
                                <div dangerouslySetInnerHTML={{__html: description}}></div>
                            </div>
                        </div>
                        <Share shareUrl={mainUrl+"/project/"+slug} />
                    </div>
                </div>
            </div>
        </>
    }
}