import React from 'react';
import {MiscService, RouterService, UserService} from '../../service'
import {AdminFooter, AdminHeader, AdminSidebar} from '../../widget'
const uS = new UserService(),
rS = new RouterService(),
miS = new MiscService()
const Admin = component => {
    const themeClass = miS.getThemeMode()
    document.getElementById('root').classList = 'admin '+themeClass
    if (uS.isAllowedAdmin)
        return <>
            <AdminSidebar />
            <div className="container">
                <AdminHeader />
                <div id="content-box" className="content">
                    {component}
                </div>
                <AdminFooter />
            </div>
        </>
    rS.navigateTo('/login')
    return
}

export default Admin