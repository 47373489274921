import React, {Component} from 'react'
import {MiscService, UserService, OrderService, RouterService, DataService, MetaService} from '../../../service'
import {Button} from '../../../element'
const dS = new DataService('log'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService()
export default class Otp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            otp: '',
            mobile: '',
            email: ''
        }
    }
    componentDidMount() {
        mS.setTitle('Otp', 'public')
    }
    _login = async e => {
        e.preventDefault()
        let { otp } = this.state,
        userType = 'old',
        error = false,
        errorMsg = '',
        otpData = JSON.parse(localStorage.getItem('otpData')),
        derivedOtp = otpData.otp,
        urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        derivedUid = otpData.uid,
        query = {}
        userType = atob(urlData[2]).substr(atob(urlData[2]).length - 3)
        if (userType === 'old') {
            query = {
                uid: derivedUid
            }
        }
        if (otp === '') {
            errorMsg = 'Please enter OTP'
            error = true
        }
        if (otp != derivedOtp) {
            errorMsg = 'OTP does not match'
            error = true
        }
        if (!error) {
            if (userType === 'new')
                rS.navigateTo('/register/'+urlData[2])
            else {
                const result = await uS.getUser({ query: JSON.stringify(query) })
                if (result.status) {
                    let userData = result.data[0]
                    let userObject = await uS.login(userData)
                    let logData = {
                        log: userObject.name+' logged in with website',
                        timing: new Date().getTime(),
                        type: 'success',
                        userId: userObject.uid,
                        uid: new Date().getTime()
                    }
                    dS.save(logData)
                    if (userObject !== null) {
                        let nextUrl = uS.isAllowedAdmin() ? '/admin/dashboard' : '/user/profile'
                        localStorage.removeItem('otpData')
                        if (oS.getCartData().length !== 0)
                            miS.proceedToCheckOut()
                        else
                            rS.navigateTo(nextUrl)
                    }
                } else miS.showAlert({ type: 'error', msg: 'Unable to uS.login!' })
            }
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _resendOtp = async () => {
        let error = false,
        errorMsg = '',
        urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        mobile= atob(urlData[2]).replace('new', '').replace('old', ''),
        userObject = {
            mobile
        }
        if (!error) {
            const result = await uS.sendOtp(userObject)
            if (result.status) {
                miS.showAlert({ type: 'info', msg: 'An OTP has been sent to your mobile number!' })
                let otpData = result.data[0]
                localStorage.setItem('otpData', JSON.stringify(otpData))
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to send OTP, try after some time.' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { mobile } = this.state
        return <div className="login-page">
            <div className="left-box-login left-box-login-otp"></div>
            <div className="body-background flex">
                <div className="mauto p30 max-width-loginBox">
                    <div className="loginIcon">
                        <i className='hi-keyboard'></i>
                    </div>
                    <h1 className="form-title tac">Please enter OTP received on your mobile {mobile}</h1>
                    <form onSubmit={this._login} noValidate className="relative">
                        <div className="form-group">
                            <input autoFocus onChange={(e) => this.setState({ otp: e.target.value })} className="input" type="number" name="otp" placeholder="Enter OTP" maxLength={6} minLength={6} max={100000} min={999999} />
                        </div>
                        <Button type="submit " className="width-full btn btn-primary mt30">Proceed</Button>
                        <span className="link resend-otp" onClick={this._resendOtp}> Resend OTP</span>
                    </form>
                </div>
                <i className="hi-arrow-circle-left footer-back-btn" onClick={() => {
                    rS.navigateTo('/login')
                }}></i>
            </div>
        </div>
    }
}