import React, {Component} from 'react'
import {UserService, MiscService, RouterService, DataService, MetaService} from '../../../service'
import Select from 'react-select'
import {Button, BackIcon} from '../../../element'
import { userRole } from '../../../constant/Enum'
import {Box, MediaBox} from '../../../component'
const lS = new DataService('log'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class AddUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            branchOptions: [],
            activeRoleIndex: 1,
            supervisor: '',
            supervisorOptions: [],
            email: '',
            name: '',
            role: '',
            parent: null,
            gstin: '',
            companies: [],
            mobile: '',
            uid: '',
            mode: 'Add',
            image: '[]',
        }
    }
    componentDidMount () {
        mS.setTitle('Add User', 'admin')
        this._getAllUsersData()
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[2] === 'account') {
            let userData = uS.getLoggedInUserData(),
            activeRoleIndex = 0
            userRole.forEach(( e, i ) => {
                if (e.value === userData.role)
                    activeRoleIndex = i
            })
            this.setState({
                email: userData.email,
                name: userData.name,
                role: userData.role,
                gstin: userData.gstin||'',
                mobile: userData.mobile,
                mode: 'Edit',
                uid: userData.uid,
                activeRoleIndex: activeRoleIndex,
                image: typeof userData.image === 'undefined'? '[]' : userData.image
            })
        } else if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getUserData(query)
        }
    }
    _getUserData = async query => {
        const result = await uS.getUser({ query: JSON.stringify(query) })
        if (result.status) {
            let userData = result.data[0],
            activeRoleIndex = 0
            userRole.forEach(( e, i ) => {
                if (e.value === userData.role)
                    activeRoleIndex = i
            })
            this.setState({
                email: userData.email,
                name: userData.name,
                role: userData.role,
                gstin: userData.gstin||'',
                mobile: userData.mobile,
                mode: 'Edit',
                uid: userData.uid,
                activeRoleIndex: activeRoleIndex,
                image: userData.image
            })
            if (['branch', 'representative', 'builder'].includes(userData.role))
                this.setState({ parent: userData.parent, supervisor: userData.supervisor !== 'undefined' && typeof userData.supervisor !== 'undefined' && userData.supervisor !== ''? JSON.parse(userData.supervisor): '' })
            else if (['manager', 'representative'].includes(userData.role))
                this.setState({ parent: userData.parent })
        } else miS.showAlert({ type: 'error', msg: 'No user added yet!' })
    }
    _getAllUsersData = async () => {
        let companies = [], supervisorOptions = [], branchOptions = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(e => {
                    let el = { label: e.name, value: e.uid }
                    if (e.role === 'company')
                        companies.push(el)
                    else if (e.role === 'supervisor')
                        supervisorOptions.push(el)
                    else if (e.role === 'branch')
                        branchOptions.push(el)
                })
                this.setState({ companies, supervisorOptions, branchOptions })
            }
        }
    }
    _addUser = async e => {
        e.preventDefault()
        let { email, name, role, gstin, mobile, uid, mode, image, parent, supervisor, company } = this.state,
        error = false,
        errorMsg = '',
        userObject = {
            email,
            name,
            role,
            gstin,
            mobile,
            image
        }
        if (role === 'branch') {
            userObject.supervisor = JSON.stringify(supervisor)
        }
        if (['branch', 'representative', 'builder'].includes(role)) {
            userObject.company = JSON.stringify(company)
        }
        if (email === '') {
            errorMsg = 'Please enter user email!'
            error = true
        } else if (name === '') {
            errorMsg = 'Please enter the Name'
            error = true
        } else if (role === '') {
            errorMsg = 'Please select the Role'
            error = true
        } else if (mobile === '') {
            errorMsg = 'Please enter Mobile Number'
            error = true
        } else if (gstin === '' && ['company', 'branch'].includes(role)) {
            errorMsg = 'Please enter GSTIN'
            error = true
        }
        if (['branch', 'manager', 'representative', 'builder'].includes(role)) {
            if (parent === null) {
                errorMsg = 'Please select a Parent Company'
                error = true
            } else {
                userObject.parent = parent
            }
        }
        if (!error) {
            if (mode === 'Add') {
                userObject.uid = new Date().getTime()
                const result = await uS.addUser(userObject)
                if (result.status) {
                    let logData = {
                        log: userObject.name+' added by admin with website',
                        timing: new Date().getTime(),
                        type: 'info',
                        userId: userObject.uid,
                        uid: new Date().getTime()
                    }
                    lS.save(logData)
                    miS.showAlert({ type: 'success', msg: 'User added successfully!' })
                    rS.navigateTo('/admin/user/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Mobile number already registered, please try loging in.' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    userData: JSON.stringify(userObject)
                }
                const result = await uS.updateUser(data)
                if (result.status) {
                    let logData = {
                        log: userObject.name+' updated by admin with website',
                        timing: new Date().getTime(),
                        type: 'info',
                        userId: userObject.uid,
                        uid: new Date().getTime()
                    }
                    lS.save(logData)
                    miS.showAlert({ type: 'success', msg: 'User updated successfully!' })
                    rS.navigateTo('/admin/user/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update user!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { supervisorOptions, supervisor, branchOptions, companies, parent, email, name, activeRoleIndex, gstin, mobile, mode, image, role } = this.state,
        urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        activeRole = userRole[activeRoleIndex],
        title = (urlData[2] === 'account') ? 'Profile' : mode + ' User'
        userRole.forEach(e => {
            if (e.value === role) {
                activeRole = e
            }
        })
        if (!image) {
            image = '[]'
        }
        return <>
            <h1>
                <BackIcon backLink='/admin/user/list' />
                {title}
            </h1>
            <form className="columns" noValidate onSubmit={this._addUser}>
                <div className="left">
                    <Box title='Name' required>
                        <input autoFocus type="text" required name="name" value={name||''} onChange={(e) => this.setState({ name: e.target.value })} />
                    </Box>
                    {
                        urlData[2] !== 'account' &&
                        <Box title='Role' required>
                            <Select
                                value={activeRole}
                                defaultValue={activeRole}
                                onChange={e => {
                                    this.setState({ role: e.value })
                                }}
                                options={userRole}
                            />
                        </Box>
                    }
                    {['manager'].includes(role) && <Box title='Parent Branch' required>
                        <Select
                            value={parent}
                            defaultValue={parent}
                            onChange={parent => this.setState({ parent })}
                            options={branchOptions}
                        />
                    </Box>}
                    {['branch', 'representative', 'builder'].includes(role) && <Box title='Parent Company' required>
                        <Select
                            value={parent}
                            defaultValue={parent}
                            onChange={parent => this.setState({ parent })}
                            options={companies}
                        />
                    </Box>}
                    <Box title='Email' required>
                        <input type="email" required name="email" value={email||''} onChange={(e) => this.setState({ email: e.target.value })} />
                    </Box>
                    {!['manager', 'representative'].includes(role) && <Box title='GSTIN' required={role==='company'}>
                        <input type="text" required name="gstin" value={gstin||''} onChange={(e) => this.setState({ gstin: e.target.value })} />
                    </Box>}
                    <Box title='Mobile No.' required>
                        <input type="number" required name="mobile" value={mobile||''} onChange={(e) => this.setState({ mobile: e.target.value })} />
                    </Box>
                </div>
                <div className="right">
                    <Box title='Meta'>
                        <Button className="btn-primary btn" type="submit" title="Click here to Add user">Submit</Button>
                    </Box>
                    <MediaBox image={image} setimage={image => this.setState({image})} />
                    {role === 'branch' && <Box title='Assign Supervisor'>
                        <Select
                            value={supervisor}
                            defaultValue={supervisor}
                            onChange={e => {
                                this.setState({ supervisor: e})
                            }}
                            options={supervisorOptions}
                        />
                    </Box>}
                </div>
            </form>
        </>
    }
}