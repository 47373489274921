export default class ValidationService {
    isValidJson = e => {
        try {
            JSON.parse(e)
            return e !== null && true
        } catch (e) {
            return false
        }
    }
    isValidMobile = mobile => {
        return 1000000000 <  Number(mobile) && Number(mobile) < 9999999999
    }
    isValidEmail = email => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(email)
    }
    isValidVehicleRegistrationNumber = regNo => {
        regNo = regNo.replace(/ /g,'').toUpperCase()
        let pattern1 = /[A-Z]{2}[0-9]{2}[A-Z][0-9]{2}\d{4}$/i,
            pattern2 = /[A-Z]{3}[A-Z]{1}\d{4}$/i,
            pattern3 = /[A-Z]{2}[0-9]{1}[0-9][A-Z]{2}\d{4}$/i,
            pattern4 = /[A-Z]{3}[A-Z]{1}\d{4}$/i,
            pattern5 = /[A-Z]{2}[0-9]{1}[1-9][A-Z]{2}\d{4}$/i,
            pattern6 = /[A-Z]{2}[0-9]{1}[A-Z]{3}\d{4}$/i,
            pattern7 = /[A-Z]{2}[0-9]{2}[A-Z]{2}\d{4}$/i,
            pattern8 = /[A-Z]{2}[0-9]{2}[0-9]{1}[A-Z]{1}\d{4}$/i,
            pattern9 = /[A-Z]{2}[0-9]{1}[A-Z]{2}\d{4}$/i,
            pattern10 = /[A-Z]{2}[0-9]{2}[A-Z]{1}\d{4}$/i,
            pattern11 = /[A-Z]{2}[0-9]{1}[A-Z]{2}[0-9]{1}\d{4}$/i,
            pattern12 = /[A-Z]{2}[0-9]{2}\d{4}$/i,
            pattern13 = /[0-9]{2}[A-Z]{2}[0-9]{4}[A-Z]{1}$/i,
            pattern14 = /[A-Z]{2}[A-Z]{1}[0-9]{4}$/i
        ;
        return (
            regNo.match(pattern1) !== null ||
            regNo.match(pattern2) !== null ||
            regNo.match(pattern3) !== null ||
            regNo.match(pattern4) !== null ||
            regNo.match(pattern5) !== null ||
            regNo.match(pattern6) !== null ||
            regNo.match(pattern7) !== null ||
            regNo.match(pattern8) !== null ||
            regNo.match(pattern9) !== null ||
            regNo.match(pattern10) !== null ||
            regNo.match(pattern11) !== null ||
            regNo.match(pattern12) !== null ||
            regNo.match(pattern13) !== null ||
            regNo.match(pattern14) !== null ||
            regNo.length === 17 // Is vehicle Vin Number
        )
    }
}