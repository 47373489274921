const routes = [
    {
        "pathName": "/event",
        "module": ["Event"],
        "component": "Event",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/event",
        "module": ["Event"],
        "component": "EventDetail",
        "type": "public",
        "mode": "dynamic"
    }
]
export default routes