import {Header, Footer} from "../../widget"

const Public = component => {
    return <>
        <Header />
        {component}
        <Footer />
    </>
}

export default Public