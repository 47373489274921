import React, {Component} from 'react'
import Select from 'react-select'
import {base, mainUrl} from '../../../constant/Data'
import {TrashConfirmModal, Table} from '../../../component'
import {Img, Button, Badge, Actor} from '../../../element'
import { b2bService } from '../../../constant/Enum'
import {DataService, MiscService, RouterService, MetaService, ProductService, ServiceService, MicroServiceService, ValidationService, UserService} from '../../../service'
const dS = new DataService('media'),
reS = new DataService('recommendation'),
coS = new DataService('companyOrder'),
uS = new UserService(),
sS = new ServiceService(),
mS = new MetaService(),
miS = new MiscService(),
micS = new MicroServiceService(),
vS = new ValidationService(),
rS = new RouterService(),
prS = new ProductService()
const commonPageSize = 50
export default class CompanyWorkListAdmin extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            assignWorkers: [],
            assignWorker: '',
            activeRecommendation: null,
            filteredWorkPostList: [],
            workPostList: b2bService,
            activeAction: '',
            uploadCount: 0,
            carImages: [],
            title: 'Business Work List',
            displayedRecommendationsList: [],
            searchPostKey: '',
            filteredPostList: [],
            fileObj: [],
            file: [],
            fileArray: [],
            comments: '',
            activeShownTotal: commonPageSize,
            owner: null,
            user: [],
            activeCar: null,
            orderData: [],
            image: '',
            filteredList: [],
            activeFilterIndex: 0,
            showTrashConfirm: false,
            searchList: [],
            recommendationList: [],
            activeOwnerId: false
        }
    }
    componentDidMount () {
        mS.setTitle('Business Work List', 'admin')
        this._getWorkersData()
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[4])
            this._getWorkData(urlData[4])
        else
            this._getWorkData()
        this._getProductsData()
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }

    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getProductsData = async () => {
        const result = await prS.getProducts()
        if (result.status) {
            if (result.data.length !== 0) {
                let product = []
                result.data.forEach(e => {
                    e.type = 'product'
                    product.push(e)
                })
                this._getServicesData(product)
            }
        }
    }
    _getServicesData = async filteredPostList => {
        const result = await sS.getServices()
        if (result.status) {
            if (result.data.length !== 0) {
                let serviceList = []
                result.data.reverse().forEach(e => {
                    e.type = 'service'
                    if (typeof e.trash === "undefined")
                        serviceList.push(e)
                    else if (!e.trash)
                        serviceList.push(e)
                })
                filteredPostList = [...filteredPostList, ...serviceList]
                this._getMicroServicesData(filteredPostList)
            }
        }
    }
    _getMicroServicesData = async (filteredPostList) => {
        const result = await micS.getMicroServices()
        if (result.status) {
            if (result.data.length !== 0) {
                let microServiceList = []
                result.data.reverse().forEach(e => {
                    e.type = 'microService'
                    if (typeof e.trash === "undefined")
                        microServiceList.push(e)
                    else if (!e.trash)
                        microServiceList.push(e)
                })
                filteredPostList = [...filteredPostList, ...microServiceList]
                this.setState({ recommendationList: filteredPostList, filteredPostList })
            }
        }
    }
    _getWorkData = async (uid = false) => {
        let query = {
            ownerId: uid
        }, result
        if (uid)
            result = await coS.fetchByAttr({ query: JSON.stringify(query) })
        else
            result = await coS.fetchAll()
        if (result.status) {
            if (result.data.length === 0)
                this.setState({ orderData: null })
            else {
                let orderData = []
                result.data.reverse().forEach(e => {
                    if (
                        uS.isAllowedAdmin() ||
                        (this.user.role === 'worker' && e.assignWorker && e.assignWorker.value === this.user.uid)
                    ) {
                        if (e.carDetails && e.carDetails !== "undefined" && vS.isValidJson(e.carDetails) && e.orderData && vS.isValidJson(e.orderData)) {
                            e.carDetails = vS.isValidJson(e.carDetails) ? JSON.parse(e.carDetails) : false
                            e.carImages = vS.isValidJson(e.carImages) ? JSON.parse(e.carImages) : false
                            e.orderData = vS.isValidJson(e.orderData) ? JSON.parse(e.orderData) : false
                            orderData.push(e)
                        }
                    }
                })
                this.setState({ orderData, filteredList: orderData })
            }
        }
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _trashEvent = async () => {
        let { activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        const result = await dS.delete({ query: JSON.stringify(query) })
        if (result.status) {
            this._getWorkData()
            miS.showAlert({ type: 'success', msg: 'Event deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to delete event!' })
    }
    _addWorkData = item => {
        this.setState({ activeCar: item, activeAction: 'work' }, () => miS.openModal('work-assign-modal'))
    }
    _pushRecommendation = async () => {
        let {displayedRecommendationsList, carImages, activeCar, comments} = this.state
        let recommendationData = {
            ownerId: activeCar.ownerId,
            uid: new Date().getTime(),
            carDetails: JSON.stringify(activeCar),
            comments,
            carImages: JSON.stringify(carImages),
            recommendData: JSON.stringify(displayedRecommendationsList)
        }
        const result = await reS.save(recommendationData)
        if (result.status) {
            miS.showAlert({type: 'success', msg: 'Recommendation added successfully!' })
            miS.closeModal('work-assign-modal')
            this.setState({ activeAction: '', activeCar: null, comments: '', fileArray: [], displayedRecommendationsList: [], searchKey: '' })
        }
    }
    _pushWorkData = async () => {
        let {carImages, activeRecommendation} = this.state
        let workData = {
            status: 'completed',
            comments: activeRecommendation.comments,
            carImages: JSON.stringify(carImages),
            uid: activeRecommendation.uid
        }
        let data = {
            query: JSON.stringify({uid: workData.uid}),
            companyOrderData: JSON.stringify(workData)
        }
        const result = await coS.update(data)
        if (result.status) {
            miS.showAlert({type: 'success', msg: 'Work data on car added successfully!' })
            miS.closeModal('work-done-modal')
            this.setState({ activeCar: null, comments: '', fileArray: [], displayedRecommendationsList: [], searchKey: '' })
        }
    }
    _chooseWorker = item => {
        this.setState({activeRecommendation: item}, () => {
            miS.openModal('work-assign-modal')
        })
    }
    _markComplete = item => {
        this.setState({activeRecommendation: item, activeCar: item.carDetails}, () => {
            miS.openModal('work-done-modal')
        })
    }
    _getTableData = filterData => {
        let data = []
        filterData.map(( e, index ) => {
            let car = e.carDetails
            let carData = typeof car.carData === 'string' ? JSON.parse(car.carData) : car.carData
            let product = e.orderData[0]
            let item = {
                orderId: '#'+e.uid,
                actionDate: miS.getFormattedDate(e.actionDate),
                carData: <div className='flex'>
                    {
                        carData?.image_path && <Img alt="Blank Placeholder" src={base.carImageBase+carData.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />
                    }
                    <div className='fs12'>
                        {(car.registrationNo).toUpperCase()}<br />
                        {carData.name+' '+carData.Segment}
                    </div>
                </div>,
                image: <div className='flex'>
                    {product.image && <Img src={base.imageBaseUrl+JSON.parse(product.image)[0]} alt={product.title} style={{ width: 50, borderRadius: 5, marginRight: 5, marginBottom: 'auto' }} />}
                    { product.title ? product.title : product.value }
                </div>,
                status: <Badge item={e.status} />,
                assignedWorker: e.status === 'assigned' && <p>{e.assignWorker.label}</p>,
                action: <div className="action-bar">
                    {
                        this.user.role !== 'worker' ? <>{
                            <>
                                <Actor type='person' onClick={() => this._chooseWorker(e)} />
                                <>
                                    {
                                        e.invoiceNo ?
                                        <Actor type='download' onClick={() => rS.navigateTo(base.invoiceBaseUrl+"/invoice_"+e.invoiceNo+".pdf", true)} /> : '' }
                                </>
                                <Actor type='person' onClick={() => this._chooseWorker(e)} />
                            </>
                        }</>:<Actor type='check' onClick={() => this._markComplete(e)} />
                    }
                </div>
            }
            data.push(item)
        })
        return data
    }
    _uploadFiles = async () => {
        let { file, carImages } = this.state
        file.forEach(e => {
            carImages.push(e.fileName)
        })
        for (let i = 0; i < file.length; i++) {
            await this._uploadCall(file[i])
            setTimeout(() => {
                if (i === file.length-1)
                    this.setState({ file: [], fileArray: [], carImages })
            }, 1000)
        }
    }
    _renderFilteredList = filteredList => {
        let {displayedRecommendationsList} = this.state
        return filteredList.map(( item, index ) => {
            let productimg = [], img = item.image ? JSON.parse(item.image): []
            img.forEach(e => {
                productimg.push(e)
            })
            return <li key={index} className="blog-product-search-box pointer" onClick={() => {
                item.uid = new Date().getTime()
                displayedRecommendationsList.push(item)
                this.setState({ displayedRecommendationsList: displayedRecommendationsList, filteredWorkPostList: [], filteredPostList: [], searchPostKey: '' })}}>
                {item.image ? <div className="flex">
                    <Img className="blog-product-search-img" src={
                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                        base.imageBaseUrl+'product-placeholder.png':
                        base.imageBaseUrl+productimg[0]} alt={item.title}
                    />
                    <div className="ml10 mb0">
                        <p className="m0">{item.title}</p>
                        {item.status && <><span className="black"><b>Item Code: </b><span className="gray">{item.sku}</span> </span> <span className="black ml10"> <b>Stock: </b> <span className="gray">{item.stock} </span></span></>}
                    </div>
                </div>:<div className='fixed-service'>{item.value}</div>}
            </li>
        })
    }
    _uploadCall = async file => {
        let _this = this, error = false, { fileArray, uploadCount } = this.state, errorMsg = ''
        if (!error) {
            file.uid = new Date().getTime()
            const result = await dS.save(file)
            if (result.status) {
                uploadCount++
                _this.setState({ uploadCount }, () => {
                    if (uploadCount === fileArray.length)
                        this.setState({ fileArray: [], file: [], uploadCount: 0 }, this._pushWorkData)
                })
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to add media!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _selectFiles = e => {
        let _this = this, fileArray = [], fileObj = []
        fileObj.push(e.target.files)
        this.setState({ fileObj }, async () => {
            for (let i = 0; i < _this.state.fileObj[0].length; i++) {
                const result = await miS.getBase64(_this.state.fileObj[0][i])
                let fileData = {
                    url: URL.createObjectURL(_this.state.fileObj[0][i]),
                    data: result,
                    type: _this.state.fileObj[0][i].type,
                    fileName: _this.state.fileObj[0][i].name,
                    size: _this.state.fileObj[0][i].size
                }
                if (fileData.size <= 4000000)
                    fileArray.push(fileData)
                else miS.showAlert({ type: 'error', msg: 'File is too large.' })
                _this.setState({ file: fileArray, fileArray: fileArray })
            }
        })
    }
    _searchPost = () => {
        let { searchPostKey, recommendationList, activeAction, workPostList } = this.state,
        tempTitle = '', filteredPostList = []
        if (activeAction === 'work')
            recommendationList = workPostList
        recommendationList.forEach(e => {
            searchPostKey = searchPostKey.toLowerCase()
            tempTitle = e.title ? e.title.toLowerCase() : e.value.toLowerCase()
            if (tempTitle.search(searchPostKey) !== -1)
                filteredPostList.push(e)
        })
        this.setState({ filteredPostList, filteredWorkPostList: filteredPostList })
    }
    _search = () => {
        let { searchKey, orderData } = this.state,
        tempTitle = '', filteredList = []
        orderData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.registrationNo.toLowerCase()
            if (tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList })
    }
    _getWorkersData = async () => {
        let tableDataWorkers = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No user added yet!' })
            else {
                result.data.forEach(result => {
                    if (result.role === 'worker') {
                        tableDataWorkers.push({label: result.name, value: result.uid})
                        this.setState({ assignWorkers: tableDataWorkers })
                    }
                })
            }
        }
    }
    _filterItems = e => {
        let {owner} = this.state
        return owner === null || (e.ownerId === owner.value)
    }
    _assignWorker = async () => {
        let {activeRecommendation, assignWorker} = this.state
        let taskData = {
            uid: activeRecommendation.uid,
            assignWorker: assignWorker,
            status: 'assigned'
        }
        let data = {
            query: JSON.stringify({uid: taskData.uid}),
            companyOrderData: JSON.stringify(taskData)
        }
        const result = await coS.update(data)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'Worker assigned to order updated successfully!' })
            this.setState({ activeRecommendation: null, assignWorker: '' }, () => {
                this._getWorkersData()
                miS.closeModal("work-assign-modal")
            })
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to assigne Worker!' })
    }
    render() {
        let { activeCar, fileArray, comments, assignWorkers, title, assignWorker, orderData, showTrashConfirm, filteredList, searchKey, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Date',
                    key: 'date'
                },
                {
                    label: 'Car',
                    key: 'car'
                },
                {
                    label: 'Product / Service',
                    key: 'productOrService'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Assigned Worker',
                    key: 'assignedWorker'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex">{title}</h1>
            <div className="filter-box">
                <div className="search-box mlauto">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: orderData, searchKey: '' }, this._search)}><i className="hi-close"></i></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={this._trashEvent} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} />
            <div className="modal" id="work-assign-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content medium">
                    <Button className="close" onClick={() => {
                        this.setState({ activeCar: null, comments: '', fileArray: [], displayedRecommendationsList: [], searchKey: '' })
                        miS.closeModal('work-assign-modal')}}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        <h4 className="head">Assign Worker</h4>
                        <div className='body overflown'>
                            <Select
                                value={assignWorker}
                                defaultValue={assignWorker}
                                onChange={assignWorker => this.setState({ assignWorker })}
                                options={assignWorkers}
                            />
                        </div>
                        <Button className="mb0 btn btn-success btn-box" onClick={this._assignWorker}>Submit</Button>
                    </div>
                </div>
            </div>
            <div className="modal" id="work-done-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content medium">
                    <Button className="close" onClick={() => {
                        this.setState({ activeCar: null, comments: '', fileArray: [], displayedRecommendationsList: [], searchKey: '' })
                        miS.closeModal('work-done-modal')}}><i className="hi-close"></i></Button>
                    {activeCar && <div className="modal-body p20">
                        <h3 className='lh1'>Car Details</h3>
                        <div className='flex'>
                            {
                                activeCar?.carData?.image_path ?
                                <Img alt="Blank Placeholder" src={base.carImageBase+activeCar?.carData?.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                                <p>No Image</p>
                            }
                            <div className='fs12'>
                                {activeCar?.registrationNo?.toUpperCase()}<br />
                                {activeCar?.carData?.name+' '+activeCar?.carData?.Segment}
                            </div>
                        </div>
                        <h3>Post Work Car Images</h3>
                        {
                            fileArray.length < 5 &&
                            <>
                                <label htmlFor="choose-file" className="btn btn-primary btn-small">Car Images</label>
                                <input type="file" className="hidden" onChange={this._selectFiles} multiple id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/jpg" />
                            </>
                        }
                        {
                            fileArray.length !== 0 &&
                            <div className="upload-box">
                                <ul className="media-box mb20">
                                    {(fileArray || []).map((file, index ) => (
                                        <li key={index}>
                                            <Img src={file.url} alt="..." />
                                        </li>
                                    ))}
                                </ul>
                                {fileArray .length >0 && <Button type="button" className="btn-outline btn btn-small" onClick={() => this.setState({ fileArray: [] })}>Reset</Button>}
                            </div>
                        }
                        <hr />
                        <h3 className='lh1'>Comments</h3>
                        <textarea className="comments" placeholder="Type your comments here" required name="comments" value={comments} onChange={(e) => this.setState({ comments: e.target.value })}></textarea>
                        <Button className="mb0 btn btn-success btn-box" onClick={this._uploadFiles}>Submit</Button>
                    </div>}
                </div>
            </div>
        </>
    }
}