import React, {Component} from 'react'
import {Button} from '../../element'
import {MiscService, OrderService, RouterService} from '../../service'
const miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService()
export default class SubscriptionData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeSubscription: '',
            purchaseGoldSubscription: true,
            purchaseSilverSubscription: true,
            silverInCart: false,
            goldInCart: false
        }
    }
    componentDidMount () {
        let cartData = JSON.stringify(oS.getCartData())
        if (cartData.includes('Silver Subscription')) {
            this.setState({ purchaseSilverSubscription: false, silverInCart: true })
        }
        if (cartData.includes('Gold Subscription')) {
            this.setState({ purchaseGoldSubscription: false, goldInCart: true })
        }
    }
    _purchase = purchaseType => {
        let price = 0,
        id = 0,
        startDate = new Date(),
        endDate = new Date()
        startDate.setDate(new Date().getDate() + 1)
        endDate.setDate(endDate.getDate() + 30)
        if (purchaseType === 'Silver') {
            price = 99
            id = 1
        } else {
            price = 249
            id = 3
        }
        price = String(price)
        let subscription = {
            uid: 'SUBSCRIPTION__00' + id,
            title: purchaseType+ ' Subscription',
            price,
            purchaseQuantity: 1,
            type: 'subscription',
            tempUid: new Date().getTime(),
            mrp: price,
            saleprice: price,
            tax: '18',
            hsn: '',
            sku: '',
            startDate,
            endDate
        }
        oS.addToCart(subscription)
        miS.showAlert({ type: 'success', msg: purchaseType+ ' Subscription added to cart successfully' })
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[1] === 'service') {
            miS.closeModal("get-subscription-modal")
        }
        if (purchaseType === 'Silver') {
            this.setState({ purchaseSilverSubscription: false, silverInCart: true, purchaseGoldSubscription: true, goldInCart: false })
        } else {
            this.setState({ purchaseGoldSubscription: false, goldInCart: true, purchaseSilverSubscription: true, silverInCart: false })
        }
    }
    render() {
        let { activeSubscription, purchaseSilverSubscription, purchaseGoldSubscription, silverInCart, goldInCart } = this.state, props = this.props
        if (typeof props.activeSubscription !== 'undefined')
            activeSubscription = props.activeSubscription
        return <div className="pricing-table-background">
            {
                activeSubscription !== '' && activeSubscription !== null &&
                <h1 className="plan-heading">Active Subscription</h1>
            }
            <div className="pricing-table-grid-box mt20 mb20">
                <div className="pricing-detail-box">
                    <div className="grid-Box">
                        <div className="upper-part">
                            <h1 className="plan-heading"><span className="blank">blank</span></h1>
                        </div>
                        <div className="middle-part">
                            <div className="features"> Services <i className="hi-arrow-circle-right"></i></div>
                            <div className="features"> Accessories <i className="hi-arrow-circle-right"></i></div>
                            <div className="features"> Parts / Lubes <i className="hi-arrow-circle-right"></i></div>
                            <div className="features"> Labour  <i className="hi-arrow-circle-right"></i></div>
                        </div>
                    </div>
                </div>
                <div className="pricing-table-grid pl0">
                    {
                        (activeSubscription === '' || activeSubscription === null) &&
                        <>
                            <div className="grid-Box pb20">
                                <div className="upper-part">
                                    <h1 className="plan-heading">SILVER</h1>
                                </div>
                                <div className="middle-part">
                                    <div className="features box11"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                                    <div className="features box12"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                                    <div className="features box13"> <div className="feature-details"> <i className="hi-check-circle"></i> NA </div></div>
                                    <div className="features box14"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                                </div>
                                    <p className="tac">(₹ 99 / MONTH)</p>
                                {
                                    purchaseSilverSubscription?
                                    <div className="bottom-part">
                                        <Button onClick={() => this._purchase('Silver')} className="btn btn-primary btn-box">Buy Now</Button>
                                    </div>:<>
                                        { silverInCart && <p className="tac">Already In the Cart</p>}
                                    </>
                                }
                            </div>
                            <div className="grid-Box grid-box-center">
                                <div className="upper-part">
                                    <h1 className="plan-heading">GOLD</h1>
                                </div>
                                <div className="middle-part">
                                    <div className="features box11"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                                    <div className="features box12"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                                    <div className="features box13"> <div className="feature-details"> <i className="hi-check-circle"></i> 5.00% </div></div>
                                    <div className="features box14"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                                </div>
                                    <p className="tac"> (₹ 249 / QUARTER)</p>
                                {
                                    purchaseGoldSubscription?
                                    <div className="bottom-part">
                                        <Button onClick={() => this._purchase('Gold')} className="btn btn-primary btn-box">Buy Now</Button>
                                    </div>:<>
                                        {goldInCart && <p className="tac">Already In the Cart</p>}
                                    </>
                                }
                                <div className="most-popular">Most Popular</div>
                            </div>
                        </>
                    }
                    {
                        activeSubscription === 'silver' ?
                        <div className="grid-Box pb20">
                            <div className="upper-part">
                                <h1 className="plan-heading">SILVER</h1>
                                <p>(₹ 99 / MONTH)</p>
                            </div>
                            <div className="middle-part">
                                <div className="features box11"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                                <div className="features box12"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                                <div className="features box13"> <div className="feature-details"> <i className="hi-check-circle"></i> NA </div></div>
                                <div className="features box14"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                            </div>
                            {
                                activeSubscription === '' &&
                                <div className="bottom-part">
                                    <Button onClick={() => this._purchase('Silver')} className="btn btn-primary btn-box">Buy Now</Button>
                                </div>
                            }
                        </div>
                        : activeSubscription === 'gold' &&
                        <div className="grid-Box grid-box-center">
                            <div className="upper-part">
                                <h1 className="plan-heading">GOLD</h1>
                                <p> (₹ 249 / QUARTER)</p>
                            </div>
                            <div className="middle-part">
                                <div className="features box11"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                                <div className="features box12"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                                <div className="features box13"> <div className="feature-details"> <i className="hi-check-circle"></i> 5.00% </div></div>
                                <div className="features box14"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                            </div>
                            {
                                activeSubscription === '' &&
                                <div className="bottom-part">
                                    <Button onClick={() => this._purchase('Gold')} className="btn btn-primary btn-box">Buy Now</Button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    }
}